import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import { Grid, Typography, Button, FormControlLabel, Switch } from '@mui/material';
import * as Sentry from '@sentry/react';
import { getPlatformSites } from '../Sites';
import { TW, GLOBAL, PLATFORMS, TASKS } from '../Constants';
import { parseCampaignName } from '../NameParser';
import WidgetSelector from '../WidgetSelector';
import { toast } from 'react-toastify';
import { constructWebsite } from '../ModifyOptions';
import CropWindow from '../CropWindow';
import CampaignsNote from '../CampaignsNote';
import TwitterCreativeGroupBuilder from './TwitterCreativeGroupBuilder';
import Prefix from '../Prefix';

import {
  storeFilesTwitter,
  createTwitterCampaign,
  getTwitterConversions,
  getTwitterFundingSources,
  getTwitterAccountByWidgetAndPrefix
} from '../Api';
import { getImageDimensions, truncateFileName, validateAndPrepareImage } from '../utils/imageUploadUtils';
import { normalizeCreative } from '../utils/creativesUtils';
import { getMaxCampaignIndex } from '../utils/commonUtils';
import {
  getFileHash,
  getNewCreatorValue,
  getSelectValue,
  makeSelectOptions,
  getOnlyValue,
  getMultiSelectValue
} from '../Shared';
import { convertToEST, convertToUTC } from '../utils/tzUtils';

class TwitterCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      osChoices: [],
      tooltips: { text: '', open: false },
      campaigns: [],
      conversions: [],
      foundingSources: [],
      conversionName: '',
      domainsToConversions: {},
      creatives: {},
      showCropWindow: false,
      creativeFromInsights: this.props.creativeFromInsights,
      creativeFromInsightsAdded: false,
      creativeFromFbLibrary: this.props.creativeFromFbLibrary,
      creativeFromFbLibraryAdded: false
    };

    if (this.props.loadedFromSnapshot) {
      this.state = { ...this.state, ...this.props.options };
    }

    this.spendingModelList = [
      {
        label: 'Standard',
        value: 'true'
      },
      {
        label: 'Accelerated',
        value: 'false'
      }
    ];
    this.biddingStrategyList = [
      {
        label: 'Target cost',
        value: 'TARGET'
      },
      {
        label: 'Automatic bid',
        value: 'AUTO'
      },
      {
        label: 'Maximum bid',
        value: 'MAX'
      }
    ];
    this.siteVisitsBiddingStrategyList = [
      {
        label: 'Automatic bid',
        value: 'AUTO'
      },
      {
        label: 'Maximum bid',
        value: 'MAX'
      }
    ];
  }

  setDefaultOptions() {
    this.props.modifyOptions(TW.KEY_BIDDING_STRATEGY, 'MAX');
    this.props.modifyOptions(TW.KEY_BUDGET, 20);
    this.props.modifyOptions(TW.KEY_BUDGET_TYPE, true);
    this.props.modifyOptions(TW.KEY_STATUS, TW.ACTIVE);
    this.props.modifyOptions(TW.KEY_CAMPAIGN_START_TIME, moment().tz('America/New_York'));
    this.props.modifyOptions(TW.KEY_GEO_DUPLICATION_TYPE, 'separate');
    this.props.modifyOptions(TW.KEY_DEVICE_DUPLICATION_TYPE, 'separate');
    this.props.modifyOptions(TW.KEY_GOAL, 'SITE_VISITS');
    this.modifyOptions(TW.KEY_BID, TW.DEFAULT_BID);
    this.props.modifyOptions(TW.KEY_FULL_SITE, this.getFullSite('trm'));
    this.modifyOptions(TW.KEY_SITE, ['trm']);
    this.addCreativeGroup();
    this.modifyOptions(TW.KEY_COUNTRY, ['us']);
    this.modifyOptions(TW.KEY_CREATOR, getNewCreatorValue(this.props.userMail, this.props.creatorsList));
    this.modifyOptions(TW.KEY_OBJECTIVE, 'standard');
  }

  async componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      this.setDefaultOptions();
    } else {
      getTwitterConversions(this.props.options[TW.KEY_DESTINATION_ACCOUNT])
        .then(res => {
          res = (res || [])
            .map(el => {
              return { label: el.name, value: el.id };
            })
            .filter(
              el =>
                el.label.toLowerCase().startsWith('visit') ||
                el.label.toLowerCase().includes('site visit all sites') ||
                el.label.toLowerCase().startsWith('site visits') ||
                el.label.toLowerCase().startsWith('landing page views') ||
                el.label.toLowerCase().startsWith('all sites ')
            );
          this.setState({ conversions: res }, () => {
            let similarConversion = res.find(el => el.label === this.state.conversionName);
            if (similarConversion) {
              this.props.modifyOptions(TW.KEY_CONVERSION, similarConversion.value);
            } else {
              this.props.modifyOptions(TW.KEY_CONVERSION, '');
            }
          });
          return getTwitterFundingSources(this.props.options[TW.KEY_DESTINATION_ACCOUNT]);
        })
        .then(res => {
          if (!res) return;

          res = res.map(el => {
            return { label: el.description, value: el.id };
          });
          this.setState({ foundingSources: res });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.task !== prevProps.task && this.props.task === TASKS.CREATE) {
      this.setDefaultOptions();
    }

    if (this.props.creative) {
      this.props.creativeDone();
      let parsedName = {};
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);
      this.modifyOptions(TW.KEY_COUNTRY, parsedName.country || '');
      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(TW.KEY_DEVICE, TW.DEVICES_LIST[3].value);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(TW.KEY_DEVICE, TW.DEVICES_LIST.map(x => x.value).join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(TW.KEY_DEVICE, TW.DEVICES_LIST[2].value);
      } else {
        if (parsedName.platform && parsedName.platform.indexOf('adr') !== -1) {
          this.modifyOptions(TW.KEY_DEVICE, TW.DEVICES_LIST[1].value);
        }
        if (parsedName.platform && parsedName.platform.indexOf('ios') !== -1) {
          this.modifyOptions(TW.KEY_DEVICE, TW.DEVICES_LIST[0].value);
        }
      }

      const creative = normalizeCreative(this.props.creative);
      this.addCreatives([
        {
          images: creative.image_url,
          texts: this.props.creative.platform === 'taboola' ? creative.title : creative.text,
          titles: creative.title,
          tooltips: []
        }
      ]);
    }

    if (this.props.creativeFromInsights && !this.state.creativeFromInsightsAdded) {
      let creative = this.props.creativeFromInsights;
      const xs = [
        {
          images: [creative.image_url],
          titles: [creative.title.slice(0, TW.MAX_TITLE_CHARS)],
          texts: [creative.text.slice(0, TW.MAX_TEXT_CHARS)]
        }
      ];
      if (this.props.state.campaignCreator) {
        this.modifyOptions(TW.KEY_CREATOR, this.props.state.campaignCreator);
      }
      this.setState({ creativeFromInsightsAdded: true }, () => this.setState({ showCropWindow: true, creatives: xs }));
    }

    if (this.props.creativeFromFbLibrary && !this.state.creativeFromFbLibraryAdded) {
      let creative = this.props.creativeFromFbLibrary;
      const xs = [
        {
          images: creative[0].images,
          titles: creative[0].titles.slice(0, TW.MAX_TITLE_CHARS),
          texts: creative[0].texts.slice(0, TW.MAX_TEXT_CHARS)
        }
      ];

      this.setState({ creativeFromFbLibraryAdded: true }, () => this.setState({ showCropWindow: true, creatives: xs }));
    }
  }

  getBrandingText(domain) {
    const site = getPlatformSites(PLATFORMS.TWITTER.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.branding;
    } else {
      throw new Error("Can't find site corresponding to 3-letter domain " + domain);
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.TWITTER.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
  }

  validateOptions(key) {
    if (key === TW.KEY_DEFAULT_BID || key === TW.KEY_BID) {
      const value = this.props.state.options[key];
      if (value < TW.MIN_BID) {
        console.log('Min bid reached');
        this.props.modifyOptions(key, TW.MIN_BID);
        return;
      }
      if (value > TW.MAX_BID) {
        console.log('Max bid reached');
        this.props.modifyOptions(key, TW.MAX_BID);
        return;
      }
    }
    if (key === TW.KEY_BUDGET) {
      const value = this.props.state.options[key];
      if (value < TW.MIN_BUDGET_LIMIT) {
        console.log('Min budget reached');
        this.props.modifyOptions(key, TW.MIN_BUDGET_LIMIT);
        return;
      }
      if (value > TW.MAX_BUDGET_LIMIT) {
        console.log('Max budget reached');
        this.props.modifyOptions(key, TW.MAX_BUDGET_LIMIT);
        return;
      }
    }
  }

  modifyOptions(key, value) {
    if (
      key === TW.KEY_BUDGET ||
      key === TW.KEY_DEFAULT_BID ||
      key === TW.KEY_PREFIX ||
      key === 'campName' ||
      key === TW.KEY_GEO_NAME ||
      key === TW.KEY_WEBSITE ||
      key === TW.KEY_BID
    ) {
      // Most probably, it's an "input" element
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === TW.KEY_DESTINATION_ACCOUNT) {
      getTwitterConversions(value)
        .then(res => {
          res = (res || [])
            .map(el => {
              return { label: el.name, value: el.id };
            })
            .filter(
              el =>
                el.label.toLowerCase().startsWith('visit') ||
                el.label.toLowerCase().startsWith('site visit all sites') ||
                el.label.toLowerCase().startsWith('site visits') ||
                el.label.toLowerCase().startsWith('landing page views') ||
                el.label.toLowerCase().startsWith('all sites ')
            );
          this.setState({ conversions: res }, () => {
            let similarConversion = res.find(el => el.label === this.state.conversionName);
            if (similarConversion) {
              this.props.modifyOptions(TW.KEY_CONVERSION, similarConversion.value);
            }
          });
          return getTwitterFundingSources(value);
        })
        .then(res => {
          if (!res) return;

          res = res.map(el => {
            return { label: el.description, value: el.id };
          });
          this.setState({ foundingSources: res }, () => {
            let accountName =
              value === 'artificial_tup'
                ? 'tup'
                : value === 'artificial_mmo'
                ? 'mmo'
                : value === 'artificial_nmi'
                ? 'nmi'
                : value === 'artificial_spn'
                ? 'spn'
                : value === 'artificial_esc'
                ? 'esc'
                : value === 'artificial_tsr'
                ? 'tsr'
                : value === 'artificial_tdw'
                ? 'tdw'
                : value === 'artificial_yrb'
                ? 'yrb'
                : value === 'artificial_sds'
                ? 'yrb'
                : this.props.state.accounts.find(el => el.id === value).desc.replace(/\s/g, '');
            // const accountId = this.props.state.accounts.find(el => el.id === value).id;
            // const domain = accountName.slice(0, 3);
            // this.fillDomainsToConversion(domain);
            // this.modifyOptions(TW.KEY_SITE, [domain]);
            // this.props.modifyOptions(TW.KEY_FULL_SITE, this.getFullSite(domain));
            //Do not need to set domain here from account name
            let options = this.props.state.options;
            this.props.modifyOptions(TW.KEY_WEBSITE, this.updateWebsite(options));
            const foundingSource = res.find(el => el.label === TW.DEFAULT_FOUNDING_SOURCE);
            if (foundingSource) {
              this.props.modifyOptions(TW.KEY_FOUNDING_SOURCE, foundingSource.value);
            }
          });
        });
      this.props.modifyOptions(TW.KEY_DESTINATION_ACCOUNT, value);
    }
    this.props.modifyOptions(key, value);
    if (key === TW.KEY_SITE) {
      if (value === 'esc') {
        this.props.modifyOptions('widgetVersion', value);
      }
      const fullSite = this.getFullSite(value);
      this.props.modifyOptions(TW.KEY_FULL_SITE, fullSite);
      const domains = value;
      domains.forEach(dom => {
        this.fillDomainsToConversion(dom);
      });
    }
    if (key === TW.KEY_DEVICE) {
      let devices = value;
      if (typeof devices === 'string') {
        devices = devices.split(',');
      }
      let newValue = [];
      this.props.modifyOptions(TW.KEY_OS, newValue);
      this.setState({
        osChoices: newValue
      });
      let conversion = null;
      if (value === TW.IOS_DEVICE) {
        this.props.modifyOptions(TW.KEY_BUDGET, 3);
        this.props.modifyOptions(TW.KEY_BID, 0.07);
        conversion = this.state.conversions.find(el => el.label.includes('visit 0.03'));
      }
      if (value === TW.ANDROID_DEVICE) {
        this.props.modifyOptions(TW.KEY_BUDGET, 8);
        this.props.modifyOptions(TW.KEY_BID, 0.13);
        conversion = this.state.conversions.find(el => el.label.includes('visit 0.07'));
      }
      if (value === TW.DESKTOP_DEVICE) {
        this.props.modifyOptions(TW.KEY_BUDGET, 8);
        this.props.modifyOptions(TW.KEY_BID, 0.13);
        conversion = this.state.conversions.find(el => el && el.label && el.label.includes('visit 0.09'));
      }
      if (conversion) {
        this.setState({ conversionName: conversion.label });
        this.props.modifyOptions(TW.KEY_CONVERSION, conversion.value);
      }
    }
    if (key === TW.KEY_COUNTRY) {
      let countries = value;
      if (typeof countries !== 'string') {
        countries = countries.join(',');
      }
      this.props.modifyOptions(TW.KEY_GEO_NAME, countries);
    }
    if (
      key === TW.KEY_COUNTRY ||
      key === TW.KEY_DEVICE ||
      key === TW.KEY_OS ||
      key === TW.KEY_SITE ||
      key === TW.KEY_GEO_DUPLICATION_TYPE
    ) {
      // Update bid rows
      //this.modifyBidsRows();
    }
    if (key === TW.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
    if (
      key === GLOBAL.KEY_WIDGET_CODE_NAME ||
      key === GLOBAL.KEY_WIDGET_LANGUAGE_CODE ||
      key === GLOBAL.KEY_WIDGET_VERSION ||
      key === TW.KEY_SITE ||
      key === TW.KEY_PREFIX ||
      key === 'article'
    ) {
      if (
        (this.props.state.options.widgetid &&
          (key === GLOBAL.KEY_WIDGET_CODE_NAME ||
            key === GLOBAL.KEY_WIDGET_LANGUAGE_CODE ||
            key === GLOBAL.KEY_WIDGET_VERSION)) ||
        (key === TW.KEY_PREFIX &&
          this.props.state.options[TW.KEY_PREFIX].length === 2 &&
          this.props.state.options.widgetid) ||
        (key === 'article' && this.props.state.options.article.target.value)
      ) {
        getTwitterAccountByWidgetAndPrefix(
          this.props.state.options.widgetid,
          this.props.state.options[TW.KEY_PREFIX]
        ).then(res => {
          this.modifyOptions(TW.KEY_DESTINATION_ACCOUNT, res);
        });
      }
      this.props.modifyOptions(TW.KEY_WEBSITE, this.updateWebsite(this.props.state.options));
      const conversionObj = this.state.conversions.find(el => el.value === this.props.state.options[TW.KEY_CONVERSION]);
      if (conversionObj) {
        this.setState({ conversionName: conversionObj.label });
      }
    }
    if (key === TW.KEY_CONVERSION) {
      const conversionName = this.state.conversions.find(el => el.value === value);
      this.setState({ conversionName: conversionName });
    }
    if (key === TW.KEY_CAMPAIGN_START_TIME) {
      this.props.modifyOptions(TW.KEY_CAMPAIGN_START_TIME, moment(value).tz('America/New_York'));
    }
    if (key === TW.KEY_CAMPAIGN_STOP_TIME) {
      this.props.modifyOptions(TW.KEY_CAMPAIGN_STOP_TIME, moment(value).tz('America/New_York'));
    }
  }

  fillDomainsToConversion(domain) {
    let domainsToConversions = this.state.domainsToConversions;
    if (!domainsToConversions[domain]) {
      const artificialDomains = ['tup', 'mmo', 'nmi', 'spn', 'esc', 'tsr', 'tdw', 'yrb', 'sds'];
      let account =
        artificialDomains.includes(domain) || !domain
          ? this.props.state.options[TW.KEY_DESTINATION_ACCOUNT]
          : TW.DOMAIN_TO_ACCOUNT[domain];
      getTwitterConversions(account)
        .then(res => {
          res = (res || [])
            .map(el => {
              return { label: el.name, value: el.id };
            })
            .filter(
              el =>
                el.label.toLowerCase().startsWith('visit') ||
                el.label.toLowerCase().startsWith('site visit all sites') ||
                el.label.toLowerCase().startsWith('site visits') ||
                el.label.toLowerCase().startsWith('landing page views') ||
                el.label.toLowerCase().startsWith('all sites ')
            );
          domainsToConversions[domain] = {};
          domainsToConversions[domain].conversions = res;
          return getTwitterFundingSources(account);
        })
        .then(res => {
          if (!res) return;

          res = res.map(el => {
            return { label: el.description, value: el.id };
          });
          domainsToConversions[domain].foundingSources = res;
          this.setState({
            domainsToConversions: domainsToConversions
          });
        });
    }
  }

  modifyBidsRows() {
    if (
      !this.props.state.options[TW.KEY_COUNTRY] ||
      !this.props.state.options[TW.KEY_DEVICE] ||
      !this.props.state.options[TW.KEY_SITE]
    ) {
      return;
    }
    // for some reason some of the values are arrays, some are strings
    //* in Select input - value type is string, when select all - array */
    let geos = this.props.state.options[TW.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    let devices = this.props.state.options[TW.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }
    let oss = this.props.state.options[TW.KEY_OS];
    if (typeof oss === 'string') {
      oss = oss.split(',');
    }
    let sites = this.props.state.options[TW.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    if (this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'group') {
      // This geos should be treated like a group
      geos = [geos.join(',')];
    }
    let newBids = [];
    let oldBids = this.props.state.options[TW.KEY_BIDS];
    geos.forEach(geo => {
      sites.forEach(site => {
        devices.forEach(dev => {
          // special handling of DESKTOP, no OSs here
          newBids.push({
            country: geo,
            device: dev,
            site: site,
            fullSite: this.getFullSite(site),
            branding_text: this.getBrandingText(site),
            bid: this.props.state.options[TW.KEY_DEFAULT_BID]
          });
        });
      });
    });
    newBids = newBids.map(newBid => {
      const old = oldBids.find(
        el =>
          el.country === newBid.country && el.device === newBid.device && el.os === newBid.os && el.site === newBid.site
      );
      if (old) {
        newBid.bid = old.bid;
      }
      return newBid;
    });
    this.props.modifyOptions(TW.KEY_BIDS, newBids);
  }

  modifyBid(idx, e) {
    const bid = e.target.value;
    let bids = this.props.state.options[TW.KEY_BIDS];
    bids[idx].bid = bid;
    this.props.modifyOptions(TW.KEY_BIDS, bids);
    let warningBids = bids.filter(el => {
      return el.bid > TW.MAX_WARN_BID;
    });
    this.props.modifyOptions('warnings', warningBids);
  }

  validateBid(idx) {
    let bids = this.props.state.options[TW.KEY_BIDS];
    const bid = bids[idx].bid;
    if (bid < TW.MIN_BID) {
      bids[idx].bid = TW.MIN_BID;
      this.props.modifyOptions(TW.KEY_BIDS, bids);
    } else if (bid > TW.MAX_BID) {
      bids[idx].bid = TW.MAX_BID;
      this.props.modifyOptions(TW.KEY_BIDS, bids);
    }
  }

  setDefaultBid(val) {
    let bids = this.props.state.options[TW.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < TW.MIN_BID) {
        b.bid = TW.MIN_BID;
      } else if (val > TW.MAX_BID) {
        b.bid = TW.MAX_BID;
      }
    });
    this.props.modifyOptions(TW.KEY_BIDS, bids);
  }

  validateCreativeGroups() {
    let errors = [];
    const groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    groups.forEach((gr, groupIdx) => {
      // Check empty groups
      if (gr.images.length === 0) {
        errors.push(`Group ${groupIdx + 1} is empty.`);
      }
      gr.images.forEach((image, imageIdx) => {
        if (!gr.texts[imageIdx]) {
          errors.push(`Group ${groupIdx + 1}, creative ${imageIdx + 1} text is empty.`);
        }
        if (!gr.titles[imageIdx]) {
          errors.push(`Group ${groupIdx + 1}, creative ${imageIdx + 1} headline is empty.`);
        }
      });
    });
    if (errors.length === 0) {
      return {
        isValid: true
      };
    } else {
      return {
        isValid: false,
        data: errors
      };
    }
  }

  onAddImages(groupIdx, imageBlob, deviceType, index) {
    const images = [imageBlob];
    const errs = [];
    const warnings = [];

    const formData = new FormData();

    let basicValidatedImages = [];
    let hashes = [];
    let targetingDevice = '';
    const device = deviceType;
    let options = {};
    if (this.props.state.options.device) {
      if (typeof this.props.state.options.device === 'string') {
        targetingDevice = this.props.state.options.device;
      } else if (this.props.state.options.device[0]) {
        targetingDevice = this.props.state.options.device[0];
      }
    }

    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        if (device) {
          images.forEach((file, i) => {
            const filename_truncated = truncateFileName(file.name);

            if (
              !validateAndPrepareImage(
                file,
                filename_truncated,
                dimensions[i],
                groupIdx,
                'TW',
                warnings,
                errs,
                device,
                options
              )
            ) {
              return;
            }
            formData.append(i, file);
            basicValidatedImages.push(file);
          });
        } else {
          warnings.push('Please, select the targeting device first.');
        }

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(calculatedHashes => {
        hashes = calculatedHashes;
        if (errs.length) {
          errs.forEach(err => {
            console.log(err);
            toast.error(err);
          });
          return;
        }
        warnings.forEach(w => {
          toast.warn(w);
        });
        return storeFilesTwitter(formData, options);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
          if (!groups[groupIdx].images[index]) {
            groups[groupIdx].images[index] = {};
          }
          groups[groupIdx].images[index].image = filenames[0];
          groups[groupIdx].imageHashes = groups[groupIdx].imageHashes.concat(hashes);
          groups[groupIdx].device = targetingDevice;
          this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  onChangeImageText(groupIdx, imageIdx, type, e) {
    const text = e.target.value;
    let maxLength = null;
    if (type === 'texts') {
      maxLength = TW.MAX_TEXT_CHARS;
    } else if (type === 'titles') {
      maxLength = TW.MAX_TITLE_CHARS;
    }
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    groups[groupIdx][type][imageIdx] = text;
    groups[groupIdx].tooltips[imageIdx] = {
      text: `Chars left: ${maxLength - text.length}`,
      open: true
    };
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  onBlurImageText(groupIdx, imageIdx) {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    groups[groupIdx].tooltips[imageIdx] = {
      open: false
    };
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  onPasteImageText(groupIdx, imageIdx, type, e) {
    const text = e.clipboardData.getData('Text');
    let maxLength = null;
    if (type === 'texts') {
      maxLength = TW.MAX_TEXT_CHARS;
    } else if (type === 'titles') {
      maxLength = TW.MAX_TITLE_CHARS;
    }
    if (text.length > maxLength) {
      toast.error(`Pasted text too long: ${text.length} chars. Truncating.`);
    }
  }

  removeImage(groupIdx, imageIdx) {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.splice(imageIdx, 1);
    groups[groupIdx].texts.splice(imageIdx, 1);
    groups[groupIdx].titles.splice(imageIdx, 1);
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateImage(groupIdx, imageIdx) {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    const duplicatedImage = groups[groupIdx].images[imageIdx];
    const duplicatedTitle = groups[groupIdx].titles[imageIdx];
    const duplicatedText = groups[groupIdx].texts[imageIdx];
    groups[groupIdx].images.push(duplicatedImage);
    groups[groupIdx].titles.push(duplicatedTitle);
    groups[groupIdx].texts.push(duplicatedText);
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(JSON.parse(JSON.stringify(groups[groupIdx])));
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.forEach((_, idx) => {
      groups[groupIdx].texts[idx] = groups[groupIdx].texts[imageIdx];
      groups[groupIdx].titles[idx] = groups[groupIdx].titles[imageIdx];
    });
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  addCreativeGroup() {
    let groups = this.props.state.options[TW.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      images: [],
      imageHashes: [],
      texts: [],
      tooltips: [],
      titles: []
    });
    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, groups);
  }

  async addCreatives(xs, images) {
    const adset = { images: [], titles: [], texts: [] };

    for (const [adsetIndex, x] of xs.entries()) {
      for (const text of x.texts) {
        for (const title of x.titles) {
          for (const [imageIndex] of x.images.entries()) {
            const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
            const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
            for (const crop of nonEmpyCrops) {
              adset.images.push(crop);
              adset.texts.push(text);
              adset.titles.push(title);
            }
          }
        }
      }
    }

    const newGroup = {
      ...adset,
      images: [],
      imageHashes: [],
      tooltips: []
    };

    this.modifyOptions(TW.KEY_CREATIVE_GROUPS, [newGroup]);

    setTimeout(async () => {
      for (const [imgIndex, img] of adset.images.entries()) {
        const blob = img.blob;
        blob.name = img.url.split('/').pop();
        this.onAddImages(0, blob, img.type, imgIndex);
      }
    }, 100);
  }

  updateOptionsForMultipleDomains(options, site) {
    options = JSON.parse(JSON.stringify(options));
    const fullSite = this.getFullSite(site);
    options[TW.KEY_SITE] = [site];
    options[TW.KEY_FULL_SITE] = fullSite;
    return options;
  }

  updateOptionsForMultipleDevices(options, device) {
    options = JSON.parse(JSON.stringify(options));
    options[TW.KEY_DEVICE] = [device];
    return options;
  }

  getConversionName(name) {
    const baseName = typeof name === 'string' ? name.toLowerCase() : name && name.label && name.label.toLowerCase();
    if (baseName.includes('landing page views')) {
      return 'landing_page_views';
    } else if (baseName.includes('site visits')) {
      return 'site_visits';
    } else if (baseName.includes('visit all sites')) {
      return 'site_visits_account';
    }
  }

  prepareOptions() {
    let options = JSON.parse(JSON.stringify(this.props.state.options));
    options.conversion_name = this.getConversionName(this.state.conversionName);
    let separateCampaigns = [];
    if (options[TW.KEY_CAMPAIGN_START_TIME]) {
      let startDate = options[TW.KEY_CAMPAIGN_START_TIME];
      //user is providing the time in their local timezone, but it should create at the same date and time but EST zone.
      //On server, we can send only UTC, so we convert from local to EST and from EST to UTC https://shinez.slack.com/archives/G018P55DT2T/p1705913743825769
      options[TW.KEY_CAMPAIGN_START_TIME] = convertToUTC(convertToEST(startDate));
    }

    if (options[TW.KEY_CAMPAIGN_STOP_TIME]) {
      let endDate = options[TW.KEY_CAMPAIGN_STOP_TIME];
      options[TW.KEY_CAMPAIGN_STOP_TIME] = convertToUTC(convertToEST(endDate));
    }
    for (let key in options) {
      if (!options[key]) {
        delete options[key];
      }
    }
    if (typeof options[TW.KEY_COUNTRY] === 'string') {
      options[TW.KEY_COUNTRY] = options[TW.KEY_COUNTRY].split(',');
    }
    if (typeof options[TW.KEY_DEVICE] === 'string') {
      options[TW.KEY_DEVICE] = options[TW.KEY_DEVICE].split(',');
    }
    if (typeof options[TW.KEY_SITE] === 'string') {
      options[TW.KEY_SITE] = options[TW.KEY_SITE].split(',');
    }
    const countries = options[TW.KEY_COUNTRY];
    const sites = options[TW.KEY_SITE];
    const devices = options[TW.KEY_DEVICE];
    if (options[TW.KEY_GEO_DUPLICATION_TYPE] === 'separate' && countries.length > 1) {
      countries.forEach(country => {
        sites.forEach(site => {
          const updatedOptions = this.updateOptionsForMultipleDomains(options, site);
          updatedOptions[TW.KEY_COUNTRY] = [country];
          if (options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate' && devices.length > 1) {
            devices.forEach(device => {
              const updatedByDeviceOptions = this.updateOptionsForMultipleDevices(updatedOptions, device);
              separateCampaigns.push(JSON.parse(JSON.stringify(updatedByDeviceOptions)));
            });
          } else {
            separateCampaigns.push(updatedOptions);
          }
        });
      });
    } else {
      sites.forEach(site => {
        const updatedOptions = this.updateOptionsForMultipleDomains(options, site);
        if (options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate' && devices.length > 1) {
          devices.forEach(device => {
            const updatedByDeviceOptions = this.updateOptionsForMultipleDevices(updatedOptions, device);
            separateCampaigns.push(JSON.parse(JSON.stringify(updatedByDeviceOptions)));
          });
        } else {
          separateCampaigns.push(updatedOptions);
        }
      });
    }
    if (
      this.props.state.options[TW.KEY_CREATIVE_GROUPS] &&
      this.props.state.options[TW.KEY_CREATIVE_GROUPS].length > 1
    ) {
      return separateCampaigns
        .map(camp => {
          return this.props.state.options[TW.KEY_CREATIVE_GROUPS].map(group => {
            camp[TW.KEY_CREATIVE_GROUPS] = [group];
            return JSON.parse(JSON.stringify(camp));
          });
        })
        .flat();
    } else {
      return separateCampaigns;
    }
  }

  upsertSnapshot() {
    this.props.upsertSnapshot(this.props.state.options);
  }

  showOptions() {
    const options = this.prepareOptions();
    console.log({ options });
  }

  startCreateCampaign() {
    const options = this.prepareOptions();
    console.log(options);
    createTwitterCampaign(this.props.state.options[TW.KEY_DESTINATION_ACCOUNT], options, this.props.session, false);
  }

  getCampaignsNumber() {
    let countries = this.props.state.options[TW.KEY_COUNTRY];
    let sites = this.props.state.options[TW.KEY_SITE];
    let devices = this.props.state.options[TW.KEY_DEVICE];
    let creativeGroups = this.props.state.options[TW.KEY_CREATIVE_GROUPS];
    countries = typeof countries === 'string' ? countries.split(',') : countries;
    sites = typeof sites === 'string' ? sites.split(',') : sites;
    devices = typeof devices === 'string' ? devices.split(',') : devices;
    if (countries && countries.length > 1 && this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'separate') {
      if (devices && devices.length > 1 && this.props.state.options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate') {
        return countries.length * sites.length * devices.length * creativeGroups.length;
      }
      return countries.length * sites.length * creativeGroups.length;
    } else {
      if (devices && devices.length > 1 && this.props.state.options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate') {
        return sites.length * devices.length * creativeGroups.length;
      }
      return sites.length * creativeGroups.length;
    }
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    let sites = this.props.state.options[TW.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (TW.SITE_ALLOWED_VERSIONS[s]) {
        return TW.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return TW.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    let res = parts.filter(el => el).join('-') + ' = ' + bid.bid;
    console.log(res);
    return res;
  }

  validateSetup() {
    const options = this.props.state.options;
    console.log({ options });
    // Check that we have campaign to create
    if (!options[GLOBAL.KEY_WIDGET_CODE_NAME]) {
      const err = 'There is no selected widget code name';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE]) {
      const err = 'There is no selected widget language code';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[GLOBAL.KEY_WIDGET_VERSION]) {
      const err = 'There is no selected widget version';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_DESTINATION_ACCOUNT]) {
      const err = 'There is no selected destination account';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_PREFIX]) {
      const err = 'There is no selected prefix';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_CREATOR]) {
      const err = 'There is no selected creator';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_OBJECTIVE]) {
      const err = 'There is no selected objective';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_COUNTRY] && !options[TW.KEY_COUNTRY].length) {
      const err = 'There is no selected country';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_GEO_NAME]) {
      const err = 'There is no selected country name';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_SITE] || !options[TW.KEY_SITE].length) {
      const err = 'There is no selected site';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_DEVICE] || !options[TW.KEY_DEVICE].length) {
      const err = 'There is no selected device';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_CONVERSION]) {
      const err = 'There is no selected conversion';
      console.log(err);
      toast.error(err);
      return false;
    }

    if (!options[TW.KEY_BUDGET]) {
      const err = 'There is no selected budget';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_BIDDING_STRATEGY]) {
      const err = 'There is no selected bidding stategy';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_BUDGET_TYPE]) {
      const err = 'There is no selected budget type';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_BID]) {
      const err = 'There is no selected bid';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_CAMPAIGN_START_TIME]) {
      const err = 'There is no selected start date';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_STATUS]) {
      const err = 'There is no selected status';
      console.log(err);
      toast.error(err);
      return false;
    }
    // Check creative group validity
    const validationInfo = this.validateCreativeGroups();
    if (validationInfo.isValid) {
      console.log('Creative groups are valid');
    } else {
      console.log('Creative groups are invalid', validationInfo.data);
      validationInfo.data.forEach(err => {
        toast.error(err);
      });
      return false;
    }
    return true;
  }

  constructCampaignName(options) {
    let oldNaming = {};
    let newCampaignIndex = 1;
    if (options.sourceName) {
      const splitted = options.sourceName.split('-');
      oldNaming.prefix = splitted[0];
      oldNaming.country = splitted[1];
      oldNaming.platform = splitted[2];
      oldNaming.site = splitted[3];
      oldNaming.article = splitted[4];
    }
    const PLATFORM_DICT = {
      desktop: 'd',
      smartphone: 'm',
      tablet: 't'
    };

    const parts = [];
    parts.push(options.prefix || oldNaming.prefix);
    if (options.country) {
      parts.push(options.country.toLowerCase().split(',')[0] || oldNaming.country);
    }
    if (options.device) {
      let device = '';
      if (typeof options.device === 'object') {
        device = options.device[0];
      } else {
        device = options.device;
      }
      if (device) {
        device = device.toLowerCase().split(',')[0];
      }
      parts.push(PLATFORM_DICT[device] || oldNaming.platform);
    }
    if (typeof options.site === 'string') {
      options.site = options.site.split(',');
    }
    if (options.site) {
      parts.push(options.site[0].toLowerCase() || oldNaming.site);
    }
    parts.push(options.widgetCodeName + options.widgetLanguageCode || oldNaming.article || 'ARTICLE');
    if (this.props.state.options.freeText) {
      parts.push(this.props.state.options.freeText);
    } else {
      if (this.state.campaigns[0]) {
        newCampaignIndex = getMaxCampaignIndex(this.state.campaigns.map(c => c.desc)) + this.getCampaignsNumber();
      }
      parts.push('r' + newCampaignIndex);
    }
    return parts.join('-');
  }

  updateWebsite(ref) {
    const domain = ref[TW.KEY_SITE];
    const websiteObj = getPlatformSites(PLATFORMS.TWITTER.toLowerCase()).find(s => s.code === domain);
    const website = websiteObj ? websiteObj.name : '';
    const isDomainPresent = website;
    const isArticleAbsent = ref['widgetCodeName'] === null && ref['widgetLanguageCode'] === null && !ref['widgetid'];
    if (isDomainPresent) {
      if (isArticleAbsent) {
        return constructWebsite(ref, website, 'THE_SAME_AS_ORIGINAL');
      } else {
        return constructWebsite(ref, website, ref['widgetid']);
      }
    } else {
      if (ref['widgetid']) {
        return constructWebsite(ref, 'SITE_NAME', ref['widgetid']);
      } else {
        // No domain, no article, leave website empty
        return '';
      }
    }
  }

  onChangeFreeText(e) {
    let text = e.target.value;
    this.props.modifyOptions('freeText', text);
    const newCampName = this.constructCampaignName(this.props.state.options);
    let tooltips = this.state.tooltips;
    const maxAddedLength = 1 + TW.MAX_ADSET_PART_LENGTH + 1 + TW.MAX_AD_PART_LENGTH;
    const charsLeft = TW.MAX_CAMP_NAME_LENGTH - newCampName.length - maxAddedLength;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    if (charsLeft < 0) {
      text = text.slice(0, -1);
      tooltips.text = `Chars left: 0`;
    }
    this.props.modifyOptions('freeText', text);
    this.setState({ tooltips: tooltips });
  }

  onBlurFreeText() {
    let tooltips = this.state.tooltips;
    tooltips = {
      open: false
    };
    this.setState({ tooltips: tooltips });
  }

  cancelCropWindow() {
    this.setState({ showCropWindow: false });
  }

  applyCropWindow(images) {
    images.map(image => {
      if (image.crops.Smartphone) image.crops.Smartphone.type = 'SMARTPHONE';

      return image;
    });
    this.addCreatives(this.state.creatives, images);
    this.setState({ showCropWindow: false });
  }

  submit() {
    if (!this.validateSetup()) {
      return;
    }
    let title = '';
    let text = null;
    if (this.props.state.options.warnings && this.props.state.options.warnings.length > 0) {
      const warningBids = this.props.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = 'Are you sure to do this?';
    }
    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No',
          onClick: () => this.showOptions()
        }
      ]
    });
  }
  getAccountOptions(xs) {
    return xs
      ? xs
          .map(el => {
            return { label: el.desc, value: el.id };
          })
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      : [];
  }

  getSiteOptions(xs) {
    return xs
      .map(el => {
        el.label = el.label.slice(0, 3);
        return el;
      })
      .concat({ label: 'mmo', value: 'mmo' });
  }
  getConversionOptions() {
    let conversions =
      this.props.state.options[TW.KEY_GOAL] === 'SITE_VISITS' ||
      this.props.state.options[TW.KEY_GOAL] === 'WEBSITE_CONVERSIONS'
        ? this.state.conversions
        : this.state.conversions.filter(
            el =>
              el.label.toLowerCase().startsWith('visit') || el.label.toLowerCase().startsWith('site visit all sites')
          );
    return conversions;
  }
  renderCreateDuplicate() {
    /*let exampleName =  '';
    if(this.props.state.options) {
      exampleName = this.constructCampaignName(this.props.state.options);
    }*/
    return (
      <>
        <WidgetSelector
          modify={(key, value) => this.modifyOptions(key, value)}
          articleList={this.props.articleList}
          options={this.props.state.options}
          allowedVersions={this.getAllowedWidgetVersions()}
          programOptions={this.props.programOptions}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_DESTINATION_ACCOUNT, x.value)}
            options={this.getAccountOptions(this.props.state.accounts)}
            value={getSelectValue(
              this.getAccountOptions(this.props.state.accounts),
              this.props.state.options[TW.KEY_DESTINATION_ACCOUNT]
            )}
          />
        </Grid>
        <Prefix
          value={{ label: this.props.state.options[TW.KEY_PREFIX], value: this.props.state.options[TW.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(TW.KEY_PREFIX, x.value)}
          options={makeSelectOptions(this.props.programOptions?.prefix)}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Creator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            options={this.props.creatorsList}
            onChange={x => this.modifyOptions(TW.KEY_CREATOR, x.value)}
            value={getSelectValue(this.props.creatorsList, this.props.state.options[TW.KEY_CREATOR])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign objective
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            options={TW.OBJECTIVES}
            onChange={x => this.modifyOptions(TW.KEY_OBJECTIVE, x.value)}
            value={getSelectValue(TW.OBJECTIVES, this.props.state.options[TW.KEY_OBJECTIVE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(TW.KEY_COUNTRY, getOnlyValue(xs))}
            options={this.props.countryList}
            value={getMultiSelectValue(this.props.countryList, this.props.state.options[TW.KEY_COUNTRY])}
          />
        </Grid>
        <Grid item xs={4} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(TW.KEY_GEO_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Geo name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="geoname"
            value={this.props.state.options[TW.KEY_GEO_NAME]}
            onChange={value => this.modifyOptions(TW.KEY_GEO_NAME, value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select language
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_LANGUAGE, x.value)}
            options={TW.LANGUAGE_LIST}
            value={getSelectValue(TW.LANGUAGE_LIST, this.props.state.options[TW.KEY_LANGUAGE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select domain
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(TW.KEY_SITE, getOnlyValue(xs))}
            options={this.getSiteOptions(this.props.siteList)}
            value={getMultiSelectValue(this.getSiteOptions(this.props.siteList), this.props.state.options[TW.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(TW.KEY_DEVICE, getOnlyValue(xs))}
            options={TW.DEVICES_LIST}
            value={getMultiSelectValue(TW.DEVICES_LIST, this.props.state.options[TW.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={4} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.props.state.options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) =>
                  this.modifyOptions(TW.KEY_DEVICE_DUPLICATION_TYPE, value ? 'group' : 'separate')
                }
                color="primary"
              />
            }
            label="Group"
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Age range
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(TW.KEY_AGE, x?.value)}
            options={TW.AGE_LIST}
            value={getSelectValue(TW.AGE_LIST, this.props.state.options[TW.KEY_AGE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Gender
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(TW.KEY_GENDER, x?.value)}
            options={TW.GENDER_LIST}
            value={getSelectValue(TW.GENDER_LIST, this.props.state.options[TW.KEY_GENDER])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Goal
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(TW.KEY_GOAL, x?.value)}
            options={TW.GOAL_LIST}
            value={getSelectValue(TW.GOAL_LIST, this.props.state.options[TW.KEY_GOAL])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Custom conversions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_CONVERSION, x.value)}
            options={this.getConversionOptions()}
            value={getSelectValue(this.getConversionOptions(), this.props.state.options[TW.KEY_CONVERSION])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Website URL
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="website"
            value={this.props.state.options[TW.KEY_WEBSITE]}
            onChange={value => this.modifyOptions(TW.KEY_WEBSITE, value)}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_BUDGET_TYPE, x.value)}
            options={this.spendingModelList}
            value={getSelectValue(this.spendingModelList, this.props.state.options[TW.KEY_BUDGET_TYPE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            name={TW.KEY_BUDGET}
            value={this.props.state.options[TW.KEY_BUDGET]}
            onChange={value => this.modifyOptions(TW.KEY_BUDGET, value)}
            onBlur={() => this.validateOptions(TW.KEY_BUDGET)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bidding Strategy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_BIDDING_STRATEGY, x.value)}
            options={
              this.props.state.options[TW.KEY_GOAL] === 'SITE_VISITS'
                ? this.siteVisitsBiddingStrategyList
                : this.biddingStrategyList
            }
            value={getSelectValue(
              this.props.state.options[TW.KEY_GOAL] === 'SITE_VISITS'
                ? this.siteVisitsBiddingStrategyList
                : this.biddingStrategyList,
              this.props.state.options[TW.KEY_BIDDING_STRATEGY]
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bid
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            name={TW.KEY_BID}
            step={TW.BID_STEP}
            value={this.props.state.options[TW.KEY_BID]}
            onChange={value => this.modifyOptions(TW.KEY_BID, value)}
            onBlur={() => this.validateOptions(TW.KEY_BID)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign start date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.props.state.options[TW.KEY_CAMPAIGN_START_TIME]
                ? new Date(this.props.state.options[TW.KEY_CAMPAIGN_START_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(TW.KEY_CAMPAIGN_START_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={moment().toDate()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign end date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.props.state.options[TW.KEY_CAMPAIGN_STOP_TIME]
                ? new Date(this.props.state.options[TW.KEY_CAMPAIGN_STOP_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(TW.KEY_CAMPAIGN_STOP_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={
              this.props.state.options[TW.KEY_CAMPAIGN_START_TIME]
                ? moment(this.props.state.options[TW.KEY_CAMPAIGN_START_TIME])
                    .add(1, 'day')
                    .toDate()
                : moment()
                    .add(1, 'day')
                    .toDate()
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_STATUS, x.value)}
            options={TW.STATUS}
            value={getSelectValue(TW.STATUS, this.props.state.options[TW.KEY_STATUS])}
          />
        </Grid>
        <CampaignsNote
          value={this.props.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
          onChange={e => {
            this.props.modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
          }}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Creative Groups:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} align="right"></Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button fullWidth variant="contained" color="primary" onClick={() => this.addCreativeGroup()}>
            Add campaign
          </Button>
        </Grid>
        <TwitterCreativeGroupBuilder
          groups={this.props.state.options[TW.KEY_CREATIVE_GROUPS]}
          onAddImages={(groupIdx, imageBlob, deviceType, index) =>
            this.onAddImages(groupIdx, imageBlob, deviceType, index)
          }
          onChangeImageText={(groupIdx, imageIdx, type, e) => this.onChangeImageText(groupIdx, imageIdx, type, e)}
          onBlurImageText={(groupIdx, imageIdx) => this.onBlurImageText(groupIdx, imageIdx)}
          onPasteImageText={(groupIdx, imageIdx, type, e) => this.onPasteImageText(groupIdx, imageIdx, type, e)}
          removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
          duplicateImage={(groupIdx, imageIdx) => this.duplicateImage(groupIdx, imageIdx)}
          removeGroup={groupIdx => this.removeGroup(groupIdx)}
          duplicateGroup={groupIdx => this.duplicateGroup(groupIdx)}
          populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
          options={this.props.state.options}
        />
        {this.state.showCropWindow ? (
          <CropWindow
            desktopAspectRatio={TW.DESKTOP_ASPECT_RATIO}
            smartphoneAspectRatio={TW.SMARTPHONE_ASPECT_RATIO}
            data={this.state.creatives}
            onlyOneCropRequired={true}
            onClose={() => this.cancelCropWindow()}
            onSave={images => this.applyCropWindow(images)}
            fromInsights={true}
            platform={PLATFORMS.TWITTER}
          />
        ) : null}
        <Grid item xs={12} sm={4} align="right"></Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
            {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getCreateButtonText()}
          </Button>
        </Grid>
      </>
    );
  }

  render() {
    return this.renderCreateDuplicate();
  }
}

function decodeHtml(text) {
  let div = document.createElement('textarea');
  div.innerHTML = text;
  return div.firstChild.nodeValue;
}

function parseTwitterSourceData(options, data) {
  console.log(data);
  options[TW.KEY_CREATIVE_GROUPS] = [];
  const lineItem = data.line_items[0];
  const sourceGroup = {
    images: [],
    imageHashes: [],
    texts: [],
    tooltips: [],
    titles: []
  };
  lineItem.promoted_tweets.forEach(promoted_tweet => {
    const tweet = lineItem.tweets.find(el => el.tweet_id === promoted_tweet.tweet_id);
    const card = lineItem.cards.find(el => el.card_uri === tweet.card_uri);
    sourceGroup.titles.push(card.website_title);
    sourceGroup.texts.push(decodeHtml(tweet.full_text));
    sourceGroup.images.push({ image: card.media_url });
    sourceGroup.imageHashes.push(card.media_url);
  });
  options[TW.KEY_CREATIVE_GROUPS].push(sourceGroup);
  options[TW.KEY_BUDGET] = data.campaign.daily_budget_amount_local_micro / 1000000;
  options[TW.KEY_BUDGET_TYPE] = true;
  options[TW.KEY_GOAL] = lineItem.goal;
  options[TW.KEY_CONVERSION] = lineItem.primary_web_event_tag;
  options[TW.KEY_BIDDING_STRATEGY] = lineItem.bid_type;
  options[TW.KEY_BID] = lineItem.bid_amount_local_micro / 1000000;

  const parsedName = parseCampaignName(PLATFORMS.TWITTER, data.campaign.name);
  options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
  options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
  options[TW.KEY_SITE] = parsedName.site;
  options[TW.KEY_COUNTRY] = parsedName.country;
  options[TW.KEY_GEO_NAME] = parsedName.country;
  const deviceTargetingCriteria = lineItem.targeting_criterias.find(el => el.targeting_type === 'PLATFORM');
  options[TW.KEY_DEVICE] =
    deviceTargetingCriteria && deviceTargetingCriteria.targeting_value
      ? deviceTargetingCriteria.targeting_value.toString()
      : null;
  const languageTargetingCriteria = lineItem.targeting_criterias.find(el => el.targeting_type === 'LANGUAGE');
  options[TW.KEY_LANGUAGE] = languageTargetingCriteria ? languageTargetingCriteria.targeting_value : null;
  const ageTargetingCriteria = lineItem.targeting_criterias.find(el => el.targeting_type === 'AGE');
  options[TW.KEY_AGE] = ageTargetingCriteria ? ageTargetingCriteria.targeting_value : null;
  const genderTargetingCriteria = lineItem.targeting_criterias.find(el => el.targeting_type === 'GENDER');
  options[TW.KEY_GENDER] = genderTargetingCriteria ? genderTargetingCriteria.targeting_value : null;
  if (options.prefix === 'xx') {
    options[TW.KEY_STATUS] = TW.PAUSED;
  } else {
    options[TW.KEY_STATUS] = TW.ACTIVE;
  }
  options[TW.KEY_CREATOR] =
    (data.campaign.campaignCreator.length && typeof data.campaign.campaignCreator === 'string') ||
    options.creator ||
    '';

  return options;
}

export { TwitterCreate, parseTwitterSourceData };
