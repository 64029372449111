import React, { Component } from 'react';
import {
  displayWarnings,
  getFullPath,
  getImageDimensions,
  truncateFileName,
  validateAndPrepareImage
} from '../../utils/imageUploadUtils';
import { storeFiles, collage } from '../../Api';
import { FB } from '../../Constants';
import * as Sentry from '@sentry/react';
import { Button, Card, CardActions, CardHeader, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CollageTemplatesPreview from './CollageTemplatesPreview';
import AddIcon from '@mui/icons-material/Add';
import { StyledCardMedia } from '../../SharedStyles';
import styled from '@emotion/styled';

// const TEMPLATE_OPTIONS = ['a', 'b', 'c', 'd', 'e', 'e2', 'f', 'g'];
// business don't need all templates
const TEMPLATE_OPTIONS = ['a', 'b', 'c', 'd', 'e', 'f'];
const MAX_IMAGES = 10;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '98vw',
  fontSize: '0.875rem',
  fontFamily: 'Open Sans',
  fontWeight: '500'
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 188
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: '45px',
  fontSize: '1rem'
}));
const StyledGirdLine = styled(Grid)(({ theme }) => ({
  borderLeft: '1px solid rgba(0, 0, 0, 0.22)',
  padding: '0 15px'
}));

const StyledFont = styled(Typography)(({ theme }) => ({
  fontSize: '1rem'
}));

class CollageMakerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      texts: ['', ''],
      selectedTemplates: '',
      subject: '',
      pasteImage: ''
    };
  }

  onSetActiveCollages = activeCollages => {
    this.setState({ selectedTemplates: activeCollages.join('+') });
  };

  onAddImage(index, event, blob) {
    let images;
    if (event) {
      images = Array.from(event.target.files);
    }
    if (blob) {
      images = [blob];
    }

    const errs = [];
    const warnings = [];

    const formData = new FormData();
    let basicValidatedImages = [];

    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        let numberOfImagesToAdd = images.length;

        if (this.state.files.length + numberOfImagesToAdd > MAX_IMAGES) {
          numberOfImagesToAdd = MAX_IMAGES - this.state.files.length;
          toast.error('You have reached the maximum number of images allowed!');
        }

        for (let i = 0; i < numberOfImagesToAdd; i++) {
          const file = images[i];
          const filename_truncated = truncateFileName(file.name);

          if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], index, 'FB', warnings, errs)) {
            return;
          }

          formData.append(i, file);
          basicValidatedImages.push(file);
        }
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) {
          return;
        }
        const options = {
          minWidth: FB.MIN_WIDTH,
          minHeight: FB.MIN_HEIGHT,
          noDownscale: true
        };
        return storeFiles(formData, options);
      })
      .then(files => {
        const images = files.map(item => getFullPath(item));
        this.setState(prevState => ({
          files: [...prevState.files, ...images]
        }));
      })
      .catch(err => {
        console.error(err);
        Sentry.captureException(err);
      });
  }

  isValidUrl(url) {
    const pattern = /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i;
    return pattern.test(url);
  }

  isImageUrl(url) {
    const allowedExtensions = ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.bmp', '.svg'];
    const extension = url.substring(url.lastIndexOf('.'));
    return allowedExtensions.includes(extension.toLowerCase());
  }

  async onPasteImageFromUrl(imageUrl) {
    if (!this.isValidUrl(imageUrl)) {
      toast.error('Invalid URL format. Use http:// or https://');
      return;
    }
    const isImage = this.isImageUrl(imageUrl);
    if (!isImage) {
      toast.error(`URL is not an image. Open the image in a new tab and click on 'Copy image address (URL)'`);
      return;
    }
    const blob = await fetch(`/api/getImg?img=${encodeURIComponent(imageUrl)}`).then(r => r.blob());
    const timestamp = Date.now();
    const file = new File([blob], `image-${timestamp}.png`, { type: 'image/png' });
    if (file) {
      this.onAddImage(0, null, file);
      this.setState({ pasteImage: '' });
    }
  }

  submit() {
    const { files, selectedTemplates, subject, texts } = this.state;

    if (files.length === 0) {
      toast.error('Please add images to create a collage.');
      return;
    }
    if (!selectedTemplates) {
      toast.error('Please select a template to create a collage.');
      return;
    }
    if (
      files.length < 3 &&
      (selectedTemplates.includes('a') || selectedTemplates.includes('d') || selectedTemplates.includes('f'))
    ) {
      toast.error('Please add minimum 3 images to create collage.');
      return;
    }
    if (
      files.length < 4 &&
      (selectedTemplates.includes('b') || selectedTemplates.includes('c') || selectedTemplates.includes('g'))
    ) {
      toast.error('Please add minimum 4 images to create collage.');
      return;
    }

    const { user } = this.props;
    if (texts[0] === undefined) {
      texts[0] = '';
    }
    const data = {
      templates: selectedTemplates,
      email: user.email,
      subject,
      images: files,
      texts: [texts[0]],
      smallTexts: texts.slice(1).map(i => i || '')
    };
    console.log(data);
    collage(data).then(res => {
      if (res.error) {
        toast.error(res.error);
      } else {
        toast.success('Collages sent to your email. You can create a new collage.');
      }
    });
  }

  handleDelete = idx => {
    const { files } = this.state;
    files.splice(idx, 1);
    this.setState({ files });
  };

  getKey(base, idx) {
    return base + '_' + idx;
  }

  showHelperText = idx => {
    const text = this.state.texts[idx];
    return `${text.length}/${FB.MAX_TITLE_MOBILE_LENGTH}`;
  };

  handleTextChange = (e, idx) => {
    const text = e.target.value;
    this.setState(prevState => {
      const updatedTexts = [...prevState.texts];
      updatedTexts[idx] = text;
      return { texts: updatedTexts };
    });
  };

  addTextField = () => {
    this.setState(prevState => ({
      texts: [...prevState.texts, '']
    }));
  };

  updateSubject(subject) {
    this.setState({ subject });
  }

  updateImageUrlPaste(pasteImage) {
    this.setState({ pasteImage });
  }

  renderImage(image, idx) {
    const fullPath = image;
    return (
      <React.Fragment key={this.getKey('image', idx)}>
        <Grid item>
          <StyledCard>
            <CardHeader />
            <StyledCardMedia key={this.getKey('image', idx)} image={fullPath} />
            <CardActions
              disableSpacing
              sx={{
                flexDirection: 'row-reverse'
              }}
            >
              <IconButton
                aria-label="menu"
                key={this.getKey('removeButton', idx)}
                onClick={() => this.handleDelete(idx)}
                title="Delete image"
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </CardActions>
          </StyledCard>
        </Grid>
      </React.Fragment>
    );
  }

  renderTextFields(idx) {
    const isLast = idx === this.state.texts.length - 1 && idx < 3;
    return (
      <React.Fragment key={this.getKey('text', idx)}>
        <Grid item container direction="row" alignItems="center">
          <Grid item sm={4}>
            <TextField
              variant="standard"
              multiline
              fullWidth
              label={idx === 0 ? 'Big image text' : `Small image text ${idx}`}
              key={this.getKey('text', idx)}
              value={this.state.texts[idx]}
              onChange={event => this.handleTextChange(event, idx)}
              helperText={this.showHelperText(idx)}
              inputProps={{
                maxLength: FB.MAX_TITLE_MOBILE_LENGTH
              }}
            />
          </Grid>
          {isLast && (
            <Grid item>
              <Tooltip placement="right" title="Add text for small images in colllage">
                <IconButton color="primary" onClick={this.addTextField} size="large">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const { user } = this.props;
    const { files, selectedTemplates, texts } = this.state;
    return (
      <>
        {user.app_metadata.spm_collage_access ? (
          <StyledPaper elevation={0} square variant="outlined">
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <StyledFont gutterBottom variant={'subtitle2'}>
                  <span role="img" aria-label="arrow-down">
                    👇{' '}
                  </span>
                  Add up to {MAX_IMAGES} images to create the collage. You can upload directly from your computer or
                  simply paste the image URL below
                </StyledFont>
              </Grid>

              <Grid item container direction={'row'} spacing={2} alignContent="center" alignItems="flex-end">
                <Grid item>
                  <Button variant="contained" color="primary" component="label" disabled={files.length >= 10}>
                    Add images
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      style={{ display: 'none' }}
                      onChange={e => this.onAddImage(0, e)}
                      onClick={e => {
                        e.target.value = null;
                      }}
                    />
                  </Button>
                </Grid>

                <Grid item container direction={'column'} spacing={2} sm={8}>
                  <Grid item container direction="row" alignItems="flex-end">
                    <StyledGirdLine item sm={9}>
                      <TextField
                        variant="standard"
                        multiline
                        fullWidth
                        label="Image URL"
                        value={this.state.pasteImage}
                        onChange={e => this.updateImageUrlPaste(e.target.value)}
                      />
                    </StyledGirdLine>
                    <Grid item sm={'auto'}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="button"
                        disabled={this.state.pasteImage === ''}
                        onClick={() => this.onPasteImageFromUrl(this.state.pasteImage)}
                      >
                        Paste image
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {files.length ? (
                <Grid item container direction={'row'} spacing={2}>
                  {files.map((ad, idx) => this.renderImage(ad, idx))}
                </Grid>
              ) : null}

              <Grid item>
                <StyledTypography gutterBottom variant={'subtitle2'}>
                  <span role="img" aria-label="arrow-down">
                    👇{' '}
                  </span>
                  To choose collage designs, simply click on the template(s) of your preference. You have the option to
                  select more than one
                </StyledTypography>
              </Grid>

              <Grid item>
                <CollageTemplatesPreview templates={TEMPLATE_OPTIONS} onSetActiveCollages={this.onSetActiveCollages} />
              </Grid>

              {selectedTemplates && (
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Selected Templates: {selectedTemplates}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <StyledTypography variant={'subtitle2'}>
                  <span role="img" aria-label="arrow-down">
                    👇{' '}
                  </span>
                  You can add text to the main image and up to three texts to the small images, or leave them empty
                </StyledTypography>
              </Grid>

              {texts.map((_, idx) => {
                if (idx < 4) {
                  return this.renderTextFields(idx);
                }
                return null;
              })}

              <Grid item sm={6}>
                <StyledTypography gutterBottom variant={'subtitle2'}>
                  <span role="img" aria-label="arrow-down">
                    👇{' '}
                  </span>
                  You can add title for yor collages, it will be in e-mail subject
                </StyledTypography>
              </Grid>

              <Grid item sm={4}>
                <input
                  style={{ lineHeight: '25px', marginBottom: '5px' }}
                  placeholder="E-mail subject"
                  type="text"
                  name="subject"
                  value={this.state.subject}
                  onChange={e => this.updateSubject(e.target.value)}
                />
              </Grid>

              <Grid item>
                <Tooltip placement="right" title="Image collages will be sent on your e-mail">
                  <Button variant="contained" color="primary" onClick={() => this.submit()}>
                    Create
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </StyledPaper>
        ) : (
          <Typography gutterBottom variant="subtitle1">
            You have no access to this page
          </Typography>
        )}
      </>
    );
  }
}

export default CollageMakerPage;
