// import { NEGATIVE_WORDS_LIST_GM, NEGATIVE_WORDS_LIST_FB, NEGATIVE_WORDS_EXACT_MATCH_LIST } from './NegativeWords';
import { PLATFORMS, NW } from '../Constants';
import { getNegativeWordsByWordType } from '../Api';

export async function getNegativeWords(platform, text) {
  const lowerCaseText = text.toLowerCase();
  const negativeWordsList = await getNegativeWordsByWordType(
    platform === PLATFORMS.FACEBOOK ? NW.NEGATIVE_WORDS_LIST_FB : NW.NEGATIVE_WORDS_LIST_GM
  );

  const negativeWords = negativeWordsList.filter(word => {
    return lowerCaseText.includes(word);
  });
  //Get individual words, see https://stackoverflow.com/a/36508315
  const exactMatchList = await getNegativeWordsByWordType(NW.NEGATIVE_WORDS_EXACT_MATCH_LIST);
  const regexMatch = text && !text.toString().match(/[\u3400-\u9FBF]/) ? text.match(/\b(\w+)'?(\w+)?\b/g) || [] : [];
  const filteredWords = exactMatchList.filter(w => regexMatch.includes(w));
  return negativeWords.concat(filteredWords);
}
