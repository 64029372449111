let sitesPerPlatform = {};

const getPlatformSites = platform => {
  return sitesPerPlatform[platform]?.length ? sitesPerPlatform[platform] : sitesPerPlatform.default;
};

const setPlatformSites = platformSites => {
  for (const [platform, sites] of Object.entries(platformSites)) {
    sitesPerPlatform[platform] = sites;
  }
};

export { getPlatformSites, setPlatformSites };
