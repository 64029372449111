import React, { Component } from 'react';
import Select from 'react-select';
import { Grid, Typography, Tooltip } from '@mui/material';
import { filterVersionBySite } from './utils/filters';
import { PLATFORMS } from './Constants';
import { articleReduce, getSelectValue } from './Shared';
import { styled } from '@mui/system';

const StyledSelect = styled(Select)(() => ({
  '& .Select-menu-outer': {
    zIndex: '2'
  }
}));

const ENGLISH_LANG = 'en';

function createLabelValueWrapper(lst) {
  return unique(lst).map(el => {
    return { label: el || 'Empty', value: el };
  });
}

function unique(lst) {
  return [...new Set(lst)];
}

function sortAllExcept(arr, val) {
  const valIndex = arr.indexOf(val);
  if (valIndex !== -1) {
    arr.splice(valIndex, 1);
    arr.sort((a, b) => a.localeCompare(b));
    arr.unshift(val);
  } else {
    arr.sort();
  }
  return arr;
}

class WidgetSelector extends Component {
  componentDidMount() {
    if (this.props.programOptions.isSwag) {
      if (this.props.defaultVersion && this.props.defaultVersion.length === 1) {
        this.changeWidgetVersion(this.props.defaultVersion[0]);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.triggerSelectorUpdate !== this.props.triggerSelectorUpdate) {
      this.updateFullWidgetCode(
        this.props.options.widgetCodeName,
        this.props.options.widgetLanguageCode,
        this.props.options.widgetVersion
      );
    }
    if (
      prevProps.options.widgetCodeName !== this.props.options.widgetCodeName &&
      this.props.options.widgetCodeName === null &&
      !this.props.programOptions.isSwag
    ) {
      this.changeWidgetVersion('');
    }
    if (this.props.registerAutoFillCallback && typeof this.props.registerAutoFillCallback === 'function') {
      this.props.registerAutoFillCallback((codeName, lang, ver) => this.updateFullWidgetCode(codeName, lang, ver));
    }
  }

  getParsedArticles() {
    const allowedVersions = filterVersionBySite(
      this.props.allowedVersions,
      this.props.options.site || this.props.options.site_code
    );
    return articleReduce([], this.props.articleList.clv, this.props.block, (result, clv) => {
      const ver = clv.substring(7, 10);
      if (!allowedVersions.includes(ver)) {
        return;
      }
      result.push([clv.substring(0, 5), clv.substring(5, 7), ver]);
    });
  }

  getCodeNames() {
    let lc = this.props.options.widgetLanguageCode;
    const possible = this.getLanguageCodes();
    if (!possible.includes(lc)) {
      lc = null;
    }
    const v = this.props.options.widgetVersion;
    return this.getParsedArticles()
      .filter(el => {
        if (lc && el[1] !== lc) {
          return false;
        } else return !(v && el[2] !== v);
      })
      .map(el => el[0]);
  }

  getLanguageCodes() {
    const cn = this.props.options.widgetCodeName;
    const v = this.props.options.widgetVersion;
    const filtered = this.getParsedArticles()
      .filter(el => {
        if (cn && el[0] !== cn) {
          return false;
        } else return !(v && el[2] !== v);
      })
      .map(el => el[1]);
    return sortAllExcept(filtered, ENGLISH_LANG);
  }

  getVersions() {
    const cn = this.props.options.widgetCodeName;
    const lc = this.props.options.widgetLanguageCode;
    return this.getParsedArticles()
      .filter(el => {
        if (cn && el[0] !== cn) {
          return false;
        } else return !(lc && el[1] !== lc);
      })
      .map(el => el[2]);
  }

  updateFullWidgetCode(codeName, lang, ver) {
    if (codeName && codeName.length === 5 && lang && lang.length === 2 && (ver === '' || (ver && ver.length === 3))) {
      const fullCodeName = codeName + lang + ver;
      const article = codeName + lang;
      const art = this.props.articleList.clv[fullCodeName];
      this.props.modify('widgetid', art ? art.wid : '');
      this.props.modify('article', { target: { value: article || '' } });
      this.props.modify('fullCodeName', fullCodeName);
      if (art) {
        if (art.path) {
          this.props.modify('widget_path', art.path);
        } else {
          this.props.modify('widget_title', art.title);
        }
      }
    } else {
      this.props.modify('widgetid', '');
      this.props.modify('article', { target: { value: '' } });
    }
  }

  autoFillValues(codeName, lang, ver) {
    if (!codeName) {
      const possible = this.getCodeNames();
      if (possible.length === 1) {
        this.props.modify('widgetCodeName', possible[0]);
        codeName = possible[0];
      }
      if (!this.props.programOptions.isSwag) {
        this.props.modify('widgetVersion', '');
        ver = '';
      }
    }
    if (!lang) {
      const possible = this.getLanguageCodes();
      if (possible.length === 1) {
        this.props.modify('widgetLanguageCode', possible[0]);
        lang = possible[0];
      }
      let dl = this.props.options.defaultLanguage;
      if (possible.includes(dl)) {
        this.props.modify('widgetLanguageCode', dl);
        lang = dl;
      }
      if (!this.props.programOptions.isSwag) {
        this.props.modify('widgetVersion', '');
        ver = '';
      }
    }
    if (!ver) {
      const possible = this.getVersions();
      if (possible.length === 1) {
        this.props.modify('widgetVersion', possible[0]);
        ver = possible[0];
      } else if (!this.props.programOptions.isSwag && codeName && lang) {
        //select version first existing article
        for (const version of this.props.allowedVersions) {
          if (this.props.articleList.clv[codeName + lang + version]) {
            this.props.modify('widgetVersion', version);
            ver = version;
            break;
          }
        }
      }
    }
    if (this.props.defaultVersion && this.props.defaultVersion.length > 1) {
      // 22.07.19 at this moment only relevant for TB
      if (codeName && !ver) {
        const possible = this.getVersions();
        for (let i = 0; i < this.props.defaultVersion.length; ++i) {
          if (possible.includes(this.props.defaultVersion[i])) {
            this.props.modify('widgetVersion', this.props.defaultVersion[i]);
            ver = this.props.defaultVersion[i];
            break;
          }
        }
      }
    }
    return [codeName, lang, ver];
  }

  updateSelector(codeName, lang, ver, newValue) {
    if (newValue) {
      // Only autofill if we are setting a new value, not clearing it
      [codeName, lang, ver] = this.autoFillValues(codeName, lang, ver);
    }
    this.updateFullWidgetCode(codeName, lang, ver);
  }

  changeWidgetCodeName(e) {
    this.props.modify('widgetCodeName', e);
    this.updateSelector(e, this.props.options.widgetLanguageCode, this.props.options.widgetVersion, e);
  }

  changeWidgetLanguageCode(e) {
    this.props.modify('widgetLanguageCode', e);
    this.updateSelector(this.props.options.widgetCodeName, e, this.props.options.widgetVersion, e);
  }

  changeWidgetVersion(e) {
    this.props.modify('widgetVersion', e);
    this.updateSelector(this.props.options.widgetCodeName, this.props.options.widgetLanguageCode, e, e);
  }

  render() {
    // let sellSize = this.props.platform === PLATFORMS.FACEBOOK2 ? 4 : 2;

    if (
      this.props.platform === PLATFORMS.FACEBOOK2 ||
      this.props.platform === PLATFORMS.FACEBOOK_UPLOAD_CREATIVES ||
      this.props.platform === PLATFORMS.TWITTER
    ) {
      return (
        <Grid container alignItems="flex-end" sx={{ paddingTop: '5px' }} spacing={1}>
          <Grid item sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Widget name
            </Typography>
            <StyledSelect
              isSearchable
              isClearable
              onChange={e => this.changeWidgetCodeName(e?.value)}
              options={createLabelValueWrapper(this.getCodeNames())}
              value={
                this.props.options.widgetCodeName
                  ? { label: this.props.options.widgetCodeName, value: this.props.options.widgetCodeName }
                  : null
              }
            />
          </Grid>
          <Grid item sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Widget language
            </Typography>
            <StyledSelect
              onChange={e => this.changeWidgetLanguageCode(e?.value)}
              options={createLabelValueWrapper(this.getLanguageCodes())}
              value={{ label: this.props.options.widgetLanguageCode, value: this.props.options.widgetLanguageCode }}
            />
          </Grid>
          {this.props.programOptions.isSwag ? (
            <Grid item sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Widget version
              </Typography>
              <StyledSelect
                onChange={e => this.changeWidgetVersion(e?.value)}
                options={createLabelValueWrapper(this.getVersions())}
                value={{ label: this.props.options.widgetVersion, value: this.props.options.widgetVersion }}
              />
            </Grid>
          ) : null}
        </Grid>
      );
    } else {
      return (
        <>
          <Grid container item xs={12} sm={4} direction={'row'}>
            {this.props.platform === PLATFORMS.FACEBOOK ? (
              <>
                <Typography gutterBottom variant="subtitle2">
                  Article code name
                </Typography>
                <Tooltip title="Article name from the portal">
                  <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                    ?
                  </Typography>
                </Tooltip>
              </>
            ) : (
              <Typography gutterBottom variant="subtitle2">
                Widget code name
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              isClearable
              isSearchable
              onChange={e => this.changeWidgetCodeName(e?.value)}
              options={createLabelValueWrapper(this.getCodeNames())}
              value={getSelectValue(createLabelValueWrapper(this.getCodeNames()), this.props.options.widgetCodeName)}
            />
          </Grid>
          <Grid container item xs={12} sm={4} direction={'row'}>
            {this.props.platform === PLATFORMS.FACEBOOK ? (
              <>
                <Typography gutterBottom variant="subtitle2">
                  Article language code
                </Typography>
                <Tooltip title="Listed as country code" placement="right">
                  <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                    ?
                  </Typography>
                </Tooltip>
              </>
            ) : (
              <Typography gutterBottom variant="subtitle2">
                Widget language code
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <StyledSelect
              onChange={e => this.changeWidgetLanguageCode(e?.value)}
              options={createLabelValueWrapper(this.getLanguageCodes())}
              value={getSelectValue(
                createLabelValueWrapper(this.getLanguageCodes()),
                this.props.options.widgetLanguageCode
              )}
            />
          </Grid>
          {this.props.programOptions.isSwag ? (
            <>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant="subtitle2">
                  Widget version
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  onChange={e => this.changeWidgetVersion(e?.value)}
                  options={createLabelValueWrapper(this.getVersions())}
                  value={{ label: this.props.options.widgetVersion, value: this.props.options.widgetVersion }}
                />
              </Grid>
            </>
          ) : null}
        </>
      );
    }
  }
}

WidgetSelector.defaultProps = {
  defaultVersion: null
};

export default WidgetSelector;
