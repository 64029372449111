import React, { Component } from 'react';
import Select from 'react-select';
import { copyCampaign, initNewSession } from '../Api';
import { PLATFORMS, FB } from '../Constants';
import { Divider, Grid, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CampaignsNote from '../CampaignsNote';
import { getNewCreatorValue, articleIsBlocked } from '../Shared';
import { toast } from 'react-toastify';

class FacebookCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adsets: [],
      copyType: 'Existing',
      id: '',
      campaignName: '',
      times: 1,
      status: FB.ACTIVE,
      accountId: '',
      prefix: '',
      campaignNote: '',
      campaignCreator: ''
    };
    this.copyTypeList = ['New campaign', 'Existing'].map(el => {
      return { label: el, value: el };
    });
    this.isSwag = props.programOptions.isSwag;
  }

  componentDidUpdate(prevProps) {
    if (this.props.sourceData !== prevProps.sourceData) {
      this.setState(this.parseFBSourceData());
    }
  }

  deleteAdset(idx) {
    let adsets = this.state.adsets;
    adsets.splice(idx, 1);
    this.setState({ adsets: adsets });
  }

  deleteAd(adsetIdx, idx) {
    let adsets = this.state.adsets;
    adsets[adsetIdx].ads.splice(idx, 1);
    this.setState({ adsets: adsets });
  }

  handleCopies(times, type, adset, ad) {
    times = Number(times);
    let state = this.state;
    switch (type) {
      case 'adset':
        state.adsets[adset].times = times;
        break;
      case 'ad':
        state.adsets[adset].ads[ad].times = times;
        break;
      case 'campaign':
        state.times = times;
        break;
      default:
    }
    this.setState(state);
  }

  updateCampaignNote(campaignNote) {
    this.setState({ campaignNote });
  }

  updateCampaignCreator(campaignCreator) {
    this.setState({ campaignCreator });
  }

  renderAdset(adset, idx) {
    return adset ? (
      <React.Fragment key={`adset-${idx}`}>
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom={false}>AdSet</Typography>
        </Grid>
        <Grid item xs={12} sm={5} align="left">
          <Typography gutterBottom variant={'subtitle2'}>
            {adset.name}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={2} align="right">
          <input
            type="number"
            min="1"
            name={'adsetCopy' + idx}
            value={this.state.adsets[idx].times}
            onChange={e => this.handleCopies(e.target.value, 'adset', idx)}
          />
        </Grid>
        <Grid item xs={4} sm={1} align="right">
          <IconButton color="primary" aria-label="delete" onClick={() => this.deleteAdset(idx)} size="large">
            <DeleteForeverIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
        {adset.ads.map((el, AdIdx) => {
          return this.renderAd(el, idx, AdIdx);
        })}
      </React.Fragment>
    ) : null;
  }

  renderAd(ad, adsetIdx, idx) {
    return (
      <React.Fragment key={`ad-${idx}`}>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Ad
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography gutterBottom variant="subtitle1">
            {ad.name}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={2}>
          <input
            type="number"
            min="1"
            name={'adCopy' + adsetIdx + idx}
            value={this.state.adsets[adsetIdx].ads[idx].times}
            onChange={e => this.handleCopies(e.target.value, 'ad', adsetIdx, idx)}
          />
        </Grid>
        <Grid item xs={4} sm={1} align="right">
          <IconButton color="primary" aria-label="delete" onClick={() => this.deleteAd(adsetIdx, idx)} size="large">
            <DeleteForeverIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
      </React.Fragment>
    );
  }

  submit() {
    const options = this.state;
    const creator = this.isSwag
      ? getNewCreatorValue(options.campaignCreator, this.props.creatorsList)
      : '';
    if (!creator && this.isSwag) {
      console.warn(`Creator wasn't selected`, options.campaignCreator);
      toast.error(`Creator wasn't selected, please select.`);
      return;
    }
    options.campaignCreator = creator;
    const request = copyCampaign(
      this.props.ws,
      this.props.session,
      PLATFORMS.FACEBOOK,
      options,
      this.props.isDevSkipCreate()
    );
    this.props.trace([
      {
        id: this.props.session,
        name: `${PLATFORMS.FACEBOOK} copy campaigns ${this.props.session}`,
        request,
        items: []
      }
    ]);
    initNewSession(this.props.ws, PLATFORMS.FACEBOOK, this.props.session);
  }

  render() {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            closeOnSelect={true}
            multi={false}
            onChange={x => this.setState({ copyType: x })}
            options={this.copyTypeList}
            removeSelected={true}
            rtl={false}
            simpleValue
            value={this.state.copyType}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign
          </Typography>
        </Grid>
        <Grid item xs={8} sm={6} align="left">
          <Typography gutterBottom variant={'subtitle2'}>
            {this.state.campaignName}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} align="right">
          <Button variant="contained" color="primary" onClick={() => this.submit()}>
            COPY
          </Button>
        </Grid>
        <CampaignsNote value={this.state.campaignNote} onChange={e => this.updateCampaignNote(e.target.value)} />
        {this.isSwag && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Creator
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                value={this.state.campaignCreator}
                onChange={e => this.updateCampaignCreator(e)}
                options={this.props.creatorsList}
                simpleValue
                clearable={false}
                removeSelected={false}
              />
            </Grid>
          </>
        )}

        {this.state.copyType === 'New campaign' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Times
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <input
                min="0"
                type="number"
                name="campaignCopy"
                value={this.state.times}
                onChange={e => this.handleCopies(e.target.value, 'campaign')}
              />
            </Grid>
          </>
        ) : null}

        {this.state.copyType === 'New campaign' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                closeOnSelect={true}
                multi={false}
                onChange={x => this.setState({ status: x })}
                options={[
                  { label: 'Paused', value: FB.PAUSED },
                  { label: 'Active', value: FB.ACTIVE }
                ]}
                removeSelected={false}
                rtl={false}
                simpleValue
                value={this.state.status}
              />
            </Grid>
          </>
        ) : null}
        {this.state.adsets.map((el, idx) => {
          return this.renderAdset(el, idx);
        })}
      </React.Fragment>
    );
  }

  parseFBSourceData() {
    const sourceData = this.props.sourceData;
    const art = this.props.articleList.wid[sourceData.widget_id];
    if (art && articleIsBlocked(art, this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B)) {
      toast.error(`Error: you trying to copy campaign with blocked article, please select another campaign.`);
      return {
        adsets: [],
        copyType: 'Existing',
        id: '',
        campaignName: '',
        times: 1,
        status: FB.ACTIVE,
        accountId: '',
        prefix: '',
        campaignNote: '',
        campaignCreator: ''
      };
    }
    const adsets = sourceData.adsets
      ? sourceData.adsets.map(adset => {
          return {
            id: adset.id,
            name: adset.name,
            ads: adset.ads
              ? adset.ads.map(ad => ({
                  id: ad.id,
                  name: ad.name,
                  times: 1
                }))
              : [],
            times: 1
          };
        })
      : [];
    const creator = this.isSwag
      ? getNewCreatorValue(sourceData.campaignCreator, this.props.creatorsList)
      : '';
    if (!creator && this.isSwag) {
      console.warn(`Campaign ${sourceData.name} doesn't have creator`);
      toast.error(`Campaign ${sourceData.name} doesn't have creator`);
    }
    return {
      accountId: sourceData.accountId,
      id: sourceData.id,
      times: 1,
      campaignName: sourceData.name,
      adsets: adsets,
      prefix: sourceData.prefix,
      campaignNote: sourceData.campaignNote,
      campaignCreator: creator
    };
  }
}
export default FacebookCopy;
