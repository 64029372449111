import React from 'react';
import { RC } from '../Constants';
import { Grid, Typography } from '@mui/material';

class RevcontentBids extends React.Component {
  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    return parts.filter(el => el).join('-');
  }

  renderBidRow(bid, idx) {
    return (
      <React.Fragment key={'bidLabel' + idx}>
        <Grid item xs={6} sm={6}>
          <Typography gutterBottom variant={'subtitle2'}>
            {this.renderRowName(bid)}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <input
            type="number"
            key={'bidInput' + idx}
            step={RC.BID_STEP}
            value={this.props.bids[idx].bid}
            onChange={e => this.props.modifyBid(idx, e)}
            onBlur={() => this.props.validateBid(idx)}
          />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {this.props.bids
          ? this.props.bids.map((bid, idx) => {
              return this.renderBidRow(bid, idx);
            })
          : null}
      </>
    );
  }
}

export default RevcontentBids;
