import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Typography } from '@mui/material';
import { PLATFORMS } from '../Constants';
import Select from 'react-select';
const options = ['Set preset', 'Save as', 'Delete preset'];

export default function PresetButtonGroup({
  setPreset,
  savePreset,
  saveAsPreset,
  deletePreset,
  handleUpdatingPreset,
  presetName,
  preset,
  platform,
  getPresetOptions,
  handleSelectPreset
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    if (options[selectedIndex] === options[0]) {
      setPreset();
    } else if (options[selectedIndex] === options[1]) {
      saveAsPreset();
    } else if (options[selectedIndex] === options[2]) {
      deletePreset();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleSave = () => {
    savePreset();
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return platform === PLATFORMS.FACEBOOK2 ? (
    <Grid container direction="row" alignItems="flex-end" spacing={1} style={{ marginBottom: '0px' }}>
      <Grid item>
        <ButtonGroup color={'primary'} ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={event => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Grid>

      <Grid item>
        <Typography gutterBottom variant={'subtitle2'}>
          Preset name
        </Typography>
        <input
          style={{ width: '150px', padding: '7px 4px' }}
          type="text"
          value={preset}
          onChange={e => handleUpdatingPreset(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button variant={'outlined'} color={'primary'} onClick={handleSave}>
          Add Preset
        </Button>
      </Grid>
    </Grid>
  ) : (
    <React.Fragment>
      <Grid item sm={4}>
        <Typography gutterBottom variant={'subtitle2'}>
          Preset name
        </Typography>
      </Grid>
      <Grid item sm={5}>
        <input
          style={{ padding: '7px 10px', height: '38px' }}
          type="text"
          value={presetName}
          onChange={e => handleUpdatingPreset(e.target.value)}
        />
      </Grid>
      <Grid item sm={3}>
        <Button
          size={'small'}
          variant={'outlined'}
          fullWidth
          color={'primary'}
          onClick={handleSave}
          style={{ height: '38px' }}
        >
          Save preset
        </Button>
      </Grid>
      <Grid item sm={4}>
        <Typography gutterBottom variant={'subtitle2'}>
          Presets
        </Typography>
      </Grid>

      <Grid item sm={5}>
        <Select
          value={{ label: preset, value: preset }}
          onChange={e => handleSelectPreset(e.value)}
          options={getPresetOptions()}
        />
      </Grid>
      <Grid item sm={3} align={'right'}>
        <ButtonGroup size={'small'} color={'primary'} ref={anchorRef} aria-label="split button">
          <Button
            size={'small'}
            fullWidth
            onClick={handleClick}
            style={{ minWidth: '98px', color: 'primary', minHeight: '38px' }}
          >
            {options[selectedIndex]}
          </Button>
          <Button
            fullWidth
            color="primary"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={event => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Grid>
    </React.Fragment>
  );
}
