import React, { Component } from 'react';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Grid,
  Typography,
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select as SelectMaterial,
  MenuItem,
  TablePagination,
  Divider,
  TextareaAutosize
} from '@mui/material';
import { updateCreativesReview, getB2BPrefixes, getFilteredCreatives } from '../Api';
import { toast } from 'react-toastify';
import { FB } from '../Constants';
import { articleReduce, getSelectValue, getMultiSelectValue, makeSelectOptions } from '../Shared';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import TableSortLabel from '@mui/material/TableSortLabel';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from '../utils/commonUtils';
import Loader from '../Loader';
import { styled } from '@mui/system';
import theme from '../../Theme';

const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: '10px',
  width: '100%'
}));

const StyledContainer = styled(Grid)(({ theme }) => ({
  margin: '10px',
  width: '100%'
}));

const StyledVisuallyHidden = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1
});

const StyledLoader = styled('div')(({ theme }) => ({
  display: 'inline-block',
  margin: 'auto',
  height: '30px',
  '& .loader': {
    width: '50px',
    height: '50px'
  }
}));

const StyledTableCell = styled(TableCell)({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  '&.MuiTableCell-body': {
    fontSize: 12
  }
});

const StyledTableRow = styled(TableRow)(({ theme, status }) => {
  let backgroundColor;
  if (status === 'APPROVED') backgroundColor = '#fff7e9';
  if (status === 'REJECTED') backgroundColor = '#d9d9d9';
  if (status === 'ON_REVIEW') backgroundColor = '#e9f1ff';

  return {
    '&.MuiTableRow-root': {
      backgroundColor
    }
  };
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const res = stabilizedThis.map(el => el[0]);
  return res;
}

class FacebookReviewCreatives extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      prefix: '',
      prefixList: [],
      article: '',
      text: '',
      title: '',
      status: '',
      comment: '',
      reviewer: '',
      startTime: null,
      startTimeRange: null,
      creatives: [],
      filteredCreatives: [],
      isCreativesReviewer: this.props.isCreativesReviewer,
      page: 0,
      rowsPerPage: 25,
      articleList: [],
      order: 'desc',
      orderBy: 'created_time',
      message: false,
      updateColorBtn: 'default',
      loading: false
    };
    this.isSwag = this.props.programOptions.isSwag;
    this.state = JSON.parse(JSON.stringify(this.defaultState));
  }

  async componentDidMount() {
    await this.updateUserOptions();
    if (this.props.isCreativesReviewer) {
      this.setState({ prefix: '', status: 'ON_REVIEW' }, () => {
        this.filterCreatives();
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.filteredCreatives !== prevState.filteredCreatives) {
      this.showMessageResult();
    }
  }

  showMessageResult() {
    if (this.state.filteredCreatives.length) {
      this.setState({ showMessage: false });
    } else {
      this.setState({ showMessage: true });
    }
  }
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    let order = isAsc ? 'desc' : 'asc';
    const creatives = this.state.filteredCreatives;
    const sortedCreatives = stableSort(creatives, getComparator(order, this.state.orderBy));
    this.setState({ order: order, filteredCreatives: sortedCreatives });
  };

  async updateUserOptions() {
    const userOpts = this.props.programOptions;
    let prefixes = [...userOpts.prefix];
    userOpts.prefix = userOpts.spm_prefix_for_facebook
      ? [userOpts.spm_prefix_for_facebook]
      : [userOpts.spm_prefix_default];
    userOpts.prefixList = prefixes;
    if (this.props.isCreativesReviewer) {
      userOpts.prefixList = await getB2BPrefixes();
    }

    const index = articleReduce(
      {},
      this.props.articleList.clv,
      this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B,
      (result, clv) => {
        result[clv.substring(0, 7)] = 1;
      }
    );
    userOpts.articleList = Object.keys(index);

    userOpts.reviewerUser = userOpts.email;
    Object.assign(this.defaultState, userOpts);
    this.setState(userOpts);
  }

  updatePrefix(prefix) {
    this.setState({ prefix });
  }

  updateArticle(article) {
    this.setState({ article });
  }

  updateText(text) {
    this.setState({ text });
  }

  updateTitle(title) {
    this.setState({ title });
  }

  updateStatus(status) {
    this.setState({ status });
  }

  updateComment(comment) {
    this.setState({ comment });
  }

  updateReviewer(reviewer) {
    this.setState({ reviewer });
  }

  updateStartTime(startTime) {
    this.setState({ startTime });
  }

  updateStartTimeRange(startTimeRange) {
    this.setState({ startTimeRange });
  }

  updateCreativePrefix(idx, prefix) {
    let filteredCreatives = this.state.filteredCreatives;
    filteredCreatives[idx].prefix = prefix;
    this.setState({ filteredCreatives });
  }

  updateCreativeArticle(idx, article) {
    let filteredCreatives = this.state.filteredCreatives;
    filteredCreatives[idx].article = article;
    this.setState({ filteredCreatives });
  }

  updateCreativeText = debounce((idx, text) => {
    this.setState(
      prevState => {
        const filteredCreatives = [...prevState.filteredCreatives];
        filteredCreatives[idx].text = text;
        return { filteredCreatives };
      },
      () => {
        this.setState({ updateColorBtn: 'update' });
      }
    );
  }, 1000);

  updateCreativeTitle = debounce((idx, title) => {
    this.setState(
      prevState => {
        const filteredCreatives = [...prevState.filteredCreatives];
        filteredCreatives[idx].title = title;
        return { filteredCreatives };
      },
      () => {
        this.setState({ updateColorBtn: 'update' });
      }
    );
  }, 1000);

  updateCreativeStatus(idx, status) {
    let filteredCreatives = this.state.filteredCreatives;
    filteredCreatives[idx].status = status;
    this.setState({ filteredCreatives, updateColorBtn: 'update' });
  }

  updateCreativeComment = debounce((idx, comment) => {
    this.setState(
      prevState => {
        const filteredCreatives = [...prevState.filteredCreatives];
        filteredCreatives[idx].comment = comment;
        return { filteredCreatives };
      },
      () => {
        this.setState({ updateColorBtn: 'update' });
      }
    );
  }, 1000);

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    const result = parseInt(event.target.value, 10);
    this.setState({ page: 0, rowsPerPage: result });
  }

  filterUpdatedCreatives(creatives) {
    const basicCreatives = this.state.creatives;
    const reviewer = this.state.reviewerUser.split('@')[0];
    const reviewed = creatives.map(el => {
      el.reviewer = reviewer;
      return el;
    });
    const filteredBasicCreatives = reviewed.map(e => basicCreatives.find(({ id }) => id === e.id));
    return reviewed.filter((el, idx) => {
      return (
        el.text !== filteredBasicCreatives[idx].text ||
        el.title !== filteredBasicCreatives[idx].title ||
        el.status !== filteredBasicCreatives[idx].status ||
        el.comment !== filteredBasicCreatives[idx].comment
      );
    });
  }

  async updateCreatives() {
    const updatedCreatives = this.filterUpdatedCreatives(this.state.filteredCreatives);
    if (updatedCreatives.length) {
      try {
        await updateCreativesReview(updatedCreatives);
        toast.success('Creatives are updated');
        this.setState({ updateColorBtn: 'default' });
      } catch (e) {
        toast.error("Creatives aren't updated " + e);
      }
    } else {
      toast.warn('Nothing to update');
    }
  }

  async filterCreatives() {
    this.setState({ loading: true });
    let creatives;
    const startTime = this.state.startTime
      ? moment(this.state.startTime)
          .format()
          .split('T')[0]
      : this.state.startTime;
    const endTime = this.state.startTimeRange
      ? moment(this.state.startTimeRange)
          .add(1, 'days')
          .format()
          .split('T')[0]
      : this.state.startTimeRange;
    creatives = await getFilteredCreatives(
      this.state.prefix,
      this.state.article,
      this.state.text,
      this.state.title,
      this.state.status,
      this.state.comment,
      this.state.reviewer,
      startTime,
      endTime
    );
    creatives = creatives.map(el => {
      if (el.comment === 'null') {
        el.comment = '';
      }
      if (el.text === 'null') {
        el.text = '';
      }
      if (el.title === 'null') {
        el.title = '';
      }
      if (el.reviewer === 'null' || el.reviewer === null) {
        el.reviewer = '';
      }
      return el;
    });
    let filteredCreatives = JSON.parse(JSON.stringify(creatives));
    this.setState({ creatives });
    filteredCreatives = filteredCreatives
      .filter(el => (this.state.prefix ? this.state.prefix.includes(el.prefix) : true))
      .filter(el => (this.state.article ? el.article.includes(this.state.article) : true))
      .filter(el => (this.state.text ? el.text.toLowerCase().includes(this.state.text.toLowerCase()) : true))
      .filter(el => (this.state.title ? el.title.toLowerCase().includes(this.state.title.toLowerCase()) : true))
      .filter(el => (this.state.status ? el.status === this.state.status : true))
      .filter(el => (this.state.comment ? el.comment.toLowerCase().includes(this.state.comment.toLowerCase()) : true))
      .filter(el =>
        this.state.reviewer ? el.reviewer.toLowerCase().includes(this.state.reviewer.toLowerCase()) : true
      );
    this.setState({ filteredCreatives, loading: false });
  }
  renderFilter() {
    return (
      <React.Fragment key="filter">
        <StyledContainer container direction={'row'} spacing={2}>
          <Grid item sm={2}>
            <Typography gutterBottom variant={'subtitle2'}>
              Prefix
            </Typography>
            <Select
              isClearable
              isMulti
              onChange={xs => this.updatePrefix(xs.map(e => e?.value))}
              options={makeSelectOptions(this.state.prefixList)}
              value={getMultiSelectValue(makeSelectOptions(this.state.prefixList), this.state.prefix)}
              isDisabled={!this.state.isCreativesReviewer}
            />
          </Grid>
          <Grid item sm={2}>
            <Typography gutterBottom variant={'subtitle2'}>
              Article
            </Typography>
            <Select
              onChange={e => this.updateArticle(e?.value)}
              options={makeSelectOptions(this.state.articleList)}
              isClearable
              value={getSelectValue(makeSelectOptions(this.state.articleList), this.state.article)}
            />
          </Grid>
          <Grid item sm={2}>
            <Typography gutterBottom variant={'subtitle2'}>
              Text
            </Typography>
            <input
              style={{ lineHeight: '25px', marginBottom: '5px', height: '38px' }}
              type="text"
              name="text"
              value={this.state.text}
              onChange={e => this.updateText(e.target.value)}
            />
          </Grid>
          <Grid item sm={2}>
            <Typography gutterBottom variant={'subtitle2'}>
              Title
            </Typography>
            <input
              style={{ lineHeight: '25px', marginBottom: '5px', height: '38px' }}
              type="text"
              name="title"
              value={this.state.title}
              onChange={e => this.updateTitle(e.target.value)}
            />
          </Grid>
          <Grid item sm={2}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
            <Select
              isClearable
              onChange={x => this.updateStatus(x && x.value ? x.value : '')}
              options={FB.STATUSES}
              value={getSelectValue(FB.STATUSES, this.state.status)}
            />
          </Grid>
          <Grid item sm={2}>
            <Typography gutterBottom variant={'subtitle2'}>
              Comment
            </Typography>
            <input
              style={{ lineHeight: '25px', marginBottom: '5px', height: '38px' }}
              type="text"
              name="comment"
              value={this.state.comment}
              onChange={e => this.updateComment(e.target.value)}
            />
          </Grid>
          {this.state.isCreativesReviewer ? (
            <Grid item sm={2}>
              <Typography gutterBottom variant={'subtitle2'}>
                Reviewer
              </Typography>
              <input
                style={{ lineHeight: '25px', marginBottom: '5px', height: '38px' }}
                type="text"
                name="reviewer"
                value={this.state.reviewer}
                onChange={e => this.updateReviewer(e.target.value)}
              />
            </Grid>
          ) : null}
          <Grid item sm={2} sx={{ marginBottom: '5px' }}>
            <Typography gutterBottom variant={'subtitle2'}>
              Start date
            </Typography>
            <DatePicker
              selectsStart
              startDate={this.state.startTime}
              endDate={this.state.startTimeRange}
              selected={this.state.startTime ? new Date(this.state.startTime) : ''}
              onChange={value => this.updateStartTime(value)}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Grid>
          <Grid item sm={2} sx={{ marginBottom: '5px' }}>
            <Typography gutterBottom variant={'subtitle2'}>
              End date
            </Typography>
            <DatePicker
              selectsEnd
              startDate={this.state.startTime}
              endDate={this.state.startTimeRange}
              selected={this.state.startTimeRange ? new Date(this.state.startTimeRange) : ''}
              onChange={value => this.updateStartTimeRange(value)}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Grid>
          <Grid item container sx={{ margin: '5px 0' }}>
            <Grid item sm={4}>
              <Button
                variant={'contained'}
                style={{
                  width: '200px'
                }}
                color={'primary'}
                onClick={() => this.filterCreatives()}
              >
                Search
              </Button>
            </Grid>
            <Grid item sm={4} align={'center'}>
              {this.state.loading ? (
                <StyledLoader>
                  <Loader />
                </StyledLoader>
              ) : null}
            </Grid>
            {this.state.isCreativesReviewer ? (
              <Grid item sm={4} align={'right'}>
                <Button
                  variant={'contained'}
                  style={{
                    width: '200px',
                    color: this.state.updateColorBtn === 'default' ? 'black' : 'white',
                    backgroundColor: this.state.updateColorBtn === 'default' ? '#A4A4A46E' : 'rgba(30,176,79,0.98)'
                  }}
                  onClick={() => this.updateCreatives()}
                >
                  Update
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {this.state.filteredCreatives.length ? this.renderCreatives(this.state.filteredCreatives) : null}
          {this.state.showMessage ? this.renderMessageTable() : null}
        </StyledContainer>
      </React.Fragment>
    );
  }
  renderArticle(article) {
    let headline;
    if (article) {
      headline = Object.values(this.props.articleList.clv).find(art => art.clv.includes(article));
    }
    return (
      <div style={{ width: '75px' }}>
        <Typography style={{ marginBottom: '10px', fontSize: '12px' }}>{article}</Typography>
        <Divider />
        <Typography style={{ fontSize: '12px' }}>{headline ? headline.title : ''}</Typography>
      </div>
    );
  }

  renderCreatives(creatives) {
    return (
      <>
        <TableContainer>
          <StyledTable aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Prefix</StyledTableCell>
                <StyledTableCell>Article</StyledTableCell>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Text</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Comment</StyledTableCell>
                <StyledTableCell>
                  <TableSortLabel direction={this.state.order} onClick={e => this.handleRequestSort(e, 'created_time')}>
                    Creation time
                    {
                      <StyledVisuallyHidden>
                        {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </StyledVisuallyHidden>
                    }
                  </TableSortLabel>
                </StyledTableCell>
                {this.state.isCreativesReviewer ? <StyledTableCell>Reviewer</StyledTableCell> : null}
                {this.state.isCreativesReviewer ? <StyledTableCell>Reviewed date</StyledTableCell> : null}
                <StyledTableCell>Blacklist words</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creatives
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                )
                .map((row, i) => (
                  <StyledTableRow key={row.id} status={row.status}>
                    <StyledTableCell component="th" scope="row">
                      {row.prefix}
                    </StyledTableCell>
                    <StyledTableCell>{this.renderArticle(row.article)}</StyledTableCell>
                    <StyledTableCell>
                      <a href={row.image} rel="noopener noreferrer" target="_blank">
                        <img src={row.image} alt={row.image} width="100px" />
                      </a>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextareaAutosize
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          backgroundColor: 'transparent',
                          outline: 'none',
                          resize: 'none'
                        }}
                        defaultValue={row.title || ''}
                        onChange={e =>
                          this.updateCreativeTitle(i + this.state.page * this.state.rowsPerPage, e.target.value)
                        }
                        readOnly={!this.state.isCreativesReviewer}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextareaAutosize
                        style={{
                          minWidth: '200px',
                          border: 'none',
                          backgroundColor: 'transparent',
                          outline: 'none',
                          resize: 'none'
                        }}
                        defaultValue={row.text || ''}
                        onChange={e =>
                          this.updateCreativeText(i + this.state.page * this.state.rowsPerPage, e.target.value)
                        }
                        readOnly={!this.state.isCreativesReviewer}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <SelectMaterial
                        style={{ fontSize: '12px' }}
                        value={row.status}
                        variant="standard"
                        onChange={e =>
                          this.updateCreativeStatus(i + this.state.page * this.state.rowsPerPage, e.target.value)
                        }
                        disabled={!this.state.isCreativesReviewer}
                      >
                        {FB.STATUSES.map(name => (
                          <MenuItem key={name.value} value={name.value} style={{ fontSize: '12px' }}>
                            {name.value}
                          </MenuItem>
                        ))}
                      </SelectMaterial>
                    </StyledTableCell>
                    <StyledTableCell>
                      <InputBase
                        type="text"
                        align={'left'}
                        defaultValue={row.comment || ''}
                        sx={{ fontSize: '12px' }}
                        onChange={e =>
                          this.updateCreativeComment(i + this.state.page * this.state.rowsPerPage, e.target.value)
                        }
                        readOnly={!this.state.isCreativesReviewer}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <InputBase
                        align={'left'}
                        sx={{ fontSize: '12px' }}
                        inputProps={{ 'aria-label': 'naked' }}
                        type="text"
                        name="created_time"
                        value={row.created_time.split('T')[0]}
                        readOnly={true}
                      />
                    </StyledTableCell>
                    {this.state.isCreativesReviewer ? (
                      <StyledTableCell component="th" scope="row">
                        {row.reviewer}
                      </StyledTableCell>
                    ) : null}
                    {this.state.isCreativesReviewer ? (
                      <StyledTableCell>
                        <InputBase
                          align={'left'}
                          sx={{ fontSize: '12px' }}
                          inputProps={{ 'aria-label': 'naked' }}
                          type="text"
                          name="reviewed_time"
                          value={row.reviewed_time ? row.reviewed_time.split('T')[0] : ''}
                          readOnly={true}
                        />
                      </StyledTableCell>
                    ) : null}
                    <StyledTableCell component="th" scope="row">
                      {row.blacklist_words && row.blacklist_words.map((item, i) => <p key={i}>{item}</p>)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={creatives.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onRowsPerPageChange={e => this.handleChangeRowsPerPage(e)}
          onPageChange={(e, value) => this.handleChangePage(e, value)}
        />
      </>
    );
  }
  renderMessageTable() {
    return (
      <Grid spacing={1} container direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <Grid item align={'center'}>
          <SearchIcon />
        </Grid>
        <Grid item align={'center'}>
          <Typography variant={'h6'} gutterBottom>
            Nothing found
          </Typography>
        </Grid>
      </Grid>
    );
  }
  render() {
    return this.renderFilter();
  }
}

export default FacebookReviewCreatives;
