import { Button, Divider, Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CreatableSelect from 'react-select/creatable';
import React from 'react';

export class BulkSection extends React.Component {
  render() {
    const {
      fetchCampaigns,
      campaignOptions,
      selectedCampaigns,
      handleChangeCampaignNames,
      handleCreateOption
    } = this.props;
    return (
      <>
        <Grid item container direction={'row'} sm={4}>
          <Tooltip
            title={
              'You can paste a list of the comma or space separated campaign names. Example:\n' +
              'name1,\n' +
              'name2,\n' +
              'name3" or\n' +
              '"name1 name2"'
            }
          >
            <Typography gutterBottom variant={'subtitle2'}>
              Source campaigns {<HelpOutlineIcon color={'disabled'} fontSize={'small'} />}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item sm={8}>
          <CreatableSelect
            isMulti
            options={campaignOptions}
            value={selectedCampaigns}
            onChange={e => handleChangeCampaignNames(e)}
            onCreateOption={e => handleCreateOption(e)}
            placeholder="Type or paste campaign name..."
            formatCreateLabel={inputValue => `${inputValue}`}
          />
        </Grid>
        <Grid item sm={4}>
          <Typography gutterBottom variant={'subtitle2'} color="textSecondary">
            Source campaign info
          </Typography>
        </Grid>
        <Grid item sm={4}>
          {this.props.sourceData && (
            <Typography gutterBottom variant={'subtitle2'} color="textSecondary">
              {selectedCampaigns && selectedCampaigns.length > 0
                ? `${this.props.sourceData.length} source campaigns for duplication`
                : ''}
            </Typography>
          )}
        </Grid>
        <Grid item sm={4}>
          <Tooltip placement={'top-start'} title={'Get source campaigns'}>
            <Button color="primary" variant="outlined" fullWidth onClick={() => fetchCampaigns(selectedCampaigns)}>
              Fetch campaigns
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={12} align="center">
          <Divider />
        </Grid>
      </>
    );
  }
}
