import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import {
  Badge,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled } from '@mui/system';
import theme from '../Theme';

const StyledDialog = styled(Dialog)({
  margin: 0,
  padding: theme.spacing(1)
});

const StyledVisuallyHidden = styled('span')({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1
});
const StyledButton = styled(Button)({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500]
});

const StyledTableCell = styled(TableCell)({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  '&.MuiTableCell-body': {
    fontSize: 12
  }
});

const StyledTableRow = styled(TableRow)(({ theme, isChecked }) => {
  let backgroundColor;
  if (isChecked) {
    backgroundColor = '#eef4ff';
  } else {
    backgroundColor = '#ffffff';
  }

  return {
    '&.MuiTableRow-root': {
      backgroundColor
    }
  };
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const res = stabilizedThis.map(el => el[0]);
  return res;
}

class CreativeBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      creativeBlocks: this.props.data.map(adBlock => ({ checked: false, block: adBlock })),
      page: 0,
      rowsPerPage: 25,
      articleList: [],
      order: 'desc',
      orderBy: 'created_time',
      adsets: this.props.adsets
    };
  }
  selectAll() {
    const newBlocks = this.state.creativeBlocks.map(e => {
      e.checked = true;
      return e;
    });
    this.setState({
      creativeBlocks: newBlocks
    });
  }

  removeAll() {
    const newBlocks = this.state.creativeBlocks.map(e => {
      e.checked = false;
      return e;
    });
    this.setState({
      creativeBlocks: newBlocks
    });
  }

  handleClose() {
    this.props.onClose();
  }

  handleChange(v, id) {
    const creativeBlocks = this.state.creativeBlocks;
    const index = creativeBlocks.findIndex(el => el.block.id === id);
    creativeBlocks[index].checked = !creativeBlocks[index].checked;
    this.setState({ creativeBlocks: creativeBlocks });
  }

  handleSave() {
    const result = this.state.creativeBlocks.filter(e => e.checked).map(e => e.block);
    this.props.onSave(result);
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    let order = isAsc ? 'desc' : 'asc';
    const creatives = this.state.data;
    const creativeBlocks = this.state.creativeBlocks;
    const sortedCreatives = stableSort(creatives, getComparator(order, this.state.orderBy));
    const newBlocks = [];

    for (let item in sortedCreatives) {
      for (let value in creativeBlocks) {
        if (sortedCreatives[item].id === creativeBlocks[value].block.id) {
          newBlocks.push({ checked: creativeBlocks[value].checked, block: sortedCreatives[item] });
        }
      }
    }

    this.setState({ order: order, creativeBlocks: newBlocks });
  };

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    const result = parseInt(event.target.value, 10);
    this.setState({ page: 0, rowsPerPage: result });
  }

  isChecked(id) {
    const creativeBlocks = this.state.creativeBlocks;
    const index = creativeBlocks.findIndex(el => el.block.id === id);
    return creativeBlocks[index].checked;
  }

  isSelectedCreative(id) {
    return (
      this.state.adsets.length > 0 &&
      this.state.adsets
        .map(el => el.ads)
        .flat()
        .find(el => el.id === id)
    );
  }

  render() {
    return (
      <StyledDialog maxWidth="md" onClose={() => this.handleClose()} aria-labelledby="simple-dialog-title" open={true}>
        <DialogTitle>
          Select creatives {this.state.creativeBlocks.filter(e => e.checked).length}/{this.state.data.length}
        </DialogTitle>
        <StyledButton aria-label="close" onClick={() => this.handleClose()}>
          <CloseIcon />
        </StyledButton>
        <DialogContent
          dividers
          sx={{
            margin: '10px 0 10px 0'
          }}
        >
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>

                  <StyledTableCell>Prefix</StyledTableCell>
                  <StyledTableCell>Article</StyledTableCell>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>Text</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      direction={this.state.order}
                      onClick={e => this.handleRequestSort(e, 'created_time')}
                    >
                      Creation time
                      {
                        <StyledVisuallyHidden>
                          {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </StyledVisuallyHidden>
                      }
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.creativeBlocks
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                  )
                  .map((row, i) => (
                    <StyledTableRow
                      isChecked={this.isChecked(row.block.id)}
                      key={row.block.id}
                      onClick={e => this.handleChange(e.target, row.block.id)}
                    >
                      <StyledTableCell>
                        <input
                          type={'checkbox'}
                          checked={this.isChecked(row.block.id)}
                          onChange={e => this.handleChange(e.target.value, row.block.id)}
                          onClick={e => this.handleChange(e.target, row.block.id)}
                          name={'creativeCheckbox'}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.block.prefix}
                      </StyledTableCell>
                      <StyledTableCell>{row.block.article}</StyledTableCell>
                      <StyledTableCell>
                        <a href={row.block.image} rel="noopener noreferrer" target="_blank">
                          {this.isSelectedCreative(row.block.id) ? (
                            <Badge badgeContent={`✔`} color="primary">
                              <img src={row.block.image} alt={row.block.image} width="100px" />
                            </Badge>
                          ) : (
                            <img src={row.block.image} alt={row.block.image} width="100px" />
                          )}
                        </a>
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputBase
                          align={'left'}
                          inputProps={{ 'aria-label': 'naked' }}
                          style={{ fontSize: '12px' }}
                          type="text"
                          value={row.block.title || ''}
                          onChange={e => this.updateCreativeTitle(i, e.target.value)}
                          readOnly={!this.state.isCreativesReviewer}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputBase
                          align={'left'}
                          style={{ fontSize: '12px' }}
                          type="text"
                          value={row.block.text || ''}
                          onChange={e => this.updateCreativeText(i, e.target.value)}
                          readOnly={!this.state.isCreativesReviewer}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputBase
                          align={'left'}
                          style={{ fontSize: '12px' }}
                          inputProps={{ 'aria-label': 'naked' }}
                          type="text"
                          name="created_time"
                          value={row.block.created_time.split('T')[0]}
                          readOnly={true}
                        />
                      </StyledTableCell>
                      {this.state.isCreativesReviewer ? (
                        <StyledTableCell component="th" scope="row">
                          {row.block.reviewer}
                        </StyledTableCell>
                      ) : null}
                      {this.state.isCreativesReviewer ? (
                        <StyledTableCell>
                          <InputBase
                            align={'left'}
                            style={{ fontSize: '12px' }}
                            inputProps={{ 'aria-label': 'naked' }}
                            type="text"
                            name="reviewed_time"
                            value={row.block.reviewed_time ? row.block.reviewed_time.split('T')[0] : ''}
                            readOnly={true}
                          />
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={this.state.creativeBlocks.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onRowsPerPageChange={e => this.handleChangeRowsPerPage(e)}
            onPageChange={(e, value) => this.handleChangePage(e, value)}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="outlined" color="primary" onClick={() => this.selectAll()}>
            Select all
          </Button>
          <Button fullWidth variant="outlined" color="primary" onClick={() => this.removeAll()}>
            Remove all
          </Button>
          <Button fullWidth variant="contained" color="primary" onClick={() => this.handleSave()}>
            Save
          </Button>
          <Button fullWidth variant="outlined" onClick={() => this.handleClose()}>
            Cancel
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
}

export default CreativeBank;
