import { createTheme } from '@mui/material/styles';
const arcBlue = '#3087e7';
const white = '#fff';
const grey = '#eaeaea';
// const colorTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export default createTheme({
  palette: {
    // mode: arcBlue,
    common: {
      blue: arcBlue
    },
    primary: {
      main: arcBlue
    },
    secondary: {
      main: white
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    secondary: {
      main: grey
    }
  }
});
