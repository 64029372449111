import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { PLATFORMS, TB, FB, OB } from '../Constants';

const StyledPreviewImage = styled('img')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  objectFit: 'contain',
  width: '100%',
  minHeight: '141px'
}));
const StyledPreviewDiv = styled('div')(() => ({
  overflow: 'hidden',
  width: '150px',
  height: '200px',
  maxWidth: '200px',
  maxHeight: '200px'
}));

const StyledPreviewPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  padding: theme.spacing(1),
  position: 'relative',
  margin: 'auto',
  maxWidth: '500px',
  maxHeight: '500px'
}));
const StyledPreviewText = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1)
}));

class ImageCrop extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.src,
      crop: {
        unit: '%',
        width: 100,
        aspect:
          this.props.platform === PLATFORMS.TABOOLA
            ? TB.DESKTOP_ASPECT_RATIO
            : this.props.platform === PLATFORMS.OUTBRAIN
            ? OB.DESKTOP_ASPECT_RATIO
            : FB.DESKTOP_ASPECT_RATIO
      },
      blob: null,
      index: this.props.adsetIndex,
      windowWidth: 0,
      windowHeight: 0,
      showCropper: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
    this.setState({ showCropper: true });
  };

  onCropComplete = (crop, type) => {
    this.makeClientCrop(crop, type);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpg');
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          this.setState({ blob: blob });
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        0.8
      );
    });
  }

  horizontalCropImage() {
    let newCrop = this.state.crop;
    newCrop.aspect =
      this.props.platform === PLATFORMS.TABOOLA
        ? TB.DESKTOP_ASPECT_RATIO
        : this.props.platform === PLATFORMS.OUTBRAIN
        ? OB.DESKTOP_ASPECT_RATIO
        : FB.DESKTOP_ASPECT_RATIO;
    this.setState({ crop: newCrop });
  }

  squareCropImage() {
    let newCrop = this.state.crop;
    newCrop.aspect = 1;
    this.setState({ crop: newCrop });
  }
  freeCropImage() {
    let newCrop = this.state.crop;
    newCrop.aspect = '';
    this.setState({ crop: newCrop });
  }

  onSave(blob, url) {
    this.props.onAddImage(this.props.adsetIndex, { target: { files: [blob] } });
    this.setState({ image: url });
  }

  render() {
    const { crop, src } = this.state;

    return (
      <>
        <Grid container direction="row" spacing={2}>
          {src && (
            <Grid item xs={12} sm={8}>
              <>
                <StyledPreviewPaper>
                  <ReactCrop
                    imageStyle={{ maxHeight: '440px' }}
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </StyledPreviewPaper>
              </>
            </Grid>
          )}
          <>
            <Grid item xs={12} sm={4}>
              <StyledPreviewDiv>
                <StyledPreviewText variant="subtitle1">Preview</StyledPreviewText>

                {this.state.croppedImageUrl && <StyledPreviewImage alt="preview" src={this.state.croppedImageUrl} />}
              </StyledPreviewDiv>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                key={'freeCropImageButton'}
                onClick={() => this.freeCropImage()}
              >
                Free aspect
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                key={'horizontalCropImageButton'}
                onClick={() => this.horizontalCropImage()}
              >
                {this.props.platform === PLATFORMS.TABOOLA
                  ? TB.DESKTOP_ASPECT_RATIO
                  : this.props.platform === PLATFORMS.OUTBRAIN
                  ? OB.DESKTOP_ASPECT_RATIO_INFO
                  : FB.DESKTOP_ASPECT_RATIO}
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                key={'squareCropImageButton'}
                onClick={() => this.squareCropImage()}
              >
                1 : 1
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                key={'saveButton'}
                onClick={() => this.onSave(this.state.blob, this.state.croppedImageUrl)}
              >
                Save
              </Button>
            </Grid>
          </>
        </Grid>
      </>
    );
  }
}
export default ImageCrop;
