import React from 'react';
import GoogleAdsCreativeGroup from './GoogleAdsCreativeGroup';

class GoogleAdsCreativeGroupBuilder extends React.Component {
  render() {
    return (
      <>
        {this.props.groups.map((el, idx) => (
          <GoogleAdsCreativeGroup
            index={idx}
            key={'gaCreativeGroup' + idx}
            onAddImages={(idx, imageBlob, deviceType, index) =>
              this.props.onAddImages(idx, imageBlob, deviceType, index)
            }
            onAddTextRow={(groupIdx, idx, type) => this.props.onAddTextRow(groupIdx, idx, type)}
            handleDeleteTextRow={(groupIdx, idx, type) => this.props.handleDeleteTextRow(groupIdx, idx, type)}
            onChangeImageText={(imageIdx, type, e) => this.props.onChangeImageText(idx, imageIdx, type, e)}
            onPasteImageText={(imageIdx, type, e) => this.props.onPasteImageText(idx, imageIdx, type, e)}
            removeImage={imageIdx => this.props.removeImage(idx, imageIdx)}
            duplicateImage={imageIdx => this.props.duplicateImage(idx, imageIdx)}
            removeGroup={() => this.props.removeGroup(idx)}
            duplicateGroup={() => this.props.duplicateGroup(idx)}
            populateText={imageIdx => this.props.populateText(idx, imageIdx)}
            group={this.props.groups[idx]}
            options={this.props.options}
          />
        ))}
      </>
    );
  }
}

export default GoogleAdsCreativeGroupBuilder;
