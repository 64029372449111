import React, { Component } from 'react';
import { PLATFORMS, TW } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import {
  Grid,
  Typography,
  Toolbar,
  Card,
  CardContent,
  CardHeader,
  TextField,
  CardActions,
  Input,
  Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PublishIcon from '@mui/icons-material/Publish';
import CropWindow from '../CropWindow';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import { StyledDiv, StyledPrimaryAppBar, StyledCardMedia } from '../SharedStyles';

class TwitterCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCropper: false,
      copyDefaultText: ''
    };
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  cancelCropWindow() {
    this.setState({ showCropper: false });
  }

  applyCropWindow(images) {
    images.forEach(image => {
      for (const type in image.crops) {
        if (image.crops[type]) {
          this.props.onAddImages(this.props.index, image.crops[type].blob, image.crops[type].type, image.imageIndex);
        }
      }
    });
    this.setState({ showCropper: false });
  }

  uploadMultipleFiles = e => {
    const fileObj = [];
    const fileArray = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    this.setState({ imageFiles: fileArray, showCropper: true });
  };

  showHelperTitle = idx => {
    const title = !this.props.group.titles[idx] ? 0 : this.props.group.titles[idx].length;
    return `${title}/${TW.MAX_TITLE_CHARS}`;
  };

  showHelperText = idx => {
    const text = !this.props.group.texts[idx] ? 0 : this.props.group.texts[idx].length;
    return `${text}/${TW.MAX_TEXT_CHARS}`;
  };

  renderImage(path, idx) {
    const fullPath = getFullPath(path) || getFullPath(path.image);
    return (
      <Grid item xs={6} sm={4} key={this.getKey('sep', idx)}>
        <Card>
          <CardHeader
            subheader={
              <>
                <Grid container direction="column">
                  <Grid item xs={12} sm={12}>
                    <Tooltip
                      key={this.getKey('tooltip', idx)}
                      title={this.props.group.tooltips[idx] ? this.props.group.tooltips[idx].text : ''}
                    >
                      <TextField
                        key={this.getKey('imageTitle', idx)}
                        value={this.props.group.titles[idx]}
                        onChange={(imageIdx, e) => this.props.onChangeImageText(idx, 'titles', imageIdx, e)}
                        onBlur={imageIdx => this.props.onBlurImageText(idx, imageIdx)}
                        onPaste={(imageIdx, e) => this.props.onPasteImageText(idx, 'titles', imageIdx, e)}
                        placeholder="Card headline"
                        variant="standard"
                        multiline
                        minRows={1}
                        maxRows={10}
                        inputProps={{
                          maxLength: TW.MAX_TITLE_CHARS
                        }}
                        helperText={this.showHelperTitle(idx)}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} align="center"></Grid>
                </Grid>
              </>
            }
          />
          <StyledCardMedia key={this.getKey('sep', idx)} image={fullPath} title="Example" />
          <CardContent>
            <Grid item xs={12} sm={12}>
              <TextField
                key={this.getKey('imageText', idx)}
                value={this.props.group.texts[idx]}
                onChange={(imageIdx, e) => this.props.onChangeImageText(idx, 'texts', imageIdx, e)}
                onBlur={imageIdx => this.props.onBlurImageText(idx, imageIdx)}
                onPaste={(imageIdx, e) => this.props.onPasteImageText(idx, 'texts', imageIdx, e)}
                inputProps={{
                  maxLength: TW.MAX_TEXT_CHARS
                }}
                minRows={1}
                maxRows={10}
                placeholder="Tweet text"
                variant="standard"
                multiline
                // label="Tweet Text"
                helperText={this.showHelperText(idx)}
              />
            </Grid>
          </CardContent>

          <CardActions disableSpacing>
            <IconButton
              color="primary"
              key={this.getKey('populateButton', idx)}
              onClick={imageIdx => this.props.populateText(idx, imageIdx)}
              title="Populate all texts in group"
              size="large"
            >
              <Tooltip title="Populate all texts in group">
                <TextRotationNoneIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              color="primary"
              aria-label="delete"
              key={this.getKey('imageRemoveButton', idx)}
              onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
              size="large"
            >
              <DeleteForeverIcon />
            </IconButton>
            <IconButton
              color="primary"
              key={this.getKey('imageDuplicateButton', idx)}
              onClick={imageIdx => this.props.duplicateImage(idx, imageIdx)}
              size="large"
            >
              <FileCopyIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  }

  render() {
    return (
      <React.Fragment>
        <StyledDiv>
          <StyledPrimaryAppBar position="static">
            <Toolbar>
              <Typography>Campaign {this.props.index + 1}</Typography>
              <label htmlFor={'addImagesButton' + this.props.index}>
                <IconButton color="secondary" component="span" size="large">
                  <PublishIcon />
                </IconButton>
              </label>
              <Input
                type="file"
                id={'addImagesButton' + this.props.index}
                onChange={this.uploadMultipleFiles}
                inputProps={{ multiple: true }}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
              <IconButton
                key={this.getKey('groupDuplicateButton')}
                color="secondary"
                aria-label="duplicate"
                onClick={idx => this.props.duplicateGroup(idx)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                key={this.getKey('campRemoveButton', this.props.index)}
                type="button"
                onClick={idx => this.props.removeGroup(idx)}
                title="Remove campaign"
                color="secondary"
                aria-label="remove"
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Toolbar>
          </StyledPrimaryAppBar>
        </StyledDiv>

        <Grid container direction="row" spacing={1}>
          {this.state.showCropper ? (
            <CropWindow
              fromInsights={false}
              notRequired={true}
              desktopAspectRatio={TW.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={TW.SMARTPHONE_ASPECT_RATIO}
              data={this.state.imageFiles}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              index={this.props.index}
              group={this.props.group.images}
              platform={PLATFORMS.TWITTER}
            />
          ) : null}
          {this.props.group.images.map((path, i) => this.renderImage(path, i))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default TwitterCreativeGroup;
