import { Component } from 'react';
import { login } from '../utils/AuthService';

class Login extends Component {
  componentDidMount() {
    login();
  }

  render() {
    return null;
  }
}

export default Login;
