// WARNING!!!
// Make sure to insert intercom code to html file before using this utils

// https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// APIs that could be used in the future

// Also make sure to make updates to monorepo/web/manage if you are making changes to this file 🙂

/**
 * @param user {{ email: string, name: string, created_at: string }}
 */
export const bootIntercomForNonShinez = user => {
  if (user.email.includes('@shinez.io')) return;
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'sa6rsezz',
    email: user.email,
    name: user.name,
    created_at: user.created_at
  });
};

/**
 * @param user {{ email: string, name: string, created_at: string }}
 * @param newData {{ page: string }}
 */
export const updateIntercom = (user, newData) => {
  window.Intercom('update', {
    page: newData.page
  });
};

export const shutdownIntercom = () => {
  window.Intercom('shutdown');
};
