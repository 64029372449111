import { DateTime } from 'luxon';

/**
 * Creates string representation of local time appending corresponding
 * timezone - EDT or EST.
 * @param {*} jsOrMomentTimestamp
 * @returns
 */
export function formatNYTimestamp(jsOrMomentTimestamp) {
  const ts = DateTime.fromISO(jsOrMomentTimestamp.toISOString());
  const tz = DateTime.fromObject(
    { year: ts.year, month: ts.month, day: ts.day },
    { zone: 'America/New_York' }
  ).toFormat('ZZZZ');
  const str = `${ts.toFormat('yyyy-MM-dd HH:mm:ss')} ${tz}`;
  return str;
}

export function convertToEST(localTime) {
  let date = DateTime.fromISO(localTime);
  let estDate = date.setZone('America/New_York', { keepLocalTime: true });
  return estDate.toFormat("yyyy-MM-dd'T'HH:mm:00ZZ");
}

export function convertToUTC(estTime) {
  let date = DateTime.fromISO(estTime);
  let utcDate = date.toUTC();
  return utcDate.toFormat("yyyy-MM-dd'T'HH:mm:00'Z'");
}
