import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { getServiceMessage } from '../Api';
import parse from 'html-react-parser';
import Close from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const Title = styled(DialogTitle)({
  textAlign: 'center',
  backgroundColor: '#f5f5f5',
  padding: '16px'
});

const CloseButton = styled(Button)({
  position: 'absolute',
  right: '8px',
  top: '8px'
});

const Content = styled(DialogContent)({
  padding: '16px'
});

const MessageModal = ({ open, handleClose }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getServiceMessage()
        .then(messageData => {
          const message = messageData.find(el => el.isBlockSpm !== true);
          setData(message);
        })
        .catch(error => {
          console.error('Error fetching service message:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  if (!open || loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Title>
        <Typography variant="inherit">{data.title}</Typography>
        <CloseButton onClick={handleClose}>
          <Close />
        </CloseButton>
      </Title>
      <Content>
        <Typography variant="body1" gutterBottom>
          {parse(String(data.text))}
        </Typography>
      </Content>
      <Button onClick={handleClose}>Close</Button>
    </Dialog>
  );
};

export default MessageModal;
