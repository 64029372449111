import React, { Component } from 'react';
import { login, isLoggedIn } from '../utils/AuthService';
import FadeMenu from './SideMenu';
import { AppBar, Toolbar, Grid, Typography, Button } from '@mui/material';
import { REVIEWER } from './Constants';
import { bootIntercomForNonShinez } from '../utils/intercom';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '620px',
  margin: 'auto'
}));
const StyledLogo = styled('img')(({ theme }) => ({
  marginTop: '5px',
  maxHeight: '35px'
}));
const StyledYJLogo = styled('img')(({ theme }) => ({
  marginTop: '5px',
  maxHeight: '45px'
}));

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user.name,
      email: this.props.user.email
    };
  }
  componentDidMount() {
    bootIntercomForNonShinez(this.props.user);
  }
  render() {
    return (
      <>
        {!isLoggedIn() ? (
          login()
        ) : (
          <AppBar>
            <Toolbar>
              <StyledContainer container direction="row" alignItems="center">
                {this.state.user === REVIEWER.DEMO_WW ? (
                  <Grid item xs={2} sm={2} align="left">
                    <Link to="/">
                      <StyledLogo alt="logo" src="../images/wewords.png" />
                    </Link>
                  </Grid>
                ) : this.state.user === REVIEWER.DEMO_YJ ? (
                  <Grid item xs={2} sm={2} align="left">
                    <Link to="/">
                      <StyledYJLogo alt="logo" src="../images/jy.png" />
                    </Link>
                  </Grid>
                ) : !this.state.user || !this.state.user.length ? null : (
                  <Grid item xs={2} sm={2} align="left">
                    <Link to="/">
                      <StyledLogo alt="logo" src="../images/logo.png" />
                    </Link>
                  </Grid>
                )}
                <Grid item sm={6} xs={6}>
                  <Typography variant="subtitle2">{this.state.user ? `Welcome ${this.state.user}` : ''}</Typography>
                </Grid>
                <Grid item xs={4} sm={4} align="right">
                  <FadeMenu
                    onLogout={() => this.forceUpdate()}
                    onTest={this.props.onTest}
                    onTasks={this.props.onTasks}
                    taskCode={this.props.taskCode}
                    devSkipCreate={this.props.devSkipCreate}
                    setDevSkipCreate={this.props.setDevSkipCreate}
                    toggleAllowPageSelection={this.props.toggleAllowPageSelection}
                    allowPageSelection={this.props.allowPageSelection}
                    email={this.state.email}
                    user={this.props.user}
                  />
                </Grid>
              </StyledContainer>
            </Toolbar>
          </AppBar>
        )}
      </>
    );
  }
}

export default Nav;
