import decode from 'jwt-decode';
import auth0 from 'auth0-js';
import { shutdownIntercom } from './intercom';
import { getPrefixList, sendLoggedUser } from '../components/Api';
import { isSwag } from '../components/Shared';

const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || 'MB4waZ5du5MFMGQepfgORncWy5tfuZXi';
const CLIENT_DOMAIN = process.env.REACT_APP_AUTH0_SHORT_DOMAIN || 'shinez.auth0.com';
const SCOPE = process.env.REACT_APP_AUTH0_SCOPE || 'full';
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://damp-fjord-90079.herokuapp.com/';

const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN
});

export function login() {
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: window.location.origin + '/callback',
    audience: AUDIENCE,
    scope: SCOPE
  });
}

export function logout() {
  clearIdToken();
  clearAccessToken();
  shutdownIntercom();
}

export function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' });
  }
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function clearIdToken() {
  return localStorage.removeItem(ID_TOKEN_KEY);
}

export function clearAccessToken() {
  return localStorage.removeItem(ACCESS_TOKEN_KEY);
}

function getParameterByName(name) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export async function getUserInfo() {
  const endpoint = '/api/userinfo';
  const response = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
  return await response.json();
}

export async function onLoginCallback() {
  const accessToken = getParameterByName('access_token');
  if (!accessToken) {
    return;
  }
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

  const idToken = getParameterByName('id_token');
  if (idToken) {
    localStorage.setItem(ID_TOKEN_KEY, idToken);
  }
  const userInfo = await getUserInfo();
  if (userInfo && userInfo.email) {
    await sendLoggedUser(userInfo.email);
  }
}

export async function getParsedUserOptions() {
  const parsed = await getUserInfo();
  let platforms = ['baidu', 'taboola', 'revcontent', 'facebook', 'outbrain', 'twitter', 'google_ads'];
  let result = {};

  try {
    result = {
      ...parsed.app_metadata,
      ...parsed.user_metadata,
      ...parsed,
      defaultLanguage: parsed.spm_default_widget_language
    };

    if (result.prefix === '*') {
      result.prefix = await getPrefixList();
    }

    for (const platform of platforms) {
      result[`spm_prefix_for_${platform}`] = result.options.prefix[platform];
    }
    result.spm_prefix_default = result.options.prefix.default;
  } catch (e) {
    console.error("Couldn't parse user options" + e, ' json:', parsed);
  }
  return result;
}

export function isLoggedIn() {
  const idToken = getIdToken();
  return !!idToken && !isTokenExpired(idToken);
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
