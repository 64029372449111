import React, { useState } from 'react';
import {
  Alert,
  Badge,
  Box,
  IconButton,
  Popper,
  Fade,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CheckIcon from '@mui/icons-material/Check';

import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function NotificationCenter() {
  const { notifications, clear, markAllAsRead, markAsRead, unreadCount } = useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleNotificationCenter = event => {
    event.stopPropagation();
    setAnchorEl(isOpen ? null : event.currentTarget);
    setIsOpen(!isOpen);
  };
  const severityMap = {
    default: 'info',
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
  };
  const toggleFilter = e => {
    setShowUnreadOnly(!showUnreadOnly);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ margin: '8px' }}>
      <IconButton size="large" onClick={toggleNotificationCenter}>
        <Badge badgeContent={unreadCount} color="primary">
          <NotificationsNoneIcon color="secondary" />
        </Badge>
      </IconButton>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper open={isOpen} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box>
                <Box
                  sx={{
                    background: '#3087e7',
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h6" color="#fff">
                    Notification center
                  </Typography>
                  <FormGroup sx={{ color: '#fff' }}>
                    <FormControlLabel
                      control={<Switch color="secondary" onChange={toggleFilter} checked={showUnreadOnly} />}
                      label="Show unread only"
                    />
                  </FormGroup>
                </Box>
                <Stack
                  sx={{
                    height: '400px',
                    width: 'min(60ch, 100ch)',
                    padding: '12px',
                    background: '#f1f1f1',
                    overflowY: 'auto'
                  }}
                  spacing={2}
                >
                  {(!notifications.length || (unreadCount === 0 && showUnreadOnly)) && (
                    <Typography variant="h6">
                      Your queue is empty! <span role="img">🎉</span>
                    </Typography>
                  )}
                  {(showUnreadOnly ? notifications.filter(v => !v.read) : notifications).map(notification => (
                    <Alert
                      key={notification.id}
                      severity={severityMap[notification.type] || 'info'}
                      action={
                        notification.read ? (
                          <CheckIcon />
                        ) : (
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => markAsRead(notification.id)}
                            size="large"
                          >
                            <MarkChatReadIcon />
                          </IconButton>
                        )
                      }
                    >
                      {notification.content}
                    </Alert>
                  ))}
                </Stack>
                <Box
                  boxShadow={4}
                  sx={{
                    background: '#3087e7',
                    padding: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '0px 0px 5px 5px'
                  }}
                >
                  <Button variant="contained" onClick={clear}>
                    Clear All
                  </Button>

                  <Button variant="contained" onClick={markAllAsRead}>
                    Mark all as read
                  </Button>
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}
