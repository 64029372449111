import { Component } from 'react';
import { onLoginCallback } from '../utils/AuthService';

class Callback extends Component {
  async componentDidMount() {
    await onLoginCallback();
    window.location.href = '/';
  }

  render() {
    return null;
  }
}

export default Callback;
