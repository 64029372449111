import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Grid, Typography, Paper } from '@mui/material';
import styled from '@emotion/styled';
import { OB, PLATFORMS } from './Constants';

export const CROP_TYPES = {
  Desktop: 'Desktop',
  Smartphone: 'Smartphone',
  Portrait: 'Portrait'
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 'auto',
  width: '100%'
}));

const StyledContainer = styled(Grid)(() => ({
  minWidth: '600px',
  margin: '10px'
}));

const StyledPortraitPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  width: '112px',
  height: '140px'
}));
const StyledDesktopPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  width: '141px',
  height: '74px'
}));
const StyledMobilePaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  width: '141px',
  height: '141px'
}));

const StyledDesktopImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '141px',
  minHeight: '74px'
}));
const StyledPortraitImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '112px',
  minHeight: '140px'
}));
const StyledMobileImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '141px',
  minHeight: '141px'
}));

class CreativeImageCropper extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      src: props.src,
      crop: {
        unit: '%',
        width: 100,
        aspect: props.platform === PLATFORMS.OUTBRAIN ? props.desktopAspectRatio : props.smartphoneAspectRatio
      },
      type: props.platform === PLATFORMS.OUTBRAIN ? CROP_TYPES.Desktop : CROP_TYPES.Smartphone,
      blob: null,
      images: {
        [CROP_TYPES.Desktop]: null,
        [CROP_TYPES.Smartphone]: null
      },
      windowWidth: 0,
      windowHeight: 0,
      showCropper: true
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    // let imageWidth = window.innerWidth / 2;
    // let aspectRatio = image.width / image.height;
    // let imageHight = imageWidth / aspectRatio;
    // image.width = imageWidth;
    // image.height = imageHight;

    this.imageRef = image;
    this.setState({ showCropper: true });
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    // You could also use percentCrop:
    //this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpg');
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    if (Math.round((canvas.width / canvas.height) * 10) / 10 === 1 && canvas.width / canvas.height !== 1) {
      canvas.width = canvas.height;
    }
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        this.setState({ blob: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  smartphoneImage() {
    let newCrop = this.state.crop;
    newCrop.aspect = this.props.smartphoneAspectRatio;
    this.setState({ crop: newCrop, type: CROP_TYPES.Smartphone });
  }

  desktopImage() {
    let newCrop = this.state.crop;
    newCrop.aspect = this.props.desktopAspectRatio;
    this.setState({ crop: newCrop, type: CROP_TYPES.Desktop });
  }
  portraitImage() {
    let newCrop = this.state.crop;
    newCrop.aspect = this.props.portraitAspectRatio;
    this.setState({ crop: newCrop, type: CROP_TYPES.Portrait });
  }
  onApply() {
    const type = this.state.type;
    const url = this.state.croppedImageUrl;

    let images = this.state.images;
    images[type] = {
      url: url,
      blob: this.state.blob,
      type: type
    };
    this.setState({ images });
    this.props.onApply(images);
  }

  onClose() {
    this.setState({ showCropper: false });
  }

  onCropAgain() {
    const emptyCrops = {
      [CROP_TYPES.Desktop]: null,
      [CROP_TYPES.Smartphone]: null
    };

    if (this.props.requirePortraitCrop) {
      emptyCrops[CROP_TYPES.Portrait] = null;
    }

    this.setState({
      images: emptyCrops,
      aspect: this.props.desktopAspectRatio,
      showCropper: true
    });

    this.props.onApply(emptyCrops);
  }

  render() {
    const { crop, src, showCropper } = this.state;
    return (
      <>
        {
          <StyledContainer container direction="row" spacing={1} alignItems="center">
            {showCropper && (
              <Grid item xs={12} sm={6} align="center">
                <StyledPaper>
                  <ReactCrop
                    imageStyle={{ maxHeight: '370px' }}
                    src={this.props.fromInsights ? `/api/getImg?img=${encodeURIComponent(src)}` : src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </StyledPaper>
              </Grid>
            )}
            <Grid item xs={12} sm={6} align="center">
              {this.state.images.Desktop && (
                <>
                  <Grid item xs={12} sm={6} align="center">
                    <Typography gutterBottom variant={'subtitle2'}>
                      Desktop
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} align="center">
                    <StyledDesktopPaper variant="outlined">
                      <StyledDesktopImage alt="CropDesktop" src={this.state.images[CROP_TYPES.Desktop].url} />
                    </StyledDesktopPaper>
                  </Grid>
                </>
              )}
              {this.state.images.Smartphone && (
                <>
                  <Grid item xs={12} sm={6} align="center">
                    <Typography gutterBottom variant={'subtitle2'}>
                      Mobile
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} align="center">
                    <StyledMobilePaper variant="outlined">
                      <StyledMobileImage alt="CropSmartphone" src={this.state.images[CROP_TYPES.Smartphone].url} />
                    </StyledMobilePaper>
                  </Grid>
                </>
              )}

              {this.props.requirePortraitCrop ? (
                <>
                  <Grid item xs={12} sm={6} align="center">
                    <Typography gutterBottom variant={'subtitle2'}>
                      Portrait (optional)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} align="center">
                    {this.state.images.Portrait && (
                      <StyledPortraitPaper variant="outlined">
                        <StyledPortraitImage alt="CropPortrait" src={this.state.images[CROP_TYPES.Portrait].url} />
                      </StyledPortraitPaper>
                    )}
                  </Grid>
                </>
              ) : null}
            </Grid>
            {showCropper && (
              <>
                <Grid item xs={6} sm={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    key={'desktopImageButton'}
                    onClick={() => this.desktopImage()}
                  >
                    Desktop
                    {` (${
                      this.props.platform === PLATFORMS.OUTBRAIN
                        ? OB.DESKTOP_ASPECT_RATIO_INFO
                        : `${this.props.desktopAspectRatio}:1`
                    })`}
                  </Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    key={'smartphoneImageButton'}
                    onClick={() => this.smartphoneImage()}
                  >
                    Mobile (1 : 1)
                  </Button>
                </Grid>
                {this.props.requirePortraitCrop ? (
                  <Grid item xs={6} sm={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      key={'portraitImageButton'}
                      onClick={() => this.portraitImage()}
                    >
                      Portrait (4 : 5)
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={6} sm={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    key={'saveButton'}
                    onClick={() => this.onApply()}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Button variant="outlined" fullWidth key={'closeButton'} onClick={() => this.onClose()}>
                    Close
                  </Button>
                </Grid>
              </>
            )}
            {!showCropper && (
              <Button variant="contained" color="primary" key={'closeButton'} onClick={() => this.onCropAgain()}>
                Crop again
              </Button>
            )}
          </StyledContainer>
        }
      </>
    );
  }
}
export default CreativeImageCropper;
