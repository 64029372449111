import React, { Component } from 'react';
import { OB, PLATFORMS } from '../Constants';
import {
  Grid,
  CardContent,
  TextField,
  CardActions,
  CardHeader,
  Tooltip,
  Input,
  Toolbar,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { getFullPath } from '../utils/imageUploadUtils';
import { StyledCard, StyledCardMedia, StyledDiv, StyledPrimaryAppBar } from '../SharedStyles';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CropIcon from '@mui/icons-material/Crop';
import CropWindow2 from '../facebook/CropWindow';
import CropWindow from '../CropWindow';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

class OutbrainCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDefaultText: '',
      anchorEl: null,
      images: [],
      uploadedImages: [],
      src: [],
      showCropWindow: false,
      showCropper: false,
      selectedImageIndex: 0,
      originFileName: [],
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      isFocusedText: Array(props.group.images.length).fill(false),
      isFocusedDescription: Array(props.group.images.length).fill(false)
    };
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  showHelperDescription = idx => {
    const description = this.props.group.descriptions[idx] || '';
    return `${description.length}/${OB.MAX_DESCRIPTION_CHARS}`;
  };

  showHelperTitle = idx => {
    const title = this.props.group.titles[idx] || '';
    return `${title.length}/${OB.MAX_TITLE_CHARS}`;
  };

  cancelCropWindow() {
    this.setState({ showCropper: false, showCropWindow: false });
  }

  onSelectFileCrop = async ad => {
    const blob = await fetch(`/api/getImg?img=${encodeURIComponent(ad)}`).then(r => r.blob());
    this.readAsDataURL(blob).then(res => {
      this.setState({ src: res, showCropper: true });
    });
  };

  applyCropWindow(images) {
    images.forEach(image => {
      for (const type in image.crops) {
        if (image.crops[type] !== null) {
          this.props.onAddImages(this.props.index, image.crops[type].blob, image.crops[type].type, image.imageIndex);
        }
      }
    });
    this.setState({ showCropper: false, showCropWindow: false });
  }

  uploadMultipleFiles = e => {
    const fileObj = [];
    const fileArray = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    this.setState({ imageFiles: fileArray, showCropWindow: true });
  };

  readAsDataURL = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  onUploadFile = async (adsetIndex, e) => {
    const { originFileName } = this.state;
    const uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      originFileName.push(uploadedFiles[i].name);
    }
    this.setState({ originFileName });
    this.props.onAddImage(adsetIndex, e);
  };

  onClose = () => {
    this.setState({ showCropWindow: false, showCropper: false, src: '' });
  };

  renderImage(path, idx) {
    const fullPath = getFullPath(path);
    return (
      <React.Fragment key={this.getKey('renderCreativeCard', idx)}>
        <Grid item sm={4}>
          <StyledCard>
            <IconButton
              style={{ backgroundColor: 'transparent' }}
              title="Delete ad"
              fontSize="small"
              aria-label="delete"
              key={this.getKey('imageRemoveButton', idx)}
              onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
              sx={{ margin: '-30px 0 -55px 150px' }}
              size="large"
            >
              <Tooltip title="Delete">
                <ClearIcon />
              </Tooltip>
            </IconButton>
            <CardHeader
              subheader={
                <TextField
                  key={this.getKey('imageTitle', idx)}
                  value={this.props.group.titles[idx] || ''}
                  onChange={(imageIdx, e) => {
                    this.props.onChangeImageTitle(idx, imageIdx, e);
                  }}
                  maxLength={OB.MAX_TITLE_CHARS}
                  variant="standard"
                  multiline
                  fullWidth
                  minRows={1}
                  maxRows={10}
                  placeholder="Title"
                  helperText={this.showHelperTitle(idx)}
                  inputProps={{
                    maxLength: OB.MAX_TITLE_CHARS
                  }}
                />
              }
            />
            <StyledCardMedia key={this.getKey('cardImage', idx)} image={fullPath} title="Example" />
            <CardContent>
              <TextField
                key={this.getKey('imageDescription', idx)}
                value={this.props.group.descriptions[idx] || ''}
                onChange={(imageIdx, e) => {
                  this.props.onChangeImageDescription(idx, imageIdx, e);
                }}
                maxLength={OB.MAX_DESCRIPTION_CHARS}
                variant="standard"
                multiline
                minRows={1}
                maxRows={10}
                fullWidth
                placeholder="Description"
                helperText={this.showHelperDescription(idx)}
                inputProps={{
                  maxLength: OB.MAX_DESCRIPTION_CHARS
                }}
              />
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                color="primary"
                key={this.getKey('populateButton', idx)}
                onClick={imageIdx => this.props.populateText(idx, imageIdx)}
                title="Populate all texts in group"
                size="large"
              >
                <TextRotationNoneIcon />
              </IconButton>
              <IconButton
                color="primary"
                key={this.getKey('imageDuplicateButton', idx)}
                onClick={imageIdx => this.props.duplicateImage(idx, imageIdx)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                aria-label="menu"
                color="primary"
                key={this.getKey('cropButton', idx)}
                onClick={() => this.onSelectFileCrop(path, idx)}
                title="Crop image"
                size="large"
              >
                <Tooltip title="Crop image">
                  <CropIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </CardActions>
          </StyledCard>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment key={this.getKey('creativeGroup', this.props.index)}>
        <StyledDiv>
          <StyledPrimaryAppBar position="static">
            <Toolbar>
              <Typography>Campaign {this.props.index + 1}</Typography>
              <label htmlFor={'addImagesButton' + this.props.index}>
                <IconButton color="secondary" component="span" size="large">
                  <PublishIcon />
                </IconButton>
              </label>
              <Input
                type="file"
                id={'addImagesButton' + this.props.index}
                onChange={this.uploadMultipleFiles}
                inputProps={{ multiple: true }}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
              <IconButton
                key={this.getKey('groupDuplicateButton')}
                color="secondary"
                aria-label="duplicate"
                onClick={idx => this.props.duplicateGroup(idx)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                key={this.getKey('campRemoveButton', this.props.index)}
                type="button"
                onClick={idx => this.props.removeGroup(idx)}
                title="Remove campaign"
                color="secondary"
                aria-label="remove"
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Toolbar>
          </StyledPrimaryAppBar>
        </StyledDiv>

        {/*<CreativesAppBar*/}
        {/*<CreativesAppBar*/}
        {/*  index={this.props.index}*/}
        {/*  images={this.props.group.images}*/}
        {/*  onUploadFile={async e => this.onUploadFile(this.props.index, e)}*/}
        {/*  removeGroup={imageIdx => this.props.removeGroup(imageIdx)}*/}
        {/*  duplicateGroup={imageIdx => this.props.duplicateGroup(imageIdx)}*/}
        {/*/>*/}
        <Grid container direction="row" spacing={1}>
          {this.state.showCropWindow ? (
            <CropWindow
              fromInsights={false}
              notRequired={true}
              desktopAspectRatio={OB.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={OB.SMARTPHONE_ASPECT_RATIO}
              data={this.state.imageFiles}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              index={this.props.index}
              group={this.props.group.images}
              platform={PLATFORMS.OUTBRAIN}
            />
          ) : null}
          {this.state.showCropper ? (
            <CropWindow2
              key={this.getKey('crop')}
              onAddImage={(index, blob) => this.onUploadFile(index, blob)}
              src={this.state.src}
              adsetIndex={this.props.index}
              onClose={() => this.onClose()}
              platform={PLATFORMS.OUTBRAIN}
            />
          ) : null}
          {this.props.group.images.map((path, i) => this.renderImage(path, i))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default OutbrainCreativeGroup;
