import React from 'react';
import { Grid, Typography } from '@mui/material';
import Select from 'react-select';
import { PLATFORMS } from './Constants';

class Prefix extends React.Component {
  render() {
    return this.props.platform === PLATFORMS.FACEBOOK_UPLOAD_CREATIVES ||
      this.props.platform === PLATFORMS.FACEBOOK2 ? (
      <>
        <Grid item>
          <Typography gutterBottom variant={'subtitle2'}>
            Prefix
          </Typography>
          <Select onChange={this.props.onChange} options={this.props.options} value={this.props.value} />
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Prefix
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select value={this.props.value} onChange={this.props.onChange} options={this.props.options} />
        </Grid>
      </>
    );
  }

  validateAlphaNumericPrefix(event) {
    let selectedvalue = window.getSelection();
    let selectedText = selectedvalue.toString();
    if (!/[A-Za-z0-9]/.test(event.key) || event.target.value.length > 1) {
      if (selectedText === '') event.preventDefault();
    }
  }
}

export default Prefix;
