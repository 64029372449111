import React from 'react';
import { Button } from '@mui/material';

class CustomNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShort: true
    };
  }

  expand() {
    this.setState({
      showShort: !this.state.showShort
    });
  }

  showText() {
    const text = this.state.showShort ? this.props.shortText : this.props.longText;
    return text;
  }

  render() {
    return (
      <div className="newline">
        <Button variant="contained" color="primary" onClick={() => this.expand()}>
          {this.showText()}
        </Button>
        {this.props.error && this.props.resume ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(session, lastSession) => this.props.resume(session, lastSession)}
          >
            Resume
          </Button>
        ) : null}
      </div>
    );
  }
}

export default CustomNotification;
