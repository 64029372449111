import React, { Component } from 'react';
import { BD, PLATFORMS } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import {
  AppBar,
  Grid,
  Typography,
  IconButton,
  Input,
  CardHeader,
  Card,
  CardMedia,
  CardActions,
  TextField,
  styled,
  Toolbar
} from '@mui/material';
import CropWindow from '../CropWindow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PublishIcon from '@mui/icons-material/Publish';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';

const StyledDiv = styled('div')(() => ({
  flexGrow: 1,
  margin: '10px 0 10px 0',
  '& .MuiTypography-root': {
    flexGrow: 1
  },
  '& .MuiIconButton-root': {
    marginLeft: '5px'
  },
  '& .MuiCard-root': {
    minWidth: 180,
    marginLeft: '1px',
    marginTop: '332px'
  }
}));

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '60%',
  backgroundSize: 'contain'
});

class BaiduCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDefaultText: '',
      showCropper: false,
      index: this.props.index
    };
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  changeCopyDefaultText(e) {
    this.setState({
      copyDefaultText: e.target.value
    });
  }

  cancelCropWindow() {
    this.setState({ showCropper: false });
  }

  applyCropWindow(images) {
    images.forEach(image => {
      for (const type in image.crops) {
        this.props.onAddImages(this.props.index, image.crops[type].blob, image.crops[type].type, image.imageIndex);
      }
    });
    this.setState({ showCropper: false });
  }

  uploadMultipleFiles = e => {
    const fileObj = [];
    const fileArray = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    this.setState({ imageFiles: fileArray, showCropper: true });
  };

  showHelperTitle = idx => {
    const title = !this.props.group.titles[idx] ? 0 : this.props.group.titles[idx].length;
    return `${title}/${BD.MAX_TITLE_CHARS}`;
  };
  renderImage(path, idx) {
    const fullPath = getFullPath(path) || getFullPath(path[BD.DESKTOP]) || getFullPath(path[BD.SMARTPHONE]);
    return (
      <React.Fragment key={this.getKey('sep', idx)}>
        <Grid item key={idx} sm={4}>
          <Card>
            <CardHeader
              subheader={
                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  minRows={2}
                  maxRows={10}
                  label="Title"
                  key={this.getKey('imageTitle', idx)}
                  value={this.props.group.titles[idx]}
                  helperText={this.showHelperTitle(idx)}
                  inputProps={{
                    maxLength: BD.MAX_TITLE_CHARS
                  }}
                  onChange={(imageIdx, e) => this.props.onChangeImageText(idx, 'titles', imageIdx, e)}
                />
              }
            />
            <StyledCardMedia key={this.getKey('sep', idx)} image={fullPath} title="Example" />
            <CardActions disableSpacing>
              <IconButton
                color="primary"
                key={this.getKey('populateButton', idx)}
                onClick={imageIdx => this.props.populateText(idx, imageIdx)}
                title="Populate all texts in group"
                size="large"
              >
                <TextRotationNoneIcon />
              </IconButton>
              <IconButton
                color="primary"
                type="button"
                key={this.getKey('imageDuplicateButton', idx)}
                onClick={imageIdx => this.props.duplicateImage(idx, imageIdx)}
                title="Copy ad"
                size="large"
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="delete"
                key={this.getKey('imageRemoveButton', idx)}
                onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
                title="Delete ad"
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <StyledDiv>
          <AppBar position="static">
            <Toolbar>
              <Typography>Group {this.props.index + 1}</Typography>
              <label htmlFor={'addImagesButton' + this.state.index}>
                <IconButton color="secondary" component="span" size="large">
                  <PublishIcon />
                </IconButton>
              </label>
              <Input
                type="file"
                id={'addImagesButton' + this.state.index}
                onChange={this.uploadMultipleFiles}
                inputProps={{ multiple: true }}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
              <IconButton
                key={this.getKey('groupDuplicateButton')}
                color="secondary"
                aria-label="duplicate"
                onClick={idx => this.props.duplicateGroup(idx)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                color="secondary"
                aria-label="remove"
                key={this.getKey('groupRemoveButton')}
                onClick={idx => this.props.removeGroup(idx)}
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </StyledDiv>

        <Grid container direction="row" spacing={1}>
          {this.state.showCropper ? (
            <CropWindow
              key={this.props.index}
              fromInsights={false}
              desktopAspectRatio={BD.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={BD.SMARTPHONE_ASPECT_RATIO}
              data={this.state.imageFiles}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              index={this.props.index}
              group={this.props.group.images}
              platform={PLATFORMS.BAIDU}
            />
          ) : null}
          {this.props.group.images.map((path, idx) => this.renderImage(path, idx))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default BaiduCreativeGroup;
