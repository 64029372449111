import * as Sentry from '@sentry/react';
import { GLOBAL, OB, PLATFORMS } from './Constants';
import SHA256 from 'crypto-js/sha256';
import { filterVersionBySite } from './utils/filters';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { parseCampaignName } from './NameParser';

export function getFileHash(file) {
  return new Promise((resolve, reject) => {
    const a = new FileReader();
    a.readAsArrayBuffer(file);
    a.onloadend = () => {
      const hash = SHA256(
        btoa(
          new Uint8Array(a.result)
            .reduce((data, byte) => {
              data.push(String.fromCharCode(byte));
              return data;
            }, [])
            .join('')
        )
      ).toString();
      resolve(hash);
    };
    a.onerror = err => {
      Sentry.captureException(err);
      reject(err);
    };
  });
}

export function validateCreativeGroups(groups) {
  let errors = [];
  groups.forEach((gr, groupIdx) => {
    // Check empty groups
    if (gr.images.length === 0) {
      errors.push(`Group ${groupIdx + 1} is empty.`);
    }
    // Check empty image texts
    gr.images.forEach((_, imageIdx) => {
      if (gr.texts) {
        if (!gr.texts[imageIdx]) {
          errors.push(`Group ${groupIdx + 1}, creative ${imageIdx + 1} text is empty.`);
        }
      }
    });
  });
  if (errors.length === 0) {
    return {
      isValid: true
    };
  } else {
    return {
      isValid: false,
      data: errors
    };
  }
}
function padWithZeros(num, size) {
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}
export function getCreationDate() {
  // DDMMYY
  const now = new Date();
  let res = '';
  res += padWithZeros(now.getDate(), 2);
  res += padWithZeros(now.getMonth() + 1, 2);
  res += padWithZeros(now.getFullYear() % 100, 2);
  return res;
}

export function getNewCreatorValue(creator, creatorsList) {
  if (creator && creatorsList && creatorsList.length) {
    const creatorWithOldValue = creatorsList.find(el => el.oldValue === creator || el?.value.includes(creator));
    return creatorWithOldValue ? creatorWithOldValue.value : creator.toLowerCase();
  }
  return '';
}

export function isSwag(email) {
  const domainPattern = new RegExp(`${GLOBAL.SHINEZ_DOMAIN}$`, 'i');
  return domainPattern.test(email.toLowerCase());
}

function intersect(a, b) {
  for (const i in b) {
    if (a[i]) {
      return i;
    }
  }
}

export function articleIsBlocked(art, block) {
  return intersect(art, block);
}

export function articleReduce(result, list, block, f) {
  for (const clv in list) {
    if (block && intersect(list[clv], block)) {
      continue;
    }
    f(result, clv);
  }
  return result;
}

export function articleCodeLanguageVersion(articleList, wid, cl, vdef, block) {
  const art = articleList.wid[wid];
  if (!art) {
    return [cl.substring(0, 5), cl.substring(5, 7), vdef || ''];
  }
  if (block && intersect(art, block)) {
    return;
  }
  const clv = art.clv;
  return [clv.substring(0, 5), clv.substring(5, 7), clv.substring(7)];
}

export function clearAdsetFields(camp) {
  camp.campaign.adsets.forEach(as => {
    const fields = Object.keys(as);
    fields.forEach(f => {
      if (f !== 'type' && f !== 'ads' && f !== 'advantage_placements' && f !== 'autoPlacements') {
        delete as[f];
      }
    });
  });
}

function getSelectLabel(xs, searchValue) {
  const value = xs?.find(e => e?.value === searchValue);
  return value?.label ?? searchValue ?? '';
}

export function getSelectValue(options, value) {
  if (Array.isArray(options) && options.length && value !== null && value !== '' && value) {
    let result = { label: getSelectLabel(options, value), value };
    return result;
  }
  return null;
}

export function getMultiSelectValue(options, xs) {
  if (Array.isArray(options) && options.length && Array.isArray(xs) && xs.length) {
    let result = xs.map(e => getSelectValue(options, e));
    return result;
  }
  return [];
}

export function makeSelectOptions(xs) {
  return Array.isArray(xs) ? xs.map(x => ({ label: x, value: x })) : [];
}

export function getOnlyValue(xs) {
  console.log({ xs });
  return xs ? xs.map(x => x.value) : [];
}

// export function widgetVersionForSourceData(parsedName, campaignName, platformKey, articleList) {
//   const { site: sites, widgetCodeName: codeName, widgetLanguageCode: languageCode } = parsedName;

//   const allowedVersions =
//     (Array.isArray(sites) ? sites : sites.split(','))
//       .map(s => platformKey.SITE_ALLOWED_VERSIONS[s])
//       .find(allowed => allowed !== undefined) || platformKey.ALLOWED_VERSIONS;

//   const block = campaignName.includes('MSN') ? { gmmsn: 1 } : undefined;

//   const filteredVersions = filterVersionBySite(allowedVersions, sites);

//   const parsedSites = articleReduce([], articleList.clv, block, (result, clv) => {
//     const ver = clv.substring(7, 10);
//     if (!filteredVersions.includes(ver)) {
//       return;
//     }
//     result.push([clv.substring(0, 5), clv.substring(5, 7), ver]);
//   });

//   const versions = parsedSites
//     .filter(el => {
//       if (codeName && el[0] !== codeName) {
//         return false;
//       } else return !(languageCode && el[1] !== languageCode);
//     })
//     .map(el => el[2]);

//   return versions[0];
// }

export function byteSize(inputString) {
  const utf8ByteSize = str => new TextEncoder().encode(str).length;
  const sizeInBytes = utf8ByteSize(inputString);
  return sizeInBytes;
  //    "🚫🔞 Not suitable for children 🚫❌ The most powerful images in history\nThere are some historical photographs that everybody has seen. From the iconic image of Marilyn Monroe standing over the Subway grate to the 1941 portrait of Winston Churchill, some"
}

export function parseBulkData(options, sourceData, articleList, platform) {
  if (!sourceData || !sourceData.length) {
    options.original_source_data = '';
    toast.error('Source campaign data empty. Please check campaigns list.');
    return options;
  }
  let campaigns = [];
  let payload = {};
  let language;
  let parsedName;

  //OB: If user paste only one source campaign, he expects to be able to edit creatives
  //https://shinez.slack.com/archives/CPDTTBV27/p1723487166058289?thread_ts=1720451883.918149&cid=CPDTTBV27
  if (platform === PLATFORMS.OUTBRAIN && sourceData && sourceData.length === 1) {
    const group = {
      images: [],
      descriptions: [],
      titles: []
    };
    group.titles.push(sourceData[0].campaign.title);
    group.images.push(sourceData[0].campaign.s3ImageUrl || sourceData[0].campaign.image_url);

    if (sourceData[0].campaign.text && sourceData[0].campaign.text.length > 0) {
      group.descriptions.push(sourceData[0].campaign.text);
    }
    options[OB.KEY_CREATIVE_GROUPS] = [group];
  }
  try {
    sourceData.forEach(data => {
      try {
        let widget = null;
        if (platform) {
          parsedName = parseCampaignName(platform, data.name);
        }
        if (parsedName) {
          language = parsedName.widgetLanguageCode;
        }
        if (articleList.wid[data.widget_id]) {
          widget = articleList.wid[data.widget_id];
        }

        payload.creativeGroups = {
          title: data.campaign.title,
          image: data.campaign.s3ImageUrl || data.campaign.image_url
        };
        if (data.campaign.text && data.campaign.text.length > 0) {
          payload.creativeGroups.text = data.campaign.text;
        }
        if (!widget) {
          toast.error(`Widget data is missing for campaign ${data.name}`);
        }
        if (!widget || !widget.wid) {
          toast.error(`Widget id is missing for campaign ${data.name}`);
          return;
        }
        if (!widget.path) {
          toast.error(`Widget path is missing for campaign ${data.name}`);
          return;
        }
        if (!widget.clv) {
          toast.error(`Article is missing for campaign ${data.name}`);
          return;
        }
        payload.widgetid = widget.wid;
        payload.widgetPath = widget.path ? widget.path : widget.title;
        payload.article = widget.clv;
        payload.fullCodeName = widget.article;
        payload.campaignCreator = data.campaignCreator;
        payload.isBulk = true;
        payload.campaignName = data.name;
        payload.language = language || data?.language;
        campaigns.push(_.cloneDeep(payload));
      } catch (e) {
        toast.error(`Invalid source campaign ${data.name}`);
        console.error(`can't parse source data`, e);
      }
    });

    if (campaigns.length) {
      options.original_source_data = campaigns;
      toast.success(`Source campaign data parsed successfully`);
    }
    return options;
  } catch (e) {
    console.error(`can't parse source data`, e);
  }
}
