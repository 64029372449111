import {
  fetchBulkCampaigns,
  getFacebookAccounts,
  getBaiduAccounts,
  getFbLibraryAds,
  getSingleInsight,
  loadTaboolaCampaign,
  startRead
} from './Api';
import { COMMANDS, GLOBAL, PLATFORMS, TASKS } from './Constants';
import { parseCampaignName } from './NameParser';
import { toast } from 'react-toastify';

function setPropertiesFromCampaignName(component, name) {
  const options = component.state.options;

  const parsedName = parseCampaignName(component.state.platform, name);
  options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
  options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
  component.setState({
    options: options,
    triggerSelectorUpdate: !component.state.triggerSelectorUpdate // to calculate widgetid
  });
}
function setNativeParamsFromInsights(component, platform, id) {
  setTimeout(async () => {
    try {
      const xs = await getSingleInsight(id);
      component.setState({ isLoading: false });
      if (!xs || xs.length === 0) {
        toast.error(`Error: can't find insight ${id}.`);
        return;
      }
      setPropertiesFromCampaignName(component, xs[0].name);

      if (xs[0].item) {
        const { item } = xs[0];
        xs[0].image_url = item.map(({ image }) => image);
        xs[0].title = item.map(({ title }) => title);
        xs[0].text = item.map(({ text }) => text);
      } else {
        xs[0].image_url = xs[0].image_url_hq || xs[0].s3ImageUrl || xs[0].image_url;
      }

      const isPlatformSupported = [PLATFORMS.TWITTER, PLATFORMS.BAIDU, PLATFORMS.GOOGLE_ADS].includes(platform);

      const stateToUpdate = isPlatformSupported
        ? { creativeFromInsights: xs[0], campaignCreator: xs[0]?.campaignCreator || xs[0]?.creator }
        : { creative: xs[0], campaignCreator: xs[0].campaignCreator || xs[0].creator };
      component.setState(stateToUpdate);
    } catch (error) {
      console.error('Error fetching insight:', error);
    }
  }, 1000);
}

function setNativeParamsFbLibrary(component, id) {
  setTimeout(() => {
    getFbLibraryAds(id).then(xs => {
      component.setState({ isLoading: false });
      const payload = {
        ads: []
      };
      if (id.length > 0) {
        for (const value of xs) {
          const { text, header, creativeUrl } = value;
          payload.ads.push({ text: text, title: header, image: creativeUrl });
        }
        component.setState({ creativeFromFbLibrary: [payload] });
      } else {
        toast.error(`Error: can't find ad ${id}.`);
      }
    });
  }, 1000);
}

function setNativeParamsFbBulk(component, xs) {
  component.handleCampaignsPaste(xs);
  setTimeout(() => {
    component.setState({ isLoading: false });
    if (xs.length > 0) {
      console.log('xs', xs);
    } else {
      toast.error(`Error: can't find campaigns. Please, check if all campaigns are valid.`);
    }
  }, 1000);
}

// Handle the "createFromInsights" query parameter
export function handleCreateFromInsights(component, queryParams) {
  component.setState(
    {
      isLoading: true
    },
    () => {
      component.selectPlatform(PLATFORMS[queryParams.platform.toUpperCase()]);
      setNativeParamsFromInsights(component, queryParams.platform, queryParams.id);
    }
  );
}

// Handle the "createFromCreatives" query parameter
export function handleCreateFromCreatives(component, queryParams) {
  component.setState(
    {
      isLoading: true
    },
    () => {
      component.selectPlatform(PLATFORMS.FACEBOOK2);
      console.log(queryParams);
      component.setCreativesByIds(queryParams.id);
    }
  );
}

// Handle the "openCampaignsFromInsights" query parameter
export async function handleOpenCampaignsFromInsights(component, queryParams) {
  component.setState(
    {
      isLoading: true
    },
    async () => {
      let platform;
      if (queryParams.platform === PLATFORMS.TWITTER) {
        platform = PLATFORMS.TWITTER_BULK;
      } else if (queryParams.platform === PLATFORMS.BAIDU) {
        platform = PLATFORMS.BAIDU;
      } else if (queryParams.accountId && queryParams.campaignId) {
        platform = PLATFORMS.FACEBOOK;
      } else if (queryParams.platform === PLATFORMS.FACEBOOK && queryParams.campaigns) {
        platform = PLATFORMS.FACEBOOK_BULK;
      } else {
        platform = PLATFORMS.TABOOLA;
      }
      if (platform !== PLATFORMS.FACEBOOK) {
        component.selectPlatform(platform);
      }
      if (
        queryParams.platform.toUpperCase() === PLATFORMS.FACEBOOK.toUpperCase() &&
        queryParams.accountId &&
        queryParams.campaignId
      ) {
        try {
          const accounts = await getFacebookAccounts();
          component.setState(
            {
              accounts: accounts,
              selectedTask: TASKS.DUPLICATE,
              platform: PLATFORMS.FACEBOOK
            },
            async () => {
              await component.selectAccount(`act_${queryParams.accountId}`);
              component.selectCampaign(queryParams.campaignId);
            }
          );
        } catch (error) {
          handleQueryParamError(component, 'Failed to fetch Facebook accounts');
        }
      } else if (platform === PLATFORMS.TWITTER_BULK || platform === PLATFORMS.FACEBOOK_BULK) {
        component.setState({ isLoading: true, sourceCampaigns: queryParams.campaigns });
        fetchBulkCampaigns(
          component.state.ws,
          component.state.session,
          queryParams.campaigns.split(','),
          platform,
          component.state.selectedAccount
        );
      } else if (platform === PLATFORMS.BAIDU) {
        try {
          const accounts = await getBaiduAccounts();
          const account = accounts.find(acc => acc.account_id === Number(queryParams.accountId));
          if (account) {
            const readOptions = {
              account: account.account_id,
              subAccount: account.account_name,
              campaign: queryParams.campaignId
            };
            startRead(component.state.ws, component.state.session, PLATFORMS.BAIDU, readOptions);
          } else {
            handleQueryParamError(component, 'Account not found');
          }
        } catch (error) {
          handleQueryParamError(component, 'Failed to fetch Baidu accounts');
        }
      } else if (platform === PLATFORMS.TABOOLA) {
        try {
          if (queryParams) {
            let payload = {
              echo: COMMANDS.LOAD_CAMPAIGN,
              name: queryParams.campaigns
            };
            loadTaboolaCampaign(component.state.ws, payload);
          }
        } catch (error) {
          handleQueryParamError(component, 'Failed to fetch  Taboola accounts');
        }
      } else {
        setNativeParamsFbBulk(component, queryParams.campaigns);
      }
    }
  );
}

// Handle the "createFromFbLibrary" query parameter
export function handleCreateFromFbLibrary(component, queryParams) {
  component.setState(
    {
      isLoading: true
    },
    () => {
      component.selectPlatform(PLATFORMS.FACEBOOK);
      setNativeParamsFbLibrary(component, queryParams.id);
    }
  );
}

// Show an error toast using toast library
export function handleQueryParamError(component, message) {
  toast.error(message);
}
