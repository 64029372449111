import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Paper } from '@mui/material';
import ImageCrop from './ImageCrop';
import Draggable from 'react-draggable';
import { styled } from '@mui/system';

function PaperComponent(props) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable handle="#draggable-dialog-title" nodeRef={nodeRef} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
}
const StyledDialog = styled(Dialog)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500]
}));

class CropWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    return (
      <StyledDialog
        maxWidth="sm"
        onClose={() => this.handleClose()}
        aria-labelledby="draggable-dialog-title"
        open={true}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Crop image
        </DialogTitle>
        <StyledButton color="primary" aria-label="close" onClick={() => this.handleClose()}>
          <CloseIcon />
        </StyledButton>
        <DialogContent dividers>
          <ImageCrop
            onAddImage={(index, blob) => this.props.onAddImage(index, blob)}
            src={this.props.src}
            adsetIndex={this.props.adsetIndex}
            platform={this.props.platform}
          />
        </DialogContent>
      </StyledDialog>
    );
  }
}

export default CropWindow;
