import { FB, PLATFORMS } from '../Constants';
import { getPlatformSites } from '../Sites';
import { constructWebsite } from '../ModifyOptions';
import { getFbBmConversionFilterList } from '../Api';

export function getCampaignAds(campaign) {
  const sourceAds = [];
  campaign.campaign.adsets.forEach(adset => {
    adset.ads.forEach(ad => sourceAds.push(ad));
  });
  return sourceAds;
}

export const prepareSiteList = allSites => {
  let siteList = [];
  for (const i in allSites) {
    siteList.push(allSites[i]);
  }
  return siteList
    .filter(el => el.enabled)
    .map(el => {
      return { label: el.code + ' (' + el.name + ')', value: el.code };
    })
    .filter(el => !FB.EXCLUDE_SITES.includes(el.value));
};

export function getWebsite(ref, domain) {
  const websiteObj = getPlatformSites(PLATFORMS.FACEBOOK.toLowerCase()).find(s => s.code === domain);
  const website = websiteObj ? websiteObj.name : '';
  const isDomainPresent = website;
  const isArticleAbsent = ref['widgetCodeName'] === null && ref['widgetLanguageCode'] === null && !ref['widget_id'];
  if (isDomainPresent) {
    if (isArticleAbsent) {
      return constructWebsite(ref, website, 'THE_SAME_AS_ORIGINAL');
    } else {
      return constructWebsite(ref, website, ref['widget_id']);
    }
  } else {
    if (ref['widget_id']) {
      return constructWebsite(ref, 'THE_SAME_AS_ORIGINAL', ref['widget_id']);
    } else {
      // No domain, no article, leave website empty
      return '';
    }
  }
}

export function filterUnusedConversions(conversion) {
  // We remove 'depc' conversions because user may perceive them like our
  // manual added 'depc X.XX' conversions, that need to be used by server logic
  return (
    conversion.name.toLowerCase().includes('sttemp') || // TODO remove this after tests - we temporarily allow STtempAll sites conversions (like "name": "sttempall sites - 0.09")
    !FB.OLD_CONVERSIONS.some(el => conversion.name.toLowerCase().includes(el))
  );
}
export function getBudgetInfo(sourceData) {
  if (sourceData.daily_budget) {
    return {
      budget: sourceData.daily_budget,
      budgetType: 'daily campaign'
    };
  }
  if (sourceData.lifetime_budget) {
    return {
      budget: sourceData.lifetime_budget,
      budgetType: 'lifetime campaign'
    };
  }
  if (sourceData.campaign.adsets[0].daily_budget) {
    return {
      budget: sourceData.campaign.adsets[0].daily_budget,
      budgetType: 'daily adset'
    };
  }
  if (sourceData.campaign.adsets[0].lifetime_budget) {
    return {
      budget: sourceData.campaign.adsets[0].lifetime_budget,
      budgetType: 'lifetime adset'
    };
  }
}

export async function filterConversionsByBmId(conversions, bmId, email, optionalFilter, dynamicUser, swag) {
  // Filter conversions according blacklisted (old) words
  conversions = conversions
    .filter(filterUnusedConversions)
    .concat(FB.DEPC_CONVERSIONS.map(c => ({ name: swag ? c.label : c.label.split(' ')[1], id: c.value })));
  // Config file with BM ID according to which we will filter conversions for swag and b2b

  if (!dynamicUser.includes(email)) {
    // Temporary solution for dynamic conversion
    conversions = conversions.filter(value => !value.name.includes('Dynamic'));
  }

  let filterValues = await getFbBmConversionFilterList(bmId);
  // Temporary solution for specific users. Will be removed after we will add support for all custom requests
  if (bmId === FB.NITIDO_BM_ID && FB.CUSTOM_USERS.includes(email)) {
    filterValues = ['REG', 'depc'];
  }
  if (!filterValues || !filterValues.length) {
    return conversions;
  }
  if (swag && optionalFilter) {
    filterValues = filterValues.filter(
      optionalFilter.filterConverions ? i => i === optionalFilter.cc : i => i !== optionalFilter.cc
    );
  }
  conversions = conversions.filter(c => {
    const name = c.id.includes('depc') ? c.id : c.name;
    const isMatched = filterValues.some(i => name.includes(i));
    return isMatched;
  });
  return conversions;
}

export function getAccount(destinationAccount, accounts) {
  if (accounts && destinationAccount) {
    return accounts.find(x => x.id === destinationAccount || x.account_id === destinationAccount);
  }
}

export const validateNoDuplicateAds = ads => {
  const uniqueAds = new Set();
  for (const ad of ads) {
    const { image, text, title } = ad;
    const adKey = `${image}-${text.trim()}-${title.trim()}`;
    if (uniqueAds.has(adKey)) {
      return false;
    }
    uniqueAds.add(adKey);
  }
  return true;
};

export const getValue = (opts, x) => {
  if (!Array.isArray(opts) || opts.length === 0) {
    return null;
  }
  const res = opts.find(option => option.value === x);
  return res ?? null;
};
