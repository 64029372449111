import React from 'react';
import { ZM } from '../Constants';
import { Grid, Typography } from '@mui/material';
class ZemantaBids extends React.Component {
  renderRowName(bid) {
    const parts = [bid.country, bid.device.toLowerCase(), bid.os, bid.site];
    return parts.filter(el => el).join('-');
  }

  renderBidRow(bid, idx) {
    return (
      <React.Fragment key={'bidLabel' + idx}>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bid {this.renderRowName(bid)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            key={'bidInput' + idx}
            step={ZM.BID_STEP}
            value={this.props.bids[idx].bid}
            onChange={e => {
              if (parseFloat(e.target.value) <= ZM.MAX_BID) {
                this.props.modifyBid(idx, e);
              }
            }}
            onBlur={() => this.props.validateBid(idx)}
          />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {this.props.bids
          ? this.props.bids.map((bid, idx) => {
              return this.renderBidRow(bid, idx);
            })
          : null}
      </>
    );
  }
}

export default ZemantaBids;
