import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Divider, FormControlLabel, Grid, Switch, Typography, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { getFacebookCampaigns, getFbConversionsAndPixels } from '../Api';

import { FB, GLOBAL, PLATFORMS } from '../Constants';
import { toast } from 'react-toastify';
import FbCampaigns from '../FbCampaigns';
import { constructCampaignName, getNewCampaignNumber } from '../FbUtils';
import { parseCampaignName } from '../NameParser';
import moment from 'moment';
import { formatNYTimestamp } from '../utils/tzUtils';
import { filterConversionsByBmId, getAccount, getWebsite, prepareSiteList } from './FacebookShared';
import {
  articleCodeLanguageVersion,
  getNewCreatorValue,
  getSelectValue,
  getOnlyValue,
  getMultiSelectValue
} from '../Shared';
import CampaignsNote from '../CampaignsNote';

const DEFAULT_GEO = {
  domain: '',
  countries: ['us'],
  duplicationType: 'group',
  geoName: '',
  geoType: 'include',
  destinationAccount: '',
  campaignsList: [],
  customConversions: [],
  mobilePlatforms: ['Android', 'iOS'],
  accountName: ''
};

class FacebookBulk extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      prefix: '',
      campaignObjective: 'outcome_sales',
      accountName: '',
      customAudiences: [],
      customAudienceList: [],
      geoList: [DEFAULT_GEO],
      platform: ['desktop'],
      destAccCurrency: '',
      budget: 0,
      bidAmount: 0,
      campaignStopTime: undefined,
      campaignStartTime: undefined,
      status: 'active',
      adsets: [],
      conversion: '',
      conversionList: [],
      videoCreatives: [],
      language: [],
      widgetCodeName: '',
      widgetLanguageCode: '',
      widgetVersion: '',
      article: '',
      widget_id: '',
      widget_path: '',
      widget_title: '',
      freeText: '',
      campaigns: [],
      tooltips: { text: '', open: false },
      website: '',
      sourceCampaignName: '',
      times: 1,
      triggerSelectorUpdate: false,
      adsetTypeToCreate: 'SINGLE',
      adNaming: 'Default',
      advertTitle: false,
      autoPlacements: false,
      useTrackingInUrl: false,
      qs: '',
      bidStrategyType: 'LOWEST_COST_WITHOUT_CAP',
      budgetType: 'daily campaign',
      siteList: [],
      allSites: [],
      sourceCampaigns: '',
      sourceData: null,
      campaignCreator: '',
      campaignNote: '',
      roasAverageFloor: 0.001,
      languageList: JSON.parse(FB.APP_LANGUAGE_LIST)
    };
    this.isSwag = this.props.programOptions.isSwag;

    this.state = JSON.parse(JSON.stringify(this.defaultState));
    this.countryList = this.props.countryList;
    for (let key in FB.GROUPS_OF_COUNTRYS_LIST) {
      this.countryList.push({ label: key, value: FB.GROUPS_OF_COUNTRYS_LIST[key].join(',') });
    }
  }

  async componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      await this.updateUserOptions();
      this.setState({ siteList: this.props.siteList, allSites: prepareSiteList(this.props.notFilteredSiteList) });
    }
  }

  async updateUserOptions() {
    const userOpts = this.props.programOptions;
    this.updatePrefix('');
  }

  async componentDidUpdate(prevProps) {
    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      const acc = this.props.accounts.find(
        x => x.id === this.props.destinationAccount || x.account_id === this.props.destinationAccount
      );
      this.setState({ accountName: acc && acc.prefix ? acc.prefix : '', destAccCurrency: acc.currency });
    }

    if (this.props.task !== prevProps.task) {
      this.setState(JSON.parse(JSON.stringify(this.defaultState)));
    }

    if (this.props.sourceData !== prevProps.sourceData) {
      const newSourceData = [];
      let blockedCampaigns = [];
      for (let campaignData of this.props.sourceData) {
        campaignData = await this.parseFBSourceData({ campaignData: campaignData });
        if (typeof campaignData === 'string') {
          blockedCampaigns.push(campaignData);
        } else {
          newSourceData.push(campaignData);
        }
      }
      if (blockedCampaigns.length) {
        toast.error(
          `You trying to duplicate campaigns with blocked article ${blockedCampaigns.join(
            ', '
          )}. These campaigns will not be duplicated. Please select another campaigns.`
        );
      }
      this.setState({ sourceData: newSourceData }, () => {
        this.setState({ triggerSelectorUpdate: !this.state.triggerSelectorUpdate });
      });
    }
  }

  fetchCampaigns(accountId) {
    getFacebookCampaigns(accountId).then(camps => {
      this.setState({
        campaigns: camps.map(el => {
          return { id: el.id, desc: el.name };
        })
      });
    });
  }

  updateObjective(campaignObjective) {
    this.setState({ campaignObjective });
  }

  updatePrefix(prefix) {
    this.setState({ prefix });
  }

  updateCampaignCreator(campaignCreator) {
    this.setState({ campaignCreator });
  }

  updateCountries(idx, xs) {
    let countries = this.state.geoList[idx].countries;
    let geoName = '';
    if (countries) {
      if (countries.indexOf('ww') !== -1 && xs !== '') {
        countries = ['ww'];
      } else if (xs.indexOf('ww') !== -1) {
        countries = ['ww'];
      } else {
        countries = xs;
      }
    } else {
      countries = xs;
    }
    if (typeof countries === 'string' && countries !== '') {
      countries = countries.split(',');
    }
    if (countries.length === 1) {
      geoName = xs;
    } else if (countries.length > 2) {
      for (let key in FB.GROUPS_OF_COUNTRYS_LIST) {
        if (countries.join(',') === FB.GROUPS_OF_COUNTRYS_LIST[key].join(',')) {
          geoName = key;
        }
      }
    } else {
      geoName = '';
    }

    let exclude = this.state.geoList[idx].geoType;
    if (exclude === 'exclude') {
      geoName = 'ww';
    }

    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], countries, geoName };
    this.setState({ geoList });
  }

  updateCountriesGroup(idx, geoType) {
    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], geoType };
    this.setState({ geoList }, () => {
      this.updateCountries(idx, this.state.geoList[idx].countries);
    });
  }

  updateDuplicationType(idx, duplicationType) {
    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], duplicationType };
    this.setState({ geoList });
  }

  fetchOneConversionList(accountId) {
    return getFbConversionsAndPixels(accountId);
  }

  getDefaultSite(account) {
    const accountName = this.props.accounts.find(el => el.account_id === account).prefix;
    if (accountName) {
      const site = this.state.siteList.find(el => accountName.includes(el.value));
      return site ? site.value : '';
    }
  }

  async updateDestinationAccount(idx, destinationAccount) {
    let siteList = this.props.siteList;
    let customConversions;

    if (!destinationAccount) {
      return;
    }

    let optionalFilter = { filterConverions: false, cc: FB.QUIZ_CONVERSIONS };
    let { widget_id } = this.state;

    if (widget_id.includes(FB.QUIZ)) {
      optionalFilter.filterConverions = true;
    }

    if (FB.ALL_SITES_ACCOUNT_LIST.includes(destinationAccount)) {
      siteList = this.state.allSites.filter(el => !FB.VIP_SITES_LIST.includes(el.value));
    }

    const geoList = this.state.geoList;
    customConversions = await this.fetchOneConversionList(destinationAccount);
    const acc = getAccount(destinationAccount, this.props.accounts);
    if (acc && acc.business_mgr_id && customConversions) {
      customConversions = await filterConversionsByBmId(
        customConversions,
        acc.business_mgr_id,
        this.props.userMail,
        optionalFilter,
        FB.DYNAMIC_CC_USERS,
        this.isSwag
      );
    }
    const accountName = acc && acc.prefix ? acc.prefix : '';
    geoList[idx] = { ...geoList[idx], destinationAccount, customConversions, accountName };
    this.setState({ geoList, siteList }, () => {
      const domain = this.getDefaultSite(destinationAccount);
      if (domain) {
        this.updateOneDomain(idx, domain);
      }
    });
  }

  updateOneAccountName(idx, accountName) {
    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], accountName };
    this.setState({ geoList });
  }

  updateOneMobilePlatforms(idx, mobilePlatforms) {
    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], mobilePlatforms };
    this.setState({ geoList });
  }

  updateOneDomain(idx, site_code) {
    const geoList = this.state.geoList;
    const campaignsList = this.modifyOneDomainRows(site_code);
    geoList[idx] = { ...geoList[idx], site_code, campaignsList };
    this.setState({ geoList });
  }

  modifyOneDomainRows(domain) {
    const state = this.state;
    let platforms = ['d', 'm', 'a'];
    let campaignsList = [];
    platforms.forEach(device => {
      campaignsList.push({
        site_code: domain,
        platform: device,
        cc: null,
        website: getWebsite(state, domain)
      });
    });
    return campaignsList;
  }

  updateOneCampaignsList(idx, campaignsList) {
    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], campaignsList };
    this.setState({ geoList });
  }

  updateGeoName(idx, geoName) {
    const geoList = this.state.geoList;
    geoList[idx] = { ...geoList[idx], geoName: geoName.trim() };
    this.setState({ geoList });
  }

  updateLanguages(language) {
    this.setState({ language });
  }

  getMobilePlatformList() {
    return FB.MOBILE_PLATFORM_LIST.map(x => ({ label: x, value: x }));
  }

  updateBudgetType(budgetType) {
    this.setState({ budgetType });
  }

  updateBudget(budget) {
    this.setState({ budget });
  }

  updateBudgetEndTime(budgetEndTime) {
    this.setState({ budgetEndTime });
  }

  updateCampaignStartTime(campaignStartTime) {
    this.setState({ campaignStartTime });
  }

  updateCampaignStopTime(campaignStopTime) {
    this.setState({ campaignStopTime });
  }

  updateBidStrategyType(bidStrategyType) {
    const campaignsList = this.state.geoList;
    campaignsList.forEach(camps => {
      camps.campaignsList.map(camp => {
        camp.cc = '';
        return camp;
      });
    });
    this.setState({ bidStrategyType, geoList: campaignsList });
  }

  updateBidAmount(bidAmount) {
    this.setState({ bidAmount });
  }

  updateStatus(status) {
    this.setState({ status });
  }

  updateCampaignNote(campaignNote) {
    this.setState({ campaignNote });
  }

  updateWebsite(website) {
    this.setState({ website });
  }

  updateFreeText(freeText) {
    const campaignNumber = getNewCampaignNumber(this.state.campaigns) + this.state.geoList[0].campaignsList.length;
    const testName = FB.TEST_CAMPAIGN_NAME;
    const newCampName = constructCampaignName(testName, this.state.accountName || 'accN', campaignNumber, this.state);
    let tooltips = this.state.tooltips;
    const maxAddedLength = 1 + FB.MAX_ADSET_PART_LENGTH + 1 + FB.MAX_AD_PART_LENGTH;
    const charsLeft = FB.MAX_CAMP_NAME_LENGTH - newCampName.length - freeText.length - maxAddedLength;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    const nonLetterNumber = /[^a-z0-9]+/i;
    if (freeText.match(nonLetterNumber)) {
      tooltips.text = 'Only letters and numbers';
      freeText = freeText.replace(nonLetterNumber, '');
      this.setState({ tooltips, freeText });
      return;
    }
    if (charsLeft <= 0) {
      //take into account the length of '-a1-v10-'
      freeText = freeText.slice(0, FB.MAX_CAMP_NAME_LENGTH - newCampName.length - maxAddedLength);
      tooltips.text = `Chars left: 0`;
    }
    this.setState({ tooltips, freeText });
  }

  onBlurFreeText() {
    let tooltips = this.state.tooltips;
    tooltips = {
      open: false
    };
    this.setState({ tooltips });
  }

  updateCampaignsList(campaignsList) {
    this.setState({ campaignsList });
  }

  updateROASAverageFloor(roasAverageFloor) {
    this.setState({ roasAverageFloor });
  }
  handleOneCcSelect(idx, idx2, ccId) {
    let campaignsList = this.state.geoList[idx].campaignsList;

    if (typeof ccId === 'string') {
      campaignsList[idx2].cc = ccId;
    } else {
      campaignsList[idx2].cc = ccId.map(cc => cc.value);
    }
    this.updateOneCampaignsList(idx, campaignsList);
  }

  handleOneRemoveCampaign(idx, idx2) {
    let campaignsList = this.state.geoList[idx].campaignsList;
    campaignsList.splice(idx2, 1);
    this.updateOneCampaignsList(idx, campaignsList);
  }

  async modifyDomainRows() {
    if (!this.state.site_code) {
      return true;
    }

    let domains = this.state.site_code;
    if (typeof domains === 'string') {
      domains = domains.split(',');
    }

    let platforms = ['d', 'm', 'a'];
    let campaignsList = [];
    domains.forEach(dom => {
      platforms.forEach(device => {
        campaignsList.push({
          site_code: dom,
          platform: device,
          cc: null,
          website: getWebsite(this.state, dom)
        });
        this.updateWebsite(getWebsite(this.state, dom));
      });
    });
    this.updateCampaignsList(campaignsList);
  }

  getSubmitButtonText() {
    return 'Duplicate';
  }

  validateSetup() {
    let validations = [
      validate(
        this.state.geoList.every(geo => geo.destinationAccount),
        'Empty destination account'
      ),
      validate(
        this.state.geoList.every(geo => geo.accountName),
        'Empty account name'
      ),
      validate(
        this.state.geoList.every(geo => geo.countries.length > 0),
        'Select at least one geo.'
      )
    ];
    if (this.state.budgetType.includes('lifetime')) {
      validations.push(validate(this.state.budgetEndTime, 'Select budget end time'));
    }
    this.state.sourceData.forEach(el => {
      if (this.isSwag) {
        validations.push(validate(el.campaignCreator, `Creator is empty, campaign ${el.sourceCampaignName}`));
      }
    });
    validations =
      this.props.platform !== PLATFORMS.FACEBOOK
        ? [...validations, validate(this.state.platform, 'Select at least one platform.')]
        : validations;
    return validations.every(x => x());
  }

  getTargetAndBudget() {
    const {
      prefix,
      geoList,
      platform,
      status,
      conversion,
      budget,
      campaignObjective: objective,
      widgetCodeName,
      widgetLanguageCode,
      widgetVersion,
      article,
      widget_path,
      widget_title,
      country,
      freeText,
      language,
      times,
      adNaming,
      qs,
      budgetType,
      autoPlacements,
      useTrackingInUrl,
      bidStrategyType: bid_strategy,
      bidAmount: bid_amount,
      campaignStartTime: start_time,
      campaignStopTime: stop_time,
      budgetEndTime: end_time,
      roasAverageFloor: roas_average_floor,
      campaignNote
    } = this.state;

    const pacing_type = ['standard'];
    const billing_event = 'IMPRESSIONS';

    const targetingTemplate = geo => ({
      prefix,
      geoType: geo.geoType,
      countries: geo.countries,
      geoName: geo.geoName,
      duplicationType: geo.duplicationType,
      site_code: geo.site_code,
      account: geo.destinationAccount,
      campaignsList: geo.campaignsList,
      mobilePlatforms:
        geo.mobilePlatforms && geo.mobilePlatforms.length ? geo.mobilePlatforms.join(',') : geo.mobilePlatforms,
      budget: budget,
      bid_amount: bid_amount,
      accountCodeName: geo.accountName,
      genders: geo.genders,
      age_min: geo.age_min,
      age_max: geo.age_max,
      status: status.toUpperCase(),
      conversion: conversion.value,
      objective: objective.toUpperCase(),
      pacing_type,
      bid_strategy,
      billing_event,
      platform,
      widgetCodeName,
      widgetLanguageCode,
      widgetVersion,
      article,
      widget_path,
      widget_title,
      country,
      freeText,
      language: language && language.length ? language.join(',') : language,
      times,
      sourceAccount: this.props.account,
      adNaming,
      qs,
      budgetType,
      autoPlacements,
      useTrackingInUrl,
      isSwag: this.isSwag,
      start_time: start_time ? formatNYTimestamp(start_time) : undefined,
      end_time: end_time ? formatNYTimestamp(end_time) : undefined,
      stop_time: stop_time ? formatNYTimestamp(stop_time) : undefined,
      campaignNote: campaignNote,
      bid_constraints:
        bid_strategy === 'LOWEST_COST_WITH_MIN_ROAS' ? { roas_average_floor: roas_average_floor * 10000 } : undefined
    });

    const targetsPerGeo = geoList.map(geo =>
      this.constructCampaigns(targetingTemplate(geo))
        .map(camp => Array.from({ length: camp.times ? camp.times : 1 }, () => Object.assign({}, camp)))
        .flat()
    );
    const campaigns = targetsPerGeo.flat();
    const targets = this.state.sourceData
      .map(el => {
        return campaigns.map(targ => {
          if (!this.state.prefix) {
            targ.prefix = parseCampaignName(PLATFORMS.FACEBOOK, el.sourceCampaignName).prefix;
          }
          return JSON.parse(JSON.stringify(Object.assign(el, targ)));
        });
      })
      .flat();

    return {
      targets,
      budget
    };
  }

  async submit() {
    // Refresh page data with each creation
    // It won't give us the latest data, but at least will fix the scenario
    // where user creates 20 campaigns on a single page during the day, because SPM tab
    // is the same and page data is not refreshed at all
    if (!this.validateSetup()) {
      return;
    }

    if (this.props.programOptions.spm_access_block_with_message) {
      window.location.reload();
    }

    const { targets, budget } = this.getTargetAndBudget();

    console.dir(targets, { colors: true });
    if (budget <= 50) {
      confirmAlert({
        title: 'Create',
        message: 'Are you sure to do this?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.createCampaigns(targets);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    } else {
      confirmAlert({
        title: 'Warning',
        message: 'Are you sure you want to create with budget over 50 USD?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.createCampaigns(targets);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
  }

  createCampaigns(targets) {
    this.props.createCampaign(targets);
  }

  constructCampaigns(sourceData) {
    let allCampaigns = [];
    if (sourceData.duplicationType === 'separate') {
      if (typeof sourceData.countries === 'string') {
        sourceData.countries = sourceData.countries.split(',');
      }
      sourceData.countries.forEach(country => {
        if (sourceData.campaignsList.length) {
          sourceData.campaignsList.forEach(campaign => {
            let conversions = typeof campaign.cc === 'string' ? [{ value: campaign.cc }] : campaign.cc;
            if (conversions) {
              conversions.forEach(cc => {
                sourceData.site_code = campaign.site_code;
                sourceData.platform = campaign.platform;
                sourceData.conversion = cc;
                sourceData.country = country;
                allCampaigns.push(JSON.parse(JSON.stringify(sourceData)));
              });
            } else {
              sourceData.site_code = campaign.site_code;
              sourceData.platform = campaign.platform;
              sourceData.country = country;
              allCampaigns.push(JSON.parse(JSON.stringify(sourceData)));
            }
          });
        } else {
          sourceData.country = country;
          allCampaigns.push(JSON.parse(JSON.stringify(sourceData)));
        }
      });
    } else {
      if (sourceData.campaignsList.length) {
        sourceData.campaignsList.forEach(campaign => {
          let conversions = typeof campaign.cc === 'string' ? [{ value: campaign.cc }] : campaign.cc;
          if (conversions) {
            conversions.forEach(cc => {
              sourceData.site_code = campaign.site_code;
              sourceData.platform = campaign.platform;
              sourceData.conversion = cc;
              sourceData.country = sourceData.countries.join(',');
              allCampaigns.push(JSON.parse(JSON.stringify(sourceData)));
            });
          } else {
            sourceData.site_code = campaign.site_code;
            sourceData.platform = campaign.platform;
            sourceData.country = sourceData.countries.join(',');
            allCampaigns.push(JSON.parse(JSON.stringify(sourceData)));
          }
        });
      }
    }
    allCampaigns = allCampaigns.map(camp => {
      delete camp.campaignsList;
      delete camp.countries;
      return camp;
    });
    return allCampaigns;
  }

  addGeo() {
    const geoList = this.state.geoList;
    geoList.push(DEFAULT_GEO);
    this.setState({ geoList });
  }

  deleteGeo(idx) {
    const geoList = this.state.geoList;
    geoList.splice(idx, 1);
    this.setState({ geoList });
  }

  renderFacebookContentFields() {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Free text
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tooltip key={'tooltip'} title={this.state.tooltips ? this.state.tooltips.text : ''}>
            <textarea
              key={'freeText'}
              value={this.state.freeText}
              onChange={e => this.updateFreeText(e.target.value)}
              onBlur={() => this.onBlurFreeText()}
              maxLength={40}
              rows="1"
            />
          </Tooltip>
        </Grid>
      </>
    );
  }

  getDestinationAccountOptions() {
    return this.props.accounts
      .map(x => ({ label: x.desc, value: x.id || x.account_id }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  renderGeo(geo, idx) {
    return (
      <React.Fragment key={idx}>
        {this.state.geoList.length > 1 ? (
          <Grid item xs={12} sm={12}>
            <Divider style={{ margin: '10px' }} />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.updateDestinationAccount(idx, x?.value)}
            options={this.getDestinationAccountOptions()}
            value={getSelectValue(this.getDestinationAccountOptions(), geo.destinationAccount)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Account name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="accountName"
            value={geo.accountName}
            onChange={e => this.updateOneAccountName(idx, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Domain
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.updateOneDomain(idx, x?.value)}
            options={this.state.siteList}
            isClearable
            value={getSelectValue(this.state.siteList, geo.site_code)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FbCampaigns
            domain={geo.site_code}
            campaignsList={geo.campaignsList}
            conversionList={geo.customConversions.map(el => {
              return { label: el.name, value: el.id };
            })}
            programOptions={this.props.programOptions}
            handleCcSelect={(idx2, ccId) => this.handleOneCcSelect(idx, idx2, ccId)}
            handleRemoveCampaign={idx2 => this.handleOneRemoveCampaign(idx, idx2)}
            userMail={this.props.userMail}
            bidStrategyType={this.state.bidStrategyType}
          />
        </Grid>
        {geo.campaignsList.some(c => c.platform === 'm') && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Mobile platform
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                isMulti
                onChange={xs =>
                  this.updateOneMobilePlatforms(
                    idx,
                    xs.map(x => x.value)
                  )
                }
                options={this.getMobilePlatformList()}
                value={getMultiSelectValue(this.getMobilePlatformList(), geo.mobilePlatforms)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Targeted geo
          </Typography>
        </Grid>
        <Grid item xs={8} sm={6}>
          <Select
            isMulti
            onChange={xs => this.updateCountries(idx, getOnlyValue(xs))}
            options={this.countryList}
            value={getMultiSelectValue(this.countryList, geo.countries)}
          />
        </Grid>
        <Grid item xs={4} sm={2} align="left">
          {idx === this.state.geoList.length - 1 ? (
            <IconButton size="small" color="primary" onClick={() => this.addGeo()}>
              <AddIcon />
            </IconButton>
          ) : null}
          {this.state.geoList.length > 1 && idx === this.state.geoList.length - 1 ? (
            <IconButton size="small" color="primary" onClick={() => this.deleteGeo(idx)}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Geo name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="geoName"
            value={geo.geoName}
            onChange={e => this.updateGeoName(idx, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo type
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControlLabel
            control={
              <Switch
                checked={geo.duplicationType === 'group'}
                onChange={e => this.updateDuplicationType(idx, e.target.checked ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              geo.duplicationType === 'group' ? (
                <Typography gutterBottom variant="subtitle2">
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant="subtitle2">
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControlLabel
            control={
              <Switch
                checked={geo.geoType === 'include'}
                onChange={e => this.updateCountriesGroup(idx, e.target.checked ? 'include' : 'exclude')}
                color="primary"
              />
            }
            label={
              geo.geoType === 'include' ? (
                <Typography gutterBottom variant="subtitle2">
                  Include
                </Typography>
              ) : (
                <Typography gutterBottom variant="subtitle2">
                  Exclude
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant="subtitle2">
            Select languages
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            closeMenuOnSelect={false}
            isMulti
            onChange={xs => this.updateLanguages(getOnlyValue(xs))}
            options={FB.LANGUAGE_LIST}
            value={getMultiSelectValue(FB.LANGUAGE_LIST, this.state.language)}
          />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign objective
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(FB.CAMPAIGN_OBJECTIVES, this.state.campaignObjective)}
            onChange={e => this.updateObjective(e.value)}
            options={FB.CAMPAIGN_OBJECTIVES}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Prefix
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <input
            type="text"
            name="prefix"
            value={this.state.prefix}
            onChange={e => this.updatePrefix(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Keep empty to keep source prefix
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            value={this.state.budgetType ? { label: this.state.budgetType, value: this.state.budgetType } : null}
            onChange={e => this.updateBudgetType(e.value)}
            options={FB.BUDGET_TYPE}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <input
            type="number"
            name="budget"
            min="0"
            value={this.state.budget}
            onChange={e => this.updateBudget(e.target.value)}
          />
        </Grid>

        {this.state.destAccCurrency && (
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              {this.state.destAccCurrency}
            </Typography>
          </Grid>
        )}
        {(this.state.budgetType === 'lifetime adset' || this.state.budgetType === 'lifetime campaign') && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Adset end time
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DatePicker
                selected={this.state.budgetEndTime ? new Date(this.state.budgetEndTime) : ''}
                onChange={x => this.updateBudgetEndTime(x)}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={
                  this.state.budgetEndTime
                    ? moment(this.state.budgetEndTime)
                        .add(1, 'day')
                        .toDate()
                    : moment()
                        .add(1, 'day')
                        .toDate()
                }
              />
            </Grid>
          </>
        )}
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Bid strategy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              value={getSelectValue(FB.BID_STRATEGY_TYPES, this.state.bidStrategyType)}
              onChange={e => this.updateBidStrategyType(e.value)}
              options={FB.BID_STRATEGY_TYPES}
            />
          </Grid>
        </>
        {this.state.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP' || this.state.bidStrategyType === 'COST_CAP' ? (
          <>
            <Grid item xs={6} sm={2}>
              <input
                type="number"
                name="bid_amount"
                step={0.01}
                value={this.state.bidAmount}
                onChange={e => this.updateBidAmount(e.target.value)}
              />
            </Grid>
          </>
        ) : null}
        {this.state.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS' ? (
          <>
            <Grid item xs={6} sm={2}>
              <input
                style={{ padding: '7px', marginBottom: '2px' }}
                type="number"
                name="roas_average_floor"
                step={0.001}
                min="0.001"
                value={this.state.roasAverageFloor}
                onChange={e => this.updateROASAverageFloor(e.target.value)}
              />
            </Grid>
          </>
        ) : null}
        {this.state.currency && (
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              {this.state.currency}
            </Typography>
          </Grid>
        )}
        {this.state.geoList.map((geo, index) => this.renderGeo(geo, index))}

        {this.renderFacebookContentFields()}

        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign start date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={this.state.campaignStartTime ? new Date(this.state.campaignStartTime) : ''}
            onChange={x => this.updateCampaignStartTime(x)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={moment().toDate()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign stop date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={this.state.campaignStopTime ? new Date(this.state.campaignStopTime) : ''}
            onChange={x => this.updateCampaignStopTime(x)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={
              this.state.campaignStartTime
                ? moment(this.state.campaignStartTime)
                    .add(1, 'day')
                    .toDate()
                : moment()
                    .add(1, 'day')
                    .toDate()
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(FB.STATUS, this.state.status)}
            onChange={e => this.updateStatus(e.value)}
            options={FB.STATUS}
          />
        </Grid>
        <CampaignsNote
          platform={PLATFORMS.FACEBOOK_BULK}
          value={this.state.campaignNote}
          onChange={e => this.updateCampaignNote(e.target.value)}
        />
        <Grid item xs={6} sm={4} align="right">
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getSubmitButtonText()}
          </Button>
        </Grid>
      </>
    );
  }

  async parseFBSourceData(campData) {
    const sourceData = campData;
    const {
      campaignData: { billing_event, campaign, name, widget_id, campaignCreator }
    } = sourceData;
    const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, name);
    const clv = articleCodeLanguageVersion(
      this.props.articleList,
      widget_id,
      parsedName.article,
      '',
      this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B
    );
    if (!clv) {
      return name;
    }
    let {
      campaignData: { language }
    } = sourceData;
    if (language && language.length) {
      this.setState({ language });
    }
    const creator = getNewCreatorValue(campaignCreator, this.props.creatorsList);
    if (!creator && !this.props.creatorsList.length) {
      console.warn(`Campaign ${name} doesn't have creator`);
      toast.error(`Campaign ${name} doesn't have creator`);
    }
    return {
      [GLOBAL.KEY_WIDGET_CODE_NAME]: clv[0],
      [GLOBAL.KEY_WIDGET_LANGUAGE_CODE]: clv[1],
      [GLOBAL.KEY_WIDGET_VERSION]: clv[2],
      [GLOBAL.KEY_CAMPAIGN_NOTE]: '',
      campaignCreator: creator,
      billing_event: billing_event,
      language: language || '',
      campaign: {
        adsets: [
          {
            type: 'SINGLE',
            ads: [
              {
                image: campaign.s3ImageUrl || campaign.image_url || '',
                text: campaign.text || '',
                title: campaign.title || ''
              }
            ]
          }
        ]
      },
      sourceCampaignName: name,
      widget_id: widget_id
    };
  }
}

const validate = (cond, errorDescription) => () => {
  if (!cond) {
    console.warn(errorDescription);
    toast.error(errorDescription);
    return false;
  }
  return true;
};

export default FacebookBulk;
