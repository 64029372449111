import moment from 'moment';
import { GLOBAL, PLATFORMS, TASKS, TB } from './Constants';
import { toast } from 'react-toastify';
import { getFacebookAccounts, getTaboolaAccounts, getSnapshot, getTaboolaSubAccounts, getTwitterAccounts } from './Api';

function formatSnapshotDates(snapshot) {
  if (snapshot.options && snapshot.options['budgetEndTime']) {
    snapshot.options['budgetEndTime'] = moment(snapshot.options['budgetEndTime']);
  }

  if (snapshot.options && snapshot.options['campaignStopTime']) {
    snapshot.options['campaignStopTime'] = moment(snapshot.options['campaignStopTime']);
  }

  if (snapshot.options && snapshot.options['campaignStartTime']) {
    snapshot.options['campaignStartTime'] = moment(snapshot.options['campaignStartTime']);
  }

  if (snapshot.options && snapshot.options['endDateStr']) {
    snapshot.options['endDateStr'] = moment(snapshot.options['endDateStr']);
  }

  if (snapshot.options && snapshot.options['startDateStr']) {
    snapshot.options['startDateStr'] = moment(snapshot.options['startDateStr']);
  }

  if (snapshot.options && snapshot.options['campaign_start_time']) {
    snapshot.options['campaign_start_time'] = moment(snapshot.options['campaign_start_time']);
  }

  if (snapshot.options && snapshot.options['campaign_stop_time']) {
    snapshot.options['campaign_stop_time'] = moment(snapshot.options['campaign_stop_time']);
  }

  if (snapshot.options && snapshot.options['campaign_start_time_range']) {
    snapshot.options['campaign_start_time_range'] = moment(snapshot.options['campaign_start_time_range']);
  }
  if (snapshot.options && snapshot.options['startTime']) {
    snapshot.options['startTime'] = moment(snapshot.options['startTime']);
  }
  if (snapshot.options && snapshot.options['endTime']) {
    snapshot.options['endTime'] = moment(snapshot.options['endTime']);
  }
  if (
    snapshot.options &&
    snapshot.options['campaign_start_dates'] &&
    Array.isArray(snapshot.options['campaign_start_dates'])
  ) {
    snapshot.options['campaign_start_dates'] = snapshot.options['campaign_start_dates'].map(v => moment(v));
  }
}

export async function handleSnapshot(component, snapshotId) {
  try {
    const snapshot = await getSnapshot(snapshotId);

    if (!snapshot) {
      toast.error(`Error: can't find the snapshot. Please check URL`);
    } else {
      formatSnapshotDates(snapshot);

      switch (snapshot.platform) {
        case PLATFORMS.TABOOLA:
          if ([TASKS.DUPLICATE, TB.DUPLICATE_WITH_DATA_SEGMENTS].includes(snapshot.snapshot.selectedTask)) {
            handleTaboolaSnapshot(component, snapshot);
            break;
          } else {
            handleDefaultSnapshot(component, snapshot);
            break;
          }
        case PLATFORMS.FACEBOOK:
          handleFacebookSnapshot(component, snapshot);
          break;
        case PLATFORMS.TWITTER:
          handleTwitterSnapshot(component, snapshot);
          break;
        default:
          handleDefaultSnapshot(component, snapshot);
      }
    }
  } catch (error) {
    console.error('Error handling snapshot:', error);
    toast.error('Error handling snapshot');
  }
}

function handleTaboolaSnapshot(component, snapshot) {
  getTaboolaAccounts().then(res => {
    component.setState({
      accounts: res
    });
  });
  getTaboolaSubAccounts().then(res => {
    component.setState({
      subAccounts: res
    });
  });
  component.setState(
    {
      selectedTask: snapshot.data.selectedTask,
      options: snapshot.data.options,
      platform: snapshot.data.platform,
      [GLOBAL.KEY_DESTINATION_ACCOUNT]: snapshot.data.selectedAccount,
      loadedFromSnapshot: true
    },
    () => {
      if (snapshot.platform === PLATFORMS.TABOOLA) {
        component.selectAccount(snapshot.data.options.selectedAccount || TB.SHINEZ_NETWORK).then(() => {
          component.selectSubAccount(snapshot.data.options.selectedSubAccount).then(() => {
            component.selectCampaign(snapshot.data.selectedCampaign);
            component.selectPrefix(snapshot.data.selectedPrefix);
          });
        });
      }
    }
  );
}

function handleFacebookSnapshot(component, snapshot) {
  getFacebookAccounts().then(res => {
    component.setState(
      {
        accounts: res,
        selectedTask: snapshot.data.selectedTask,
        options: snapshot.data.options,
        platform: snapshot.data.platform,
        loadedFromSnapshot: true
      },
      () => {
        component.selectAccount(snapshot.data.selectedAccount).then(() => {
          if (snapshot.snapshot.selectedTask === TASKS.DUPLICATE) {
            component.selectCampaign(snapshot.data.selectedCampaign);
            component.selectPrefix(snapshot.data.selectedPrefix);
            component.selectDestinationAccount(snapshot.data.destinationAccount);
          }
        });
      }
    );
  });
}

function handleTwitterSnapshot(component, snapshot) {
  getTwitterAccounts().then(accounts => {
    component.setState(
      {
        accounts: accounts.map(el => ({
          id: el.id,
          desc: el.name
            .replace(/\./g, '')
            .replace(/-/g, '')
            .replace('shinezioltd', '')
        })),
        twitterReady: true,
        selectedTask: snapshot.data.selectedTask,
        options: snapshot.data.options,
        platform: snapshot.data.platform,
        loadedFromSnapshot: true
      },
      () => {
        component.selectAccount(snapshot.data.selectedAccount).then(() => {
          if (snapshot.data.selectedTask === TASKS.DUPLICATE) {
            component.selectCampaign(snapshot.data.selectedCampaign);
            component.selectPrefix(snapshot.data.selectedPrefix);
          }
        });
      }
    );
  });
}

function handleDefaultSnapshot(component, snapshot) {
  component.setState({
    options: snapshot.data.options,
    platform: snapshot.data.platform,
    [GLOBAL.KEY_DESTINATION_ACCOUNT]: snapshot.data.destinationAccount,
    loadedFromSnapshot: true
  });
}
