import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Nav from './components/Nav';
import MultiTrace from './components/MultiTrace';
import MessageModal from './components/modals/MessageModal';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Collapse } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import theme from './Theme';

const Layout = props => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  useEffect(() => {
    if (props.user && props.user.app_metadata.spm_show_alert_message) {
      setShowMessageModal(true);
    }
  }, [props.user]);

  const handleCloseModal = () => {
    setShowMessageModal(false);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Nav
            onTasks={() => props.onTasks()}
            onTest={() => props.onTest()}
            taskCode={props.taskCode}
            devSkipCreate={props.devSkipCreate}
            setDevSkipCreate={props.setDevSkipCreate}
            toggleAllowPageSelection={props.toggleAllowPageSelection}
            allowPageSelection={props.allowPageSelection}
            user={props.user}
          />
          <div className="container">
            <Collapse in={props.taskView} timeout={0}>
              <MultiTrace ref={props.traces} onChanged={props.onTraceChanged} />
            </Collapse>

            <Outlet />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
      <ToastContainer newestOnTop />
      <MessageModal open={showMessageModal} handleClose={handleCloseModal} />
    </>
  );
};

export default Layout;
