import React from 'react';
import { Grid, CardMedia } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Card from '@mui/material/Card';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(() => ({
  margin: '5px'
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  height: '150px',
  width: '180px',
  margin: 'auto',
  backgroundSize: 'contain'
}));

class ImageCheck extends React.Component {
  render() {
    if (!this.props.image) {
      return null;
    }
    let image = null;
    if (this.props.image.startsWith('http')) {
      image = this.props.image;
    } else {
      image = 'uploads/' + this.props.image;
    }
    return (
      <>
        <Grid item xs={6} sm={4}>
          <StyledCard>
            <StyledCardMedia image={image} />
            <IconButton
              color="primary"
              onClick={() => this.props.handleDeleteImage(this.props.imageIndex)}
              size="large"
            >
              <DeleteForeverIcon fontSize={'small'} />
            </IconButton>
          </StyledCard>
        </Grid>
      </>
    );
  }
}

export default ImageCheck;
