import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, styled } from '@mui/material';

const StyledLink = styled(Link)(({ theme }) => ({
  color: '#296cf2',
  '&:hover': {
    color: 'blue'
  }
}));

const UnauthorizedPage = () => {
  return (
    <>
      <Typography gutterBottom variant={'h2'}>
        You have no access
      </Typography>
      <Typography gutterBottom variant={'h5'}>
        Please contact your manager
      </Typography>
      <Typography gutterBottom variant={'h5'}>
        Please <StyledLink to="/login">Login</StyledLink>
      </Typography>
    </>
  );
};

export default UnauthorizedPage;
