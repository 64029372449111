import React from 'react';
import RevcontentCreativeGroup from './RevcontentCreativeGroup';

class RevcontentCreativeGroupBuilder extends React.Component {
  render() {
    return (
      <>
        {this.props.groups.map((el, idx) => (
          // TODO: FIX CREATIVES TEXT
          <RevcontentCreativeGroup
            index={idx}
            key={'tbCreativeGroup' + idx}
            onAddImages={e => this.props.onAddImages(idx, e)}
            onChangeImageText={(imageIdx, e) => this.props.onChangeImageText(idx, imageIdx, e)}
            onBlurImageText={imageIdx => this.props.onBlurImageText(idx, imageIdx)}
            onPasteImageText={(imageIdx, e) => this.props.onPasteImageText(idx, imageIdx, e)}
            removeImage={imageIdx => this.props.removeImage(idx, imageIdx)}
            removeGroup={() => this.props.removeGroup(idx)}
            duplicateGroup={() => this.props.duplicateGroup(idx)}
            populateText={imageIdx => this.props.populateText(idx, imageIdx)}
            group={this.props.groups[idx]}
          />
        ))}
      </>
    );
  }
}

export default RevcontentCreativeGroupBuilder;
