import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ImageCheck from '../ImageCheck.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Input, Grid, Typography, AppBar, Toolbar, TextField, Tooltip, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { FB, PLATFORMS } from '../Constants';
import PublishIcon from '@mui/icons-material/Publish';
import { toast } from 'react-toastify';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ImageIcon from '@mui/icons-material/Image';
import { byteSize } from '../Shared';
import { styled } from '@mui/system';

const StyledDiv = styled('div')(({ theme }) => {
  return {
    flexGrow: 1,
    margin: '10px 0 10px 0',
    '& .MuiTypography-root': {
      flexGrow: 1
    },
    '& .MuiIconButton-root': {
      marginLeft: '5px'
    }
  };
});

const StyledAppBar = styled(AppBar)(({ theme, platform }) => {
  let backgroundColor = theme.palette.primary.main;
  let color = '#ffffff';
  if (platform === PLATFORMS.FACEBOOK) {
    backgroundColor = '#f1f2f5';
    color = '#262626';
  }
  return {
    '&.MuiAppBar-root': {
      backgroundColor,
      color
    }
  };
});
class DynamicAdset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      isCheckedAdvantagePlusAdset: false
    };
  }

  duplicateAdset() {
    this.props.addAdset(JSON.parse(JSON.stringify(this.props.adset)));
  }

  handleTitleChange = (e, idx) => {
    const title = [...this.state.titleTooltip];
    const titleMobile = [...this.state.titleTooltipMobile];
    const inputString = e.target.value;
    const sizeInBytes = byteSize(inputString);
    const mobileCharLimit = FB.MAX_TITLE_MOBILE_LENGTH - sizeInBytes;
    title[idx] = `${sizeInBytes}/${FB.MAX_TITLE_LENGTH}`;
    titleMobile[idx] = {
      text: `${mobileCharLimit}`,
      open: mobileCharLimit >= 0
    };
    this.setState({ titleTooltip: title, titleTooltipMobile: titleMobile });
  };

  handleTextChange = (e, idx) => {
    const text = this.state.textTooltip;
    text[idx] = `${e.target.value.length}/${FB.MAX_TEXT_LENGTH}`;
    this.setState({ textTooltip: text });
  };

  handleTitleDelete(index) {
    const adset = this.props.adset;
    adset.ads[0].title.splice(index, 1);
    this.props.modifyAdset(this.props.index, adset);
  }

  handleTextDelete(index) {
    const adset = this.props.adset;
    adset.ads[0].text.splice(index, 1);

    this.props.modifyAdset(this.props.index, adset);
  }

  onAddText() {
    const adset = this.props.adset;
    adset.ads[0].text.push('');
    this.props.modifyAdset(this.props.index, adset);
  }

  onAddTitle() {
    const adset = this.props.adset;
    adset.ads[0].title.push('');
    this.props.modifyAdset(this.props.index, adset);
  }

  onChangeText(index, event) {
    const adset = this.props.adset;
    adset.ads[0].text[index] = event.target.value;
    this.handleTextChange(event, index);
    this.props.modifyAdset(this.props.index, adset);
  }

  onChangeTitle(index, event) {
    const adset = this.props.adset;
    adset.ads[0].title[index] = event.target.value;
    this.handleTitleChange(event, index);
    this.props.modifyAdset(this.props.index, adset);
  }

  handleDeleteImage(idx) {
    const adset = this.props.adset;
    adset.ads[0].image.splice(idx, 1);
    this.props.modifyAdset(this.props.index, adset);
  }
  showHelperTitle = idx => {
    const title = this.props.adset.ads[0].title[idx];
    const sizeInBytes = byteSize(title);
    const mobileCharLimit = FB.MAX_TITLE_MOBILE_LENGTH - sizeInBytes;
    return `${sizeInBytes}/255\n Mobile char: ${mobileCharLimit}/${FB.MAX_TITLE_MOBILE_LENGTH}`;
  };
  showHelperText = idx => {
    const text = this.props.adset.ads[0].text[idx];
    return `${text.length}/${FB.MAX_TEXT_LENGTH}`;
  };

  uploadMultipleImages(idx, event) {
    const adset = JSON.parse(JSON.stringify(this.props.adset.ads[0]));
    const imagesToUpload = Array.from(event.target.files);
    let images_limit = FB.DYNAMIC_IMAGE_LIMIT;
    let capacity = images_limit - adset.image.length;

    if (capacity < 0) {
      toast.error(`Adset has a limit - maximum 10 images.`);
    } else if (imagesToUpload.length > capacity) {
      imagesToUpload.splice(capacity, imagesToUpload.length);
      toast.error(`Up to 10 images can be uploaded at a time.`);
    }
    this.props.onAddDynamicImage(idx, imagesToUpload);
  }
  getKey(base, idx) {
    return base + this.props.index + '_' + idx;
  }

  handleAdvantagePlusAdset = () => {
    this.setState(
      prevState => ({
        isCheckedAdvantagePlusAdset: !prevState.isCheckedAdvantagePlusAdset
      }),
      () => {
        this.props.toggleAdvantagePlusAdset(this.props.adset, this.state.isCheckedAdvantagePlusAdset);
      }
    );
  };

  renderAd(ad) {
    return (
      <React.Fragment key={`dynamic_ad_${this.props.index}`}>
        {ad.title &&
          ad.title.map((title, index) => (
            <React.Fragment key={`fragment_ad_${index}`}>
              <Grid item xs={11} sm={10}>
                <TextField
                  variant="standard"
                  multiline
                  label="Title"
                  fullWidth
                  key={this.props.index + 'title'}
                  value={title}
                  onChange={event => this.onChangeTitle(index, event)}
                  inputProps={{
                    maxLength: FB.MAX_TITLE_LENGTH
                  }}
                  helperText={this.showHelperTitle(index)}
                  disabled={this.props.spmUploadAccess && !this.props.spmUploadDirectly}
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="menu"
                  onClick={() => this.handleTitleDelete(index)}
                >
                  <Tooltip title="Delete title">
                    <CloseIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        {ad.title.length === 0 && (
          <Grid item xs={11} align="left">
            <Typography variant="body2">Add title</Typography>
          </Grid>
        )}
        {this.props.spmUploadAccess && !this.props.spmUploadDirectly ? null : (
          <Grid item xs={ad.title.length === 0 ? 1 : 6} sm={1} align="left">
            {ad.title.length < 5 ? (
              <IconButton
                size="small"
                color="primary"
                aria-label="menu"
                onClick={() => this.onAddTitle()}
                disabled={this.props.spmUploadAccess && !this.props.spmUploadDirectly}
              >
                <Tooltip title="Add title">
                  <AddIcon />
                </Tooltip>
              </IconButton>
            ) : null}
          </Grid>
        )}
        {ad.text &&
          ad.text.map((text, index) => (
            <React.Fragment key={'text_' + index}>
              <Grid item xs={11} sm={10}>
                <TextField
                  variant="standard"
                  multiline
                  label="Text"
                  fullWidth
                  key={this.props.index + 'Text' + index}
                  value={text}
                  helperText={this.showHelperText(index)}
                  inputProps={{
                    maxLength: FB.MAX_TEXT_LENGTH
                  }}
                  onChange={event => this.onChangeText(index, event)}
                  disabled={this.props.spmUploadAccess && !this.props.spmUploadDirectly}
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton size="small" color="primary" aria-label="menu" onClick={() => this.handleTextDelete(index)}>
                  <Tooltip title="Delete text">
                    <CloseIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        {ad.text.length === 0 && (
          <Grid item xs={11} align="left">
            <Typography variant="body2">Add text</Typography>
          </Grid>
        )}
        {this.props.spmUploadAccess && !this.props.spmUploadDirectly ? null : (
          <Grid item xs={ad.title.length === 0 ? 1 : 6} sm={1} align="rigth">
            {ad.text.length < 5 ? (
              <IconButton
                size="small"
                color="primary"
                aria-label="menu"
                onClick={() => this.onAddText()}
                disabled={this.props.spmUploadAccess && !this.props.spmUploadDirectly}
              >
                <Tooltip title="Add text">
                  <AddIcon />
                </Tooltip>
              </IconButton>
            ) : null}
          </Grid>
        )}
      </React.Fragment>
    );
  }
  render() {
    if (!this.props.adset.ads[0]) {
      return null;
    }

    let imageUrls = this.props.adset.ads[0].image;

    return (
      <React.Fragment>
        <StyledDiv>
          <StyledAppBar platform={this.props.platform} position="static">
            <Toolbar>
              <Typography>Images: {imageUrls.filter(el => el !== null).length} of 10</Typography>
              {this.props.platform === PLATFORMS.FACEBOOK2 ? (
                <>
                  <input
                    type="checkbox"
                    id={'advantage+d' + this.props.index}
                    onChange={this.handleAdvantagePlusAdset}
                    checked={this.props.adset.autoPlacements && this.props.adset.advantage_placements}
                  />
                  <label htmlFor={'advantage+d' + this.props.index}>Advantage+</label>
                </>
              ) : null}
              {this.props.spmUploadAccess && !this.props.spmUploadDirectly ? null : (
                <>
                  <label htmlFor={'multi' + this.props.index + this.props.geoIdx + this.props.campIdx}>
                    <IconButton
                      color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                      component="span"
                      disabled={imageUrls.filter(el => el !== null).length > 9}
                      size="large"
                    >
                      <Tooltip title="Upload">
                        <PublishIcon fontSize={'small'} />
                      </Tooltip>
                    </IconButton>
                  </label>
                  <Input
                    type="file"
                    id={'multi' + this.props.index + this.props.geoIdx + this.props.campIdx}
                    onChange={event => this.uploadMultipleImages(this.props.index, event)}
                    accept="image/*"
                    inputProps={{ multiple: true }}
                    onClick={e => {
                      e.target.value = null;
                    }}
                    style={{ display: 'none' }}
                  />
                </>
              )}
              {this.props.platform === PLATFORMS.FACEBOOK &&
              this.props.selectArticleCreativeButton &&
              this.props.spmCreativeBankAccess ? (
                <Tooltip title="Select creatives">
                  <IconButton
                    key={this.props.geoIdx}
                    variant="outlined"
                    color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                    onClick={() => this.props.showArticleCreative(this.props.campIdx, this.props.geoIdx)}
                    size="large"
                  >
                    <ImageIcon fontSize={'small'} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {this.props.platform === PLATFORMS.FACEBOOK2 ? (
                <>
                  {this.props.spmUploadAccess && !this.props.spmUploadDirectly ? null : (
                    <IconButton
                      color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                      aria-label="duplicate"
                      onClick={() => this.duplicateAdset()}
                      size="large"
                    >
                      <Tooltip title="Duplicate">
                        <FileCopyIcon fontSize={'small'} />
                      </Tooltip>
                    </IconButton>
                  )}
                  <IconButton
                    color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                    aria-label="menu"
                    key={this.getKey('groupAddButton')}
                    onClick={() => this.props.addAdset()}
                    size="large"
                  >
                    <Tooltip title="Add additional ads group">
                      <AddIcon />
                    </Tooltip>
                  </IconButton>
                  {this.props.adsets.length < 2 && this.props.platform === PLATFORMS.FACEBOOK ? null : (
                    <IconButton
                      color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                      aria-label="delete"
                      onClick={() => this.props.deleteAdset(this.props.index)}
                      size="large"
                    >
                      <DeleteForeverIcon fontSize={'small'} />
                    </IconButton>
                  )}
                </>
              ) : null}
              {this.props.toggleAd ? (
                <IconButton
                  size="small"
                  color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                  onClick={idx => this.props.handleAdsDropdown(idx)}
                >
                  <ExpandLessIcon fontSize={'small'} />
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  color={this.props.platform === PLATFORMS.FACEBOOK2 ? 'secondary' : 'default'}
                  onClick={idx => this.props.handleAdsDropdown(idx)}
                >
                  <KeyboardArrowDownIcon fontSize={'small'} />
                </IconButton>
              )}
            </Toolbar>
          </StyledAppBar>
        </StyledDiv>
        {this.props.toggleAd ? (
          <Grid container direction="row">
            {imageUrls.map((x, idx) => (
              <ImageCheck
                imageIndex={idx}
                key={'ImageCheck' + idx}
                handleDeleteImage={() => this.handleDeleteImage(idx)}
                image={x}
                spmUploadAccess={this.props.spmUploadAccess}
              />
            ))}
            <Grid container alignItems="center" sx={{ margin: '10px' }} direction="row" spacing={1}>
              {this.props.adset.ads.map((ad, idx) => this.renderAd(ad, idx))}
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}
export default DynamicAdset;
