import React from 'react';
import TaboolaCreativeGroup from './TaboolaCreativeGroup';

class TaboolaCreativeGroupBuilder extends React.Component {
  render() {
    return (
      <>
        {this.props.groups.map((el, groupIdx) => (
          <TaboolaCreativeGroup
            index={groupIdx}
            key={'tbCreativeGroup' + groupIdx}
            onAddImages={(groupIdx, e) => this.props.onAddImages(groupIdx, e)}
            onChangeImageText={(imageIdx, e) => this.props.onChangeImageText(groupIdx, imageIdx, e)}
            onChangeImageDescription={(imageIdx, e) => this.props.onChangeImageDescription(groupIdx, imageIdx, e)}
            onChangeImageCTA={(imageIdx, e) => this.props.onChangeImageCTA(groupIdx, imageIdx, e)}
            onPasteImageText={(imageIdx, e) => this.props.onPasteImageText(groupIdx, imageIdx, e)}
            removeImage={imageIdx => this.props.removeImage(groupIdx, imageIdx)}
            duplicateImage={imageIdx => this.props.duplicateImage(groupIdx, imageIdx)}
            removeGroup={() => this.props.removeGroup(groupIdx)}
            duplicateGroup={() => this.props.duplicateGroup(groupIdx)}
            populateText={imageIdx => this.props.populateText(groupIdx, imageIdx)}
            group={this.props.groups[groupIdx]}
          />
        ))}
      </>
    );
  }
}

export default TaboolaCreativeGroupBuilder;
