import React from 'react';
import { OB } from '../Constants';
import { Grid, Typography, Button } from '@mui/material';
class OutbrainBids extends React.Component {
  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    return parts.filter(el => el).join('-');
  }

  setBidOnClick(idx, bid) {
    const parsedBid = parseFloat(this.props.getSuggestedBid(bid));
    if (!Number.isNaN(parsedBid)) {
      this.props.modifyBid(idx, { target: { value: parsedBid } });
    } else {
      console.log("Can't parse the suggested bid");
    }
  }

  renderBidRow(bid, idx) {
    return (
      <React.Fragment key={'bidLabel' + idx}>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            bid cpc {this.renderRowName(bid)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <input
            type="number"
            key={'bidInput' + idx}
            step={OB.BID_STEP}
            value={this.props.bids[idx].bid}
            onChange={e => {
              if (parseFloat(e.target.value) <= OB.MAX_BID) {
                this.props.modifyBid(idx, e);
              }
            }}
            onBlur={() => this.props.validateBid(idx)}
          />
        </Grid>
        <Grid item xs={12} sm={3} align={'center'}>
          <Button
            key={'suggestedBidLabel' + idx}
            variant="outlined"
            fullWidth
            onClick={() => this.setBidOnClick(idx, bid)}
          >
            {this.props.getSuggestedBid(bid)}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {this.props.bids
          ? this.props.bids.map((bid, idx) => {
              return this.renderBidRow(bid, idx);
            })
          : null}
      </>
    );
  }
}

export default OutbrainBids;
