import React, { Component } from 'react';
import { PLATFORMS, TB } from '../Constants';
import Select from 'react-select';
import { getFullPath } from '../utils/imageUploadUtils';
import { Grid, CardContent, TextField, CardActions, Tooltip, CardHeader } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CreativesAppBar from '../SharedCreativesAppBar';
import { getSelectValue } from '../Shared';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ClearIcon from '@mui/icons-material/Clear';
import { StyledCard, StyledCardMedia } from '../SharedStyles';
import CropIcon from '@mui/icons-material/Crop';
import CropWindow from '../facebook/CropWindow';

class TaboolaCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDefaultText: '',
      anchorEl: null,
      images: [],
      uploadedImages: [],
      src: [],
      showCropWindow: false,
      selectedImageIndex: 0,
      originFileName: [],
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      isFocusedText: Array(props.group.images.length).fill(false),
      isFocusedDescription: Array(props.group.images.length).fill(false)
    };
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  showHelperText = idx => {
    const text = this.props.group.texts[idx] || '';
    return `${text.length}/${TB.MAX_TEXT_CHARS}`;
  };

  showHelperDesc = idx => {
    const description = this.props.group.descriptions[idx] || '';
    return `${description.length}/${TB.MAX_DESCRIPTION_CHARS}`;
  };

  onSelectFileCrop = async ad => {
    const blob = await fetch(`/api/getImg?img=${encodeURIComponent(ad)}`).then(r => r.blob());
    this.readAsDataURL(blob).then(res => {
      console.log('res');
      this.setState({ src: res, showCropWindow: true });
    });
  };

  readAsDataURL = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  onUploadFile = async (adsetIndex, e) => {
    const title = this.state.titleTooltip;
    title[adsetIndex] = `0/500`;
    const { originFileName } = this.state;
    const uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      originFileName.push(uploadedFiles[i].name);
    }
    this.setState({ titleTooltip: title, originFileName });
    this.props.onAddImages(adsetIndex, e);
  };

  onClose = () => {
    this.setState({ showCropWindow: false, src: '' });
  };

  renderImage(path, idx) {
    const fullPath = getFullPath(path);
    return (
      <React.Fragment key={this.getKey('renderCreativeCard', idx)}>
        <Grid item sm={4}>
          <StyledCard>
            <IconButton
              style={{ backgroundColor: 'transparent' }}
              title="Delete ad"
              fontSize="small"
              aria-label="delete"
              key={this.getKey('imageRemoveButton', idx)}
              onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
              sx={{ margin: '-30px 0 -55px 150px' }}
              size="large"
            >
              <Tooltip title="Delete">
                <ClearIcon />
              </Tooltip>
            </IconButton>
            <CardHeader
              subheader={
                <TextField
                  key={this.getKey('imageText', idx)}
                  value={this.props.group.texts[idx]}
                  onChange={(imageIdx, e) => {
                    this.props.onChangeImageText(idx, imageIdx, e);
                  }}
                  variant="standard"
                  label="Title"
                  minRows={1}
                  maxRows={10}
                  multiline
                  fullWidth
                  helperText={this.showHelperText(idx)}
                  inputProps={{
                    maxLength: TB.MAX_TEXT_CHARS
                  }}
                  InputLabelProps={{ shrink: !!this.props.group.texts[idx] || this.state.isFocusedText[idx] }}
                />
              }
            />
            <StyledCardMedia key={this.getKey('cardImage', idx)} image={fullPath} title="Example" />
            <CardContent>
              <TextField
                variant="standard"
                multiline
                fullWidth
                minRows={1}
                maxRows={10}
                label="Description"
                key={this.getKey('imageDesc', idx)}
                value={this.props.group.descriptions[idx] || ''}
                onChange={(imageIdx, e) => {
                  this.props.onChangeImageDescription(idx, imageIdx, e);
                }}
                helperText={this.showHelperDesc(idx)}
                inputProps={{
                  maxLength: TB.MAX_DESCRIPTION_CHARS
                }}
                InputLabelProps={{
                  shrink: !!this.props.group.descriptions[idx] || this.state.isFocusedDescription[idx]
                }}
              />
            </CardContent>
            <CardContent>
              <Tooltip title="Call to action button" placement="right-end">
                <Grid item xs={12} sm={12}>
                  <Select
                    menuPlacement="top"
                    options={TB.CTA_VALUES}
                    onChange={(imageIdx, e) => this.props.onChangeImageCTA(idx, imageIdx, e)}
                    value={getSelectValue(TB.CTA_VALUES, this.props.group.cta[idx])}
                  />
                </Grid>
              </Tooltip>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                color="primary"
                key={this.getKey('populateButton', idx)}
                onClick={imageIdx => this.props.populateText(idx, imageIdx)}
                title="Populate all texts in group"
                size="large"
              >
                <Tooltip title="Populate all texts in group">
                  <TextRotationNoneIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                color="primary"
                key={this.getKey('imageDuplicateButton', idx)}
                onClick={imageIdx => this.props.duplicateImage(idx, imageIdx)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                aria-label="menu"
                color="primary"
                key={this.getKey('cropButton', idx)}
                onClick={() => this.onSelectFileCrop(path, idx)}
                title="Crop image"
                size="large"
              >
                <Tooltip title="Crop image">
                  <CropIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </CardActions>
          </StyledCard>
        </Grid>
      </React.Fragment>
    );
  }
  render() {
    return (
      <React.Fragment key={this.getKey('creativeGroup', this.props.index)}>
        <CreativesAppBar
          index={this.props.index}
          images={this.props.group.images}
          onUploadFile={async e => this.onUploadFile(this.props.index, e)}
          removeGroup={imageIdx => this.props.removeGroup(imageIdx)}
          duplicateGroup={imageIdx => this.props.duplicateGroup(imageIdx)}
        />
        <Grid container direction="row" spacing={1}>
          {this.state.showCropWindow ? (
            <CropWindow
              key={this.getKey('crop')}
              onAddImage={(index, blob) => this.onUploadFile(index, blob)}
              src={this.state.src}
              adsetIndex={this.props.index}
              onClose={() => this.onClose()}
              platform={PLATFORMS.TABOOLA}
            />
          ) : null}
          {this.props.group.images.map((path, i) => this.renderImage(path, i))}
        </Grid>
      </React.Fragment>
    );
  }
}
export default TaboolaCreativeGroup;
