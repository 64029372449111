import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { clone, merge } from '../utils/objects';
import { getArchive } from './Api';
import { styled } from '@mui/system';
import theme from '../Theme';

function prepare(data) {
  console.log(data);
  if (data[0].id) {
    const res = [];
    for (const i of data) {
      const item = {};
      res.push(item);
      for (const k in i) {
        item[k.startsWith('item') ? '_'.concat(k.substring(4)) : k] = i[k];
      }
    }
    console.log(res);
    return res;
  }
  if (data[0].echo) {
    for (const i of data) {
      data[0].id = `echo${i.echo}`;
    }
  }
  return data;
}

function mergeTraces(traces, data) {
  if (!traces) {
    return clone(data);
  }
  merge(traces, data);
  return traces;
}

function removeGroup(traces, id) {
  return traces.filter(i => i.id !== id);
}

function idNext(it, node, prefix) {
  if (node) {
    it.node = node;
    it.prefix = prefix;
    it.index = 0;
  } else if (!it.current) {
    return;
  }
  it.index++;
  it.current = it.node[it.prefix.concat(it.index)];
  return it.current;
}

function hasStatusError(traces) {
  if (traces) {
    for (const t of traces) {
      if (t.state && t.state.error) {
        return true;
      }
      for (const k in t) {
        if (k.startsWith('_')) {
          const status = t[k].status;
          if (status === 'error' || status === 'failed') {
            return true;
          }
        }
      }
    }
  }
}

function isPending(traces) {
  if (traces) {
    for (const t of traces) {
      if (t.state && t.state.error) {
        continue;
      }
      if (!t.result) {
        return true;
      }
    }
  }
}

const StyledCard = styled(Card)(() => ({
  width: '100%',
  maxWidth: 1060,
  backgroundColor: theme.palette.background.paper,
  marginBottom: '1em'
}));

const StyledList = styled(List)(({ theme, prefix }) => {
  if (prefix === '_') {
    return {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    };
  } else {
    return {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingLeft: '2em'
    };
  }
});
const getIconColor = status => {
  switch (status) {
    case 'error':
      return 'purple';
    case 'errorfb':
      return 'red';
    case 'errorfb_sec':
      return 'orange';
    case 'errorfb_rate':
    case 'errorrate':
      return 'darkred';
    case 'errorretry':
      return 'red';
    case 'erroraccess':
      return 'orange';
    case 'ok':
      return 'green';
    default:
      return 'inherit';
  }
};

const DynamicStyledIcon = styled(({ iconType, status, ...otherProps }) => {
  const IconComponent = iconType;
  return <IconComponent {...otherProps} />;
})(({ theme, status }) => ({
  fontSize: '2.5em',
  color: getIconColor(status)
}));

function jsonToClipBoard(src) {
  navigator.clipboard.writeText(JSON.stringify(src, null, 2));
}

class MultiTrace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      traces: null
    };
  }
  setAll(traces) {
    this.setState({ traces });
    const code =
      !traces || !traces.length ? 0 : traces.length * 3 + (hasStatusError(traces) ? 1 : isPending(traces) ? 0 : 2);
    this.props.onChanged(code);
  }
  trace(data) {
    this.setAll(mergeTraces(this.state.traces, prepare(data)));
  }
  onRemove(id) {
    this.setAll(removeGroup(this.state.traces, id));
  }

  onReload(archive) {
    const self = this;
    getArchive(archive).then(data => {
      if (data) {
        if (data.result) {
          data._reload = false;
        }
        self.trace([data]);
      }
    });
  }
  setTimer() {
    if (!this.timer) {
      this.timer = true;
      setTimeout(() => this.onTimer(), 10000);
    }
  }
  onTimer() {
    this.timer = false;
    const traces = this.state.traces;
    if (!traces || !traces.length) {
      return;
    }
    let reload = false;
    for (const i of traces) {
      if (!i._reload) {
        continue;
      }
      reload = true;
      this.onReload(i.archive);
    }
    reload && this.setTimer();
  }
  autoReload() {
    const traces = this.state.traces;
    if (!traces || !traces.length) {
      return;
    }
    let reload = false;
    for (const i of traces) {
      if (i.result || !i.archive) {
        continue;
      }
      i._reload = true;
      reload = true;
      this.onReload(i.archive);
    }
    reload && this.setTimer();
  }

  render() {
    const traces = this.state.traces;
    if (!traces || !traces.length) {
      return null;
    }

    const node = (n, prefix) => {
      const it = {};
      let i = idNext(it, n, prefix);
      if (!i) {
        return null;
      }
      const res = [];
      for (; i; i = idNext(it)) {
        console.log({ res });
        res.push(
          <React.Fragment key={it.index}>
            <ListItem role={undefined} alignItems="flex-start" dense>
              <ListItemIcon>
                <DynamicStyledIcon
                  iconType={
                    i.status === 'failed' || i.status === 'error'
                      ? ErrorIcon
                      : i.status === 'ok'
                      ? CheckCircleIcon
                      : AutorenewIcon
                  }
                  status={i.status}
                />
              </ListItemIcon>
              <ListItemText primary={i.name || it.index} secondary={i.trace} />
            </ListItem>
            {node(n, `${prefix}${it.index}_`)}
          </React.Fragment>
        );
      }
      return <StyledList prefix={prefix}>{res}</StyledList>;
    };

    return (
      <>
        {traces.map(g => {
          return (
            <StyledCard variant="outlined" key={g.id}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {g.name || g.id}
                </Typography>
                {g.archive ? (
                  <Typography variant="body2" component="div">
                    {g.archive}
                  </Typography>
                ) : null}
                {g.state && g.state.error ? (
                  <Typography variant="body2" component="div">
                    {g.state.error}
                  </Typography>
                ) : null}
                {node(g, '_')}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    jsonToClipBoard(g);
                  }}
                >
                  Copy Report
                </Button>
                {g.archive ? (
                  <Button
                    size="small"
                    onClick={() => {
                      this.onReload(g.archive);
                    }}
                  >
                    Reload
                  </Button>
                ) : null}
                {g.result ? (
                  <Button
                    size="small"
                    onClick={() => {
                      this.onRemove(g.id);
                    }}
                  >
                    Close
                  </Button>
                ) : null}
              </CardActions>
            </StyledCard>
          );
        })}
      </>
    );
  }
}

export default MultiTrace;
