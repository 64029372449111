import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import { Grid, Typography, Button, FormControlLabel, Switch } from '@mui/material';
import { getPlatformSites } from '../Sites';
import { TW, GLOBAL, TASKS, PLATFORMS } from '../Constants';
import { toast } from 'react-toastify';
import { constructWebsite } from '../ModifyOptions';
import CampaignsNote from '../CampaignsNote';
import { getMultiSelectValue, getNewCreatorValue, getOnlyValue, getSelectValue } from '../Shared';
import { Promise } from 'bluebird';

import { createTwitterCampaign, fetchTwitterAccountsData, getWidgetIdFromLink } from '../Api';
import Prefix from '../Prefix';

class TwitterBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      osChoices: [],
      tooltips: { text: '', open: false },
      campaigns: [],
      conversions: [],
      foundingSources: [],
      conversionName: '',
      accountName: [],
      sourceData: [],
      accountToCCAndFsource: {},
      creativeFromInsights: this.props.creativeFromInsights,
      creativeFromInsightsAdded: false
    };

    if (this.props.loadedFromSnapshot) {
      this.state = { ...this.state, ...this.props.options };
    }

    this.spendingModelList = [
      {
        label: 'Standard',
        value: 'true'
      },
      {
        label: 'Accelerated',
        value: 'false'
      }
    ];
    this.biddingStrategyList = [
      {
        label: 'Target cost',
        value: 'TARGET'
      },
      {
        label: 'Automatic bid',
        value: 'AUTO'
      },
      {
        label: 'Maximum bid',
        value: 'MAX'
      }
    ];
    this.siteVisitsBiddingStrategyList = [
      {
        label: 'Automatic bid',
        value: 'AUTO'
      },
      {
        label: 'Maximum bid',
        value: 'MAX'
      }
    ];
  }

  async componentDidMount() {
    //tr-us-adr-trd-antiwen-sth-c3127-ag1-v1,tr-us-d-fih-bluspen-sth-c3130-ag1-v1
    // /?openCampaignsFromInsights&campaigns=tr-us-adr-trd-antiwen-sth-c3127-ag1-v1,tr-us-d-fih-bluspen-sth-c3130-ag1-v1&platform=twitter
    // /?openCampaignsFromInsights&campaigns=dt-ww-a-spb-magiten-wespb24-c2-a1-v1,mh-us-m-edm-intrwen-wpedm29-c1-a1-v47&platform=facebook
    const accountToCCAndFsource = await fetchTwitterAccountsData();
    this.setState({ accountToCCAndFsource });
    // this.updateUserOptions();
    this.props.modifyOptions(TW.KEY_BIDDING_STRATEGY, 'MAX');
    this.props.modifyOptions(TW.KEY_BUDGET, 20);
    this.props.modifyOptions(TW.KEY_BUDGET_TYPE, true);
    this.props.modifyOptions(TW.KEY_STATUS, TW.ACTIVE);
    this.props.modifyOptions(TW.KEY_CAMPAIGN_START_TIME, moment().tz('America/New_York'));
    this.props.modifyOptions(TW.KEY_GEO_DUPLICATION_TYPE, 'separate');
    this.props.modifyOptions(TW.KEY_DEVICE_DUPLICATION_TYPE, 'separate');
    this.props.modifyOptions(TW.KEY_GOAL, 'SITE_VISITS');
    this.modifyOptions(TW.KEY_ACCOUNT_NAME, TW.DEFAULT_ACCOUNT_ID);
    this.modifyOptions(TW.KEY_BID, TW.DEFAULT_BID);
    this.props.modifyOptions(TW.KEY_FULL_SITE, this.getFullSite('trd'));
    this.modifyOptions(TW.KEY_SITE, ['trd']);
    this.modifyOptions(TW.KEY_COUNTRY, ['us']);
    this.modifyOptions(TW.KEY_CREATOR, getNewCreatorValue(this.props.userMail, this.props.creatorsList));
  }

  async componentDidUpdate(prevProps) {
    if (this.props.sourceData !== prevProps.sourceData) {
      const newSourceData = [];
      const errorCampaigns = [];
      for (let campaignData of this.props.sourceData) {
        campaignData = await parseTwitterData(campaignData);
        if (campaignData.widgetId) {
          newSourceData.push(campaignData);
        } else {
          errorCampaigns.push(campaignData.name);
        }
      }
      if (errorCampaigns.length) {
        toast.error(
          `Can't get data for campaigns ${errorCampaigns.join(
            ', '
          )}. These campaigns will not be duplicated. Please select another campaigns.`
        );
      }
      toast.success(`Successfully read ${newSourceData.length} campaigns`);
      this.setState({ sourceData: newSourceData });
    }

    if (this.props.creativeFromInsights && !this.state.creativeFromInsightsAdded) {
      const newSourceData = [];
      const errorCampaigns = [];
      for (let campaignData of this.props.creativeFromInsights) {
        campaignData = await parseByCreativeTwitterData(campaignData);
        if (campaignData.widgetId) {
          newSourceData.push(campaignData);
        } else {
          errorCampaigns.push(campaignData.name);
        }
      }
      if (errorCampaigns.length) {
        toast.error(
          `Can't get data for campaigns ${errorCampaigns.join(
            ', '
          )}. These campaigns will not be duplicated. Please select another campaigns.`
        );
      }
      this.setState({ sourceData: newSourceData, creativeFromInsightsAdded: true });
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.TWITTER.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
  }

  validateOptions(key) {
    if (key === TW.KEY_DEFAULT_BID || key === TW.KEY_BID) {
      const value = this.props.state.options[key];
      if (value < TW.MIN_BID) {
        console.log('Min bid reached');
        this.props.modifyOptions(key, TW.MIN_BID);
        return;
      }
      if (value > TW.MAX_BID) {
        console.log('Max bid reached');
        this.props.modifyOptions(key, TW.MAX_BID);
        return;
      }
    }
    if (key === TW.KEY_BUDGET) {
      const value = this.props.state.options[key];
      if (value < TW.MIN_BUDGET_LIMIT) {
        console.log('Min budget reached');
        this.props.modifyOptions(key, TW.MIN_BUDGET_LIMIT);
        return;
      }
      if (value > TW.MAX_BUDGET_LIMIT) {
        console.log('Max budget reached');
        this.props.modifyOptions(key, TW.MAX_BUDGET_LIMIT);
      }
    }
  }

  modifyOptions(key, value) {
    if (
      key === TW.KEY_BUDGET ||
      key === TW.KEY_DEFAULT_BID ||
      key === TW.KEY_PREFIX ||
      key === 'campName' ||
      key === TW.KEY_GEO_NAME ||
      key === TW.KEY_WEBSITE ||
      key === TW.KEY_BID
    ) {
      // Most probably, it's an "input" element
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === TW.KEY_ACCOUNT_NAME) {
      //temporary solution fot "tup" 22.05.2020
      let accountName =
        value === 'artificial_tup'
          ? 'tup'
          : value === 'artificial_mmo'
          ? 'mmo'
          : value === 'artificial_nmi'
          ? 'nmi'
          : value === 'artificial_spn'
          ? 'spn'
          : value === 'artificial_esc'
          ? 'esc'
          : value === 'artificial_tsr'
          ? 'tsr'
          : value === 'artificial_tdw'
          ? 'tdw'
          : value === 'artificial_yrb'
          ? 'yrb'
          : value === 'artificial_sds'
          ? 'sds'
          : this.props.state.accounts.find(el => el.id === value).desc.replace(/\s/g, '');
      this.setState({ accountName: accountName });
      this.props.modifyOptions(TW.KEY_ACCOUNT_NAME, accountName);
      const domain = accountName.slice(0, 3);
      this.modifyOptions(TW.KEY_SITE, domain);
      this.props.modifyOptions(TW.KEY_FULL_SITE, this.getFullSite(domain));
      let options = this.props.state.options;
      options[TW.KEY_SITE] = accountName.slice(0, 3);
      this.props.modifyOptions(TW.KEY_WEBSITE, this.updateWebsite(options));
    }
    if (key === TW.KEY_DESTINATION_ACCOUNT) {
      let accountName =
        value === 'artificial_tup'
          ? 'tup'
          : value === 'artificial_mmo'
          ? 'mmo'
          : value === 'artificial_nmi'
          ? 'nmi'
          : value === 'artificial_spn'
          ? 'spn'
          : value === 'artificial_esc'
          ? 'esc'
          : value === 'artificial_tsr'
          ? 'tsr'
          : value === 'artificial_tdw'
          ? 'tdw'
          : value === 'artificial_yrb'
          ? 'yrb'
          : value === 'artificial_sds'
          ? 'yrb'
          : this.props.state.accounts.find(el => el.id === value).desc.replace(/\s/g, '');
      const accountId = this.props.state.accounts.find(el => el.id === value).id;
      const domain = accountName.slice(0, 3);
      this.modifyOptions(TW.KEY_SITE, domain);
      if (accountName !== 'mdm1') {
        this.setState({ accountName: accountName });
        this.props.modifyOptions(TW.KEY_ACCOUNT_NAME, accountId);
        this.props.modifyOptions(TW.KEY_FULL_SITE, this.getFullSite(domain));
        let options = this.props.state.options;
        this.props.modifyOptions(TW.KEY_WEBSITE, this.updateWebsite(options));
      }
    }
    this.props.modifyOptions(key, value);
    if (key === TW.KEY_SITE) {
      if (value === 'esc') {
        this.props.modifyOptions('widgetVersion', value);
      }
      const fullSite = this.getFullSite(value);
      this.props.modifyOptions(TW.KEY_FULL_SITE, fullSite);
    }
    if (key === TW.KEY_DEVICE) {
      let devices = value;
      let newValue = [];
      this.props.modifyOptions(TW.KEY_OS, newValue);
      this.setState({
        osChoices: newValue
      });
      let conversion = null;
      if (value === TW.IOS_DEVICE) {
        this.props.modifyOptions(TW.KEY_BUDGET, 3);
        this.props.modifyOptions(TW.KEY_BID, 0.07);
        conversion = TW.BULK_CONVERSIONS.find(el => el.label.includes('visit 0.03'));
      }
      if (value === TW.ANDROID_DEVICE) {
        this.props.modifyOptions(TW.KEY_BUDGET, 8);
        this.props.modifyOptions(TW.KEY_BID, 0.13);
        conversion = TW.BULK_CONVERSIONS.find(el => el.label.includes('visit 0.07'));
      }
      if (value === TW.DESKTOP_DEVICE) {
        this.props.modifyOptions(TW.KEY_BUDGET, 8);
        this.props.modifyOptions(TW.KEY_BID, 0.13);
        conversion = TW.BULK_CONVERSIONS.find(el => el && el.label && el.label.includes('visit 0.09'));
      }
      if (conversion) {
        this.setState({ conversionName: conversion.label });
        this.props.modifyOptions(TW.KEY_CONVERSION, conversion.value);
      }
    }
    if (key === TW.KEY_COUNTRY) {
      let countries = value;
      if (typeof countries !== 'string') {
        countries = countries.join(',');
      }
      this.props.modifyOptions(TW.KEY_GEO_NAME, countries);
    }
    if (key === TW.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
    if (key === TW.KEY_SITE || key === TW.KEY_PREFIX || key === 'article') {
      this.props.modifyOptions(TW.KEY_WEBSITE, this.updateWebsite(this.props.state.options));
      const conversionObj = TW.BULK_CONVERSIONS.find(el => el.value === this.props.state.options[TW.KEY_CONVERSION]);
      if (conversionObj) {
        this.setState({ conversionName: conversionObj.label });
      }
    }
    if (key === TW.KEY_CONVERSION) {
      this.setState({ conversionName: value });
    }
    if (key === TW.KEY_CAMPAIGN_START_TIME) {
      this.props.modifyOptions(TW.KEY_CAMPAIGN_START_TIME, moment(value).tz('America/New_York'));
    }
    if (key === TW.KEY_CAMPAIGN_STOP_TIME) {
      this.props.modifyOptions(TW.KEY_CAMPAIGN_STOP_TIME, moment(value).tz('America/New_York'));
    }
  }

  setDefaultBid(val) {
    let bids = this.props.state.options[TW.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < TW.MIN_BID) {
        b.bid = TW.MIN_BID;
      } else if (val > TW.MAX_BID) {
        b.bid = TW.MAX_BID;
      }
    });
    this.props.modifyOptions(TW.KEY_BIDS, bids);
  }

  updateOptionsForMultipleDomains(options, site) {
    options = JSON.parse(JSON.stringify(options));
    const fullSite = this.getFullSite(site);
    options[TW.KEY_SITE] = [site];
    options[TW.KEY_FULL_SITE] = fullSite;
    return options;
  }

  updateOptionsForMultipleDevices(options, device) {
    options = JSON.parse(JSON.stringify(options));
    options[TW.KEY_DEVICE] = [device];
    return options;
  }

  async prepareOptions() {
    let options = JSON.parse(JSON.stringify(this.props.state.options));
    let separateCampaigns = [];
    if (options[TW.KEY_CAMPAIGN_START_TIME] && typeof options[TW.KEY_CAMPAIGN_START_TIME] === 'string') {
      // We display America/New_York time on UI, but convert it to UTC when sending to server
      let startDate = options[TW.KEY_CAMPAIGN_START_TIME];
      options[TW.KEY_CAMPAIGN_START_TIME] = startDate.slice(0, startDate.indexOf('.')) + 'Z';
    }
    if (options[TW.KEY_CAMPAIGN_STOP_TIME] && typeof options[TW.KEY_CAMPAIGN_STOP_TIME] === 'string') {
      // We display America/New_York time on UI, but convert it to UTC when sending to server
      let endDate = options[TW.KEY_CAMPAIGN_STOP_TIME];
      options[TW.KEY_CAMPAIGN_STOP_TIME] = endDate.slice(0, endDate.indexOf('.')) + 'Z';
    }
    for (let key in options) {
      if (!options[key]) {
        delete options[key];
      }
    }
    if (typeof options[TW.KEY_COUNTRY] === 'string') {
      options[TW.KEY_COUNTRY] = options[TW.KEY_COUNTRY].split(',');
    }
    if (typeof options[TW.KEY_DEVICE] === 'string') {
      options[TW.KEY_DEVICE] = options[TW.KEY_DEVICE].split(',');
    }
    if (typeof options[TW.KEY_SITE] === 'string') {
      options[TW.KEY_SITE] = options[TW.KEY_SITE].split(',');
    }
    const countries = options[TW.KEY_COUNTRY];
    const sites = options[TW.KEY_SITE];
    const devices = options[TW.KEY_DEVICE];
    if (options[TW.KEY_GEO_DUPLICATION_TYPE] === 'separate' && countries.length > 1) {
      countries.forEach(country => {
        sites.forEach(site => {
          const updatedOptions = this.updateOptionsForMultipleDomains(options, site);
          updatedOptions[TW.KEY_COUNTRY] = [country];
          if (options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate' && devices.length > 1) {
            devices.forEach(device => {
              const updatedByDeviceOptions = this.updateOptionsForMultipleDevices(updatedOptions, device);
              separateCampaigns.push(JSON.parse(JSON.stringify(updatedByDeviceOptions)));
            });
          } else {
            separateCampaigns.push(updatedOptions);
          }
        });
      });
    } else {
      sites.forEach(site => {
        const updatedOptions = this.updateOptionsForMultipleDomains(options, site);
        if (options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate' && devices.length > 1) {
          devices.forEach(device => {
            const updatedByDeviceOptions = this.updateOptionsForMultipleDevices(updatedOptions, device);
            separateCampaigns.push(JSON.parse(JSON.stringify(updatedByDeviceOptions)));
          });
        } else {
          separateCampaigns.push(updatedOptions);
        }
      });
    }

    if (this.state.sourceData && this.state.sourceData.length) {
      const targets = await Promise.all(
        this.state.sourceData.map(async data => {
          return Promise.all(
            separateCampaigns.map(async camp => {
              camp = JSON.parse(JSON.stringify(camp));
              delete camp[TW.KEY_ACCOUNT_NAME];
              camp[TW.KEY_CREATIVE_GROUPS] = data.creativeGroups;
              camp.widgetid = data.widgetId;
              camp.sourceCampaignName = data.name;
              if (data.validate_image) {
                camp.validate_image = true;
              }
              return JSON.parse(JSON.stringify(camp));
            })
          );
        })
      );
      return targets.flat();
    } else {
      return [];
    }
  }

  async startCreateCampaign() {
    this.props.startLoading();
    const options = await this.prepareOptions();
    this.props.endLoading();
    console.log(options);
    createTwitterCampaign(this.props.state.options[TW.KEY_DESTINATION_ACCOUNT], options, this.props.session, true);
  }

  getCampaignsNumber() {
    let countries = this.props.state.options[TW.KEY_COUNTRY];
    let sites = this.props.state.options[TW.KEY_SITE] || [];
    let devices = this.props.state.options[TW.KEY_DEVICE];
    let creativeGroups = this.state.sourceData || [];
    countries = typeof countries === 'string' ? countries.split(',') : countries;
    sites = typeof sites === 'string' ? sites.split(',') : sites;
    devices = typeof devices === 'string' ? devices.split(',') : devices;
    if (countries && countries.length > 1 && this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'separate') {
      if (devices && devices.length > 1 && this.props.state.options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate') {
        return countries.length * sites.length * devices.length * creativeGroups.length;
      }
      return countries.length * sites.length * creativeGroups.length;
    } else {
      if (devices && devices.length > 1 && this.props.state.options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'separate') {
        return sites.length * devices.length * creativeGroups.length;
      }
      return sites.length * creativeGroups.length;
    }
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    let res = parts.filter(el => el).join('-') + ' = ' + bid.bid;
    console.log(res);
    return res;
  }

  validateSetup() {
    const options = this.props.state.options;
    // Check that we have campaign to create
    if (!options[TW.KEY_COUNTRY]) {
      const err = 'There is no selected geo';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_DEVICE]) {
      const err = 'There is no selected device';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_SITE]) {
      const err = 'There is no selected site';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_ACCOUNT_NAME]) {
      const err = 'There is no selected account name';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_CREATOR]) {
      const err = 'There is no selected creator';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!options[TW.KEY_CONVERSION]) {
      const err = 'There is no selected conversion';
      console.log(err);
      toast.error(err);
      return false;
    }
    return true;
  }

  getSiteOptions(xs) {
    return xs
      .map(el => {
        el.label = el.label.slice(0, 3);
        return el;
      })
      .concat({ label: 'mmo', value: 'mmo' });
  }

  updateWebsite(ref) {
    const domain = ref[TW.KEY_SITE];
    const websiteObj = getPlatformSites(PLATFORMS.TWITTER.toLowerCase()).find(s => s.code === domain);
    const website = websiteObj ? websiteObj.name : '';
    const isDomainPresent = website;
    const isArticleAbsent = ref['widgetCodeName'] === null && ref['widgetLanguageCode'] === null && !ref['widgetid'];
    if (isDomainPresent) {
      if (isArticleAbsent) {
        return constructWebsite(ref, website, 'THE_SAME_AS_ORIGINAL');
      } else {
        return constructWebsite(ref, website, ref['widgetid']);
      }
    } else {
      if (ref['widgetid']) {
        return constructWebsite(ref, 'SITE_NAME', ref['widgetid']);
      } else {
        // No domain, no article, leave website empty
        return '';
      }
    }
  }

  submit() {
    if (!this.validateSetup()) {
      return;
    }
    let title;
    let text;
    if (this.props.state.options.warnings && this.props.state.options.warnings.length > 0) {
      const warningBids = this.props.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = 'Are you sure to do this?';
    }

    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  renderCreateDuplicate() {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Source creatives
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="sourceCreatives"
            value={this.props.creativeHashes}
            onChange={e => this.props.updateCreativeHashes(e.target.value)}
            onPaste={this.props.handleCreativesPaste.bind(this)}
            onKeyDown={e => this.props.handleCreativeHashesKeyDown(e)}
          />
        </Grid>
        <Prefix
          // platform={this.props.platform}
          value={{ label: this.props.state.options[TW.KEY_PREFIX], value: this.props.state.options[TW.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(TW.KEY_PREFIX, x.value)}
          options={this.props.programOptions.prefix?.map(x => ({ label: x, value: x }))}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Creator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            options={this.props.creatorsList}
            onChange={x => this.modifyOptions(TW.KEY_CREATOR, x.value)}
            value={getSelectValue(this.props.creatorsList, this.props.state.options[TW.KEY_CREATOR])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(TW.KEY_COUNTRY, getOnlyValue(xs))}
            options={this.props.countryList}
            value={getMultiSelectValue(this.props.countryList, this.props.state.options[TW.KEY_COUNTRY])}
          />
        </Grid>
        <Grid item xs={4} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(TW.KEY_GEO_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.props.state.options[TW.KEY_GEO_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Geo name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="geoname"
            value={this.props.state.options[TW.KEY_GEO_NAME]}
            onChange={value => this.modifyOptions(TW.KEY_GEO_NAME, value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select language
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_LANGUAGE, x.value)}
            options={TW.LANGUAGE_LIST}
            value={getSelectValue(TW.LANGUAGE_LIST, this.props.state.options[TW.KEY_LANGUAGE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select domain
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(TW.KEY_SITE, getOnlyValue(xs))}
            options={this.getSiteOptions(this.props.siteList)}
            value={getMultiSelectValue(this.getSiteOptions(this.props.siteList), this.props.state.options[TW.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(TW.KEY_DEVICE, getOnlyValue(xs))}
            options={TW.DEVICES_LIST}
            value={getMultiSelectValue(TW.DEVICES_LIST, this.props.state.options[TW.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={4} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.props.state.options[TW.KEY_DEVICE_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) =>
                  this.modifyOptions(TW.KEY_DEVICE_DUPLICATION_TYPE, value ? 'group' : 'separate')
                }
                color="primary"
              />
            }
            label="Group"
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Age range
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(TW.KEY_AGE, x?.value)}
            options={TW.AGE_LIST}
            value={getSelectValue(TW.AGE_LIST, this.props.state.options[TW.KEY_AGE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Gender
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(TW.KEY_GENDER, x?.value)}
            options={TW.GENDER_LIST}
            value={getSelectValue(TW.GENDER_LIST, this.props.state.options[TW.KEY_GENDER])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Goal
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(TW.KEY_GOAL, x?.value)}
            options={TW.GOAL_LIST}
            value={getSelectValue(TW.GOAL_LIST, this.props.state.options[TW.KEY_GOAL])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Custom conversions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_CONVERSION, x.value)}
            options={
              this.props.state.options[TW.KEY_GOAL] === 'SITE_VISITS'
                ? TW.BULK_CONVERSIONS
                : TW.BULK_CONVERSIONS.filter(
                    el =>
                      el.label.toLowerCase().startsWith('visit') ||
                      el.label.toLowerCase().startsWith('site visit all sites')
                  )
            }
            value={{
              label: this.props.state.options[TW.KEY_CONVERSION],
              value: this.props.state.options[TW.KEY_CONVERSION]
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Website URL
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name="website"
            value={this.props.state.options[TW.KEY_WEBSITE]}
            onChange={value => this.modifyOptions(TW.KEY_WEBSITE, value)}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_BUDGET_TYPE, x.value)}
            options={this.spendingModelList}
            value={getSelectValue(this.spendingModelList, this.props.state.options[TW.KEY_BUDGET_TYPE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            name={TW.KEY_BUDGET}
            value={this.props.state.options[TW.KEY_BUDGET]}
            onChange={value => this.modifyOptions(TW.KEY_BUDGET, value)}
            onBlur={() => this.validateOptions(TW.KEY_BUDGET)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bidding Strategy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_BIDDING_STRATEGY, x.value)}
            options={
              this.props.state.options[TW.KEY_GOAL] === 'SITE_VISITS'
                ? this.siteVisitsBiddingStrategyList
                : this.biddingStrategyList
            }
            value={getSelectValue(
              this.props.state.options[TW.KEY_GOAL] === 'SITE_VISITS'
                ? this.siteVisitsBiddingStrategyList
                : this.biddingStrategyList,
              this.props.state.options[TW.KEY_BIDDING_STRATEGY]
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bid
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            name={TW.KEY_BID}
            step={TW.BID_STEP}
            value={this.props.state.options[TW.KEY_BID]}
            onChange={value => this.modifyOptions(TW.KEY_BID, value)}
            onBlur={() => this.validateOptions(TW.KEY_BID)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign start date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.props.state.options[TW.KEY_CAMPAIGN_START_TIME]
                ? new Date(this.props.state.options[TW.KEY_CAMPAIGN_START_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(TW.KEY_CAMPAIGN_START_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={moment().toDate()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign end date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.props.state.options[TW.KEY_CAMPAIGN_STOP_TIME]
                ? new Date(this.props.state.options[TW.KEY_CAMPAIGN_STOP_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(TW.KEY_CAMPAIGN_STOP_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={
              this.props.state.options[TW.KEY_CAMPAIGN_START_TIME]
                ? moment(this.props.state.options[TW.KEY_CAMPAIGN_START_TIME])
                    .add(1, 'day')
                    .toDate()
                : moment()
                    .add(1, 'day')
                    .toDate()
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(TW.KEY_STATUS, x.value)}
            options={TW.STATUS}
            value={getSelectValue(TW.STATUS, this.props.state.options[TW.KEY_STATUS])}
          />
        </Grid>
        <CampaignsNote
          value={this.props.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
          onChange={e => {
            this.props.modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
          }}
        />
        <Grid item xs={6} sm={4} align="right">
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getCreateButtonText()}
          </Button>
        </Grid>
      </>
    );
  }

  render() {
    return this.renderCreateDuplicate();
  }
}

function decodeHtml(text) {
  let div = document.createElement('textarea');
  div.innerHTML = text;
  return div.firstChild.nodeValue;
}

async function parseTwitterData(data) {
  let options = {};
  options[TW.KEY_CREATIVE_GROUPS] = [];
  const sourceGroup = {
    images: [{ image: data.campaign.s3ImageUrl || data.campaign.image_url }],
    imageHashes: [data.campaign.s3ImageUrl || data.campaign.image_url],
    texts:
      data.campaign.platform.toLowerCase() === 'taboola'
        ? [data.campaign.title.slice(0, TW.MAX_TITLE_CHARS)]
        : [decodeHtml(data.campaign.text.slice(0, TW.MAX_TEXT_CHARS))],
    tooltips: [],
    titles: [data.campaign.title.slice(0, TW.MAX_TITLE_CHARS)]
  };
  options.url = data.campaign.link;
  options[TW.KEY_CREATIVE_GROUPS].push(sourceGroup);
  options.name = data.campaign.name;
  if (options.prefix === 'xx') {
    options[TW.KEY_STATUS] = TW.PAUSED;
  } else {
    options[TW.KEY_STATUS] = TW.ACTIVE;
  }
  options[TW.KEY_CREATOR] = data.campaignCreator;
  options.widgetId = data.widget_id;
  if (!options.widgetId) {
    const widgetData = await getWidgetIdFromLink(options.url, options.name);
    options.widgetId = widgetData && widgetData.widgetId ? widgetData.widgetId : '';
  }
  if (data.campaign.platform.toLowerCase() !== 'twitter') {
    //to validate images from other platforms on TW server
    options.validate_image = true;
  }
  return options;
}

async function parseByCreativeTwitterData(data) {
  //1096394fcb8bf45d251f15b5fc80789c4105c04b,40915a907e56e408d4cfced8407ede44faa536de

  let options = {};
  options[TW.KEY_CREATIVE_GROUPS] = [];
  const sourceGroup = {
    images: [{ image: data.s3ImageUrl || data.image_url }],
    imageHashes: [data.s3ImageUrl || data.image_url],
    texts: [decodeHtml(data.text.slice(0, TW.MAX_TEXT_CHARS))],
    tooltips: [],
    titles: [data.title.slice(0, TW.MAX_TITLE_CHARS)]
  };
  options.url = data.link;
  options[TW.KEY_CREATIVE_GROUPS].push(sourceGroup);
  options.name = data.name;
  if (options.prefix === 'xx') {
    options[TW.KEY_STATUS] = TW.PAUSED;
  } else {
    options[TW.KEY_STATUS] = TW.ACTIVE;
  }
  options[TW.KEY_CREATOR] = data.campaignCreator;
  options.widgetId = data.widget_id;
  if (!options.widgetId) {
    const widgetData = await getWidgetIdFromLink(options.url, options.name);
    options.widgetId = widgetData && widgetData.widgetId ? widgetData.widgetId : '';
  }
  if (data.platform.toLowerCase() !== 'twitter') {
    //to validate images from other platforms on TW server
    options.validate_image = true;
  }
  return options;
}

export { TwitterBulk };
