import { WIDGET_SITE_FILTERS } from '../Constants';

/**
 * Filters 'widgetVersion' regarding WIDGET_SITE_FILTERS configuration or if widgetVersion is equal to site code
 * @param {Array} allowedVersions - Platform specific allowed versions
 * @param {Array} selectedSites - Selected sites
 * @returns {*[]} - Filtered versions
 */
export const filterVersionBySite = (allowedVersions, selectedSites) => {
  if (selectedSites && selectedSites.length) {
    if (!selectedSites.push) {
      selectedSites = selectedSites.split(',');
    }

    allowedVersions = allowedVersions.filter(version => {
      if (WIDGET_SITE_FILTERS[version]) {
        return selectedSites.every(site => {
          return WIDGET_SITE_FILTERS[version].sites.includes(site);
        });
      }

      return true;
    });

    if (selectedSites.length === 1) allowedVersions = allowedVersions.concat(selectedSites);
  }

  return allowedVersions;
};
