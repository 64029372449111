import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ViewListIcon from '@mui/icons-material/ViewList';
import { IconButton, Menu, MenuItem, Divider, Fade, Badge, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { logout } from '../utils/AuthService';
import { clearCache, reinitializeServer } from './Api';
import { toast } from 'react-toastify';
import { isSwag } from './Shared';
import NotificationCenter from './NotificationCenter';
import { styled } from '@mui/system';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    '&.MuiBadge-colorPrimary': {
      color: 'gray',
      backgroundColor: 'white'
    },
    '&.MuiBadge-colorSecondary': {
      color: 'white',
      backgroundColor: 'green'
    },
    '&.MuiBadge-colorError': {
      color: 'white',
      backgroundColor: 'red'
    }
  }
}));
export default function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [enhanced, setEnhanced] = useState(false);
  const [showReinitialize, setShowReinitialize] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showCollageMaker, setShowCollageMaker] = useState(false);
  const open = Boolean(anchorEl);
  const userOptions = props.user;

  useEffect(() => {
    if (
      userOptions &&
      userOptions.user_metadata &&
      userOptions.user_metadata.options &&
      (userOptions.user_metadata.options.prefix === 'xx' ||
        (userOptions.user_metadata.options.prefix.default && userOptions.user_metadata.options.prefix.default === 'xx'))
    ) {
      setShowReinitialize(true);
    }
    if (userOptions.app_metadata.spm_review_access) {
      setShowReview(true);
    }
    if (userOptions.app_metadata.spm_upload_access) {
      setShowUpload(true);
    }
    if (userOptions.app_metadata.spm_collage_access) {
      setShowCollageMaker(true);
    }
  }, [userOptions]);

  const handleOpen = event => {
    setEnhanced(!!event.ctrlKey);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const taskCount = Math.floor(props.taskCode / 3);

  return (
    <>
      <Badge>
        <NotificationCenter />
      </Badge>
      <Tooltip title="Tasks">
        <IconButton color="secondary" onClick={props.onTasks} size="large">
          <StyledBadge badgeContent={taskCount}>
            <ViewListIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <IconButton color="secondary" aria-controls="fade-menu" aria-haspopup="true" onClick={handleOpen} size="large">
        <MenuIcon />
      </IconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {showCollageMaker && (
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Link style={{ color: 'black' }} to="/collage-maker">
              Collage maker
            </Link>
          </MenuItem>
        )}
        {(showUpload || showReview) && userOptions.app_metadata.platforms.length > 0 && (
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            <Link style={{ color: 'black' }} to="/fbcreatives">
              FbCreatives
            </Link>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <Link style={{ color: 'black' }} to="/checkAccountStatus">
            Accounts info
          </Link>
        </MenuItem>
        <Divider />
        {enhanced ? <Divider /> : null}
        {enhanced && props.onTest ? (
          <MenuItem
            onClick={() => {
              props.onTest();
              handleClose();
            }}
          >
            Test
          </MenuItem>
        ) : null}
        {enhanced ? (
          <MenuItem
            onClick={() => {
              props.setDevSkipCreate(!props.devSkipCreate);
              handleClose();
            }}
          >
            {`devSkipCreate=${props.devSkipCreate}`}
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            clearCache().then(res => {
              if (res.success) {
                toast.info('Cache cleared');
                handleClose();
              }
            });
          }}
        >
          Clear cache
        </MenuItem>
        {showReinitialize && (
          <MenuItem
            onClick={() => {
              reinitializeServer();
              toast.info('Reinitialize server');
              handleClose();
            }}
          >
            Reinitialize server
          </MenuItem>
        )}
        <Divider />
        {isSwag(props.email) && (
          <MenuItem
            onClick={() => {
              props.toggleAllowPageSelection();
            }}
          >
            <input
              style={{ padding: '7px', marginLeft: '5px' }}
              type="checkbox"
              onChange={props.toggleAllowPageSelection}
              checked={props.allowPageSelection}
            />
            Allow manual pages
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            logout();
            props.onLogout();
            handleClose();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}
