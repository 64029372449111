import React from 'react';
import ZemantaCreativeGroup from './ZemantaCreativeGroup';

class ZemantaCreativeGroupBuilder extends React.Component {
  render() {
    return (
      <>
        {this.props.groups.map((el, groupIdx) => (
          <ZemantaCreativeGroup
            index={groupIdx}
            key={'obCreativeGroup' + groupIdx}
            onAddImages={(idx, imageBlob, deviceType, index) =>
              this.props.onAddImages(idx, imageBlob, deviceType, index)
            }
            onChangeImageDescription={(imageIdx, e) => this.props.onChangeImageDescription(groupIdx, imageIdx, e)}
            onChangeImageTitle={(imageIdx, e) => this.props.onChangeImageTitle(groupIdx, imageIdx, e)}
            removeImage={imageIdx => this.props.removeImage(groupIdx, imageIdx)}
            duplicateImage={imageIdx => this.props.duplicateImage(groupIdx, imageIdx)}
            removeGroup={() => this.props.removeGroup(groupIdx)}
            duplicateGroup={() => this.props.duplicateGroup(groupIdx)}
            populateText={imageIdx => this.props.populateText(groupIdx, imageIdx)}
            group={this.props.groups[groupIdx]}
          />
        ))}
      </>
    );
  }
}

export default ZemantaCreativeGroupBuilder;
