import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { FB, PLATFORMS } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CropIcon from '@mui/icons-material/Crop';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import CropWindow from './CropWindow';
import { byteSize } from '../Shared';
import PublishIcon from '@mui/icons-material/Publish';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import {
  Avatar,
  Grid,
  Typography,
  IconButton,
  Input,
  Tooltip,
  CardHeader,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  TextField,
  styled,
  Toolbar
} from '@mui/material';
import { StyledPrimaryAppBar, StyledSecondaryAppBar, StyledDiv } from '../SharedStyles';

const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '60%',
  backgroundSize: 'contain'
});

class SingleAdset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      anchorEl: null,
      images: [],
      src: [],
      showCropWindow: false,
      selectedImageIndex: 0,
      originFileName: [],
      isCheckedAdvantagePlusAdset: false,
      isCheckedAdvantagePlusCreative: false
    };
  }

  handleTitleChange = (e, idx) => {
    const title = [...this.state.titleTooltip];
    const titleMobile = [...this.state.titleTooltipMobile];
    const inputString = e.target.value;
    const sizeInBytes = byteSize(inputString);
    const mobileCharLimit = FB.MAX_TITLE_MOBILE_LENGTH - sizeInBytes;
    title[idx] = `${sizeInBytes}/${FB.MAX_TITLE_LENGTH}`;
    titleMobile[idx] = {
      text: `${mobileCharLimit}`,
      open: mobileCharLimit >= 0
    };
    this.setState({ titleTooltip: title, titleTooltipMobile: titleMobile });
  };

  handleTextChange = (e, idx) => {
    const text = this.state.textTooltip;
    text[idx] = `${e.target.value.length}/${FB.MAX_TEXT_LENGTH}`;
    this.setState({ textTooltip: text });
  };

  populateText(index) {
    const adset = this.props.adset;
    adset.ads.forEach((_, idx) => {
      adset.ads[idx].text = adset.ads[index].text;
      adset.ads[idx].title = adset.ads[index].title;
    });
    this.props.modifyAdset(this.props.index, adset);
  }
  populateAllText(index) {
    const adset = this.props.adset;
    this.props.populateAllText({ text: adset.ads[index].text, title: adset.ads[index].title });
  }

  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleDelete = idx => {
    this.removeAd(idx);
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getKey(base, idx) {
    return base + this.props.index + '_' + idx;
  }

  copyAd(idx) {
    const adset = this.props.adset;
    if (adset.ads.length >= FB.ADSET_LIMIT) {
      const tooltipText = this.state.textTooltip;
      const tooltipTitle = this.state.titleTooltip;
      const tooltipMobile = this.state.titleTooltipMobile;
      const fileName = this.state.originFileName[idx];
      const copyfileName = `${fileName} copy`;
      const fileNames = this.state.originFileName;
      fileNames.push(copyfileName);
      tooltipText.push(`${adset.ads[idx].text.length}/${FB.MAX_TEXT_LENGTH}`);
      tooltipTitle.push(`${adset.ads[idx].title.length}/${FB.MAX_TITLE_LENGTH}`);
      const charsLeft = FB.MAX_TITLE_MOBILE_LENGTH - adset.ads[idx].title.length;
      tooltipMobile.push({ text: charsLeft, open: true });
      this.setState({
        textTooltip: tooltipText,
        titleTooltip: tooltipTitle,
        titleTooltipMobile: tooltipMobile,
        originFileName: fileNames
      });

      this.props.modifyAdsets({ ...adset.ads[idx] });
    } else {
      adset.ads.push({ ...adset.ads[idx] });
      const tooltipText = this.state.textTooltip;
      const tooltipTitle = this.state.titleTooltip;
      const tooltipMobile = this.state.titleTooltipMobile;
      const fileName = this.state.originFileName[idx];
      const copyfileName = `${fileName} copy`;
      const fileNames = this.state.originFileName;
      fileNames.push(copyfileName);
      tooltipText.push(`${adset.ads[idx].text.length}/${FB.MAX_TEXT_LENGTH}`);
      tooltipTitle.push(`${adset.ads[idx].title.length}/${FB.MAX_TITLE_LENGTH}`);
      const charsLeft = FB.MAX_TITLE_MOBILE_LENGTH - adset.ads[idx].title.length;
      tooltipMobile.push({ text: charsLeft, open: true });
      this.setState({
        textTooltip: tooltipText,
        titleTooltip: tooltipTitle,
        titleTooltipMobile: tooltipMobile,
        originFileName: fileNames
      });
      this.props.modifyAdset(this.props.index, adset);
    }
  }

  sliceElement(xs, idx) {
    return xs.splice(idx, 1);
  }
  removeAd(idx) {
    const adset = this.props.adset;
    const tooltipText = this.state.textTooltip;
    tooltipText.splice(idx, 1);
    const tooltipTitle = this.state.titleTooltip;
    tooltipTitle.splice(idx, 1);
    const tooltipTitleMobile = this.state.titleTooltipMobile;
    tooltipTitleMobile.splice(idx, 1);
    const fileNames = this.state.originFileName;
    fileNames.splice(idx, 1);
    adset.ads.splice(idx, 1);
    // const tooltipMobile = this.state.titleTooltipMobile[idx].text.splice(idx, 1);
    this.setState({
      textTooltip: tooltipText,
      titleTooltip: tooltipTitle,
      tooltipTitleMobile: tooltipTitleMobile,
      originFileName: fileNames
    });
    this.props.modifyAdset(this.props.index, adset);
  }

  onChangeImageText(idx, event) {
    const adset = this.props.adset;
    adset.ads[idx].text = event.target.value;
    this.handleTextChange(event, idx);
    this.props.modifyAdset(this.props.index, adset);
  }

  onChangeImageTitle(idx, event) {
    const adset = this.props.adset;
    adset.ads[idx].title = event.target.value;
    this.handleTitleChange(event, idx);
    this.props.modifyAdset(this.props.index, adset);
  }

  onBlurImageText() {
    const adset = this.props.adset;
    this.handleTextChange();
    this.props.modifyAdset(this.props.index, adset);
  }

  removeImage(idx) {
    const adset = this.props.adset;
    adset.ads.splice(idx, 1);
    this.props.modifyAdset(this.props.index, adset);
  }

  duplicateAdset() {
    this.props.addAdset(JSON.parse(JSON.stringify(this.props.adset)));
  }

  onSelectFileCrop = async ad => {
    const blob = await fetch(`/api/getImg?img=${encodeURIComponent(ad.image)}`).then(r => r.blob());
    this.readAsDataURL(blob).then(res => {
      this.setState({ src: res, showCropWindow: true });
    });
  };

  readAsDataURL = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  onUploadFile = async (adsetIndex, e) => {
    const title = this.state.titleTooltip;
    title[adsetIndex] = `0/500`;
    const { originFileName } = this.state;
    const uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      originFileName.push(uploadedFiles[i].name);
    }
    this.setState({ titleTooltip: title, originFileName });
    this.props.onAddImage(adsetIndex, e);
  };

  onClose = () => {
    this.setState({ showCropWindow: false, src: '' });
  };

  showHelperTitle = idx => {
    const title = this.props.adset.ads[idx].title;
    const sizeInBytes = byteSize(title);
    const mobileCharLimit = FB.MAX_TITLE_MOBILE_LENGTH - sizeInBytes;
    return `${sizeInBytes}/255\n Mobile char: ${mobileCharLimit}/${FB.MAX_TITLE_MOBILE_LENGTH}`;
  };

  showHelperText = idx => {
    const title = this.props.adset.ads[idx].text;
    return `${title.length}/${FB.MAX_TEXT_LENGTH}`;
  };

  handleAdvantagePlusAdset = () => {
    this.setState(
      prevState => ({
        isCheckedAdvantagePlusAdset: !prevState.isCheckedAdvantagePlusAdset
      }),
      () => {
        this.props.toggleAdvantagePlusAdset(this.props.adset, this.state.isCheckedAdvantagePlusAdset);
      }
    );
  };

  handleAdvantagePlusCreative = ad => {
    this.props.toggleAdvantagePlusCreative(ad);
    const hasAdvantagePlacementsCreative = this.props.adset.ads.some(ad => ad.advantage_placements_creative === true);
    this.setState({ isCheckedAdvantagePlusCreative: hasAdvantagePlacementsCreative });
  };
  renderFacebook() {
    return (
      <StyledDiv>
        <StyledSecondaryAppBar position="static">
          <Toolbar>
            <Avatar>{this.props.index + 1}</Avatar>
            <Typography gutterBottom variant="subtitle2">
              Ads {this.props.adset.ads.length}
            </Typography>
            {this.props.spmUploadAccess && !this.props.spmUploadDirectly ? null : (
              <>
                <label htmlFor={'uploadImagesButton' + this.props.index + this.props.geoIdx + this.props.campIdx}>
                  <IconButton size="small" component="span" color="default">
                    <Tooltip title="Upload">
                      <PublishIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </label>
                <Input
                  type="file"
                  id={'uploadImagesButton' + this.props.index + this.props.geoIdx + this.props.campIdx}
                  inputProps={{ multiple: true }}
                  onChange={async e => await this.onUploadFile(this.props.index, e)}
                  onClick={e => {
                    e.target.value = null;
                  }}
                  style={{ display: 'none' }}
                />
              </>
            )}
            {this.props.selectArticleCreativeButton && this.props.spmCreativeBankAccess ? (
              <IconButton
                key={this.props.geoIdx}
                size="small"
                color="default"
                onClick={() => this.props.showArticleCreative(this.props.index)}
              >
                <Tooltip title="Select creatives">
                  <ImageIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            ) : null}
            {this.props.toggleAd ? (
              <IconButton size="small" color="default" onClick={() => this.props.handleAdsDropdown()}>
                <ExpandLessIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" color="default" onClick={() => this.props.handleAdsDropdown()}>
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            )}
          </Toolbar>
        </StyledSecondaryAppBar>
      </StyledDiv>
    );
  }

  renderFacebook2() {
    return (
      <StyledDiv>
        <StyledPrimaryAppBar position="static">
          <Toolbar>
            <Avatar>{this.props.index + 1}</Avatar>
            <Typography gutterBottom variant="subtitle2">
              Ads {this.props.adset.ads.length}
            </Typography>
            <input
              type="checkbox"
              id={'advantage+' + this.props.index}
              onChange={this.handleAdvantagePlusAdset}
              checked={this.props.adset.autoPlacements && this.props.adset.advantage_placements}
              disabled={this.props.adset.ads.some(ad => ad.advantage_placements_creative === true)}
            />
            <label htmlFor={'advantage+' + this.props.index}>Advantage+</label>
            {this.props.spmUploadAccess && !this.props.spmUploadDirectly ? null : (
              <>
                <label htmlFor={'uploadImagesButton' + this.props.index}>
                  <IconButton component="span" color="secondary" size="large">
                    <Tooltip title="Upload">
                      <PublishIcon fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </label>
                <Input
                  type="file"
                  id={'uploadImagesButton' + this.props.index}
                  inputProps={{ multiple: true }}
                  onChange={async e => await this.onUploadFile(this.props.index, e)}
                  onClick={e => {
                    e.target.value = null;
                  }}
                  style={{ display: 'none' }}
                />
              </>
            )}
            <IconButton
              color="secondary"
              key={this.getKey('groupDuplicateButton')}
              size="small"
              onClick={() => this.duplicateAdset()}
            >
              <Tooltip title="Duplicate">
                <FileCopyIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              aria-label="menu"
              key={this.getKey('groupAddButton')}
              onClick={() => this.props.addAdset()}
            >
              <Tooltip title="Add additional ads group">
                <AddIcon />
              </Tooltip>
            </IconButton>
            {this.props.adsets.length < 2 && this.props.platform === PLATFORMS.FACEBOOK ? null : (
              <IconButton
                size="small"
                color="secondary"
                aria-label="menu"
                key={this.getKey('groupRemoveButton')}
                onClick={() => this.props.deleteAdset(this.props.index)}
              >
                <Tooltip title="Delete ads group">
                  <DeleteForeverIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            )}
            {this.props.toggleAd ? (
              <IconButton size="small" color="secondary" onClick={() => this.props.handleAdsDropdown()}>
                <ExpandLessIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" color="secondary" onClick={() => this.props.handleAdsDropdown()}>
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
            )}
          </Toolbar>
        </StyledPrimaryAppBar>
      </StyledDiv>
    );
  }
  renderImage(ad, idx) {
    const fullPath = getFullPath(ad.image);
    return (
      <React.Fragment key={this.getKey('sep', idx)}>
        {this.props.toggleAd ? (
          <Grid item sm={this.props.platform === PLATFORMS.FACEBOOK ? 4 : undefined}>
            <Card>
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                key={this.getKey('removeButton', idx)}
                onClick={() => this.handleDelete(idx)}
                sx={{ margin: '-30px 0 -55px 150px' }}
                title="Delete ad"
                fontSize="small"
                size="large"
              >
                <Tooltip title="Delete">
                  <ClearIcon />
                </Tooltip>
              </IconButton>
              <CardHeader
                subheader={
                  <TextField
                    variant="standard"
                    multiline
                    fullWidth
                    minRows={1}
                    maxRows={10}
                    label="Text"
                    key={this.getKey('imageText', idx)}
                    value={ad.text}
                    onChange={event => this.onChangeImageText(idx, event)}
                    helperText={this.showHelperText(idx)}
                    inputProps={{
                      maxLength: FB.MAX_TEXT_LENGTH
                    }}
                    disabled={ad.fromCreativeBank}
                  />
                }
              />
              <StyledCardMedia
                key={this.getKey('image', idx)}
                image={fullPath}
                title={this.state.originFileName ? this.state.originFileName[idx] : 'Example'}
              />
              <CardContent>
                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  minRows={1}
                  maxRows={10}
                  label="Title"
                  key={this.getKey('imageTitle', idx)}
                  value={ad.title}
                  helperText={this.showHelperTitle(idx)}
                  inputProps={{
                    maxLength: FB.MAX_TITLE_LENGTH
                  }}
                  onChange={event => this.onChangeImageTitle(idx, event)}
                  disabled={ad.fromCreativeBank}
                />
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  color="primary"
                  key={this.getKey('populateButton', idx)}
                  onClick={() => this.populateText(idx)}
                  title="Populate all texts in group"
                  disabled={ad.fromCreativeBank}
                  size="large"
                >
                  <Tooltip title="Populate all texts in group">
                    <TextRotationNoneIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="primary"
                  key={this.getKey('populateAllButton', idx)}
                  onClick={() => this.populateAllText(idx)}
                  title="Populate all texts in all groups"
                  disabled={ad.fromCreativeBank}
                  size="large"
                >
                  <Tooltip title="Populate all texts in all groups">
                    <FontDownloadIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="menu"
                  color="primary"
                  key={this.getKey('cropButton', idx)}
                  onClick={() => this.onSelectFileCrop(ad, idx)}
                  title="Crop image"
                  disabled={ad.fromCreativeBank}
                  size="large"
                >
                  <Tooltip title="Crop image">
                    <CropIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
                <IconButton
                  key={this.getKey('copyButton', idx)}
                  color="primary"
                  onClick={() => this.copyAd(idx)}
                  title="Copy ad"
                  size="large"
                >
                  <Tooltip title="Duplicate ad">
                    <FileCopyIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              </CardActions>
              {this.props.platform === PLATFORMS.FACEBOOK2 ? (
                <CardActions>
                  <input
                    type="checkbox"
                    id={this.getKey('advantage+', idx)}
                    onChange={() => this.handleAdvantagePlusCreative(ad)}
                    disabled={this.props.adset.advantage_placements && this.props.adset.autoPlacements}
                    checked={ad.advantage_placements_creative}
                  />
                  <label htmlFor={this.getKey('advantage+', idx)}>Advantage+</label>
                </CardActions>
              ) : null}
            </Card>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.platform === PLATFORMS.FACEBOOK ? this.renderFacebook() : this.renderFacebook2()}
        <Grid container direction="row">
          {this.state.showCropWindow ? (
            <CropWindow
              key={this.getKey('crop')}
              onAddImage={(index, blob) => this.onUploadFile(index, blob)}
              src={this.state.src}
              adsetIndex={this.props.index}
              onClose={() => this.onClose()}
              platform={this.props.platform}
            />
          ) : null}
          {this.props.adset.ads.map((ad, idx) => this.renderImage(ad, idx))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default SingleAdset;
