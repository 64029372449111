import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const commonStyles = {
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: 'white',
  width: '187px',
  height: '200px',
  display: 'flex',
  justifyContent: 'space-between',
  '& .image': {
    backgroundColor: '#57b6f87a',
    margin: '1px'
  },
  '& .small-images-container': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  '& .text': {
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'black',
    color: 'white'
  }
};

const StyledPreviewA = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'row',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '66%',
    height: '99%'
  },
  '& .small-images-container': {
    ...commonStyles['& .small-images-container'],
    width: '33%',
    height: '100%'
  },
  '& .small-image': {
    ...commonStyles['& .image'],
    width: '100%',
    height: '49%'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '155px'
  },
  '& .small-text': {
    ...commonStyles['& .text'],
    marginTop: '75px',
    fontSize: '10px'
  }
}));

const StyledPreviewB = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'row',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '66%',
    height: '99%'
  },
  '& .small-images-container': {
    ...commonStyles['& .small-images-container'],
    width: '33%',
    height: '100%'
  },
  '& .small-image': {
    ...commonStyles['& .image'],
    width: '100%',
    height: '32%'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '155px'
  },
  '& .small-text': {
    ...commonStyles['& .text'],
    marginTop: '40px',
    fontSize: '10px'
  }
}));

const StyledPreviewC = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'column',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '99%',
    height: '66%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  '& .small-images-container': {
    ...commonStyles['& .small-images-container'],
    flexWrap: 'nowrap',
    width: '100%',
    height: '33%'
  },
  '& .small-image': {
    ...commonStyles['& .image'],
    width: '100%',
    height: '97%'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '100px'
  },
  '& .small-text': {
    ...commonStyles['& .text'],
    marginTop: '40px',
    fontSize: '10px'
  }
}));

const StyledPreviewD = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'column',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '99%',
    height: '66%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  '& .small-images-container': {
    ...commonStyles['& .small-images-container'],
    flexWrap: 'nowrap',
    width: '100%',
    height: '33%'
  },
  '& .small-image': {
    ...commonStyles['& .image'],
    width: '100%',
    height: '97%'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '100px'
  },
  '& .small-text': {
    ...commonStyles['& .text'],
    marginTop: '40%',
    fontSize: '10px'
  }
}));

const StyledPreviewE = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'column',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '99%',
    height: '99%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '155px'
  },
  '& .large-text': {
    ...commonStyles['& .text'],
    marginTop: '155px',
    fontSize: '20px'
  }
}));

const StyledPreviewF = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'row',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '49%',
    height: '99%'
  },
  '& .small-images-container': {
    ...commonStyles['& .small-images-container'],
    width: '50%',
    height: '100%'
  },
  '& .small-image': {
    ...commonStyles['& .image'],
    width: '100%',
    height: '49%'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '155px'
  },
  '& .small-text': {
    ...commonStyles['& .text'],
    marginTop: '75px',
    fontSize: '10px'
  }
}));

const StyledPreviewG = styled(Grid)(() => ({
  ...commonStyles,
  flexDirection: 'row',
  '& .big-image': {
    ...commonStyles['& .image'],
    width: '49%',
    height: '99%'
  },
  '& .small-images-container': {
    ...commonStyles['& .small-images-container'],
    width: '50%',
    height: '100%'
  },
  '& .small-image': {
    ...commonStyles['& .image'],
    width: '100%',
    height: '32%'
  },
  '& .text': {
    ...commonStyles['& .text'],
    marginTop: '155px'
  },
  '& .small-text': {
    ...commonStyles['& .text'],
    marginTop: '40px',
    fontSize: '10px'
  }
}));

const CollageTemplatesPreview = ({ templates, onSetActiveCollages }) => {
  const [activeCollages, setActiveCollages] = useState([]);

  const onHandleClick = collage => {
    const index = activeCollages.indexOf(collage);
    if (index === -1) {
      setActiveCollages([...activeCollages, collage]);
    } else {
      setActiveCollages(activeCollages.filter(c => c !== collage));
    }
  };

  useEffect(() => {
    onSetActiveCollages(activeCollages);
  }, [activeCollages, onSetActiveCollages]);

  const templateMap = {
    a: {
      typography: 'Collage template a',
      PreviewComponent: StyledPreviewA,
      smallimages: [1, 2]
    },
    b: {
      typography: 'Collage template b',
      PreviewComponent: StyledPreviewB,
      smallimages: [1, 2, 3]
    },
    c: {
      typography: 'Collage template c',
      PreviewComponent: StyledPreviewC,
      smallimages: [1, 2, 3]
    },
    d: {
      typography: 'Collage template d',
      PreviewComponent: StyledPreviewD,
      smallimages: [1, 2]
    },
    e: {
      typography: 'Collage template e',
      PreviewComponent: StyledPreviewE,
      smallimages: []
    },
    e2: {
      typography: 'Collage template e2',
      PreviewComponent: StyledPreviewE,
      smallimages: [],
      bigtext: true,
      largetext: true
    },
    f: {
      typography: 'Collage template f',
      PreviewComponent: StyledPreviewF,
      smallimages: [1, 2]
    },
    g: {
      typography: 'Collage template g',
      PreviewComponent: StyledPreviewG,
      smallimages: [1, 2, 3]
    }
  };
  const renderTemplate = templates => {
    return templates.map((collage, index) => {
      const { typography, PreviewComponent, smallimages } = templateMap[collage];

      const itemKey = `${collage}_${index}`;
      const isActive = activeCollages.includes(collage);

      return (
        <Grid item key={itemKey} onClick={() => onHandleClick(collage)} sx={{ opacity: isActive ? 1 : 0.7 }}>
          <Typography gutterBottom align="center" variant="subtitle2">
            {typography}
          </Typography>
          <PreviewComponent item>
            <Grid item className="big-image">
              <Typography className="text">text</Typography>
            </Grid>
            <Grid item className="small-images-container">
              {smallimages.map((_, siIndex) => (
                <Grid item key={siIndex} className="small-image">
                  <Typography className="small-text">small text {siIndex + 1}</Typography>
                </Grid>
              ))}
            </Grid>
          </PreviewComponent>
        </Grid>
      );
    });
  };

  return (
    <Grid container direction="row" spacing={2}>
      {renderTemplate(templates)}
    </Grid>
  );
};

export default CollageTemplatesPreview;
