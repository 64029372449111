import { getTaboolaBrowsers, getTaboolaConversionRules, getTaboolaCustomAudiences } from './Api';
// stores data with some ttl, refreshes it periodically
class RefreshingTTLStore {
  constructor({ fetcher, keyFunction, ttl = 30 * 60 * 1000, refreshInterval = 1000, initialParams = undefined } = {}) {
    this.fetcher = fetcher;
    this.keyFunction = keyFunction;
    this.ttl = ttl;
    this.refreshInterval = refreshInterval;
    this.entries = {};
    if (initialParams) {
      for (const ps of initialParams) {
        this.get.apply(this, ps);
      }
    }
    setInterval(() => {
      this.checkExpiration();
    }, refreshInterval);
  }

  get(...params) {
    const key = this.keyFunction(params);
    if (this.entries[key]) {
      return this.entries[key].resultPromise;
    } else {
      const resultPromise = this.fetcher.apply(null, params);
      const now = Date.now();
      this.entries[key] = {
        resultPromise,
        params,
        timestamp: now // a bit unaccurate, since we probably like to put the timestamp when promise actually completes
      };
      resultPromise.catch(() => {
        // TODO: a bit imprecise
        if (this.entries[key] && this.entries[key].timestamp === now) {
          delete this.entries[key];
        }
      });
      return resultPromise;
    }
  }

  checkExpiration() {
    const now = Date.now();
    for (const key in this.entries) {
      const entry = this.entries[key];
      if (entry.timestamp + this.ttl < now) {
        entry.timestamp = now;
        entry.resultPromise = this.fetcher.apply(null, entry.params);
      }
    }
  }
}

export const taboolaBrowsersStore = new RefreshingTTLStore({
  fetcher: getTaboolaBrowsers,
  keyFunction: accountId => accountId
});

export const taboolaCCStore = new RefreshingTTLStore({
  fetcher: getTaboolaCustomAudiences,
  keyFunction: accountId => accountId
  // initialParams: [['shinez-network']]
});

export const taboolaConversionRulesStore = new RefreshingTTLStore({
  fetcher: getTaboolaConversionRules,
  keyFunction: accountId => accountId
});
