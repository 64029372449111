import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import CreativeImageCropper from './CreativeImageCropper';
import { CROP_TYPES } from './CreativeImageCropper';
import { Button } from '@mui/material';
import { PLATFORMS } from './Constants';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500]
}));

class CropWindow extends React.Component {
  constructor(props) {
    super(props);
    const images = [];
    if (this.props.fromInsights) {
      console.log('from insights', this.props);
      for (let adsetIndex = 0; adsetIndex < this.props.data.length; adsetIndex++) {
        const adset = this.props.data[adsetIndex];
        for (let imageIndex = 0; imageIndex < adset.images.length; imageIndex++) {
          const image = adset.images[imageIndex];
          images.push({
            imageIndex: imageIndex,
            adsetIndex: adsetIndex,
            src: image,
            crops: {
              [CROP_TYPES.Smartphone]: null,
              [CROP_TYPES.Desktop]: null,
              [CROP_TYPES.Portrait]: null
            }
          });
        }
      }
    } else {
      for (let imageIndex = 0; imageIndex < this.props.data.length; imageIndex++) {
        images.push({
          adsetIndex: this.props.index,
          imageIndex: this.props.group.length + imageIndex,
          src: this.props.data[imageIndex],
          crops: {
            [CROP_TYPES.Smartphone]: null,
            [CROP_TYPES.Desktop]: null,
            [CROP_TYPES.Portrait]: null
          }
        });
      }
    }

    this.state = {
      images: images
    };
  }

  handleClose() {
    this.props.onClose();
  }

  handleSave() {
    this.props.onSave(this.state.images);
  }

  onApply(crops, imageIndex, adsetIndex) {
    const images = this.state.images;
    if (this.props.fromInsights) {
      const images = this.state.images;
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (image.adsetIndex === adsetIndex && image.imageIndex === imageIndex) {
          image.crops = crops;
        }
      }
    } else {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (image.imageIndex === imageIndex) {
          image.crops = crops;
        }
      }
    }

    this.setState({ images: images });
  }

  allSet() {
    const images = this.state.images;
    for (const image of images) {
      if (this.props.notRequired) {
        const cropsSelected = image.crops[CROP_TYPES.Smartphone] !== null || image.crops[CROP_TYPES.Desktop] !== null;
        if (!cropsSelected) return false;
      } else {
        const cropsSelected = image.crops[CROP_TYPES.Smartphone] !== null && image.crops[CROP_TYPES.Desktop] !== null;
        if (!cropsSelected) return false;
      }
    }

    return true;
  }

  render() {
    const { images } = this.state;

    return (
      <StyledDialog maxWidth="md" onClose={() => this.handleClose()} aria-labelledby="simple-dialog-title" open={true}>
        <DialogTitle>Crop images</DialogTitle>
        <StyledButton color="primary" aria-label="close" onClick={() => this.handleClose()}>
          <CloseIcon />
        </StyledButton>
        <DialogContent dividers>
          {images.map((img, idx) => (
            <CreativeImageCropper
              fromInsights={this.props.fromInsights}
              desktopAspectRatio={this.props.desktopAspectRatio}
              smartphoneAspectRatio={this.props.smartphoneAspectRatio}
              portraitAspectRatio={this.props.portraitAspectRatio}
              requirePortraitCrop={this.props.requirePortraitCrop}
              key={img.src + idx}
              src={img.src}
              onApply={crops => this.onApply(crops, img.imageIndex, img.adsetIndex)}
              platform={this.props.platform}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleSave()}
            disabled={this.props.platform === PLATFORMS.TWITTER ? false : !this.allSet()}
          >
            Save changes
          </Button>
          <Button variant="outlined" onClick={() => this.handleClose()}>
            Cancel
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
}

export default CropWindow;
