import React from 'react';
import SingleAdset from './SingleAdset';
import DynamicAdset from './DynamicAdset';
import SingleVideoAdset from './SingleVideoAdset';

class AdsetsBuilder extends React.Component {
  renderAdset(adset, idx) {
    switch (adset.type) {
      case 'SINGLE':
        return (
          <SingleAdset
            index={idx}
            campIdx={this.props.campIdx}
            geoIdx={this.props.geoIdx}
            key={idx}
            adset={adset}
            adsets={this.props.adsets}
            addAdset={maybeAdset => this.props.addAdset(maybeAdset)}
            modifyAdset={(idx, adset) => this.props.modifyAdset(idx, adset)}
            modifyAdsets={ad => this.props.modifyAdsets(ad)}
            populateAllText={adset => this.props.populateAllText(adset)}
            deleteAdset={idx => this.props.deleteAdset(idx)}
            onAddImage={(adsetIdx, value) => this.props.onAddImage(adsetIdx, value)}
            spmCreativeBankAccess={this.props.spmCreativeBankAccess}
            spmUploadAccess={this.props.spmUploadAccess}
            spmUploadDirectly={this.props.spmUploadDirectly}
            spmReviewAccess={this.props.spmReviewAccess}
            isCreativesReviewer={this.props.isCreativesReviewer}
            platform={this.props.platform}
            handleAdsDropdown={() => this.props.handleAdsDropdown()}
            toggleAd={this.props.toggleAd}
            showArticleCreative={adIdx => this.props.showArticleCreative(adIdx)}
            selectArticleCreativeButton={this.props.selectArticleCreativeButton}
            toggleAdvantagePlusAdset={(adset, isChecked) => this.props.toggleAdvantagePlusAdset(adset, isChecked)}
            toggleAdvantagePlusCreative={ad => this.props.toggleAdvantagePlusCreative(ad)}
          />
        );
      case 'SINGLE_VIDEO':
        return (
          <SingleVideoAdset
            index={idx}
            key={idx}
            adset={adset}
            videoCreatives={this.props.videoCreatives}
            addAds={maybeAdset => this.props.addAds(maybeAdset)}
            campIdx={this.props.campIdx}
            geoIdx={this.props.geoIdx}
            adsets={this.props.adsets}
            addAdset={maybeAdset => this.props.addAdset(maybeAdset)}
            modifyAdset={(idx, adset) => this.props.modifyAdset(idx, adset)}
            modifyAdsets={ad => this.props.modifyAdsets(ad)}
            populateAllText={adset => this.props.populateAllText(adset)}
            deleteAdset={idx => this.props.deleteAdset(idx)}
            onAddVideo={(adsetIdx, value) => this.props.onAddVideo(adsetIdx, value)}
            platform={this.props.platform}
            handleAdsDropdown={() => this.props.handleAdsDropdown()}
            toggleAd={this.props.toggleAd}
          />
        );
      default:
        return (
          <DynamicAdset
            index={idx}
            geoIdx={this.props.geoIdx}
            campIdx={this.props.campIdx}
            key={idx}
            adset={adset}
            adsets={this.props.adsets}
            addAdset={maybeAdset => this.props.addAdset(maybeAdset)}
            modifyAdset={(idx, adset) => this.props.modifyAdset(idx, adset)}
            deleteAdset={idx => this.props.deleteAdset(idx)}
            onAddDynamicImage={(adsetIdx, value) => this.props.onAddDynamicImage(adsetIdx, value)}
            spmCreativeBankAccess={this.props.spmCreativeBankAccess}
            spmUploadAccess={this.props.spmUploadAccess}
            spmReviewAccess={this.props.spmReviewAccess}
            spmUploadDirectly={this.props.spmUploadDirectly}
            isCreativesReviewer={this.props.isCreativesReviewer}
            platform={this.props.platform}
            handleAdsDropdown={() => this.props.handleAdsDropdown()}
            toggleAd={this.props.toggleAd}
            showArticleCreative={(a, b) => this.props.showArticleCreative(a, b)}
            selectArticleCreativeButton={this.props.selectArticleCreativeButton}
            toggleAdvantagePlusAdset={(adset, isChecked) => this.props.toggleAdvantagePlusAdset(adset, isChecked)}
          />
        );
    }
  }

  render() {
    return <>{this.props.adsets.map((adset, idx) => this.renderAdset(adset, idx))}</>;
  }
}

export default AdsetsBuilder;
