import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Grid, Typography } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import { FB, PLATFORMS } from '../Constants';
import { toast } from 'react-toastify';
import WidgetSelector from '../WidgetSelector';
import { processFacebookImages } from '../utils/imageUploadUtils';
import { getMultiSelectValue, makeSelectOptions } from '../Shared';
import { storeFilesUploads, addCreativesReview } from '../Api';
import CreativeUploader from './CreativeUploader';
import Select from 'react-select';
import { getNegativeWords } from '../utils/checkForNegativeWords';

class FacebookUploadCreatives extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      prefix: '',
      status: 'ON_REVIEW',
      creatives: [{ content: [] }],
      widgetCodeName: '',
      widgetLanguageCode: '',
      widgetVersion: '',
      article: '',
      widget_id: '',
      widget_title: '',
      creation_time: undefined,
      comment: ''
    };

    this.state = JSON.parse(JSON.stringify(this.defaultState));
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount() {
    await this.updateUserOptions();
  }

  async updateUserOptions() {
    const userOpts = this.props.programOptions;
    let prefix = userOpts.spm_prefix_for_facebook ? userOpts.spm_prefix_for_facebook : userOpts.spm_prefix_default;
    this.setState({ prefix });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.task !== prevProps.task) {
      this.setState(JSON.parse(JSON.stringify(this.defaultState)));
    }
  }

  updatePrefix(prefix) {
    if (prefix.target) {
      prefix = prefix.target.value;
    }
    this.setState({ prefix });
  }

  updateWidgetFields(key, value) {
    value = key === 'article' ? value.target.value : value;
    key = key === 'widgetid' ? 'widget_id' : key;
    this.setState({ [key]: value });
  }

  getAllowedWidgetVersions() {
    return FB.ALLOWED_VERSIONS;
  }

  validateSetup() {
    let validations = [validate(this.state.article, 'Empty widget article')];

    return validations.every(x => x());
  }

  async validateCreatives() {
    const xs = this.state.creatives[0].content.flatMap(async (creative, creativeIdx) => {
      let errorsSingle = [validate(creative.text.trim() !== '', "Creative's should have all text fields populated")];
      const negativeTextsWords = await getNegativeWords(PLATFORMS.FACEBOOK, creative.text);
      const negativeTitlesWords = await getNegativeWords(PLATFORMS.FACEBOOK, creative.title);
      if (negativeTextsWords.length) {
        errorsSingle.push(
          validate(
            false,
            `You're trying to use a sensational words.\n
                Creative ${creativeIdx + 1}, text:\n
                ${negativeTextsWords.join('\n')}`
          )
        );
      }
      if (negativeTitlesWords.length) {
        errorsSingle.push(
          validate(
            false,
            `You're trying to use a sensational words.\n
                Creative ${creativeIdx + 1}, title:\n
                ${negativeTitlesWords.join('\n')}`
          )
        );
      }
      return errorsSingle;
    });
    console.log(xs);
    return Promise.all(xs).then(errors => errors.flat().every(x => x()));
  }

  constructCreatives() {
    let { prefix, status, comment, creatives } = this.state;
    const result = creatives[0].content.map(content => ({
      prefix,
      article: `${this.state.widgetCodeName}${this.state.widgetLanguageCode}`,
      status,
      creation_time: moment(new Date()),
      comment,
      image: content.image,
      title: content.title,
      text: content.text,
      blacklist_words: content.blacklist_words,
      is_blacklisted: content.is_blacklisted
    }));
    return JSON.parse(JSON.stringify(result));
  }
  async uploadCreatives(creatives) {
    try {
      addCreativesReview(creatives).then(() => {
        toast.success('Creatives successfully uploaded');
        this.clearCreative();
      });
    } catch (e) {
      toast.error(e);
    }
  }

  async submit() {
    if (!this.validateSetup()) {
      return;
    }

    if (!(await this.validateCreatives())) {
      return;
    }

    confirmAlert({
      title: 'Upload',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.uploadCreatives(this.constructCreatives());
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  addCreative() {
    const creatives = this.state.creatives;
    creatives.push({
      content: []
    });
    this.setState({ creatives });
  }

  clearCreative() {
    this.setState({ creatives: [{ content: [] }] });
  }

  deleteCreative(idx) {
    const creatives = this.state.creatives;
    creatives.splice(idx, 1);
    this.setState({ creatives });
  }

  modifyCreative(idx, creative) {
    const creatives = this.state.creatives;
    creatives[idx] = creative;
    this.setState({ creatives });
  }

  render() {
    return (
      <>
        <Grid
          item
          sx={{
            minWidth: '145px'
          }}
        >
          <Typography gutterBottom variant={'subtitle2'}>
            Prefix
          </Typography>
          <Select
            isMulti
            onChange={e => this.updatePrefix(e.map(x => x.value))}
            options={makeSelectOptions(this.props.programOptions.prefix)}
            isClearable
            value={getMultiSelectValue(makeSelectOptions(this.props.programOptions.prefix), this.state.prefix)}
          />
        </Grid>
        <Grid item sm={5}>
          <WidgetSelector
            platform={PLATFORMS.FACEBOOK_UPLOAD_CREATIVES}
            modify={(name, code, version) => this.updateWidgetFields(name, code, version)}
            articleList={this.props.articleList}
            block={FB.ARTICLE_BLOCK_B2B}
            options={this.state}
            allowedVersions={this.getAllowedWidgetVersions()}
            triggerSelectorUpdate={this.state.triggerSelectorUpdate}
            programOptions={this.props.programOptions}
          />
        </Grid>
        <Grid item sm={4}></Grid>

        <Grid
          item
          sm={4}
          sx={{
            margin: '10px 0px 10px 20px'
          }}
        >
          <Typography gutterBottom variant="body2">
            Will not be approved:
          </Typography>
          <ul>
            <li>Celebrities negative content (including gossip, affairs, cheating etc.)</li>
            <li>Sensational words and phrases</li>
            <li>Overly sexual images (cleavage, nipples, men without shirts, people kissing)</li>
            <li>Misleading creatives</li>
          </ul>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            margin: '10px 0px 10px 20px'
          }}
        >
          <ul>
            <li>
              Shocking images (dead animals, guns, health issues, etc) Images that might seem as “before and after”
            </li>
            <li>Where “follow button” is visible or any other image that seems clickable</li>
            <li>Using the word you/your/you’re</li>
            <li>Using all Caps lock</li>
            <li>Images where people’s heads are cut off</li>
          </ul>
        </Grid>
        <Grid container item sm={12}>
          <Grid item xs={12} sm={12}>
            {this.state.creatives.map((creative, idx) => (
              <CreativeUploader
                index={idx}
                key={idx}
                creative={creative}
                addCreative={maybeCreative => this.addCreative(maybeCreative)}
                modifyCreative={(idx, creative) => this.modifyCreative(idx, creative)}
                deleteCreative={idx => this.deleteCreative(idx)}
                onAddImage={(a, b) => this.onAddImage(a, b)}
                creatives={this.state.creatives}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container item sm={12} justifyContent={'flex-end'} spacing={1}>
          {!this.state.creatives.length && (
            <Grid item xs={4} sm={2}>
              <Button fullWidth variant="outlined" color="primary" onClick={() => this.addCreative()}>
                Add Creatives
              </Button>
            </Grid>
          )}
          {this.state.creatives[0] && this.state.creatives[0].content.length > 0 && (
            <Grid
              item
              xs={4}
              sm={2}
              sx={{
                marginBottom: '5px'
              }}
            >
              <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
                Upload
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
  onAddImage(index, event) {
    const images = Array.from(event.target.files);

    return processFacebookImages(images, index, storeFilesUploads, (index, filenames) => {
      this.updateCreativesWithImages(index, filenames);
    });
  }

  updateCreativesWithImages(creativeIndex, imgFilenames) {
    if (imgFilenames) {
      const creatives = this.state.creatives[creativeIndex];

      const creative = imgFilenames.map(image => {
        return {
          blacklist_words: image.blacklist_words,
          image: image.data.url,
          is_blacklisted: image.is_blacklisted,
          text: '',
          title: ''
        };
      });
      creatives.content = creatives.content.concat(creative);

      this.modifyCreative(this.props.index, creatives);
    }
  }
}

const validate = (cond, errorDescription) => () => {
  if (!cond) {
    console.warn(errorDescription);
    toast.error(errorDescription);
    return false;
  }
  return true;
};

export default FacebookUploadCreatives;
