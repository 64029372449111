import React from 'react';
import { TB } from '../Constants';
import { Grid, Typography, Button } from '@mui/material';
import Select from 'react-select';
import { getSelectValue } from '../Shared';

class TaboolaBids extends React.Component {
  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site, bid.browser];
    return parts.filter(el => el).join('-');
  }

  setBidOnClick(idx, bid) {
    const parsedBid = parseFloat(this.props.getSuggestedBid(bid));
    if (!Number.isNaN(parsedBid)) {
      this.props.modifyBid(idx, { target: { value: parsedBid } });
    } else {
      console.log("Can't parse the suggested bid");
    }
  }

  renderBidRow(bid, idx) {
    return (
      <React.Fragment key={bid.country + bid.device + bid.os + bid.site}>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            {this.renderRowName(bid)}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2}>
          <input
            type="number"
            key={'bidInput' + idx}
            step={TB.BID_STEP}
            value={this.props.bids[idx].bid}
            onChange={imageIdx => this.props.modifyBid(idx, imageIdx)}
            onBlur={imageIdx => this.props.validateBid(idx, imageIdx)}
          />
        </Grid>
        <Grid item xs={4} sm={2} align={'center'}>
          <Button
            key={'suggestedBidLabel' + idx}
            variant="outlined"
            fullWidth
            onClick={() => this.setBidOnClick(idx, bid)}
          >
            {this.props.getSuggestedBid(bid)}
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Select
            isClearable={this.props.customAudience && this.props.customAudience.length}
            onChange={e => this.props.modifyConversionRule(idx, e?.value)}
            options={this.props.conversionRules.map(x => ({ label: x.display_name, value: x.id }))}
            value={getSelectValue(
              this.props.conversionRules.map(x => ({ label: x.display_name, value: x.id })),
              this.props.bids[idx].conversionId
            )}
          />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {this.props.bids
          ? this.props.bids.map((bid, idx) => {
              return this.renderBidRow(bid, idx);
            })
          : null}
      </>
    );
  }
}

export default TaboolaBids;
