import React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  width: '186px',
  margin: 'auto'
}));

export default function DuplicationPopover({ camp, idx, updateInterval, updateRepeat, field }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleIntervalChange = (idx, e, field) => {
    updateInterval(idx, e, field);
  };
  const handleRepeatChange = (idx, e, field) => {
    updateRepeat(idx, e, field);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        style={{ position: 'absolute', marginBottom: '5px', padding: 0 }}
        size="large"
      >
        <ExpandMoreIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <StyledContainer>
          {field === 'cc' ? (
            <Grid container direction={'row'} spacing={1}>
              <Grid item>
                <Typography variant={'subtitle2'} gutterBottom>
                  Interval
                </Typography>
                <input
                  style={{ width: '80px' }}
                  type="number"
                  name="interval"
                  min={0}
                  step={0.01}
                  value={camp.interval.cc}
                  onChange={e => handleIntervalChange(idx, e.target.value, 'cc')}
                />
              </Grid>
              <Grid item>
                <Typography variant={'subtitle2'} gutterBottom>
                  Repeat
                </Typography>
                <input
                  style={{ width: '80px' }}
                  type="number"
                  name="repeat"
                  step={1}
                  min={0}
                  value={camp.repeat.cc}
                  onChange={e => handleRepeatChange(idx, e.target.value, 'cc')}
                />
              </Grid>
            </Grid>
          ) : field === 'bid' ? (
            <Grid container direction={'row'} spacing={1}>
              <Grid item>
                <Typography variant={'subtitle2'} gutterBottom>
                  Interval
                </Typography>
                <input
                  style={{ width: '80px' }}
                  type="number"
                  name="interval"
                  min={0}
                  step={0.01}
                  value={camp.interval.bid}
                  onChange={e => handleIntervalChange(idx, e.target.value, 'bidAmount')}
                />
              </Grid>
              <Grid item>
                <Typography variant={'subtitle2'} gutterBottom>
                  Repeat
                </Typography>
                <input
                  style={{ width: '80px' }}
                  type="number"
                  name="repeat"
                  min={0}
                  step={1}
                  value={camp.repeat.bid}
                  onChange={e => handleRepeatChange(idx, e.target.value, 'bidAmount')}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction={'row'} spacing={1}>
              <Grid item>
                <Typography variant={'subtitle2'} gutterBottom>
                  Interval
                </Typography>
                <input
                  style={{ width: '80px' }}
                  type="number"
                  name="interval"
                  min={0}
                  step={1}
                  value={camp.interval.startDateTime}
                  onChange={e => handleIntervalChange(idx, e.target.value, 'startDateTime')}
                />
              </Grid>
              <Grid item>
                <Typography variant={'subtitle2'} gutterBottom>
                  Repeat
                </Typography>
                <input
                  style={{ width: '80px' }}
                  type="number"
                  name="repeat"
                  min={0}
                  step={1}
                  value={camp.repeat.startDateTime}
                  onChange={e => handleRepeatChange(idx, e.target.value, 'startDateTime')}
                />
              </Grid>
            </Grid>
          )}
        </StyledContainer>
      </Popover>
    </>
  );
}
