import React, { useState } from 'react';
import { getAccountStatus } from './Api';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button, Card, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';

const StyledCard = styled(Card)({
  width: '620px'
});

const CheckAccountStatusPage = () => {
  const [text, setText] = useState('');
  const [status, setStatus] = useState('');
  const [permission, setPermission] = useState('');

  const onInputChange = e => {
    setText(e.target.value);
    setStatus('');
    setPermission('');
  };

  const getStatus = e => {
    e.preventDefault();
    getAccountStatus(text).then(data => {
      setStatus(data.status);
      setPermission(data.permission);
    });
  };

  return (
    <StyledCard variant="outlined">
      <CardContent>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item xs={6} sm={1}>
            <Link to="/">
              <ArrowBackIcon color="primary" />
            </Link>
          </Grid>
          <Grid item xs={6} sm={9}>
            <input
              style={{ padding: '7px' }}
              type="text"
              name="serchAccount"
              placeholder="Enter account name"
              onChange={onInputChange}
              value={text}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button variant="contained" color="primary" onClick={getStatus}>
              Search
            </Button>
          </Grid>
          {status.length ? (
            <>
              <Grid item xs={6} sm={1} />

              <Grid item xs={6} sm={2}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                <Typography gutterBottom variant={'subtitle2'}>
                  {status}
                </Typography>
              </Grid>
            </>
          ) : null}
          {permission.length ? (
            <>
              <Grid item xs={6} sm={1} />
              <Grid item xs={6} sm={2}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Permission:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                <Typography gutterBottom variant={'subtitle2'}>
                  {permission}
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default CheckAccountStatusPage;
