import React, { Component } from 'react';
import Select from 'react-select';
import GoogleBids from './GoogleBids';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import GoogleAdsCreativeGroupBuilder from './GoogleAdsCreativeGroupBuilder';
import { getGoogleAdsCampaigns, storeFiles } from '../Api';
import { getPlatformSites } from '../Sites';
import { GA, GLOBAL, PLATFORMS, TASKS } from '../Constants';
import { parseCampaignName } from '../NameParser';
import WidgetSelector from '../WidgetSelector';
import { toast } from 'react-toastify';
import CropWindow from '../CropWindow';
import CampaignsNote from '../CampaignsNote';
import {
  displayWarnings,
  getImageDimensions,
  truncateFileName,
  validateAndPrepareImage
} from '../utils/imageUploadUtils';
import Prefix from '../Prefix';
import { normalizeCreative } from '../utils/creativesUtils';
import { getMaxCampIndex } from '../utils/commonUtils';
import {
  articleCodeLanguageVersion,
  getFileHash,
  getMultiSelectValue,
  getNewCreatorValue,
  getOnlyValue,
  getSelectValue,
  makeSelectOptions
} from '../Shared';
import Promise from 'bluebird';
import _ from 'lodash';

const DEVICES = [GA.DESKTOP, GA.TABLET, GA.MOBILE, GA.OTHER, GA.CONNECTED_TV];
class GoogleAdsCreate extends Component {
  constructor(props) {
    super(props);
    const defaultOptions = {
      [GA.KEY_BUDGET]: GA.DEFAULT_BUDGET,
      [GA.KEY_BIDS]: [],
      [GA.KEY_CREATIVE_GROUPS]: [],
      [GA.KEY_SITE]: GA.DEFAULT_SITE,
      [GA.KEY_DEVICE]: GA.DEFAULT_DEVICE,
      [GA.KEY_COUNTRY]: GA.DEFAULT_COUNTRY,
      [GA.KEY_PREFIX]:
        this.props.programOptions.spm_prefix_default || this.props.programOptions.spm_prefix_for_google_ads,
      [GA.KEY_ADSET_CPC]: '',
      [GA.KEY_CAMPAIGN_CPA]: '',
      [GA.KEY_CAMPAIGN_BIDDING_STRATEGY]: GA.DEFAULT_CAMPAIGN_BIDDING_STRATEGY,
      osChoices: [],
      campaigns: [],
      creatives: {},
      showCropWindow: false,
      creativeFromInsights: this.props.creativeFromInsights,
      creativeFromInsightsAdded: false,
      creativeFromFbLibrary: this.props.creativeFromFbLibrary,
      creativeFromFbLibraryAdded: false,
      conversions: [],
      triggerSelectorUpdate: false,
      siteList: this.props.siteList
    };
    this.defaultState = {
      options: {
        ...defaultOptions,
        ...this.props.options
      }
    };
    this.isSwag = this.props.programOptions.isSwag;
    this.state = _.cloneDeep(this.defaultState);
  }

  setDefaultOptions() {
    this.modifyOptions(GA.KEY_COUNTRY, GA.DEFAULT_COUNTRY);
    this.modifyOptions(GA.KEY_DEVICE, GA.DEFAULT_DEVICE);
    this.modifyOptions(GA.KEY_CAMPAIGN_TYPE, GA.DEFAULT_CAMPAIGN_TYPE);
    this.modifyOptions(GA.KEY_CAMPAIGN_BIDDING_STRATEGY, GA.DEFAULT_CAMPAIGN_BIDDING_STRATEGY);
    this.modifyOptions(GA.KEY_STATUS, GA.ACTIVE);
    this.modifyOptions(GA.KEY_CAMPAIGN_START_TIME, moment());
    this.modifyOptions(GA.KEY_GEO_DUPLICATION_TYPE, 'separate');
    this.modifyOptions(GA.KEY_CREATOR, getNewCreatorValue(this.props.programOptions.email, this.props.creatorsList));
  }

  componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      this.setDefaultOptions();
    } else {
      this.modifyOptions(GA.KEY_DESTINATION_ACCOUNT, this.state.selectedAccount);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.task !== prevProps.task && this.props.task === TASKS.CREATE) {
      this.setDefaultOptions();
    }

    if (this.props.triggerRowUpdate !== prevProps.triggerRowUpdate) {
      this.modifyBidsRows();
    }
    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      getGoogleAdsCampaigns(this.props.destinationAccount).then(camps => {
        const preparedCamps = camps.map(el => {
          return { id: el.id, desc: el.name };
        });
        this.setState({ campaigns: preparedCamps });
      });
    }
    if (this.props.sourceData !== prevProps.sourceData) {
      let res = await this.parseGoogleAdsSourceData();
      this.setState(res, () => {
        this.setState({ triggerSelectorUpdate: !this.state.triggerSelectorUpdate });
      });
    }
    if (this.props.triggerRowUpdate !== prevProps.triggerRowUpdate) {
      this.modifyBidsRows();
    }
    if (this.props.creative) {
      this.props.creativeDone();
      const parsedName = parseCampaignName(
        PLATFORMS[this.props.creative.platform.toUpperCase()],
        this.props.creative.name
      );
      this.modifyOptions(GA.KEY_COUNTRY, parsedName.country || '');
      this.modifyOptions(GA.KEY_SITE, parsedName.site || []);
      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(GA.KEY_DEVICE, GA.DESKTOP);
      }

      const creative = normalizeCreative(this.props.creative);

      this.addCreatives([
        {
          images: creative.image_url,
          texts: creative.text,
          titles: creative.title
        }
      ]);
    }
    if (this.props.creativeFromInsights && !this.state.creativeFromInsightsAdded) {
      let creative = this.props.creativeFromInsights;
      let creator = creative?.campaignCreator || creative?.creator;
      const xs = [
        {
          images: [creative.image_url],
          titles: [''],
          texts: [''],
          longTitles: [''],
          youtubeUrl: []
        }
      ];
      const parsedName = parseCampaignName(this.props.platform, creative.name);
      this.modifyOptions(GLOBAL.KEY_WIDGET_CODE_NAME, parsedName.widgetCodeName || '');
      this.modifyOptions(GLOBAL.KEY_WIDGET_LANGUAGE_CODE, parsedName.widgetLanguageCode || '');
      this.modifyOptions(GA.KEY_COUNTRY, creative.country || '');
      this.modifyOptions(GA.KEY_SITE, creative.site || []);
      if (creator) {
        this.modifyOptions(GA.KEY_CREATOR, creator);
      }
      this.setState({ creativeFromInsightsAdded: true }, () => this.setState({ showCropWindow: true, creatives: xs }));
    }
    if (this.props.creativeFromFbLibrary && !this.state.creativeFromFbLibraryAdded) {
      let creative = this.props.creativeFromFbLibrary;
      const xs = [
        {
          images: creative[0].images,
          titles: creative[0].titles.slice(0, GA.MAX_TITLE_CHARS),
          texts: creative[0].texts.slice(0, GA.MAX_TEXT_CHARS)
        }
      ];

      this.setState({ creativeFromFbLibraryAdded: true }, () => this.setState({ showCropWindow: true, creatives: xs }));
    }
  }
  getBrandingText(domain) {
    const site = getPlatformSites(PLATFORMS.GOOGLE_ADS.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.branding;
    } else {
      throw new Error("Can't find site corresponding to 3-letter domain " + domain);
    }
  }

  async addCreatives(xs, images) {
    const groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    const adset = { images: [], titles: [], texts: [], longTitles: [], youtubeUrl: [] };

    for (const [adsetIndex, x] of xs.entries()) {
      for (const text of x.texts) {
        for (const title of x.titles) {
          for (const longTitles of x.longTitles) {
            for (const [imageIndex] of x.images.entries()) {
              const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
              const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
              for (const crop of nonEmpyCrops) {
                adset.images.push(crop);
              }
              adset.texts.push(text);
              adset.titles.push(title);
              adset.longTitles.push(longTitles);
            }
          }
        }
      }
    }

    const sourceGroup = {
      ads: [{ ...adset, images: [] }]
    };

    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, [...groups, sourceGroup]);
    setTimeout(async () => {
      for (const image of images) {
        for (const type in image.crops) {
          this.onAddImages(0, image.crops.Desktop.blob, image.crops[type].type, image.imageIndex);
        }
      }
    });
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.GOOGLE_ADS.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
  }
  _modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  modifyOptions(key, value) {
    if (key === GA.KEY_PREFIX || key === 'campName' || key === GA.KEY_BUDGET_TYPE) {
      // It's an "input" element
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === GA.KEY_BUDGET || key === GA.KEY_CAMPAIGN_CPA || key === GA.KEY_ADSET_CPC) {
      // It's an "input" element
      if (value.target) {
        value = value.target.valueAsNumber;
      }
    }
    if (key === GA.KEY_CAMPAIGN_TYPE) {
      if (value === 'PERFORMANCE_MAX') {
        this._modifyOptions(GA.KEY_CAMPAIGN_BIDDING_STRATEGY, 'MAXIMIZE_CONVERSIONS');
      }
    }
    this._modifyOptions(key, value);
    if (key === GA.KEY_COUNTRY || key === GA.KEY_DEVICE || key === GA.KEY_SITE || key === GA.KEY_GEO_DUPLICATION_TYPE) {
      // Update bid rows
      this.modifyBidsRows();
    }
  }

  modifyBidsRows() {
    if (!this.state.options[GA.KEY_COUNTRY] || !this.state.options[GA.KEY_DEVICE] || !this.state.options[GA.KEY_SITE]) {
      return;
    }
    // for some reason some of the values are arrays, some are strings
    //* in Select input - value type is string, when select all - array */
    // TODO for some reason some of the values are arrays, some are strings
    let geos = this.state.options[GA.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    let devices = this.state.options[GA.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }
    let sites = this.state.options[GA.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    if (this.state.options[GA.KEY_GEO_DUPLICATION_TYPE] === 'group') {
      // This geos should be treated like a group
      geos = [geos.join(',')];
    }
    let campaigns = [];
    geos.forEach(geo => {
      sites.forEach(site => {
        devices.forEach(dev => {
          // special handling of DESKTOP, no OSs here
          campaigns.push({
            country: geo,
            device: dev,
            site: site,
            fullSite: this.getFullSite(site),
            branding_text: this.getBrandingText(site),
            campaignConversion: this.state.options[GA.KEY_CAMPAIGN_CONVERSION]
          });
        });
      });
    });
    this._modifyOptions(GA.KEY_BIDS, campaigns);
  }
  validateArticles() {
    const clv = articleCodeLanguageVersion(
      this.props.articleList,
      this.state.options.widgetid || '',
      this.state.options.article.target.value || '',
      '',
      this.isSwag ? GA.ARTICLE_BLOCK_SWAG : GA.ARTICLE_BLOCK_B2B
    );
    if (!clv) return false;
    return clv.every(value => typeof value === 'string' && value !== '');
  }
  validateCreativeGroups() {
    let errors = [];
    const groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    if (!groups.length) {
      errors.push(`There are no creative groups`);
    }
    groups.forEach((gr, groupIdx) => {
      if (gr.ads[0].images.length === 0) {
        errors.push(`Group ${groupIdx + 1} no images, add at least one image.`);
      }
      if (gr.ads[0].images.length > GA.AD_IMAGE_LIMIT) {
        errors.push(
          `Group ${groupIdx + 1} Ad image amount more than ad limit.\n 
          Please remove ${gr.ads[0].images.length - GA.AD_IMAGE_LIMIT} images`
        );
      }
      if (!gr.ads[0].longTitles[0]) {
        errors.push(`Group ${groupIdx + 1},the creative long title is empty.`);
      }
      if (!gr.ads[0].titles[0]) {
        errors.push(`Group ${groupIdx + 1}, the creative title is empty.`);
      }
      if (!gr.ads[0].texts[0]) {
        errors.push(`Group ${groupIdx + 1}, the creative text is empty.`);
      }

      if (this.state.options[GA.KEY_CAMPAIGN_TYPE] === 'PERFORMANCE_MAX') {
        if (gr.ads[0].titles[0].length > 15) {
          errors.push(`Group ${groupIdx + 1},the creative short title should be 15 characters or less.`);
        }
        if (gr.ads[0].texts[0].length > 30) {
          errors.push(`Group ${groupIdx + 1},the creative short text should be 30 characters or less.`);
        }
        if (gr.ads[0].titles.length < 3) {
          errors.push(`Group ${groupIdx + 1}, minimum 3 titles.`);
        }
        if (gr.ads[0].texts.length < 3) {
          errors.push(`Group ${groupIdx + 1}, minimum 3 texts.`);
        }
      }

      const unique = a => [...new Set(a)];
      let titles = unique(gr.ads[0].titles).map(el => el);
      let longTitles = unique(gr.ads[0].longTitles).map(el => el);
      let texts = unique(gr.ads[0].texts).map(el => el);

      if (titles.length < gr.ads[0].titles.length) {
        errors.push(`Group ${groupIdx + 1}, creative titles is duplicated.`);
      }
      if (longTitles.length < gr.ads[0].longTitles.length) {
        errors.push(`Group ${groupIdx + 1}, creative longTitles is duplicated.`);
      }
      if (texts.length < gr.ads[0].texts.length) {
        errors.push(`Group ${groupIdx + 1}, creative texts is duplicated.`);
      }
      gr.ads[0].titles.forEach(title => {
        if (gr.ads[0].texts.includes(title) || gr.ads[0].longTitles.includes(title)) {
          errors.push(`Group ${groupIdx + 1}, titles not unique.`);
        }
      });
      gr.ads[0].texts.forEach(text => {
        if (gr.ads[0].titles.includes(text)) {
          errors.push(`Group ${groupIdx + 1}, texts not unique.`);
        }
      });
      gr.ads[0].longTitles.forEach(longTitle => {
        if (gr.ads[0].titles.includes(longTitle)) {
          errors.push(`Group ${groupIdx + 1}, longTitles not unique.`);
        }
      });
      if (gr.ads[0].images.length > 20) {
        errors.push(`Group ${groupIdx + 1}, has a limit - maximum 20 images.`);
      }
    });
    if (errors.length === 0) {
      return {
        isValid: true
      };
    } else {
      return {
        isValid: false,
        data: errors
      };
    }
  }

  validateBids() {
    let errors = [];
    const bids = this.state.options[GA.KEY_BIDS];
    bids.forEach((bid, groupIdx) => {
      if (!bid.campaignConversion) {
        errors.push(`Campaign ${groupIdx + 1} conversion is empty.`);
      }
    });
    if (errors.length === 0) {
      return {
        isValid: true
      };
    } else {
      return {
        isValid: false,
        data: errors
      };
    }
  }

  onAddImages(groupIdx, imageBlob, deviceType, index) {
    const images = [imageBlob];
    const errs = [];
    const warnings = [];
    const formData = new FormData();
    let basicValidatedImages = [];
    const device = deviceType;
    let options = {};
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        if (device) {
          images.forEach((file, i) => {
            const filename_truncated = truncateFileName(file.name);

            if (
              !validateAndPrepareImage(
                file,
                filename_truncated,
                dimensions[i],
                groupIdx,
                'GA',
                warnings,
                errs,
                device,
                options
              )
            ) {
              return;
            }
            formData.append(i, file, file.name);
            basicValidatedImages.push(file);
          });
        } else {
          warnings.push('Please, select the targeting device first.');
        }

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) return;
        return storeFiles(formData, options);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
          if (!groups[groupIdx].ads[0].images[index]) {
            groups[groupIdx].ads[0].images[index] = {};
          }
          groups[groupIdx].ads[0].images[index][deviceType] = filenames[0];
          this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  onChangeImageText(groupIdx, imageIdx, type, e) {
    const text = e.target.value;
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    groups[groupIdx].ads[0][type][imageIdx] = text;
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  onPasteImageText(groupIdx, imageIdx, type, e) {
    const text = e.clipboardData.getData('Text');
    let maxLength = null;
    if (type === 'texts') {
      maxLength = GA.MAX_TEXT_CHARS;
    } else if (type === 'titles') {
      maxLength = GA.MAX_TITLE_CHARS;
    } else if (type === 'longTitles') {
      maxLength = GA.MAX_LONG_TITLE_CHARS;
    }
    if (text.length > maxLength) {
      toast.error(`Pasted text too long: ${text.length} chars. Truncating.`);
    }
  }

  removeImage(groupIdx, imageIdx) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    groups[groupIdx].ads[0].images.splice(imageIdx, 1);
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateImage(groupIdx, imageIdx) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    const duplicatedImage = groups[groupIdx].ads[0].images[imageIdx];
    groups[groupIdx].ads[0].images.push(duplicatedImage);
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(_.cloneDeep(groups[groupIdx]));
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS];
    groups[groupIdx].ads[0].images.forEach((_, idx) => {
      groups[groupIdx].ads[0].texts[idx] = groups[groupIdx].ads[0].texts[imageIdx];
      groups[groupIdx].ads[0].titles[idx] = groups[groupIdx].ads[0].titles[imageIdx];
      groups[groupIdx].ads[0].longTitles[idx] = groups[groupIdx].ads[0].longTitles[imageIdx];
    });
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }
  onAddTextRow(groupIdx, idx, type) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS] || [];
    groups[groupIdx].ads[0][type].push('');
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }
  handleDeleteTextRow(groupIdx, idx, type) {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS] || [];
    groups[groupIdx].ads[0][type].splice(idx, 1);
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  addCreativeGroup() {
    let groups = this.state.options[GA.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      ads: [
        {
          images: [],
          texts: [''],
          longTitles: [''],
          titles: [''],
          youtubeUrl: []
        }
      ]
    });
    this.modifyOptions(GA.KEY_CREATIVE_GROUPS, groups);
  }

  upsertSnapshot() {
    this.props.upsertSnapshot();
  }
  getDestinationAccountOptions() {
    return this.props.accounts.map(x => ({ label: x.desc, value: x.id }));
  }
  constructAdsets(options) {
    //adsetCPC
    return options.creativeGroups.map(adset => {
      let payload = {
        adsetCPC: options.adsetCPC,
        ad: {
          adTitleLong: adset.ads[0].longTitles.map(e => e.trim()),
          adTitle: adset.ads[0].titles.map(e => e.trim()),
          adText: adset.ads[0].texts.map(e => e.trim()),
          adImageBig: adset.ads[0].images.map(img => {
            return img.Desktop.startsWith('https://')
              ? img.Desktop
              : `https://shinez-pictures.s3.amazonaws.com/${img.Desktop}`;
          }),
          adImageBigSquare: adset.ads[0].images.map(img => {
            return img.Smartphone.startsWith('https://')
              ? img.Smartphone
              : `https://shinez-pictures.s3.amazonaws.com/${img.Smartphone}`;
          }),
          note: options.campaignNote || '',
          creator: options.campaignCreator,
          tags: options.tags || ''
        }
      };
      if (options.campaignType === 'PERFORMANCE_MAX') {
        const portraitImages = adset.ads[0].images.filter(img => img.Portrait && img.Portrait.length);

        if (portraitImages.length > 0) {
          const result = portraitImages.map(img => {
            return img.Portrait.startsWith('https://')
              ? img.Portrait
              : `https://shinez-pictures.s3.amazonaws.com/${img.Portrait}`;
          });

          payload.ad.adImageBigPortrait = result;
        }
      }

      if (adset.ads[0].youtubeUrl.length) {
        payload.ad.adVideoYoutube = adset.ads[0].youtubeUrl;
      }
      return payload;
    });
  }

  prepareCampaigns(options) {
    let campaigns = [];
    options.bids.map(b => {
      let payload = {
        prefix: options.prefix,
        device:
          b.device === GA.DESKTOP
            ? 'd'
            : b.device === GA.MOBILE
            ? 'm'
            : b.device === GA.TABLET
            ? 't'
            : b.device === GA.CONNECTED_TV
            ? 'v'
            : 'o',
        country: b.country,
        site: b.site,
        campaignType: options.campaignType,
        campaignConversion: b.campaignConversion,
        article: options.fullCodeName,
        campaignBudget: options.budget,
        campaignStart: moment(options.campaignStartTime).format('YYYY-MM-DD'),
        campaignBiddingType: options.campaignBiddingType,
        campaignCPA: options.campaignCPA,
        campaignStatus: options.status,
        adset: this.constructAdsets(options)
      };
      if (options.campaignStopTime) {
        payload.campaignEnd = moment(options.campaignStopTime).format('YYYY-MM-DD');
      }
      campaigns.push(payload);
    });
    if (options.destinationAccount) {
      return {
        account: [
          {
            accountId: options.destinationAccount,
            campaign: campaigns
          }
        ]
      };
    } else {
      return { campaign: campaigns };
    }
  }

  startCreateCampaign() {
    const options = this.state.options;
    const campaigns = this.prepareCampaigns(options);
    console.log(campaigns);
    this.props.createGoogleAdsCampaign(campaigns);
  }

  getCampaignsNumber() {
    return this.state.options[GA.KEY_BIDS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    let sites = this.state.options[GA.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (GA.SITE_ALLOWED_VERSIONS[s]) {
        return GA.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return GA.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    return parts.filter(el => el).join('-') + ' = ' + bid.bid;
  }
  validateSetup() {
    // Check that we have campaigns to create
    if (!this.getCampaignsNumber()) {
      const err = 'There are no campaigns to create/duplicate';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!this.state.options.widgetid) {
      const err = 'Widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }
    if (!this.validateArticles()) {
      const err = 'You are trying to use blocked article. Please choose another article';
      console.warn(err);
      toast.error(err);
      return false;
    }
    if (
      this.state.options[GA.KEY_CAMPAIGN_TYPE] === 'PERFORMANCE_MAX' &&
      this.state.options[GA.KEY_CAMPAIGN_BIDDING_STRATEGY] !== 'MAXIMIZE_CONVERSIONS'
    ) {
      const err = 'Performance max campaign type support only maximize conversions strategy';
      console.warn(err);
      toast.error(err);
      return false;
    }
    // Check creative group validity
    const validationInfo = this.validateCreativeGroups();
    if (validationInfo.isValid) {
      console.log('Creative groups are valid');
    } else {
      console.log('Creative groups are invalid', validationInfo.data);
      validationInfo.data.forEach(err => {
        toast.error(err);
      });
      return false;
    }
    // Check bids validity
    const validateBidInfo = this.validateBids();

    if (validateBidInfo.isValid) {
      console.log('Campaign bids are valid');
    } else {
      console.log('Campaign bids are invalid', validateBidInfo.data);
      validateBidInfo.data.forEach(err => {
        toast.error(err);
      });
      return false;
    }

    if (!this.state.options[GA.KEY_CREATOR]) {
      const err = 'Please, select campaign creator';
      console.log(err);
      toast.error(err);
      return false;
    }

    return true;
  }

  constructCampaignName(options) {
    let naming = {};
    let newCampaignIndex = 1;
    if (options.sourceName) {
      const splitted = options.sourceName.split('-');
      naming.prefix = splitted[0];
      naming.country = splitted[1];
      naming.platform = splitted[2];
      naming.site = splitted[3];
      naming.article = splitted[4];
    }
    const parts = [];
    let device = '';
    parts.push(options.prefix || naming.prefix);
    if (options.country) {
      parts.push(options.country[0] || naming.country);
    }
    if (options.device) {
      device = options.device[0].toLowerCase();

      switch (device) {
        case 'desktop':
          parts.push('d');
          break;
        case 'mobile':
          parts.push('m');
          break;
        case 'tablet':
          parts.push('t');
          break;
        case 'connected_tv':
          parts.push('t');
          break;
        case 'other':
          parts.push('o');
          break;
        default:
          break;
      }
    }
    if (typeof options.site === 'string') {
      options.site = options.site.split(',');
    }
    if (options.site && options.site[0]) {
      parts.push(options.site[0] || naming.site);
    }
    parts.push(options.widgetCodeName + options.widgetLanguageCode || naming.article || 'ARTICLE');
    if (this.state.campaigns[0]) {
      newCampaignIndex = getMaxCampIndex(this.state.campaigns.map(c => c.desc)) + this.getCampaignsNumber();
    }
    parts.push('c' + newCampaignIndex);

    return parts.join('-');
  }

  submit() {
    if (!this.validateSetup()) {
      return;
    }
    let result = this.prepareCampaigns(this.state.options);
    console.log(result);
    let title;
    let text;
    if (this.state.options.warnings && this.state.options.warnings.length > 0) {
      const warningBids = this.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = 'Are you sure to do this?';
    }

    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  cancelCropWindow() {
    this.setState({ showCropWindow: false });
  }

  applyCropWindow(images) {
    this.addCreatives(this.state.creatives, images);
    this.setState({ showCropWindow: false });
  }
  // getConversionOptions(site) {
  //   const accounts = this.props.accounts;
  //   const conversions = [];
  //   const account = accounts.filter(acc => acc.desc.toLowerCase() === site.toLowerCase());
  //   if (account.length) {
  //     getGoogleAdsConversions(account[0].id).then(res => {
  //       for (const [key, value] of Object.entries(res)) {
  //         conversions.push({ label: value, value: key });
  //       }
  //     });
  //   }
  //   return conversions;
  // }
  updateOneDomain(domain) {
    this._modifyOptions(GA.KEY_SITE, domain);
    this.modifyBidsRows();
    let siteList = this.props.siteList.filter(e => e.value === domain);
    this.setState({ siteList });
  }
  modifyBid(idx, conversion) {
    let bids = this.state.options[GA.KEY_BIDS];
    bids[idx].campaignConversion = conversion;
    this._modifyOptions(GA.KEY_BIDS, bids);
  }
  getDefaultSite(account, name) {
    const accountName = name ? name.split(' ')[0] : '';
    if (accountName) {
      const site = this.props.siteList.find(el => accountName.toLowerCase().includes(el.value));
      return site ? site.value : '';
    }
  }
  updateDestinationAccount(account) {
    if (account) {
      const acc = this.props.accounts.find(x => x.id === `${account}`);
      const accountName = acc && acc.desc ? acc.desc : '';
      this.modifyOptions(GA.KEY_DESTINATION_ACCOUNT, account);
      const domain = this.getDefaultSite(account, accountName);
      if (domain) {
        this.updateOneDomain(domain);
      }
    } else {
      this.modifyOptions(GA.KEY_DESTINATION_ACCOUNT, account);
      this.setState({ siteList: this.props.siteList });
    }
  }
  getStrategyOptions() {
    return this.state.options[GA.KEY_CAMPAIGN_TYPE] === 'PERFORMANCE_MAX'
      ? [
          {
            label: 'Maximize conversions',
            value: 'MAXIMIZE_CONVERSIONS'
          }
        ]
      : GA.CAMPAIGN_BIDDING_STRATEGY;
  }

  async parseGoogleAdsSourceData() {
    let data = this.props.sourceData;
    const widgetId = data.account.campaign.article;
    const widgetData = this.props.articleList.wid[widgetId];

    let options = this.state.options;
    const adData = data.account.campaign.adset[0].ad[0];
    options[GA.KEY_CREATIVE_GROUPS] = [];
    const longTitles = adData.adTitleLong;
    const images = adData.adImageBig.map((el, idx) => {
      return { Desktop: el, Smartphone: adData.adImageBigSquare[idx] };
    });
    const texts = adData.adText;
    const titles = adData.adTitle;
    const sourceGroup = {
      ads: [
        {
          images: images,
          texts: texts,
          longTitles: longTitles,
          titles: titles,
          youtubeUrl: []
        }
      ]
    };
    options[GA.KEY_CREATIVE_GROUPS].push(sourceGroup);
    options[GA.KEY_BIDS] = [];
    options[GLOBAL.KEY_WIDGET_CODE_NAME] = typeof widgetData.clv === 'string' ? widgetData.clv.substring(0, 5) : '';
    options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = typeof widgetData.clv === 'string' ? widgetData.clv.substring(5, 7) : '';
    options[GA.KEY_SITE] = GA.DEFAULT_SITE;
    options[GA.KEY_BUDGET] = GA.DEFAULT_BUDGET;
    options[GA.KEY_CREATOR] = getNewCreatorValue(adData.creator, this.props.creatorsList);
    return options;
  }

  renderCreateDuplicate() {
    let exampleName = '';
    if (this.state.options && this.state.options.length > 0) {
      exampleName = this.constructCampaignName(this.state.options);
    }

    return (
      <>
        <Prefix
          platform={this.props.platform}
          value={{ label: this.state.options[GA.KEY_PREFIX], value: this.state.options[GA.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(GA.KEY_PREFIX, x.value)}
          options={makeSelectOptions(this.props.programOptions?.prefix)}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Creator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(this.props.creatorsList, this.state.options[GA.KEY_CREATOR])}
            onChange={e => this.modifyOptions(GA.KEY_CREATOR, e.value)}
            options={this.props.creatorsList}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(this.getDestinationAccountOptions(), this.state.options[GA.KEY_DESTINATION_ACCOUNT])}
            onChange={e => this.updateDestinationAccount(e.value)}
            options={this.getDestinationAccountOptions()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            closeMenuOnSelect={false}
            isMulti
            onChange={xs => this.modifyOptions(GA.KEY_COUNTRY, getOnlyValue(xs))}
            options={this.props.countryList}
            value={getMultiSelectValue(this.props.countryList, this.state.options[GA.KEY_COUNTRY])}
          />
        </Grid>
        <Grid item xs={4} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[GA.KEY_GEO_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(GA.KEY_GEO_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.state.options[GA.KEY_GEO_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(GA.KEY_SITE, getOnlyValue(xs))}
            options={this.state.siteList}
            value={getMultiSelectValue(this.state.siteList, this.state.options[GA.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(GA.KEY_DEVICE, getOnlyValue(xs))}
            options={GA.DEVICE_LIST}
            value={getMultiSelectValue(GA.DEVICE_LIST, this.state.options[GA.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => this.modifyOptions(GA.KEY_DEVICE, DEVICES)}
          >
            Select all
          </Button>
        </Grid>
        <WidgetSelector
          modify={(key, value) => this.modifyOptions(key, value)}
          articleList={this.props.articleList}
          options={this.state.options}
          block={this.isSwag ? GA.ARTICLE_BLOCK_SWAG : GA.ARTICLE_BLOCK_B2B}
          allowedVersions={this.getAllowedWidgetVersions()}
          defaultVersion={GA.DEFAULT_VERSION}
          triggerSelectorUpdate={this.state.triggerSelectorUpdate}
          programOptions={this.props.programOptions}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(GA.CAMPAIGN_TYPE, this.state.options[GA.KEY_CAMPAIGN_TYPE])}
            onChange={e => this.modifyOptions(GA.KEY_CAMPAIGN_TYPE, e.value)}
            options={GA.CAMPAIGN_TYPE}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bidding strategy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={e => this.modifyOptions(GA.KEY_CAMPAIGN_BIDDING_STRATEGY, e.value)}
            options={this.getStrategyOptions(this.state.options[GA.KEY_CAMPAIGN_BIDDING_STRATEGY])}
            value={getSelectValue(
              this.getStrategyOptions(this.state.options[GA.KEY_CAMPAIGN_BIDDING_STRATEGY]),
              this.state.options[GA.KEY_CAMPAIGN_BIDDING_STRATEGY]
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign budget
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            name={GA.KEY_BUDGET}
            step={GA.BUDGET_STEP}
            value={this.state.options[GA.KEY_BUDGET]}
            onChange={value => this.modifyOptions(GA.KEY_BUDGET, value)}
          />
        </Grid>
        {this.state.options.campaignBiddingType !== 'MAXIMIZE_CONVERSION_VALUE' &&
          this.state.options.campaignBiddingType !== 'MANUAL_CPC' && (
            <>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Campaign CPA
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <input
                  type="number"
                  name={GA.KEY_CAMPAIGN_CPA}
                  step={GA.CAMPAIGN_CPA_STEP}
                  value={this.state.options[GA.KEY_CAMPAIGN_CPA]}
                  onChange={value => this.modifyOptions(GA.KEY_CAMPAIGN_CPA, value)}
                />
              </Grid>
            </>
          )}
        {this.state.options.campaignBiddingType === 'MANUAL_CPC' && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Adset CPC
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <input
                type="number"
                name={GA.KEY_ADSET_CPC}
                step={GA.ADSET_CPC_STEP}
                value={this.state.options[GA.KEY_ADSET_CPC]}
                onChange={value => this.modifyOptions(GA.KEY_ADSET_CPC, value)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign start date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.state.options[GA.KEY_CAMPAIGN_START_TIME]
                ? new Date(this.state.options[GA.KEY_CAMPAIGN_START_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(GA.KEY_CAMPAIGN_START_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={moment().toDate()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign end date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.state.options[GA.KEY_CAMPAIGN_STOP_TIME]
                ? new Date(this.state.options[GA.KEY_CAMPAIGN_STOP_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(GA.KEY_CAMPAIGN_STOP_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={
              this.state.options[GA.KEY_CAMPAIGN_START_TIME]
                ? moment(this.state.options[GA.KEY_CAMPAIGN_START_TIME])
                    .add(1, 'day')
                    .toDate()
                : moment()
                    .add(1, 'day')
                    .toDate()
            }
          />
        </Grid>
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={e => this.modifyOptions(GA.KEY_STATUS, e.value)}
              options={GA.STATUS}
              value={getSelectValue(GA.STATUS, this.state.options[GA.KEY_STATUS])}
            />
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Typography gutterBottom variant={'subtitle2'}>
              Bids
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} align="center"></Grid>
          <GoogleBids
            accounts={this.props.accounts}
            bids={this.state.options[GA.KEY_BIDS]}
            modifyBid={(idx, e) => this.modifyBid(idx, e)}
            validateBid={idx => this.validateBid(idx)}
          />
          <CampaignsNote
            value={this.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
            onChange={e => {
              this._modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
            }}
          />
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Example campaign name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography gutterBottom variant={'subtitle2'}>
              {exampleName}
            </Typography>
          </Grid>
          <GoogleAdsCreativeGroupBuilder
            groups={this.state.options[GA.KEY_CREATIVE_GROUPS]}
            onAddImages={(groupIdx, imageBlob, deviceType, index) =>
              this.onAddImages(groupIdx, imageBlob, deviceType, index)
            }
            onAddTextRow={(groupIdx, idx, type) => this.onAddTextRow(groupIdx, idx, type)}
            handleDeleteTextRow={(groupIdx, idx, type) => this.handleDeleteTextRow(groupIdx, idx, type)}
            onChangeImageText={(groupIdx, imageIdx, type, e) => this.onChangeImageText(groupIdx, imageIdx, type, e)}
            onPasteImageText={(groupIdx, type, e, imageIdx) => this.onPasteImageText(groupIdx, type, e, imageIdx)}
            removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
            duplicateImage={(groupIdx, imageIdx) => this.duplicateImage(groupIdx, imageIdx)}
            removeGroup={groupIdx => this.removeGroup(groupIdx)}
            duplicateGroup={groupIdx => this.duplicateGroup(groupIdx)}
            populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
            options={this.state.options}
          />
          {this.state.showCropWindow ? (
            <CropWindow
              fromInsights={true}
              desktopAspectRatio={GA.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={GA.SMARTPHONE_ASPECT_RATIO}
              portraitAspectRatio={GA.PORTRAIT_ASPECT_RATIO}
              data={this.state.creatives}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              platform={PLATFORMS.GOOGLE_ADS}
            />
          ) : null}
          <Grid item xs={6} sm={4} align="right">
            <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
              {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button fullWidth variant="outlined" color="primary" onClick={() => this.addCreativeGroup()}>
              Add adset
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
              {this.getCreateButtonText()}
            </Button>
          </Grid>
        </>
      </>
    );
  }

  render() {
    return this.renderCreateDuplicate();
  }
}

export { GoogleAdsCreate };
