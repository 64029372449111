import React from 'react';
import { getLink } from './Api';
import queryString from 'query-string';

class GetAdLink extends React.Component {
  componentDidMount() {
    const queryValues = queryString.parse(this.props.location.search);
    this.redirectToLink(queryValues.ad);
  }

  redirectToLink(adName) {
    getLink(adName).then(res => {
      window.location.href = res.url;
      return null;
    });
  }

  render() {
    return null;
  }
}

export default GetAdLink;
