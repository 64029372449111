function cloneDeep(obj) {
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }
  if (obj instanceof Array) {
    const ret = [...obj];
    for (let i = ret.length; i >= 0; i--) {
      const v = ret[i];
      if (v !== null && typeof v === 'object') {
        ret[i] = cloneDeep(v);
      }
    }
    return ret;
  }
  if (obj instanceof Object) {
    const ret = { ...obj };
    for (const i in ret) {
      const v = ret[i];
      if (v !== null && typeof v === 'object') {
        ret[i] = cloneDeep(v);
      }
    }
    return ret;
  }
}

export function clone(obj) {
  return obj === null || typeof obj !== 'object' ? obj : cloneDeep(obj);
}

//

function mergeArrays(src, value) {
  const index = {};
  for (const v of src) {
    if (typeof v === 'object' && v.id !== null) {
      index[v.id] = v;
    }
  }
  for (const v of value) {
    if (typeof v === 'object' && !(v instanceof Array)) {
      let id = v.id;
      if (id !== null) {
        id = index[id];
        if (id) {
          mergeObjects(id, v);
          continue;
        }
      }
    }
    src.push(clone(v));
  }
}

function mergeObjects(src, value) {
  for (const i in value) {
    const v = value[i];
    const s = src[i];
    if (s !== null) {
      if (typeof s === 'object' && typeof v === 'object') {
        const arr = s instanceof Array;
        if (arr === v instanceof Array) {
          (arr ? mergeArrays : mergeObjects)(s, v);
          continue;
        }
      }
    }
    src[i] = clone(v);
  }
}

export function merge(src, value) {
  if (typeof src === 'object' && typeof value === 'object') {
    const arr = src instanceof Array;
    if (arr === value instanceof Array) {
      (arr ? mergeArrays : mergeObjects)(src, value);
    }
  }
}
