export const getMaxCampaignIndex = (names, carousel) => {
  if (names.length === 0) {
    return 0;
  }
  const indices = names.map(n => {
    if (n.length === 0) {
      return 0;
    }
    let match = null;
    if (carousel) {
      match = n.match(/c(\d+)/);
    } else {
      match = n.match(/r(\d+)/);
    }

    const campaignNumber = match ? Number(match[1]) : 0;
    if (Number.isNaN(campaignNumber)) {
      return 0;
    }
    if (campaignNumber > 9999) {
      return 0;
    }
    return campaignNumber;
  });
  const max = indices.reduce((a, b) => {
    return Math.max(a, b);
  });
  return max;
};

export function getMaxCampIndex(campaigns) {
  if (campaigns.length === 0) {
    return 0;
  }
  let result = [];
  campaigns.forEach(camp => {
    const campIndex = camp.name.split('-')[6];
    if (!campIndex) {
      return;
    }
    const idx = Number(campIndex.split('c')[1]);
    if (!idx) {
      return;
    }
    if (campIndex.startsWith('c') && Number.isInteger(idx)) {
      result.push(idx);
    }
  });
  return result.length ? Math.max(...result) : 0;
}
/**
 *
 * @param {*} func function you want to debounce
 * @param {*} delay amount of time in milliseconds that the debounced function should wait before executing
 * @returns another function that actually does the debouncing
 */
export function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
