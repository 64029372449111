import React from 'react';
import TwitterCreativeGroup from './TwitterCreativeGroup';

class TwitterCreativeGroupBuilder extends React.Component {
  render() {
    return (
      <>
        {this.props.groups.map((el, idx) => (
          <TwitterCreativeGroup
            index={idx}
            key={'twCreativeGroup' + idx}
            onAddImages={(idx, imageBlob, deviceType, index) =>
              this.props.onAddImages(idx, imageBlob, deviceType, index)
            }
            onChangeImageText={(imageIdx, type, e) => this.props.onChangeImageText(idx, imageIdx, type, e)}
            onBlurImageText={imageIdx => this.props.onBlurImageText(idx, imageIdx)}
            onPasteImageText={(imageIdx, type, e) => this.props.onPasteImageText(idx, imageIdx, type, e)}
            removeImage={imageIdx => this.props.removeImage(idx, imageIdx)}
            duplicateImage={imageIdx => this.props.duplicateImage(idx, imageIdx)}
            duplicateGroup={() => this.props.duplicateGroup(idx)}
            populateText={imageIdx => this.props.populateText(idx, imageIdx)}
            group={this.props.groups[idx]}
            options={this.props.options}
            removeGroup={() => this.props.removeGroup(idx)}
          />
        ))}
      </>
    );
  }
}

export default TwitterCreativeGroupBuilder;
