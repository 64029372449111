import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { FB } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import { byteSize } from '../Shared';
import PublishIcon from '@mui/icons-material/Publish';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Grid,
  Typography,
  IconButton,
  Input,
  Tooltip,
  CardHeader,
  Card,
  CardContent,
  CardActions,
  TextField,
  Toolbar
} from '@mui/material';
import { StyledSecondaryAppBar, StyledDiv } from '../SharedStyles';

const videoStyle = {
  width: '100%',
  height: 'auto'
};

class SingleVideoAdset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      anchorEl: null,
      videos: [],
      uploadedVideos: [],
      src: [],
      selectedVideoIndex: 0,
      originFileName: []
    };
  }

  handleTitleChange = (e, idx) => {
    const title = [...this.state.titleTooltip];
    const titleMobile = [...this.state.titleTooltipMobile];
    const inputString = e.target.value;
    const sizeInBytes = byteSize(inputString);
    const mobileCharLimit = FB.MAX_TITLE_MOBILE_LENGTH - sizeInBytes;
    title[idx] = `${sizeInBytes}/${FB.MAX_TITLE_LENGTH}`;
    titleMobile[idx] = {
      text: `${mobileCharLimit}`,
      open: mobileCharLimit >= 0
    };
    this.setState({ titleTooltip: title, titleTooltipMobile: titleMobile });
  };

  populateText(index) {
    const adset = this.props.adset;
    adset.ads.forEach((_, idx) => {
      adset.ads[idx].text = adset.ads[index].text;
      adset.ads[idx].title = adset.ads[index].title;
    });
    this.props.modifyAdset(this.props.index, adset);
  }

  populateAllText(index) {
    const adset = this.props.adset;
    this.props.populateAllText({ text: adset.ads[index].text, title: adset.ads[index].title });
  }

  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleDelete = idx => {
    this.removeAd(idx);
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getKey(base, idx) {
    return base + this.props.index + '_' + idx;
  }

  copyAd(idx) {
    const adset = this.props.adset;
    if (adset.ads.length >= FB.ADSET_LIMIT) {
      const tooltipText = this.state.textTooltip;
      const tooltipTitle = this.state.titleTooltip;
      const tooltipMobile = this.state.titleTooltipMobile;
      const fileName = this.state.originFileName[idx];
      const copyfileName = `${fileName} copy`;
      const fileNames = this.state.originFileName;
      fileNames.push(copyfileName);
      tooltipText.push(`${adset.ads[idx].text.length}/${FB.MAX_TEXT_LENGTH}`);
      tooltipTitle.push(`${adset.ads[idx].title.length}/${FB.MAX_TITLE_LENGTH}`);
      const charsLeft = FB.MAX_TITLE_MOBILE_LENGTH - adset.ads[idx].title.length;
      tooltipMobile.push({ text: charsLeft, open: true });
      this.setState({
        textTooltip: tooltipText,
        titleTooltip: tooltipTitle,
        titleTooltipMobile: tooltipMobile,
        originFileName: fileNames
      });

      this.props.modifyAdsets({ ...adset.ads[idx] });
    } else {
      adset.ads.push({ ...adset.ads[idx] });
      const tooltipText = this.state.textTooltip;
      const tooltipTitle = this.state.titleTooltip;
      const tooltipMobile = this.state.titleTooltipMobile;
      const fileName = this.state.originFileName[idx];
      const copyfileName = `${fileName} copy`;
      const fileNames = this.state.originFileName;
      fileNames.push(copyfileName);
      tooltipText.push(`${adset.ads[idx].text.length}/${FB.MAX_TEXT_LENGTH}`);
      tooltipTitle.push(`${adset.ads[idx].title.length}/${FB.MAX_TITLE_LENGTH}`);
      const charsLeft = FB.MAX_TITLE_MOBILE_LENGTH - adset.ads[idx].title.length;
      tooltipMobile.push({ text: charsLeft, open: true });
      this.setState({
        textTooltip: tooltipText,
        titleTooltip: tooltipTitle,
        titleTooltipMobile: tooltipMobile,
        originFileName: fileNames
      });
      this.props.modifyAdset(this.props.index, adset);
    }
  }

  removeAd(idx) {
    const adset = this.props.adset;
    const tooltipText = this.state.textTooltip;
    tooltipText.splice(idx, 1);
    const tooltipTitle = this.state.titleTooltip;
    tooltipTitle.splice(idx, 1);
    const tooltipTitleMobile = this.state.titleTooltipMobile;
    tooltipTitleMobile.splice(idx, 1);
    const fileNames = this.state.originFileName;
    fileNames.splice(idx, 1);
    adset.ads.splice(idx, 1);
    // const tooltipMobile = this.state.titleTooltipMobile[idx].text.splice(idx, 1);
    this.setState({
      textTooltip: tooltipText,
      titleTooltip: tooltipTitle,
      tooltipTitleMobile: tooltipTitleMobile,
      originFileName: fileNames
    });
    this.props.modifyAdset(this.props.index, adset);
  }

  handleTextChange(e, idx) {
    const text = this.state.textTooltip;
    text[idx] = `${e.target.value.length}/${FB.MAX_TEXT_LENGTH}`;
    this.setState({ textTooltip: text });
  }

  onChangeVideoText(idx, event) {
    const adset = this.props.adset;
    adset.ads[idx].text = event.target.value;
    this.handleTextChange(event, idx);
    this.props.modifyAdset(this.props.index, adset);
  }

  onChangeVideoTitle(idx, event) {
    const adset = this.props.adset;
    adset.ads[idx].title = event.target.value;
    this.handleTitleChange(event, idx);

    this.props.modifyAdset(this.props.index, adset);
  }

  removeVideo(idx) {
    const adset = this.props.adset;
    adset.ads.splice(idx, 1);
    this.props.modifyAdset(this.props.index, adset);
  }

  onUploadFile = async (adsetIndex, e) => {
    const title = this.state.titleTooltip;
    title[adsetIndex] = `0/500`;
    const { originFileName } = this.state;
    const uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      originFileName.push(uploadedFiles[i].name);
    }
    this.setState({ titleTooltip: title, originFileName });
    this.props.onAddVideo(adsetIndex, e);
  };

  showHelperTitle = idx => {
    const title = this.props.adset.ads[idx].title;
    const sizeInBytes = byteSize(title);
    const mobileCharLimit = FB.MAX_TITLE_MOBILE_LENGTH - sizeInBytes;
    return `${sizeInBytes}/255\n Mobile char: ${mobileCharLimit}/${FB.MAX_TITLE_MOBILE_LENGTH}`;
  };

  showHelperText = idx => {
    const title = this.props.adset.ads[idx].text;
    return `${title.length}/${FB.MAX_TEXT_LENGTH}`;
  };

  renderVideo(ad, idx) {
    const fullPath = getFullPath(ad.video_url);
    return (
      <React.Fragment key={this.getKey('sep', idx)}>
        {this.props.toggleAd ? (
          <Grid item xs={6} sm={4}>
            <Card>
              <IconButton
                style={{ backgroundColor: 'transparent' }}
                key={this.getKey('removeButton', idx)}
                onClick={() => this.removeVideo(idx)}
                sx={{ margin: '-30px 0 -55px 150px' }}
                title="Delete ad"
                fontSize="small"
                size="large"
              >
                <Tooltip title="Delete">
                  <ClearIcon />
                </Tooltip>
              </IconButton>
              <CardHeader
                subheader={
                  <TextField
                    value={ad.text}
                    onChange={event => this.onChangeVideoText(idx, event)}
                    variant="standard"
                    multiline
                    fullWidth
                    minRows={1}
                    maxRows={10}
                    label="Text"
                    key={this.getKey('videoText', idx)}
                    helperText={this.showHelperText(idx)}
                    inputProps={{
                      maxLength: FB.MAX_TEXT_LENGTH
                    }}
                  />
                }
              />
              <CardContent>
                <video src={fullPath} title={'Example'} style={videoStyle} controls />

                <TextField
                  variant="standard"
                  multiline
                  fullWidth
                  label="Title"
                  key={this.getKey('imageTitle', idx)}
                  value={ad.title}
                  helperText={this.showHelperTitle(idx)}
                  inputProps={{
                    maxLength: FB.MAX_TITLE_LENGTH
                  }}
                  onChange={event => this.onChangeVideoTitle(idx, event)}
                />
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  color="primary"
                  key={this.getKey('populateButton', idx)}
                  onClick={() => this.populateText(idx)}
                  title="Populate all texts in group"
                  disabled={ad.fromCreativeBank}
                  size="large"
                >
                  <Tooltip title="Populate all texts in group">
                    <TextRotationNoneIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  key={this.getKey('copyButton', idx)}
                  color="primary"
                  onClick={() => this.copyAd(idx)}
                  title="Copy ad"
                  size="large"
                >
                  <Tooltip title="Duplicate ad">
                    <FileCopyIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <StyledDiv>
          <StyledSecondaryAppBar position="static">
            <Toolbar>
              <Avatar>{this.props.index + 1}</Avatar>
              <Typography gutterBottom variant="subtitle2">
                Video Ads {this.props.adset.ads.length}
              </Typography>
              <label htmlFor={'uploadImagesButton' + this.props.index + this.props.geoIdx + this.props.campIdx}>
                <IconButton size="small" component="span" color="default">
                  <Tooltip title="Upload">
                    <PublishIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              </label>
              <Input
                type="file"
                id={'uploadImagesButton' + this.props.index + this.props.geoIdx + this.props.campIdx}
                inputProps={{ multiple: true }}
                onChange={async e => await this.onUploadFile(this.props.index, e)}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
              {this.props.toggleAd ? (
                <IconButton size="small" color="default" onClick={() => this.props.handleAdsDropdown()}>
                  <ExpandLessIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" color="default" onClick={() => this.props.handleAdsDropdown()}>
                  <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
              )}
            </Toolbar>
          </StyledSecondaryAppBar>
        </StyledDiv>
        <Grid container direction="row">
          {this.props.adset.ads.map((ad, idx) => this.renderVideo(ad, idx))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default SingleVideoAdset;
