export function constructWebsite(options, base, widgetid) {
  const TUP = '12up';
  const NMI = '90min';
  const MNT = 'mentalfloss';

  const prefixPostDict = {
    [NMI]: {
      default: '6242836-greatest-stories'
    },
    [MNT]: {
      dl: 'daily-story',
      rb: 'daily-story',
      ne: 'weekly-story',
      ha: 'weekly-story',
      default: 'daily-story'
    }
  };
  if (widgetid) {
    if (base.indexOf(TUP) !== -1) {
      const tupPages = ['greatest-stories', 'amazing-stories', 'fun-stories', 'daily-article'];
      return 'https://' + base + '/' + tupPages[Math.floor(Math.random() * tupPages.length)] + '?art-id=' + widgetid;
    } else if (base.indexOf(NMI) !== -1) {
      const middle =
        options.prefix && prefixPostDict[NMI][options.prefix]
          ? prefixPostDict[NMI][options.prefix]
          : prefixPostDict[NMI]['default'];
      return 'https://' + base + '/posts/' + middle + '?noads&art-id=' + widgetid;
    } else if (base.indexOf(MNT) !== -1) {
      const middle =
        options.prefix && prefixPostDict[MNT][options.prefix]
          ? prefixPostDict[MNT][options.prefix]
          : prefixPostDict[MNT]['default'];
      return 'https://' + base + '/article/' + middle + '?art-id=' + widgetid;
    } else {
      let site = 'https://' + base;
      if (options.widget_path) {
        site += '/' + options.widget_path;
      } else {
        let pathPart = Math.floor(256 * 16 + Math.random() * (256 * 256 - 256 * 16 - 1)).toString(16); // 4 hex chars
        if (options.widget_title) {
          let strippedTitle = options.widget_title
            .split(' ') // Split into words.
            .map(str => str.replace(/\W/g, '')) // First remove any non alpha-numeric chars.
            .filter(str => str) // After filtering non alpha-numeric remove empty cells.
            .join('-')
            .toLowerCase()
            .substr(0, 30) // Make sure the url path is not too long
            .replace(/-$/, ''); // Remove last "-" in case there are leftover from previous trimming.
          if (strippedTitle) {
            pathPart = options.prefix + '-' + strippedTitle;
          }
          site += '/post-' + pathPart + '/?dai=' + widgetid;
        }
      }
      return site;
    }
  } else {
    return 'https://' + base;
  }
}
