import React, { Component } from 'react';
import { RC } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import { Grid, CardContent, CardMedia, TextField, CardActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CreativesAppBar from '../SharedCreativesAppBar';
import { StyledCard } from '../SharedStyles';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';

class RevcontentCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  showHelperText = idx => {
    const text = !this.props.group.texts[idx] ? 0 : this.props.group.texts[idx].length;
    return `${text}/${RC.MAX_TEXT_CHARS}`;
  };

  onUploadFile = async (adsetIndex, e) => {
    this.props.onAddImages(adsetIndex, e);
  };

  renderImage(path, idx) {
    const fullPath = getFullPath(path);
    return (
      <Grid item xs={6} sm={4} key={this.getKey('renderCreativeCard', idx)}>
        <StyledCard>
          <CardMedia key={this.getKey('sep', idx)} image={fullPath} title="Example" />
          <CardContent>
            <Grid container direction="column">
              <Grid item xs={12} sm={12}>
                <TextField
                  key={this.getKey('imageText', idx)}
                  value={this.props.group.texts[idx]}
                  onChange={(imageIdx, e) => this.props.onChangeImageText(idx, imageIdx, e)}
                  onBlur={imageIdx => this.props.onBlurImageText(idx, imageIdx)}
                  onPaste={(imageIdx, e) => this.props.onPasteImageText(idx, imageIdx, e)}
                  maxLength={RC.MAX_TEXT_CHARS}
                  rows="3"
                  variant="standard"
                  multiline
                  fullWidth
                  // label="Text"
                  placeholder="Text"
                  helperText={this.showHelperText(idx)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton
              color="primary"
              key={this.getKey('populateButton', idx)}
              onClick={imageIdx => this.props.populateText(idx, imageIdx)}
              title="Populate all texts in group"
              size="large"
            >
              <TextRotationNoneIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              key={this.getKey('imageRemoveButton', idx)}
              onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
              size="large"
            >
              <DeleteForeverIcon />
            </IconButton>
          </CardActions>
        </StyledCard>
      </Grid>
    );
  }

  render() {
    return (
      <React.Fragment>
        <CreativesAppBar
          index={this.props.index}
          images={this.props.group.images}
          onUploadFile={(groupIdx, e) => this.onUploadFile(groupIdx, e)}
          removeGroup={() => this.props.removeGroup(this.props.index)}
          duplicateGroup={() => this.props.duplicateGroup(this.props.index)}
        />
        <Grid container direction="row" spacing={1}>
          {this.props.group.images.map((path, idx) => this.renderImage(path, idx))}
        </Grid>
      </React.Fragment>
    );
  }
}
export default RevcontentCreativeGroup;
