import { AppBar, Card, CardMedia, styled } from '@mui/material';
import theme from '../Theme';

export const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  margin: '10px 0 10px 0',
  '& .MuiTypography-root': {
    flexGrow: 1,
    margin: '5px'
  },
  '& .MuiIconButton-root': {
    marginLeft: '5px'
  },
  '& .MuiCard-root': {
    minWidth: 180,
    marginLeft: '1px',
    marginTop: '332px'
  },
  '& .MuiAvatar-root': {
    fontSize: '12px',
    color: '#262626',
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '25px'
  }
}));

export const StyledCardMedia = styled(CardMedia)({
  height: 0,
  paddingTop: '60%',
  backgroundSize: 'contain'
});

export const StyledCard = styled(Card)(({ theme }) => ({
  '& .MuiCard-root': {
    minWidth: 180,
    marginLeft: '1px'
  },
  '& .MuiCardContent-root': {
    marginTop: '-10px',
    marginBottom: '-10px'
  }
}));

export const StyledPrimaryAppBar = styled(AppBar)({
  color: '#ffffff',
  backgroundColor: theme.palette.primary
});

export const StyledSecondaryAppBar = styled(AppBar)({
  color: '#262626',
  backgroundColor: '#f1f2f5'
});
