import React, { Component } from 'react';
import { GA, PLATFORMS } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import { Grid, Typography, AppBar, Toolbar, Card, TextField, CardActions, Input, Avatar } from '@mui/material';
import CropWindow from '../CropWindow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PublishIcon from '@mui/icons-material/Publish';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { StyledCardMedia, StyledDiv } from '../SharedStyles';

const StyledRow = styled(Grid)({
  height: 40,
  marginTop: 5,
  marginBottom: 5,
  backgroundColor: 'rgba(243,243,243,0.37)'
});

class GoogleAdsCreativeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyDefaultText: '',
      showCropper: false,
      index: this.props.index
    };
  }

  getKey(base, imageIndex) {
    return base + this.props.index + '_' + imageIndex;
  }

  cancelCropWindow() {
    this.setState({ showCropper: false });
  }

  applyCropWindow(images) {
    images.forEach(image => {
      for (const type in image.crops) {
        this.props.onAddImages(this.props.index, image.crops[type].blob, image.crops[type].type, image.imageIndex);
      }
    });
    this.setState({ showCropper: false });
  }
  onAddTextRow(textIdx, idx, type) {
    this.props.onAddTextRow(textIdx, idx, type);
  }

  uploadMultipleFiles = e => {
    const fileObj = [];
    const fileArray = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    const adset = JSON.parse(JSON.stringify(this.props.group.ads[0]));
    let images_limit = GA.AD_IMAGE_LIMIT;
    let capacity = images_limit - adset.images.length;

    if (capacity < 0) {
      toast.error(`Ad has a limit - maximum 20 images.`);
    } else if (fileArray.length > capacity) {
      fileArray.splice(capacity, fileArray.length);
      toast.error(`Up to 20 images can be uploaded at a time.`);
    }
    this.setState({ imageFiles: fileArray, showCropper: true });
  };

  showHelperText = (idx, type, required) => {
    const { group } = this.props;
    const { longTitles, titles, texts } = group.ads[0];

    const text = type === 'longTitles' ? longTitles[idx] : type === 'titles' ? titles[idx] : texts[idx];

    switch (type) {
      case 'longTitles':
        return `${text.length}/${GA.MAX_LONG_TITLE_CHARS}`;
      case 'titles':
        if (!required) {
          return `${text.length}/${GA.MAX_TITLE_CHARS}`;
        } else if (required === 'shortTitles') {
          return `${text.length}/${GA.MAX_SHORT_TITLE_CHARS}`;
        }
        break;
      case 'texts':
        if (!required) {
          return `${text.length}/${GA.MAX_TEXT_CHARS}`;
        } else if (required === 'shortTexts') {
          return `${text.length}/${GA.MAX_SHORT_TEXT_CHARS}`;
        }
        break;
      default:
        break;
    }
  };
  isMaxCampType() {
    return this.props.options[GA.KEY_CAMPAIGN_TYPE] === 'PERFORMANCE_MAX';
  }

  renderAd(ad, idx) {
    // eslint-disable-next-line no-prototype-builtins
    // const fullPath = ad.images.length ? getFullPath(ad.images[0][GA.DESKTOP]) : null;
    return (
      <React.Fragment key={`fragment_ads_${idx}`}>
        <StyledRow item container sm={12} alignItems={'center'} justifyContent={'center'}>
          <Grid item sm={1}></Grid>
          <Grid item sm={11}>
            <Typography variant={'subtitle2'}>Youtube video url</Typography>
          </Grid>
        </StyledRow>
        <Grid item sm={12}>
          <TextField
            key={this.getKey('youtubeUrl', 0)}
            value={ad.youtubeUrl[0]}
            onChange={e => this.props.onChangeImageText(0, 'youtubeUrl', e)}
            onPaste={e => this.props.onPasteImageText(0, 'youtubeUrl', e)}
            variant="standard"
            placeholder={'Example: https://www.youtube.com/watch?v=2hhJXPBlk-s'}
            multiline
            fullWidth
            row="3"
          />
        </Grid>
        <StyledRow item container sm={12} alignItems={'center'} justifyContent={'center'}>
          <Grid item sm={1}></Grid>
          <Grid item sm={11}>
            <Typography variant={'subtitle2'}>Long headline</Typography>
          </Grid>
        </StyledRow>
        {ad.longTitles &&
          ad.longTitles.map((title, index) => (
            <React.Fragment key={`fragment_ad_${index}`}>
              <Grid item sm={!index ? 12 : 11}>
                <TextField
                  key={this.getKey('imageLongTitle', idx)}
                  value={title}
                  onChange={e => this.props.onChangeImageText(index, 'longTitles', e)}
                  onPaste={e => this.props.onPasteImageText(index, 'longTitles', e)}
                  maxLength={GA.MAX_LONG_TITLE_CHARS}
                  variant="standard"
                  // placeholder={'Long headline'}
                  multiline
                  fullWidth
                  row="3"
                  inputProps={{
                    maxLength: GA.MAX_LONG_TITLE_CHARS
                  }}
                  helperText={this.showHelperText(index, 'longTitles')}
                />
              </Grid>
              {!index ? null : (
                <Grid item sm={1} align={'right'}>
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label="menu"
                    onClick={() => this.props.handleDeleteTextRow(this.props.index, index, 'longTitles')}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </React.Fragment>
          ))}
        <StyledRow item container sm={12} alignItems={'center'} justifyContent={'center'}>
          {ad.titles && ad.titles.length < GA.MAX_TITLES_AMOUNT ? (
            <Grid item sm={1} align={'left'}>
              <IconButton
                color="primary"
                onClick={() => this.onAddTextRow(this.props.index, idx, 'titles')}
                size="large"
              >
                <AddCircleIcon fontSize={'small'} />
              </IconButton>
            </Grid>
          ) : null}
          <Grid item sm={11}>
            <Typography variant={'subtitle2'}>Headline</Typography>
          </Grid>
        </StyledRow>
        {ad.titles &&
          ad.titles.map((title, index) => (
            <React.Fragment key={`fragment_ad_${index}`}>
              <Grid item sm={11} key={`fragment_grid_${index}`}>
                <TextField
                  key={this.getKey('imageTitle', idx)}
                  value={title}
                  onChange={e => this.props.onChangeImageText(index, 'titles', e)}
                  onPaste={e => this.props.onPasteImageText(index, 'titles', e)}
                  maxLength={!index ? GA.MAX_SHORT_TITLE_CHARS : GA.MAX_TITLE_CHARS}
                  variant="standard"
                  multiline
                  fullWidth
                  placeholder={this.isMaxCampType() && !index ? 'Required one short headline' : ''}
                  row="3"
                  inputProps={{
                    maxLength: this.isMaxCampType() && !index ? GA.MAX_SHORT_TITLE_CHARS : GA.MAX_TITLE_CHARS
                  }}
                  helperText={this.showHelperText(
                    index,
                    'titles',
                    this.isMaxCampType() && !index ? 'shortTitles' : null
                  )}
                />
              </Grid>
              <Grid item sm={1} align={'right'}>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="menu"
                  onClick={() => this.props.handleDeleteTextRow(this.props.index, index, 'titles')}
                >
                  <DeleteForeverIcon fontSize={'small'} />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        <StyledRow item container sm={12} justifyContent={'center'} alignItems={'center'}>
          {ad.texts && ad.texts.length < GA.MAX_TEXTS_AMOUNT ? (
            <Grid item sm={1}>
              <IconButton
                color="primary"
                onClick={() => this.onAddTextRow(this.props.index, idx, 'texts')}
                size="large"
              >
                <AddCircleIcon fontSize={'small'} />
              </IconButton>
            </Grid>
          ) : null}
          <Grid item sm={11}>
            <Typography variant={'subtitle2'}>Description</Typography>
          </Grid>
        </StyledRow>
        {ad.texts &&
          ad.texts.map((title, index) => (
            <React.Fragment key={`fragment_ad_${index}`}>
              <Grid item sm={11}>
                <TextField
                  key={this.getKey('imageTexts', idx)}
                  value={title}
                  onChange={e => this.props.onChangeImageText(index, 'texts', e)}
                  onPaste={e => this.props.onPasteImageText(index, 'texts', e)}
                  maxLength={GA.MAX_TEXT_CHARS}
                  variant="standard"
                  placeholder={this.isMaxCampType() && !index ? 'Required one short description' : ''}
                  multiline
                  fullWidth
                  row="3"
                  inputProps={{
                    maxLength: this.isMaxCampType() && !index ? GA.MAX_SHORT_TEXT_CHARS : GA.MAX_TEXT_CHARS
                  }}
                  helperText={this.showHelperText(index, 'texts', this.isMaxCampType() && !index ? 'shortTexts' : null)}
                />
              </Grid>
              <Grid item xs={1} sm={1} align={'right'}>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="menu"
                  onClick={() => this.props.handleDeleteTextRow(this.props.index, index, 'texts')}
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        {ad.images &&
          ad.images.map((img, idx) => (
            <Grid item sm={4} key={'cardContainer' + idx}>
              <Card key={'card' + idx}>
                <StyledCardMedia
                  key={this.getKey('sep', idx)}
                  image={getFullPath(img.Desktop)}
                  title="Example"
                  component="div"
                />
                <CardActions disableSpacing>
                  <IconButton
                    color="primary"
                    aria-label="delete"
                    onClick={imageIdx => this.props.removeImage(idx, imageIdx)}
                    size="large"
                  >
                    <DeleteForeverIcon fontSize={'small'} />
                  </IconButton>

                  <IconButton
                    color="primary"
                    key={this.getKey('imageDuplicateButton', idx)}
                    onClick={imageIdx => this.props.duplicateImage(idx, imageIdx)}
                    size="large"
                  >
                    <FileCopyIcon fontSize={'small'} />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <StyledDiv>
          <AppBar position="static">
            <Toolbar>
              <Avatar>{this.props.index + 1}</Avatar>
              <Typography gutterBottom variant="subtitle2">
                {this.props.group.length}
              </Typography>
              <label htmlFor={'addImagesButton' + this.state.index}>
                <IconButton color="secondary" component="span" size="large">
                  <PublishIcon />
                </IconButton>
              </label>
              <Input
                type="file"
                id={'addImagesButton' + this.state.index}
                onChange={this.uploadMultipleFiles}
                inputProps={{ multiple: true }}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
              {this.props.options[GA.KEY_CAMPAIGN_TYPE] === 'PERFORMANCE_MAX' ? null : (
                <IconButton
                  key={this.getKey('groupDuplicateButton')}
                  color="secondary"
                  aria-label="duplicate"
                  onClick={idx => this.props.duplicateGroup(idx)}
                  size="large"
                >
                  <FileCopyIcon />
                </IconButton>
              )}
              {this.props.index < 1 ? null : (
                <IconButton
                  color="secondary"
                  aria-label="remove"
                  key={this.getKey('groupRemoveButton')}
                  onClick={idx => this.props.removeGroup(idx)}
                  size="large"
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        </StyledDiv>
        <Grid container direction="row">
          {this.state.showCropper ? (
            <CropWindow
              fromInsights={false}
              desktopAspectRatio={GA.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={GA.SMARTPHONE_ASPECT_RATIO}
              portraitAspectRatio={GA.PORTRAIT_ASPECT_RATIO}
              data={this.state.imageFiles}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              index={this.props.index}
              group={this.props.group.ads[0].images}
              requirePortraitCrop={
                this.props.options.campaignType && this.props.options.campaignType === 'PERFORMANCE_MAX'
              }
              platform={PLATFORMS.GOOGLE_ADS}
            />
          ) : null}
          {this.renderAd(this.props.group.ads[0], this.props.index)}
        </Grid>
      </React.Fragment>
    );
  }
}

export default GoogleAdsCreativeGroup;
