import React from 'react';
import { AppBar, Avatar, Input, Toolbar, Typography, styled, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';

const StyledDiv = styled('div')(({ theme }) => ({
  margin: '10px 3px 10px 3px',
  flexGrow: 1,
  '& .MuiAvatar-root': {
    fontSize: '12px',
    color: '#262626',
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '25px'
  },
  '& .MuiCard-root': {
    minWidth: 180,
    marginLeft: '1px',
    marginTop: '332px'
  },
  '& .MuiToolbar-root': {
    justifyContent: 'space-between'
  }
}));

const CreativesAppBar = props => {
  const getKey = (base, imageIdx) => {
    return base + props.index + '_' + imageIdx;
  };

  return (
    <StyledDiv>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" alignItems="center">
            <Avatar>{props.index + 1}</Avatar>
            <Typography gutterBottom variant="subtitle2">
              Creatives {props.images.length}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <label htmlFor={'addImagesButton' + props.index}>
              <IconButton color="secondary" component="span" size="large">
                <PublishIcon />
              </IconButton>
            </label>
            <Input
              type="file"
              id={'addImagesButton' + props.index}
              onChange={(imageIdx, e) => props.onUploadFile(imageIdx, e)}
              inputProps={{ multiple: true }}
              onClick={e => {
                e.target.value = null;
              }}
              style={{ display: 'none' }}
            />
            <IconButton
              key={getKey('groupDuplicateButton')}
              color="secondary"
              aria-label="duplicate"
              onClick={imageIdx => props.duplicateGroup(imageIdx)}
              size="large"
            >
              <FileCopyIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="remove"
              key={getKey('groupRemoveButton')}
              onClick={imageIdx => props.removeGroup(imageIdx)}
              size="large"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </StyledDiv>
  );
};

export default CreativesAppBar;
