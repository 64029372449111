import React from 'react';
import { TextareaAutosize, Grid, Typography } from '@mui/material';
import { PLATFORMS } from './Constants';

class CampaignsNote extends React.Component {
  render() {
    if (this.props.platform === PLATFORMS.FACEBOOK2) {
      return (
        <>
          <Grid item>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaigns note
            </Typography>
            <TextareaAutosize
              style={{ lineHeight: '27px', marginBottom: '-4px' }}
              value={this.props.value}
              maxLength={150}
              aria-label="minimum height"
              placeholder="Campaigns note"
              onChange={this.props.onChange}
              onKeyPress={this.props.onKeyPress}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaigns note
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextareaAutosize
              value={this.props.value}
              maxLength={150}
              aria-label="minimum height"
              placeholder="Campaigns note"
              onChange={this.props.onChange}
              onKeyPress={this.props.onKeyPress}
            />
          </Grid>
        </>
      );
    }
  }
}

export default CampaignsNote;
