import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { FB, PLATFORMS } from '../Constants';
import { getFullPath } from '../utils/imageUploadUtils';
import {
  Input,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  CardHeader,
  TextField,
  CardActions
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CropIcon from '@mui/icons-material/Crop';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import CropWindow from './CropWindow';
import PublishIcon from '@mui/icons-material/Publish';
import { StyledCardMedia, StyledDiv } from '../SharedStyles';

class CreativeUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textTooltip: [],
      titleTooltip: [],
      titleTooltipMobile: [],
      anchorEl: null,
      images: [],
      uploadedImages: [],
      src: [],
      showCropWindow: false,
      selectedImageIndex: 0
    };
  }

  handleTitleChange = (e, idx) => {
    const title = this.state.titleTooltip;
    const titleMobile = this.state.titleTooltipMobile;
    const charsLeft = FB.MAX_TITLE_MOBILE_LENGTH - e.target.value.length;
    title[idx] = `${e.target.value.length}/${FB.MAX_TITLE_LENGTH}`;
    titleMobile[idx] = {
      text: `${charsLeft}`,
      open: charsLeft >= 0 ? true : false
    };
    this.setState({ titleTooltip: title, titleTooltipMobile: titleMobile });
  };

  handleTextChange = (e, idx) => {
    const text = this.state.textTooltip;
    text[idx] = `${e.target.value.length}/${FB.MAX_TEXT_LENGTH}`;
    this.setState({ textTooltip: text });
  };

  populateText(index) {
    const creative = this.props.creative;
    creative.content.forEach((_, idx) => {
      creative.content[idx].text = creative.content[index].text;
      creative.content[idx].title = creative.content[index].title;
    });
    this.props.modifyCreative(this.props.index, creative);
  }

  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleDelete = idx => {
    this.removeContent(idx);
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  getKey(base, idx) {
    return base + this.props.index + '_' + idx;
  }

  copyContent(idx) {
    const creative = this.props.creative;
    creative.content.push({ ...creative.content[idx] });
    this.props.modifyCreative(this.props.index, creative);
  }
  removeContent(idx) {
    const creative = this.props.creative;
    creative.content.splice(idx, 1);
    this.props.modifyCreative(this.props.index, creative);
  }

  onChangeImageText(idx, event) {
    const creative = this.props.creative;
    creative.content[idx].text = event.target.value;
    this.handleTextChange(event, idx);
    this.props.modifyCreative(this.props.index, creative);
  }

  onChangeImageTitle(idx, event) {
    const creative = this.props.creative;
    creative.content[idx].title = event.target.value;
    this.handleTitleChange(event, idx);
    this.props.modifyCreative(this.props.index, creative);
  }

  onBlurImageText() {
    const creative = this.props.creative;
    this.handleTextChange();
    this.props.modifyCreative(this.props.index, creative);
  }

  removeImage(idx) {
    const creative = this.props.creative;
    creative.content.splice(idx, 1);
    this.props.modifyCreative(this.props.index, creative);
  }

  onSelectFileCrop = async ad => {
    const blob = await fetch(`/api/getImg?img=${encodeURIComponent(ad.image)}`).then(r => r.blob());
    this.readAsDataURL(blob).then(res => {
      this.setState({ src: res, showCropWindow: true });
    });
  };

  readAsDataURL = blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  onUploadFile = async (creativeIndex, e) => {
    this.props.onAddImage(creativeIndex, e);
  };

  onClose = () => {
    this.setState({ showCropWindow: false, src: '' });
  };

  showHelperTitle = idx => {
    const title = this.props.creative.content[idx].title;
    return `${title.length}/${FB.MAX_TITLE_LENGTH}
   Mobile char: ${title.length}/${FB.MAX_TITLE_MOBILE_LENGTH}`;
  };

  showHelperText = idx => {
    const text = this.props.creative.content[idx].text;
    return `${text.length}/${FB.MAX_TEXT_LENGTH}`;
  };

  renderImage(creative, idx) {
    const fullPath = getFullPath(creative.image);
    return (
      <React.Fragment key={this.getKey('sep', idx)}>
        <Grid item>
          <Card>
            <CardHeader
              subheader={
                <TextField
                  variant="standard"
                  multiline
                  minRows={1}
                  maxRows={10}
                  label="Text"
                  key={this.getKey('imageText', idx)}
                  value={creative.text}
                  onChange={event => this.onChangeImageText(idx, event)}
                  helperText={this.showHelperText(idx)}
                  inputProps={{
                    maxLength: FB.MAX_TEXT_LENGTH
                  }}
                />
              }
            />
            <StyledCardMedia key={this.getKey('image', idx)} image={fullPath} title="Example" />
            <CardContent>
              <TextField
                variant="standard"
                multiline
                minRows={1}
                maxRows={10}
                label="Title"
                key={this.getKey('imageTitle', idx)}
                value={creative.title}
                helperText={this.showHelperTitle(idx)}
                inputProps={{
                  maxLength: FB.MAX_TITLE_LENGTH
                }}
                onChange={event => this.onChangeImageTitle(idx, event)}
              />
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                color="primary"
                key={this.getKey('populateButton', idx)}
                onClick={() => this.populateText(idx)}
                title="Populate all texts in group"
                size="large"
              >
                <TextRotationNoneIcon />
              </IconButton>
              <IconButton
                aria-label="menu"
                color="primary"
                key={this.getKey('cropButton', idx)}
                onClick={() => this.onSelectFileCrop(creative, idx)}
                title="Crop image"
                size="large"
              >
                <CropIcon fontSize="small" />
              </IconButton>
              <IconButton
                key={this.getKey('copyButton', idx)}
                color="primary"
                type="button"
                onClick={() => this.copyContent(idx)}
                title="Copy ad"
                size="large"
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="menu"
                key={this.getKey('removeButton', idx)}
                onClick={() => this.handleDelete(idx)}
                title="Delete ad"
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <StyledDiv>
          <AppBar position="static">
            <Toolbar>
              <Typography gutterBottom variant="subtitle2">
                Creatives {this.props.creative.content.length}
              </Typography>
              <label htmlFor={'uploadImagesButton' + this.props.index}>
                <IconButton color="secondary" component="span" size="large">
                  <PublishIcon />
                </IconButton>
              </label>
              <Input
                type="file"
                id={'uploadImagesButton' + this.props.index}
                inputProps={{ multiple: true }}
                onChange={async e => await this.onUploadFile(this.props.index, e)}
                onClick={e => {
                  e.target.value = null;
                }}
                style={{ display: 'none' }}
              />
              <IconButton
                color="secondary"
                aria-label="menu"
                key={this.getKey('groupRemoveButton')}
                onClick={() => this.props.deleteCreative(this.props.index)}
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </StyledDiv>
        <Grid container direction="row" spacing={1}>
          {this.state.showCropWindow ? (
            <CropWindow
              key={this.getKey('crop')}
              onAddImage={(index, blob) => this.onUploadFile(index, blob)}
              src={this.state.src}
              adsetIndex={this.props.index}
              onClose={() => this.onClose()}
              platform={PLATFORMS.FACEBOOK}
            />
          ) : null}
          {this.props.creative.content.map((ad, idx) => this.renderImage(ad, idx))}
        </Grid>
      </React.Fragment>
    );
  }
}

export default CreativeUploader;
