import React, { Component } from 'react';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Grid, Typography, Button, FormControlLabel, Switch, Divider, Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import BaiduCreativeGroupBuilder from './BaiduCreativeGroupBuilder';
import { storeFiles, getBaiduCampaigns } from '../Api';
import { BD, GLOBAL, PLATFORMS, TASKS } from '../Constants';
import { parseCampaignName } from '../NameParser';
import WidgetSelector from '../WidgetSelector';
import { toast } from 'react-toastify';
import CampaignsNote from '../CampaignsNote';
import {
  truncateFileName,
  validateAndPrepareImage,
  displayWarnings,
  getImageDimensions
} from '../utils/imageUploadUtils';
import Prefix from '../Prefix';
import { normalizeCreative } from '../utils/creativesUtils';
import {
  getNewCreatorValue,
  getFileHash,
  getSelectValue,
  getOnlyValue,
  getMultiSelectValue,
  makeSelectOptions
} from '../Shared';
import BaiduBids from './BaiduBids';
import { getMaxCampIndex } from '../utils/commonUtils';
import CropWindow from '../CropWindow';
import { getPlatformSites } from '../Sites';

const DEVICES = [BD.DESKTOP, BD.ANDROID, BD.IOS];

class BaiduCreate extends Component {
  constructor(props) {
    super(props);
    const defaultOptions = {
      [BD.KEY_PREFIX]: '',
      [BD.KEY_BUDGET]: BD.DEFAULT_BUDGET,
      [BD.KEY_BUDGET_TYPE]: 'DAILY',
      [BD.KEY_DEFAULT_BID]: BD.DEFAULT_BID,
      [BD.KEY_COUNTRY]: BD.DEFAULT_COUNTRY,
      [BD.KEY_SITE]: BD.DEFAULT_SITE,
      [BD.KEY_DEVICE]: BD.DEFAULT_DEVICE,
      [BD.KEY_DEVICE]: BD.SMARTPHONE,
      [BD.KEY_BIDS]: [],
      [BD.KEY_LANGUAGE]: BD.DEFAULT_LANGUAGE,
      [BD.KEY_DAILY_CAP]: BD.DEFAULT_DAILY_CAP,
      [BD.KEY_SPEND_LIMIT]: BD.DEFAULT_SPEND_LIMIT,
      [BD.KEY_PACING]: BD.DEFAULT_PACING,
      [BD.KEY_PRODUCT_TYPE]: BD.DEFAULT_PRODUCT,
      [BD.KEY_CREATIVE_GROUPS]: [],
      [BD.KEY_TARGET_CPA]: BD.DEFAULT_TARGET_CPA,
      [BD.KEY_OPTIMIZATION_TYPE]: BD.DEFAULT_OPTIMIZATION_TYPE,
      [BD.KEY_BROWSER]: BD.ALL_BROWSERS,
      [BD.KEY_CAMPAIGN_START_TIME]: moment(),
      [BD.KEY_GEO_DUPLICATION_TYPE]: 'separate'
    };
    this.defaultState = {
      options: {
        ...defaultOptions,
        ...this.props.options,
        prefixes: [],
        prefix: ''
      },
      osChoices: [],
      tooltips: { text: '', open: false },
      campaigns: [],
      triggerSelectorUpdate: false,
      triggerRowUpdate: false
    };

    this.state = JSON.parse(JSON.stringify(this.defaultState));
  }

  updateUserOptions() {
    if (!this.props.loadedFromSnapshot) {
      const userOpts = this.props.programOptions;
      let prefix = userOpts.spm_prefix_for_baidu ? userOpts.spm_prefix_for_baidu : userOpts.spm_prefix_default;
      this.modifyOptions(BD.KEY_CREATOR, getNewCreatorValue(userOpts.email, this.props.creatorsList));
      this.modifyOptions(BD.KEY_PREFIX, prefix);
    }
  }
  componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      this.updateUserOptions();
    } else {
      this.modifyOptions(BD.KEY_DESTINATION_ACCOUNT, this.props.state.selectedAccount);
    }
  }

  getArticlesList() {
    return this.props.articleList;
  }
  getSourceData(options, sourceData) {
    let parsed = null;
    if (this.state.isBulk) {
      //For future
      console.log('bulk data');
    } else {
      console.log('source data');
      parsed = parseDuplicateData(options, sourceData);
    }
    console.log({ parsed });
    return parsed;
  }

  componentDidUpdate(prevProps) {
    if (this.props.task !== prevProps.task && this.props.task === TASKS.CREATE) {
      this.setDefaultOptions();
    }
    if (this.props.sourceData !== prevProps.sourceData) {
      const newOptions = this.getSourceData(this.state.options, this.props.sourceData);
      this.setState(
        {
          options: newOptions,
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate,
          triggerRowUpdate: !this.state.triggerRowUpdate
        },
        () => {
          this.modifyBidsRows();
        }
      );
    }
    if (this.props.triggerRowUpdate !== prevProps.triggerRowUpdate) {
      this.modifyBidsRows();
      if (this.state.options.campaign_name) {
        const campIndex = this.state.options.campaign_name.split('-')[6];
        if (campIndex && campIndex.includes('c')) {
          return;
        }
        if (!campIndex) {
          this.onAddManualImages(0, this.state.options[BD.KEY_CREATIVE_GROUPS][0].images);
        }
      }
    }
    if (this.props.creative) {
      this.props.creativeDone();
      const parsedName = parseCampaignName(
        PLATFORMS[this.props.creative.platform.toUpperCase()],
        this.props.creative.name
      );
      this.modifyOptions(BD.KEY_COUNTRY, parsedName.country || '');
      this.modifyOptions(BD.KEY_SITE, parsedName.site || []);
      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(BD.KEY_DEVICE, BD.DESKTOP);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(BD.KEY_DEVICE, [BD.DESKTOP, BD.SMARTPHONE].join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(BD.KEY_DEVICE, BD.SMARTPHONE);
      } else {
        if (parsedName.platform && parsedName.platform.indexOf('adr') !== -1) {
          this.modifyOptions(BD.KEY_DEVICE, BD.SMARTPHONE);
        } else if (parsedName.platform && parsedName.platform.indexOf('ios') !== -1) {
          this.modifyOptions(BD.KEY_DEVICE, BD.SMARTPHONE);
        } else {
          this.modifyOptions(BD.KEY_DEVICE, [BD.DESKTOP, BD.SMARTPHONE].join(','));
        }
      }

      const creative = normalizeCreative(this.props.creative);

      this.addCreatives([
        {
          images: creative.image_url,
          titles: creative.title,
          tooltips: []
        }
      ]);
    }
    if (this.props.creativeFromInsights && !this.state.creativeFromInsightsAdded) {
      let creative = this.props.creativeFromInsights;
      const xs = [
        {
          images: [creative.image_url].flat(),
          titles: [creative.title].flat()
        }
      ];
      if (this.props.state.campaignCreator) {
        this.modifyOptions(BD.KEY_CREATOR, this.props.state.campaignCreator);
      }
      this.setState({ creativeFromInsightsAdded: true }, () => this.setState({ showCropWindow: true, creatives: xs }));
    }
  }

  async addCreatives(xs, images) {
    let adset = '';
    const groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    if (this.props.creativeFromInsights) {
      adset = { images: [], titles: xs[0].titles };
      for (const [adsetIndex, x] of xs.entries()) {
        for (const [imageIndex] of x.images.entries()) {
          const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
          const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
          for (const crop of nonEmpyCrops) {
            adset.images.push(crop);
          }
        }
      }
    } else {
      adset = { images: [], titles: [] };
      for (const [adsetIndex, x] of xs.entries()) {
        for (const title of x.titles) {
          for (const [imageIndex] of x.images.entries()) {
            const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
            const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
            for (const crop of nonEmpyCrops) {
              adset.images.push(crop);
              adset.titles.push(title);
            }
          }
        }
      }
    }

    const newGroup = {
      ...adset,
      images: [],
      tooltips: []
    };

    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, [...groups, newGroup]);
    setTimeout(async () => {
      images.forEach(image => {
        for (const type in image.crops) {
          this.onAddImages(0, image.crops[type].blob, image.crops[type].type, image.imageIndex);
        }
      });
    });
  }

  getBrandingText(domain) {
    const site = getPlatformSites(PLATFORMS.BAIDU.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.branding;
    } else {
      throw new Error("Can't find site corresponding to 3-letter domain" + domain);
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.BAIDU.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
  }

  validateOptions(key) {
    if (key === BD.KEY_DEFAULT_BID) {
      const value = this.state.options[key];
      if (value < BD.MIN_BID) {
        console.log('Min bid reached');
        this._modifyOptions(key, BD.MIN_BID);
        return;
      }
      if (value > BD.MAX_BID) {
        console.log('Max bid reached');
        this._modifyOptions(key, BD.MAX_BID);
        return;
      }
    }
  }
  _modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  modifyOptions(key, value) {
    if (
      key === BD.KEY_DEFAULT_BID ||
      key === BD.KEY_SPEND_LIMIT ||
      key === BD.KEY_DAILY_CAP ||
      key === BD.KEY_TARGET_CPA
    ) {
      if (value.target) {
        value = value.target.valueAsNumber;
      }
    }
    if (
      key === BD.KEY_BUDGET ||
      key === BD.KEY_PREFIX ||
      key === 'campName' ||
      key === BD.KEY_PACING ||
      key === BD.KEY_OBJECTIVE ||
      key === BD.KEY_OPTIMIZATION_TYPE ||
      key === BD.KEY_PRODUCT_TYPE
    ) {
      // It's an "input" element
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === BD.KEY_DESTINATION_ACCOUNT) {
      this._modifyOptions(BD.KEY_DESTINATION_ACCOUNT, value);
      getBaiduCampaigns(value).then(camps => {
        this.setState({ campaigns: camps });
      });
    }
    if (key === BD.KEY_SITE) {
      //  this._modifyOptions(BD.KEY_BRANDING_TEXT, this.getBrandingText(value));
    }
    this._modifyOptions(key, value);
    if (key === BD.KEY_DEVICE) {
      let devices = value;
      if (typeof devices === 'string') {
        devices = devices.split(',');
      }
      let newValue = [];
      this._modifyOptions(BD.KEY_OS, newValue);
      this.setState({
        osChoices: newValue
      });
    }
    if (
      key === BD.KEY_COUNTRY ||
      key === BD.KEY_DESTINATION_ACCOUNT ||
      key === BD.KEY_DEVICE ||
      key === BD.KEY_OS ||
      key === BD.KEY_SITE ||
      key === BD.KEY_GEO_DUPLICATION_TYPE ||
      key === BD.KEY_COUNTRY_GROUP ||
      key === BD.KEY_BROWSER
    ) {
      // Update bid rows
      this.modifyBidsRows();
    }
    if (key === BD.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
  }

  modifyBidsRows() {
    if (
      !this.state.options[BD.KEY_COUNTRY] ||
      !this.state.options[BD.KEY_DEVICE] ||
      !this.state.options[BD.KEY_SITE] ||
      !this.state.options[BD.KEY_BROWSER]
    ) {
      return;
    }
    // for some reason some of the values are arrays, some are strings
    //* in Select input - value type is string, when select all - array */
    let geos = this.state.options[BD.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    let devices = this.state.options[BD.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }
    // let oss = this.state.options[BD.KEY_OS];
    // if (typeof oss === 'string') {
    //   oss = oss.split(',');
    // }
    let sites = this.state.options[BD.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    let browsers = this.state.options[BD.KEY_BROWSER];
    if (typeof browsers === 'string') {
      browsers = browsers.split(',');
    }
    if (this.state.options[BD.KEY_GEO_DUPLICATION_TYPE] === 'group') {
      // This geos should be treated like a group
      geos = [geos.join(',')];
    }
    let newBids = [];
    geos.forEach(geo => {
      sites.forEach(site => {
        devices.forEach(dev => {
          // special handling of DESKTOP, no OSs here
          newBids.push({
            country: geo,
            device: dev,
            site: site,
            fullSite: this.getFullSite(site),
            branding_text: this.getBrandingText(site),
            bid: this.state.options[BD.KEY_DEFAULT_BID],
            dailyCap: this.state.options[BD.KEY_DAILY_CAP],
            spendLimit: this.state.options[BD.KEY_SPEND_LIMIT],
            browser: browsers
          });
        });
      });
    });
    this._modifyOptions(BD.KEY_BIDS, newBids);
  }

  modifyBid(idx, e) {
    const bid = e.target.valueAsNumber;
    let bids = this.state.options[BD.KEY_BIDS];
    bids[idx].bid = bid;
    this._modifyOptions(BD.KEY_BIDS, bids);
    let warningBids = bids.filter(el => {
      return el.bid > BD.MAX_WARN_BID;
    });
    this._modifyOptions('warnings', warningBids);
  }

  validateBid(idx) {
    let bids = this.state.options[BD.KEY_BIDS];
    const bid = bids[idx].bid;
    if (bid < BD.MIN_BID) {
      bids[idx].bid = BD.MIN_BID;
      this._modifyOptions(BD.KEY_BIDS, bids);
    } else if (bid > BD.MAX_BID) {
      bids[idx].bid = BD.MAX_BID;
      this._modifyOptions(BD.KEY_BIDS, bids);
    }
  }

  setDefaultBid(val) {
    let bids = this.state.options[BD.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < BD.MIN_BID) {
        b.bid = BD.MIN_BID;
      } else if (val > BD.MAX_BID) {
        b.bid = BD.MAX_BID;
      }
    });
    this._modifyOptions(BD.KEY_BIDS, bids);
  }

  validateCreativeGroups() {
    let errors = [];
    const groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    if (groups.length === 0) {
      errors.push(`Group is empty.`);
    }
    groups.forEach((gr, groupIdx) => {
      // Check empty groups
      if (gr.images.length === 0) {
        errors.push(`Group ${groupIdx + 1} is empty.`);
      }
      if (gr.images.length > 10) {
        errors.push(`Can contain at most 10 creatives. Please remove ${gr.images.length - 10} creatives`);
      }
      if (gr.titles.length === 0) {
        errors.push(`Titles ${groupIdx + 1} is empty.`);
      }
      gr.titles.forEach((title, titleIdx) => {
        if (!title) {
          errors.push(`Creative ${titleIdx + 1}  Title is empty.`);
        }
      });
    });
    if (errors.length === 0) {
      return {
        isValid: true
      };
    } else {
      return {
        isValid: false,
        data: errors
      };
    }
  }

  onAddImages(groupIdx, imageBlob, deviceType, index) {
    const images = [imageBlob];
    const errs = [];
    const warnings = [];

    const formData = new FormData();
    let targetingDevice = '';
    let basicValidatedImages = [];
    const device = deviceType;

    let options = {};
    if (this.state.options.device) {
      if (typeof this.state.options.device === 'string') {
        targetingDevice = this.state.options.device;
      } else if (this.state.options.device[0]) {
        targetingDevice = this.state.options.device[0];
      }
    }
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        if (device) {
          images.forEach((file, i) => {
            const filename_truncated = truncateFileName(file.name);

            if (
              !validateAndPrepareImage(
                file,
                filename_truncated,
                dimensions[i],
                groupIdx,
                'BD',
                warnings,
                errs,
                deviceType,
                options
              )
            ) {
              return;
            }

            formData.append(i, file, file.name);
            basicValidatedImages.push(file);
          });
        } else {
          warnings.push('Please, select the targeting device first.');
        }

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) return;
        const scaledOptions = {
          minWidth: options.cropWidth,
          minHeight: options.cropHeight
        };
        return storeFiles(formData, scaledOptions);
      })
      .then(filenames => {
        if (filenames) {
          console.log('filenames', filenames);
          let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
          if (!groups[groupIdx].images[index]) {
            groups[groupIdx].images[index] = {};
          }
          groups[groupIdx].images[index][deviceType] = filenames[0];
          groups[groupIdx].device = targetingDevice;
          this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }
  onAddManualImages(groupIdx, data) {
    const errs = [];
    const warnings = [];
    const formData = new FormData();

    let basicValidatedImages = [];
    return Promise.all(
      data.map(file => {
        return fetch(file).then(b => b.blob());
      })
    )
      .then(files => {
        files.forEach((file, idx) => {
          formData.append(idx, file, 'newFile.jpg');
          basicValidatedImages.push(file);
        });

        if (displayWarnings(errs, warnings)) return;
        const scaledOptions = {
          minWidth: 600,
          minHeight: 400
        };
        return storeFiles(formData, scaledOptions);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
          if (!groups[groupIdx].images) {
            return;
          }
          groups[groupIdx].images.map((img, index) => {
            groups[groupIdx].images[index] = filenames[index];
          });
          this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  onChangeImageText(groupIdx, imageIdx, type, e) {
    const text = e.target.value;
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    groups[groupIdx][type][imageIdx] = text;
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  removeImage(groupIdx, imageIdx) {
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.splice(imageIdx, 1);
    groups[groupIdx].titles.splice(imageIdx, 1);
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateImage(groupIdx, imageIdx) {
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    const duplicatedImage = groups[groupIdx].images[imageIdx];
    const duplicatedTitle = groups[groupIdx].titles[imageIdx];
    groups[groupIdx].images.push(duplicatedImage);
    groups[groupIdx].titles.push(duplicatedTitle);
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(JSON.parse(JSON.stringify(groups[groupIdx])));
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.forEach((_, idx) => {
      groups[groupIdx].titles[idx] = groups[groupIdx].titles[imageIdx];
    });
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  addCreativeGroup() {
    let groups = this.state.options[BD.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      images: [],
      titles: []
    });
    this.modifyOptions(BD.KEY_CREATIVE_GROUPS, groups);
  }

  upsertSnapshot() {
    const options = this.state.options;
    this.props.upsertSnapshot(options);
  }

  startCreateCampaign() {
    const options = this.state.options;
    if (this.props.task === TASKS.CREATE) {
      this.props.createCampaign(options);
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.duplicateCampaign();
    }
  }

  getCampaignsNumber() {
    return this.state.options[BD.KEY_BIDS].length * this.state.options[BD.KEY_CREATIVE_GROUPS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    return BD.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    let res = parts.filter(el => el).join('-') + ' = ' + bid.bid;
    return res;
  }

  validateSetup() {
    // Check that we have campaigns to create
    if (!this.getCampaignsNumber()) {
      const err = 'Select all required fields';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!this.state.options.widgetid) {
      const err = 'Widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }
    if (!this.state.options.productType) {
      const err = 'Choose at least one product type';
      console.warn(err);
      toast.error(err);
      return false;
    }
    // Check creative group validity
    const validationInfo = this.validateCreativeGroups();
    if (validationInfo.isValid) {
      console.log('Creative groups are valid');
    } else {
      console.log('Creative groups are not invalid', validationInfo.data);
      validationInfo.data.forEach(err => {
        toast.error(err);
      });
      return false;
    }
    // Check budget validity
    let budgetToBidRatio = true;
    this.state.options.bids.forEach(el => {
      const budget = this.state.options.budget;
      const bid = el.bid;
      const ratio = budget / bid;
      if (ratio < 50) {
        budgetToBidRatio = false;
      }
    });

    if (!budgetToBidRatio) {
      const err = 'Budget are invalid, bids should fulfill a budget-to-bid ratio of 50:1';
      console.log(err);
      toast.error(err);
      return false;
    }

    if (this.state.options[BD.KEY_BUDGET_TYPE] === 'LIFETIME' && !this.state.options[BD.KEY_CAMPAIGN_STOP_TIME]) {
      const err = 'Please, select campaign end date';
      console.log(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[BD.KEY_CREATOR]) {
      const err = 'Please, select campaign creator';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (
      (this.state.options[BD.KEY_OBJECTIVE] === 'lead' || this.state.options[BD.KEY_OBJECTIVE] === 'conversions') &&
      !this.state.options[BD.KEY_TARGET_CPA]
    ) {
      const err = 'Please, select target CPA';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (
      (this.state.options[BD.KEY_OBJECTIVE] === 'lead' || this.state.options[BD.KEY_OBJECTIVE] === 'conversions') &&
      !this.state.options[BD.KEY_OPTIMIZATION_TYPE]
    ) {
      const err = 'Please, select optimization type';
      console.log(err);
      toast.error(err);
      return false;
    }
    return true;
  }

  constructCampaignName(options) {
    let oldNaming = {};
    let newCampaignIndex = 1;
    const parts = [];
    let device = '';
    parts.push(options.prefix || oldNaming.prefix);
    if (options.country) {
      parts.push(options.country[0] || oldNaming.country);
    }
    if (options.device) {
      if (typeof options.device === 'object') {
        device = options.device[0];
      } else {
        device = options.device;
      }
      if (device) {
        device = device[0];
      }
      switch (device) {
        case 'desktop':
          parts.push('d');
          break;
        case 'android':
          parts.push('m');
          break;
        case 'ios':
          parts.push('m');
          break;
        default:
          break;
      }
    }
    if (typeof options.site === 'string') {
      options.site = options.site.split(',');
    }
    if (options.site && options.site[0]) {
      parts.push(options.site[0] || oldNaming.site);
    }
    parts.push(options.widgetCodeName + options.widgetLanguageCode || oldNaming.article || 'ARTICLE');
    if (options.startTime) {
      parts.push(moment(options.startTime).format('DDMMYY'));
    }
    if (this.state.campaigns[0]) {
      newCampaignIndex = getMaxCampIndex(this.state.campaigns) + 1;
    }
    parts.push('c' + newCampaignIndex);
    if (this.state.options.freeText) {
      parts.push(this.state.options.freeText);
    }
    return parts.join('-');
  }

  onChangeFreeText(e) {
    let text = e.target.value;
    this._modifyOptions('freeText', text);
    const newCampName = this.constructCampaignName(this.state.options);
    let tooltips = '';
    const charsLeft = BD.MAX_CAMP_NAME_LENGTH - newCampName.length;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    const nonLetterNumber = /[^a-z0-9]+/i;
    if (text.match(nonLetterNumber)) {
      tooltips.text = 'Only letters and numbers';
      text = text.replace(nonLetterNumber, '');
      this._modifyOptions('freeText', text);
      this.setState({ tooltips });
      return;
    }
    if (charsLeft < 0) {
      text = text.slice(0, -1);
      tooltips.text = `Chars left: 0`;
    }
    this._modifyOptions('freeText', text);
    this.setState({ tooltips: tooltips });
  }
  getAccounts() {
    return this.props.accounts.map(e => ({ value: e.account_id || e.id, label: e.account_name || e.desc }));
  }
  onBlurFreeText() {
    let tooltips = this.state.tooltips;
    tooltips = {
      open: false
    };
    this.setState({ tooltips: tooltips });
  }

  getProducts(products) {
    return products.map(el => ({ label: el, value: el }));
  }

  submit() {
    if (!this.validateSetup()) {
      return;
    }
    console.log(this.state.options);

    let title = '';
    let text = null;

    title = this.getCreateButtonText();
    text = 'Are you sure to do this?';

    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  cancelCropWindow() {
    this.setState({ showCropWindow: false });
  }

  applyCropWindow(images) {
    this.addCreatives(this.state.creatives, images);
    this.setState({ showCropWindow: false });
  }

  renderCreateDuplicate() {
    let exampleName = '';
    if (this.state.options) {
      exampleName = this.constructCampaignName(this.state.options);
    }

    return (
      <>
        <Prefix
          value={{ label: this.state.options[BD.KEY_PREFIX], value: this.state.options[BD.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(BD.KEY_PREFIX, x.value)}
          options={makeSelectOptions(this.props.state.programOptions?.prefix)}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Creator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(this.props.creatorsList, this.state.options[BD.KEY_CREATOR])}
            onChange={x => this.modifyOptions(BD.KEY_CREATOR, x.value)}
            options={this.props.creatorsList}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(this.getAccounts(), this.state.options[BD.KEY_DESTINATION_ACCOUNT])}
            onChange={x => this.modifyOptions(BD.KEY_DESTINATION_ACCOUNT, x.value)}
            options={this.getAccounts()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            isMulti
            isClearable
            onChange={xs => this.modifyOptions(BD.KEY_COUNTRY, getOnlyValue(xs))}
            value={getMultiSelectValue(BD.STATE_LIST, this.state.options[BD.KEY_COUNTRY])}
            options={BD.STATE_LIST}
          />
        </Grid>
        <Grid item xs={4} sm={4} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[BD.KEY_GEO_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(BD.KEY_GEO_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.state.options[BD.KEY_GEO_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select language
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(BD.KEY_LANGUAGE, x?.value)}
            value={getSelectValue(BD.LANGUAGE_LIST, this.state.options[BD.KEY_LANGUAGE])}
            options={BD.LANGUAGE_LIST}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(BD.KEY_SITE, getOnlyValue(xs))}
            options={this.props.siteList}
            value={getMultiSelectValue(this.props.siteList, this.state.options[BD.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            isMulti
            closeOnSelect={false}
            onChange={xs => this.modifyOptions(BD.KEY_DEVICE, getOnlyValue(xs))}
            options={BD.DEVICES}
            isClearable
            value={getMultiSelectValue(BD.DEVICES, this.state.options[BD.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => this.modifyOptions(BD.KEY_DEVICE, DEVICES)}
          >
            Select all
          </Button>
        </Grid>
        <WidgetSelector
          modify={(key, value) => this.modifyOptions(key, value)}
          articleList={this.getArticlesList()}
          options={this.state.options}
          allowedVersions={this.getAllowedWidgetVersions()}
          defaultVersion={BD.DEFAULT_VERSION}
          triggerSelectorUpdate={this.state.triggerSelectorUpdate}
          programOptions={this.props.programOptions}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select browser
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            closeMenuOnSelect={false}
            isMulti
            onChange={xs => this.modifyOptions(BD.KEY_BROWSER, getOnlyValue(xs))}
            options={BD.BROWSERS}
            isClearable
            value={getMultiSelectValue(BD.BROWSERS, this.state.options[BD.KEY_BROWSER])}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => this.modifyOptions(BD.KEY_BROWSER, BD.ALL_BROWSERS)}
          >
            Select all
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select product type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(BD.KEY_PRODUCT_TYPE, x?.value)}
            options={BD.PRODUCT_TYPE}
            value={
              this.state.options[BD.KEY_PRODUCT_TYPE]
                ? {
                    label: this.state.options[BD.KEY_PRODUCT_TYPE],
                    value: this.state.options[BD.KEY_PRODUCT_TYPE]
                  }
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select objective
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            onChange={x => this.modifyOptions(BD.KEY_OBJECTIVE, x?.value)}
            options={BD.OBJECTIVE}
            value={getSelectValue(BD.OBJECTIVE, this.state.options[BD.KEY_OBJECTIVE])}
          />
        </Grid>
        {this.state.options[BD.KEY_OBJECTIVE] === 'lead' || this.state.options[BD.KEY_OBJECTIVE] === 'conversions' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Target CPA
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <input
                type="number"
                name={BD.KEY_TARGET_CPA}
                step={BD.TARGET_CPA_STEP}
                value={this.state.options[BD.KEY_TARGET_CPA]}
                onChange={value => this.modifyOptions(BD.KEY_TARGET_CPA, value)}
                onBlur={() => this.validateOptions(BD.KEY_TARGET_CPA)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Select optimization type
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                isClearable
                onChange={x => this.modifyOptions(BD.KEY_OPTIMIZATION_TYPE, x?.value)}
                options={BD.OPTIMIZATION_TYPE}
                value={getSelectValue(BD.OPTIMIZATION_TYPE, this.state.options[BD.KEY_OPTIMIZATION_TYPE])}
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Pacing
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={e => this.modifyOptions(BD.KEY_PACING, e.value)}
            value={getSelectValue(BD.PACING, this.state.options[BD.KEY_PACING])}
            options={BD.PACING}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign start date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            selected={
              this.state.options[BD.KEY_CAMPAIGN_START_TIME]
                ? new Date(this.state.options[BD.KEY_CAMPAIGN_START_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(BD.KEY_CAMPAIGN_START_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            minDate={moment().toDate()}
          />
        </Grid>

        {this.state.options.budgetType === 'LIFETIME' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Campaign end date
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DatePicker
                selected={
                  this.state.options[BD.KEY_CAMPAIGN_STOP_TIME]
                    ? new Date(this.state.options[BD.KEY_CAMPAIGN_STOP_TIME])
                    : ''
                }
                onChange={value => this.modifyOptions(BD.KEY_CAMPAIGN_STOP_TIME, value)}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={
                  this.state.options[BD.KEY_CAMPAIGN_START_TIME]
                    ? moment(this.state.options[BD.KEY_CAMPAIGN_START_TIME])
                        .add(1, 'day')
                        .toDate()
                    : moment()
                        .add(1, 'day')
                        .toDate()
                }
              />
            </Grid>
          </>
        ) : null}
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(BD.KEY_STATUS, x.value)}
              options={BD.STATUS}
              value={getSelectValue(BD.STATUS, this.state.options[BD.KEY_STATUS])}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Daily spend cap
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="number"
              name={BD.KEY_DAILY_CAP}
              value={this.state.options[BD.KEY_DAILY_CAP]}
              onChange={value => this.modifyOptions(BD.KEY_DAILY_CAP, value)}
              onBlur={() => this.validateOptions(BD.KEY_DAILY_CAP)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Spend limit
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="number"
              name={BD.KEY_SPEND_LIMIT}
              value={this.state.options[BD.KEY_SPEND_LIMIT]}
              onChange={value => this.modifyOptions(BD.KEY_SPEND_LIMIT, value)}
              onBlur={() => this.validateOptions(BD.KEY_SPEND_LIMIT)}
            />
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Typography gutterBottom variant={'subtitle2'}>
              Bids
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Default bid budget
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="number"
              name={BD.KEY_DEFAULT_BID}
              step={BD.BID_STEP}
              value={this.state.options[BD.KEY_DEFAULT_BID]}
              onChange={value => this.modifyOptions(BD.KEY_DEFAULT_BID, value)}
              onBlur={() => this.validateOptions(BD.KEY_DEFAULT_BID)}
            />
          </Grid>
          <BaiduBids
            bids={this.state.options[BD.KEY_BIDS]}
            modifyBid={(idx, e) => this.modifyBid(idx, e)}
            validateBid={idx => this.validateBid(idx)}
          />
          <CampaignsNote
            value={this.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
            onChange={e => {
              this._modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
            }}
          />

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Example campaign name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography gutterBottom variant={'subtitle2'}>
              {exampleName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Free text
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Tooltip key={'tooltip'} title={this.state.tooltips ? this.state.tooltips.text : ''}>
              <textarea
                key={'freeText' + this.props.key}
                value={this.state.options.freeText}
                onChange={e => this.onChangeFreeText(e)}
                onBlur={() => this.onBlurFreeText()}
                maxLength={40}
                rows="1"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography gutterBottom variant={'subtitle2'}>
              Creative Groups:
            </Typography>
          </Grid>
          {this.state.options[BD.KEY_CREATIVE_GROUPS] < 2 ? (
            <Grid item xs={6} sm={4} align="right">
              <Button fullWidth variant="contained" color="primary" onClick={() => this.addCreativeGroup()}>
                Add Campaign
              </Button>
            </Grid>
          ) : null}
          <BaiduCreativeGroupBuilder
            groups={this.state.options[BD.KEY_CREATIVE_GROUPS]}
            onAddImages={(groupIdx, imageBlob, deviceType, index) =>
              this.onAddImages(groupIdx, imageBlob, deviceType, index)
            }
            onChangeImageText={(groupIdx, imageIdx, type, e) => this.onChangeImageText(groupIdx, imageIdx, type, e)}
            onBlurImageText={(groupIdx, imageIdx) => this.onBlurImageText(groupIdx, imageIdx)}
            onPasteImageText={(groupIdx, imageIdx, type, e) => this.onPasteImageText(groupIdx, imageIdx, type, e)}
            removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
            duplicateImage={(groupIdx, imageIdx) => this.duplicateImage(groupIdx, imageIdx)}
            removeGroup={groupIdx => this.removeGroup(groupIdx)}
            duplicateGroup={groupIdx => this.duplicateGroup(groupIdx)}
            populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
            options={this.state.options}
          />
          {this.state.showCropWindow ? (
            <CropWindow
              fromInsights={true}
              desktopAspectRatio={BD.DESKTOP_ASPECT_RATIO}
              smartphoneAspectRatio={BD.SMARTPHONE_ASPECT_RATIO}
              data={this.state.creatives}
              onClose={() => this.cancelCropWindow()}
              onSave={images => this.applyCropWindow(images)}
              platform={PLATFORMS.BAIDU}
            />
          ) : null}
          <Grid item xs={12} sm={4} align="right"></Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
              {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
              {this.getCreateButtonText()}
            </Button>
          </Grid>
        </>
      </>
    );
  }

  render() {
    return this.renderCreateDuplicate();
  }
}

function parseDuplicateData(options, data) {
  if (!data || data.length === 0) {
    toast.error('No data found');
    return options;
  }
  try {
    options[GLOBAL.KEY_CAMPAIGN_NOTE] = data.campaignNote;
    options[BD.KEY_CREATIVE_GROUPS] = [];
    let sourceGroup = {
      images: [],
      titles: []
    };
    data.ad.map(a => {
      sourceGroup.images.push(a.img);
      sourceGroup.titles.push(a.headline);
    });
    options[BD.KEY_CREATIVE_GROUPS].push(sourceGroup);
    options[BD.KEY_BIDS] = [];

    const parsedName = parseCampaignName(PLATFORMS.BAIDU, data.campaign_name);
    options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
    options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
    options[BD.KEY_SITE] = BD.DEFAULT_SITE;
    options[BD.KEY_COUNTRY] = parsedName.country;
    options[BD.KEY_DEVICE] = parsedName.platform === 'm' ? BD.SMARTPHONE : BD.DESKTOP;
    options[BD.KEY_LANGUAGE] = data.language;
    options[BD.KEY_BUDGET_TYPE] = 'daily';
    options[BD.KEY_BUDGET] = BD.DEFAULT_BUDGET;
    options[BD.KEY_DAILY_CAP] = BD.DEFAULT_DAILY_CAP;
    options[BD.KEY_SPEND_LIMIT] = BD.DEFAULT_SPEND_LIMIT;
    options.campaign_name = data.campaign_name;
    options[BD.KEY_PREFIX] = parsedName.prefix;
    return options;
  } catch (e) {
    console.error(`can't parse source date`, e);
  }
}

export { BaiduCreate };
