import { FB } from './Constants';

export function prepareAccounts(accs) {
  // Add empty account
  const accsPlusEmpty = [{ account_id: '', desc: '' }].concat(accs);
  // Sort by description
  accsPlusEmpty.sort((l, r) => {
    if (l.desc === r.desc) {
      return 0;
    } else {
      return l.desc < r.desc ? -1 : 1;
    }
  });
  return accsPlusEmpty.map(el => {
    let description = el.desc;
    return {
      id: el.account_id,
      desc: description,
      prefix: el.prefix,
      currency: el.currency,
      currency_mul: el.currency_mul
    };
  });
}

function getNewNumber(elements, parserFunc, fieldName) {
  // Start numbering with 1
  if (elements.length === 0) {
    return 1;
  }
  let parsed = elements.map(as => parserFunc(as.desc)).filter(Boolean);
  const numbers = parsed.map(as => as[fieldName] || 0);
  if (numbers.length === 0) {
    console.log('No valid campaign names for this account');
    return 1;
  }
  const max = numbers.reduce((a, b) => {
    return Math.max(a, b);
  });
  return max;
}

export function getNewCampaignNumber(campaigns) {
  return getNewNumber(campaigns, parseCampaignName, 'campaign');
}

export function getNewAdsetNumber(adsets) {
  return getNewNumber(adsets, parseAdSetName, 'adset');
}

function parseCampaignName(name) {
  // Template: fb-us-m-omg-article-g1-c2
  if (!name) {
    return '';
  }
  const elements = name.split('-');
  if (elements.length < 7) {
    return;
  }
  return {
    prefix: elements[0],
    country: elements[1],
    platform: elements[2],
    site: elements[3],
    article: elements[4],
    accountPrefix: elements[5].substring(0, elements[5].search(/\d/)),
    account: Number(elements[5].substring(elements[5].search(/\d/))),
    fullAccountName: elements[5],
    campaign: Number(elements[6].substring(elements[6].search(/\d/)))
  };
}

export function constructCampaignName(original_campaign, accNumber, campaignNumber, options) {
  const parsed = parseCampaignName(original_campaign);
  if (!parsed || !campaignNumber) {
    return '';
  }
  const parts = constructNameHelper(parsed, accNumber, campaignNumber, options);
  return parts.join('-');
}

export function constructAdSetName(original_adset, accNumber, campaignNumber, adsetNumber, options) {
  const parsed = parseAdSetName(original_adset);
  if (!parsed || !campaignNumber || !adsetNumber) {
    return '';
  }
  const parts = constructNameHelper(parsed, accNumber, campaignNumber, options);
  const adsetName = 'a' + adsetNumber;
  return parts.concat(adsetName).join('-');
}

function constructNameHelper(parsed, accNumber, campaignNumber, options) {
  let platformLetter = options.platform ? getPlatformLetter(options.platform) : parsed.platform;
  if (options.platform === 'the same as original') {
    platformLetter = parsed.platform;
  }
  let accountName = null;
  if (options.account || options.accountName) {
    accountName = options.account || options.accountName;
  } else if (Number.isNaN(accNumber) || isNaN(accNumber)) {
    accountName = parsed.fullAccountName;
  } else {
    accountName = accNumber;
  }
  const campaignName = 'c' + campaignNumber;
  let countryCode = null;
  if (options.geoname || options.geoName) {
    countryCode = options.geoname || options.geoName;
  } else if (options.country === 'the same as original') {
    countryCode = parsed.country;
  } else if (options.country === 'worldwide' || (options.country && options.country.split(',').length > 1)) {
    countryCode = 'ww';
  } else {
    countryCode = parsed.country;
  }
  const newPrefix = options.prefix || parsed.prefix;
  let newArticle = null;
  if (options.article) {
    // article specified as the duplication field overrides all
    newArticle = options.article;
  } else if (options.widgetCodeName) {
    // article taken from the widget code
    newArticle = options.widgetCodeName + options.widgetLanguageCode;
  } else {
    // use the same article as original
    newArticle = parsed.article;
  }
  let newDomain = options.domain || options.site_code || parsed.site;
  if (newDomain) {
    newDomain = newDomain.split(',')[0];
  }
  return [newPrefix, countryCode, platformLetter, newDomain, newArticle, accountName, campaignName];
}

function parseAdSetName(name) {
  // Template: fb-us-m-omg-article-g1-c2-a3
  const elements = name.split('-');
  if (elements.length < 8) {
    console.log('Incorrect AdSet name ' + name);
    return;
  }
  return {
    prefix: elements[0],
    country: elements[1],
    platform: elements[2],
    site: elements[3],
    article: elements[4],
    accountPrefix: elements[5].substring(0, elements[5].search(/\d/)),
    account: Number(elements[5].substring(elements[5].search(/\d/))),
    fullAccountName: elements[5],
    campaign: Number(elements[6].substring(elements[6].search(/\d/))),
    adset: Number(elements[7].substring(elements[7].search(/\d/)))
  };
}

function getPlatformLetter(platform) {
  return platform === 'desktop' ? 'd' : platform === 'mobile' ? 'm' : '';
}

export function getInternalConversion(url) {
  let conversion = 'depc ';
  const target = 'pixel_value=';
  const start = url.indexOf(target) + target.length;
  let end = url.indexOf('&', start);
  if (end === -1) {
    end = url.length;
  }
  conversion = conversion + url.substring(start, end);
  return { label: conversion, value: conversion };
}

export function getPurchaseConversion(urlTags) {
  if (urlTags.indexOf('s2s_algo') !== -1) {
    // There is no easy way to resolve conversion target event for existing campaign, so instead of it
    // server-to-server purchase should be selected as target event when url contains `s2s_algo` parameter.
    return FB.CONVS.PURCHASE_CAPI;
  }
  if (urlTags.indexOf('bs=mr') !== -1) {
    // There is no easy way to resolve conversion target event for existing campaign, so instead of
    // client-site purchase should be selected as target event when url contains `bs=mr` parameter.
    return FB.CONVS.PURCHASE;
  }
}

export function isPurchaseConversion(cc) {
  if (typeof cc !== 'string') {
    return false;
  }
  return cc.toLowerCase().includes('purchase');
}
