import React from 'react';
import { Grid, Typography } from '@mui/material';
import Select from 'react-select';
import { GA } from '../Constants';
import { getSelectValue } from '../Shared';

class GoogleBids extends React.Component {
  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.site];
    return parts.filter(el => el).join('-');
  }

  renderBidRow(bid, idx) {
    return (
      <React.Fragment key={'bidLabel' + idx}>
        <Grid item sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            {this.renderRowName(bid)}
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Select
            onChange={e => this.props.modifyBid(idx, e.value)}
            options={GA.CAMPAIGN_CONVERSIONS}
            value={getSelectValue(GA.CAMPAIGN_CONVERSIONS, this.props.bids[idx].campaignConversion)}
          />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {this.props.bids
          ? this.props.bids.map((bid, idx) => {
              return this.renderBidRow(bid, idx);
            })
          : null}
      </>
    );
  }
}

export default GoogleBids;
