import React, { Component } from 'react';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid, Typography, Button, FormControlLabel, Switch, Avatar } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { formatNYTimestamp } from '../utils/tzUtils';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  getFbConversionsAndPixels,
  getFacebookCampaigns,
  storeFiles,
  getPresets,
  retrievePreset,
  updatePreset,
  deletePreset,
  createPreset,
  getArticleData
} from '../Api';
import AdsetsBuilder from './AdsetsBuilder';
import { PLATFORMS, FB, TASKS, GLOBAL, PRESET_OPTIONS } from '../Constants';
import { toast } from 'react-toastify';
import WidgetSelector from '../WidgetSelector';
import {
  constructCampaignName,
  getNewCampaignNumber,
  getInternalConversion,
  getPurchaseConversion,
  isPurchaseConversion
} from '../FbUtils';
import { parseCampaignName } from '../NameParser';
import CampaignsNote from '../CampaignsNote';
import {
  truncateFileName,
  displayWarnings,
  validateAndPrepareImage,
  getFullPath,
  processFacebookImages,
  getImageDimensions
} from '../utils/imageUploadUtils';
import {
  getCampaignAds,
  prepareSiteList,
  getWebsite,
  getBudgetInfo,
  getAccount,
  filterConversionsByBmId,
  getValue
} from './FacebookShared';
import DuplicationPopover from './DuplicationPopover';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { normalizeCreative } from '../utils/creativesUtils';
import {
  getNewCreatorValue,
  articleCodeLanguageVersion,
  clearAdsetFields,
  getSelectValue,
  getMultiSelectValue,
  getOnlyValue,
  makeSelectOptions,
  byteSize
} from '../Shared';
import PresetButtonGroup from './PresetButtonGroup';
import { getPlatformSites } from '../Sites';
import Loader from '../Loader';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Prefix from '../Prefix';
import * as Yup from 'yup';
import { styled } from '@mui/system';

const StyledContainer = styled(Grid)(() => ({
  marginLeft: '2px',
  paddingTop: '5px',
  paddingBottom: '5px',
  position: 'relative'
}));

const StyledParentAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginLeft: '5px',
  marginTop: '5px'
}));

const StyledAvatar = styled(Avatar)(({ theme, idx }) => {
  if (idx === 0) {
    return {
      color: '#eef4ff',
      backgroundColor: '#eef4ff',
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: '5px',
      marginTop: '5px'
    };
  } else {
    return {
      color: '#1f2021',
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: '5px',
      marginTop: '5px'
    };
  }
});

/* eslint-disable react/display-name */
const CustomMultiValue = React.forwardRef((props, ref) => (
  <Tooltip title={props.data.label} placement="top">
    <div ref={ref}>
      <components.MultiValue {...props}>{props.children}</components.MultiValue>
    </div>
  </Tooltip>
));

const StyledGrid = styled(({ presetMode, ...otherProps }) => <Grid {...otherProps} />)(({ presetMode }) => {
  if (presetMode) {
    return {
      marginTop: '5px',
      marginLeft: '2px',
      paddingTop: '5px',
      paddingBottom: '5px',
      backgroundColor: 'rgba(255,190,0,0.09)'
    };
  } else {
    return {
      backgroundColor: '#eef4ff',
      marginTop: '5px',
      marginLeft: '2px',
      paddingTop: '5px',
      paddingBottom: '5px'
    };
  }
});

const StyledTemplateGrid = styled(Grid)(() => ({
  marginTop: '5px',
  backgroundColor: '#f6f5f5'
}));

const StyledPaper = styled(({ idx, presetMode, ...otherProps }) => <Grid {...otherProps} />)(({ idx, presetMode }) => {
  if (idx === 0) {
    if (presetMode) {
      return {
        width: '98vw',
        backgroundColor: 'rgba(255,190,0,0.09)'
      };
    } else {
      return {
        width: '98vw',
        backgroundColor: '#eef4ff'
      };
    }
  } else {
    return {
      width: '98vw',
      backgroundColor: '#f6f5f5'
    };
  }
});

const StyledDiv = styled('div')(({ isLoadingAcc }) => {
  if (isLoadingAcc) {
    return {
      position: 'absolute',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      left: '0',
      right: '0',
      top: '0',
      transition: '0.5s',
      background: 'rgba(255, 255, 255, 0.5)',
      zIndex: '1',
      backdropFilter: 'blur(1px)'
    };
  } else {
    return {
      opacity: '0',
      display: 'none'
    };
  }
});

// selectMenuOuter: {
//   '& .Select-menu-outer': {
//     zIndex: '2'
//   }
// }

const DEFAULT_CAMPAIGN = {
  site_code: '',
  countries: ['us'],
  geoName: 'us',
  duplicationType: 'group',
  geoType: 'include',
  destinationAccount: '',
  customConversions: [],
  mobilePlatforms: '',
  accountName: '',
  bmId: '',
  campaignNote: '',
  budgetType: 'daily campaign',
  budget: 16,
  budgetEndTime: undefined,
  bidStrategyType: 'LOWEST_COST_WITHOUT_CAP',
  bidAmount: 0,
  roasAverageFloor: 0.001,
  campaignStartTime: undefined,
  campaignStopTime: undefined,
  times: 1,
  page: '',
  device: '',
  cc: '',
  platform: '',
  website: '',
  campaignObjective: 'outcome_sales',
  secondSetup: false,
  presetStartDate: false,
  presetEndDate: false,
  genders: 0,
  ageList: {
    minAge: FB.AGE_LIST,
    maxAge: FB.AGE_LIST
  },
  language: [],
  freeText: '',
  presetStartDateValue: 0,
  presetStartTime: '00:00',
  presetEndDateValue: 0,
  presetEndTime: '00:00',

  tooltips: { text: '', open: false },
  status: 'active',
  useDynamicCc: false,
  interval: {
    cc: 0,
    bid: 0,
    startDateTime: 0
  },
  repeat: {
    cc: 0,
    bid: 0,
    startDateTime: 0
  },
  presetFields: [],
  articleData: [],
  imgLink: '',
  paramSub: false,
  qs: ''
};

const DEFAULT_PARENT = [
  {
    campaignsList: [DEFAULT_CAMPAIGN],
    showChilds: true,
    imagesAmount: ''
  }
];

const adSchema = Yup.object().shape({
  text: Yup.string()
    .trim()
    .required("Ad's text field must be populated"),
  title: Yup.string().test(
    'maxByteSize',
    `Title is longer than ${FB.MAX_TITLE_LENGTH} bytes`,
    value => byteSize(value) <= FB.MAX_TITLE_LENGTH
  ),
  image: Yup.string()
    .url('Image must be a valid URL')
    .required("Ad's image is required")
});

const adsetSchema = Yup.object().shape({
  ads: Yup.array()
    .of(adSchema)
    .min(1, 'Adset should have at least one ad')
    .required('Ads are required')
});

const campaignSchema = Yup.object().shape({
  prefix: Yup.string()
    .required('Prefix is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Invalid prefix format'),
  widgetCodeName: Yup.string().required('Widget code name is required'),
  widgetLanguageCode: Yup.string().required('Widget language code is required'),
  widgetVersion: Yup.string().required('Widget version is required'),
  widget_id: Yup.string().required('Widget ID is required'),
  sourceCampaignName: Yup.string().nullable(),
  presetName: Yup.string().nullable(),
  sourceAccount: Yup.string().nullable(),
  account: Yup.string().required('Account is required'),
  budget: Yup.number().required('Budget is required'),
  objective: Yup.string().required('Objective is required'),
  budgetType: Yup.string().required('Budget type is required'),
  geoType: Yup.string().required('Geo type is required'),
  geoName: Yup.string().required('Geo name is required'),
  duplicationType: Yup.string().required('Duplication type is required'),
  site_code: Yup.string().required('Site code is required'),
  conversion: Yup.string().required('Conversion is required'),
  platform: Yup.string().required('Device is required'),
  country: Yup.string().required('Country is required'),
  campaign: Yup.object().shape({
    adsets: Yup.array()
      .of(adsetSchema)
      .min(1, 'At least one adset is required')
      .required('Adsets are required')
  })
});

const arraySchema = Yup.array()
  .of(campaignSchema)
  .min(1, 'At least one campaign is required')
  .required('Campaigns are required');

class FacebookCreate2 extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      prefix: '',
      adsets: [],
      pageList: [],
      conversionList: [],
      videoCreatives: [],
      widgetCodeName: '',
      widgetLanguageCode: '',
      widgetVersion: '',
      toggleAd: true,
      article: '',
      widget_id: '',
      widget_path: '',
      widget_title: '',
      campaigns: [],
      sourceCampaignName: '',
      triggerSelectorUpdate: false,
      adsetTypeToCreate: 'SINGLE',
      userMail: '',
      siteList: [],
      allSites: [],
      campaignCreator: '',
      useTrackingInUrl: true,
      sourceAds: [],
      parentsList: DEFAULT_PARENT,
      isBulkDuplication: false,
      presets: [],
      preset: '',
      presetData: {},
      presetName: '',
      tags: ['Generate_S1'],
      creativeFromInsightsAdded: false,
      isLoadingAcc: false,
      loadingRows: {},
      saveCreator: false,
      accounts: [],
      campaignOptions: [],
      selectedCampaigns: [],
      validationErrors: {}
    };

    if (this.props.loadedFromSnapshot) {
      this.state = {
        ...this.props.options,
        destinationAccount: this.props.destinationAccount
      };
    } else {
      this.state = JSON.parse(JSON.stringify(this.defaultState));
    }
    // keep cache on state transitions?
    this.isSwag = this.props.programOptions.isSwag;
    this.countryList = this.props.countryList;
    for (let key in FB.GROUPS_OF_COUNTRYS_LIST) {
      this.countryList.push({ label: key, value: FB.GROUPS_OF_COUNTRYS_LIST[key].join(',') });
    }
    this.languageList = JSON.parse(FB.APP_LANGUAGE_LIST);
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      await this.updateUserOptions();
      this.setPrefixDuplicateCampaign();
      this.setWidgetLanguageCode();
      this.setWidgetVersion();
      this.readPresets();
      let accounts = this.getDestinationAccountOptions();
      this.setState({
        siteList: this.props.siteList,
        accounts,
        allSites: prepareSiteList(this.props.notFilteredSiteList)
      });
    }
  }

  async fetchConversionList(accountId) {
    const conversionsAndPixels = await getFbConversionsAndPixels(accountId);
    return await this.setStateAsync({ conversionList: conversionsAndPixels });
  }

  async updateUserOptions() {
    const userOpts = this.props.programOptions;
    let prefix = userOpts.spm_prefix_for_facebook ? userOpts.spm_prefix_for_facebook : userOpts.spm_prefix_default;
    this.updateCreator(getNewCreatorValue(userOpts.email, this.props.creatorsList));
    this.setState({ prefix, userMail: userOpts.email });
  }

  setPrefixDuplicateCampaign() {
    if (this.props.task === TASKS.DUPLICATE) {
      this.setState({
        prefix: this.props.prefix
      });
    }
  }

  setWidgetLanguageCode() {
    if (this.props.task === TASKS.CREATE) {
      this.setState({
        widgetLanguageCode: 'en'
      });
    }
  }

  setWidgetVersion() {
    if (this.props.task === TASKS.CREATE) {
      this.setState({
        widgetVersion: 'shz'
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.prefix !== prevProps.prefix) {
      this.setPrefixDuplicateCampaign();
    }
    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      const acc = this.props.accounts.find(
        x => x.id === this.props.destinationAccount || x.account_id === this.props.destinationAccount
      );
      this.setState({ accountName: acc && acc.prefix ? acc.prefix : '', destAccCurrency: acc.currency });
      await this.fetchConversionList(this.props.destinationAccount);
      if (this.props.platform === PLATFORMS.FACEBOOK2) {
        this.fetchCampaigns(this.props.destinationAccount);
      }
    }

    if (this.props.task !== prevProps.task) {
      this.setState(JSON.parse(JSON.stringify(this.defaultState)));
    }

    if (this.props.sourceData !== prevProps.sourceData) {
      let parsedData = await this.parseData();
      this.setState(parsedData, () => {
        this.setState({ triggerSelectorUpdate: !this.state.triggerSelectorUpdate });
      });
    }

    if (this.props.accounts !== prevProps.accounts) {
      this.setState({ accounts: this.getDestinationAccountOptions() });
    }

    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      await this.addCreativesFromFbLibrary(this.props.creativeFromFbLibrary);
    }

    if (this.props.creativeFromInsights && !this.state.creativeFromInsightsAdded) {
      this.setState({ creativeFromInsightsAdded: true });
      await this.addCreativesFromInsights(this.props.creativeFromInsights);
    }

    if (this.props.creative) {
      this.props.creativeDone();

      let parsedName = {};
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);

      setTimeout(() => {
        this.updateCountries(0, 0, [parsedName.country || '']);
        setTimeout(() => {
          this.setState(
            {
              widgetCodeName: parsedName.widgetCodeName || '',
              widgetLanguageCode: parsedName.widgetLanguageCode,
              widgetVersion: 'shz'
            },
            () => {
              this.updateWebsite(getWebsite(this.state));
            }
          );
        }, 100);
      }, 500);

      const creative = normalizeCreative(this.props.creative);

      this.addCreatives([
        {
          images: creative.image_url,
          texts: creative.text,
          titles: creative.title
        }
      ]);
    }
  }

  async addCreativesFromInsights(creatives) {
    const blacklistedCreatives = this.props.blacklistedCreatives;
    if (!creatives) {
      return;
    }
    const parents = [];
    let blockedCampaigns = [];
    creatives.forEach(creative => {
      const adset = [
        {
          type: 'SINGLE',
          ads: [
            {
              title: creative.title,
              text: creative.text,
              image: creative.s3ImageUrl || creative.image_url
            }
          ]
        }
      ];
      const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, creative.name);
      const clv = articleCodeLanguageVersion(
        this.props.articleList,
        creative.widget_id,
        parsedName.article,
        '',
        this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B
      );
      if (!clv) {
        blockedCampaigns.push(creative.name);
        return;
      }
      parents.push({
        [GLOBAL.KEY_WIDGET_CODE_NAME]: clv[0],
        [GLOBAL.KEY_WIDGET_LANGUAGE_CODE]: clv[1],
        [GLOBAL.KEY_WIDGET_VERSION]: clv[2],
        [GLOBAL.KEY_CAMPAIGN_NOTE]: '',
        adsets: adset,
        campaignsList: [DEFAULT_CAMPAIGN],
        sourceCampaignName: creative.name,
        widget_id: creative.widget_id,
        sourceAds: adset.ads,
        campaignCreator: this.state.campaignCreator,
        creativeId: creative.creative_id,
        blockedForAccounts: blacklistedCreatives[creative.creative_id] || []
      });
    });
    if (blockedCampaigns.length) {
      toast.error(
        `You trying to duplicate campaigns with blocked article ${blockedCampaigns.join(
          ', '
        )}. These campaigns will not be duplicated`
      );
    }
    console.log(parents);
    this.setState({ parentsList: parents, unfilteredParentsList: parents, isBulkDuplication: true, prefix: '' }, () => {
      this.setState({ triggerSelectorUpdate: !this.state.triggerSelectorUpdate });
    });
  }

  fetchCampaigns(accountId) {
    getFacebookCampaigns(accountId).then(camps => {
      this.setState({
        campaigns: camps.map(el => {
          return { id: el.id, desc: el.name };
        })
      });
    });
  }

  handleTemplateSelect(parentsList, idx, idx2, key, value) {
    if (idx2 === 0) {
      parentsList[idx].campaignsList = parentsList[idx].campaignsList.map(camp => {
        camp[key] = value;
        return camp;
      });
      this.setState({ parentsList });
    }
  }

  updateObjective(idx, idx2, campaignObjective) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].campaignObjective = campaignObjective;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'campaignObjective', campaignObjective);
    });
  }

  updatePrefix(prefix) {
    this.setState({ prefix });
  }

  updateCreator(campaignCreator) {
    this.setState({ campaignCreator });
  }

  updateTags(tags) {
    this.setState({ tags });
  }

  getAccName(account) {
    const acc = this.props.accounts.find(x => x.id === account || x.account_id === account);
    return acc && acc.prefix ? acc.prefix : '';
  }

  setPresetDate(date) {
    return formatNYTimestamp(moment().add(date, 'days'));
  }

  readPresets() {
    const result = getPresets(PLATFORMS.FACEBOOK2);
    result.then(res =>
      this.setState({
        saveCreator: false,
        presets: res
      })
    );
  }

  getPresetOptions() {
    const options = this.state.presets;
    return options.map(x => ({ label: x.name, value: x.name }));
  }

  async getAllAccountsConversionsCached(data) {
    const accounts = {};
    data.forEach(el => {
      el.campaignsList.forEach(camp => {
        if (camp.destinationAccount === 'THE_SAME_AS_ORIGINAL') {
          return;
        } else {
          accounts[camp.destinationAccount] = [];
        }
      });
    });
    for (const account in accounts) {
      accounts[account] = await this.fetchOneConversionListCached(account);
    }
    return accounts;
  }
  clearPresetFields(preset) {
    preset.widgetVersion = '';
    preset.widgetLanguageCode = '';
    preset.widgetCodeName = '';
    preset.widget_id = '';
    preset.widget_path = '';
    preset.showChilds = true;
    return preset;
  }

  async filterConversions(acc, conversions) {
    let optionalFilter = { filterConverions: false };
    let filteredCoversions = conversions;
    if (acc && acc.business_mgr_id && conversions) {
      filteredCoversions = await filterConversionsByBmId(
        conversions,
        acc.business_mgr_id,
        this.state.userMail,
        optionalFilter,
        FB.DYNAMIC_CC_USERS,
        this.isSwag
      );
    }
    return filteredCoversions;
  }

  getPresetCc(conversion, conversions) {
    if (!conversion && conversion.length && !conversions && !conversions.length) {
      return '';
    } else {
      const ccByName = conversions.find(cc => conversion === cc.name);
      const ccById = conversions.find(cc => conversion === cc.id);
      return ccByName ? ccByName.id : ccById ? ccById.id : '';
    }
  }
  ensurePresetDataStructure(preset) {
    if ('preset' in preset && !('data' in preset.preset)) {
      return {
        ...preset,
        preset: {
          data: preset.preset
        }
      };
    }
    return preset;
  }
  async processPresetData(singlePresetData, conversionsByAccount) {
    for (const preset of singlePresetData.preset.data) {
      await this.processCampaignsList(preset, conversionsByAccount);
    }
  }
  determineCampaignObjective(currentObjective) {
    switch (currentObjective) {
      case 'link_clicks':
        return 'outcome_traffic';
      case 'conversions':
        return 'outcome_sales';
      default:
        return currentObjective;
    }
  }

  async processCampaignsList(preset, conversionsByAccount) {
    for (const campaign of preset.campaignsList) {
      if (campaign.destinationAccount) {
        const account = getAccount(campaign.destinationAccount, this.props.accounts);
        if ((account && !account.fb_account_status) || account.fb_account_status === 41) {
          let customConversions = conversionsByAccount[campaign.destinationAccount];
          let filteredConversions = await this.filterConversions(account, customConversions);
          campaign.customConversions = filteredConversions;
          campaign.cc = this.getPresetCc(campaign.cc, filteredConversions);
          preset.showChilds = true;

          if (!campaign.cc.includes('depc') && !isPurchaseConversion(campaign.cc)) {
            if (campaign.destinationAccount !== 'THE_SAME_AS_ORIGINAL') {
              campaign.campaignObjective =
                campaign.campaignObjective === 'conversions'
                  ? 'outcome_sales'
                  : campaign.campaignObjective === 'link_clicks'
                  ? 'outcome_traffic'
                  : campaign.campaignObjective;
            } else {
              campaign.cc = '';
            }
          }
        } else {
          campaign.cc = '';
          campaign.destinationAccount = '';
          campaign.accountName = '';
        }
      }

      if (this.props.task === TASKS.CREATE) {
        this.clearPresetFields(preset);

        if (campaign.campaignObjective === 'conversions') {
          campaign.campaignObjective = 'outcome_sales';
        } else if (campaign.campaignObjective === 'link_clicks') {
          campaign.campaignObjective = 'outcome_traffic';
        }
        if (campaign.presetStartDate) {
          campaign.campaignStartTime = this.setPresetDate(campaign.presetStartDateValue);
        }
        if (campaign.presetEndDate) {
          campaign.campaignStopTime = this.setPresetDate(campaign.presetEndDateValue);
        }
        if (campaign.countries) {
          campaign.geoName = campaign.countries;
        }
        campaign.campaignObjective = this.determineCampaignObjective(campaign.campaignObjective);
        campaign.ageList = { minAge: 18, maxAge: 65 };
      }

      if (this.props.task === TASKS.DUPLICATE) {
        const data = this.props.sourceData[0];
        const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, data.name);

        if (campaign.destinationAccount) {
          const acc = getAccount(campaign.destinationAccount, this.props.accounts);
          let filteredCoversions = await this.filterConversions(acc, conversionsByAccount[campaign.destinationAccount]);
          campaign.customConversions = filteredCoversions;
          campaign.cc = this.getPresetCc(campaign.cc, filteredCoversions);
          if (campaign.cc && !campaign.cc.includes('depc') && !isPurchaseConversion(campaign.cc)) {
            if (campaign.destinationAccount !== 'THE_SAME_AS_ORIGINAL') {
              campaign.campaignObjective =
                campaign.campaignObjective === 'conversions'
                  ? 'outcome_sales'
                  : campaign.campaignObjective === 'link_clicks'
                  ? 'outcome_traffic'
                  : campaign.campaignObjective;
            } else {
              let cc = data.conversion;
              if (data?.campaign?.adsets?.[0]?.ads?.[0]?.url_tags?.includes('pixel_value')) {
                let internalCc = getInternalConversion(data.campaign.adsets[0].ads[0].url_tags);
                if (internalCc) {
                  campaign.cc = internalCc.value;
                }
                campaign.campaignNote = data.campaignNote;
              } else {
                campaign.cc = cc;
                campaign.campaignNote = data.campaignNote;
              }
            }
          }
        }

        //take fields from source campaign
        let fields = campaign.presetFields && campaign.presetFields.length ? campaign.presetFields.split(',') : [];

        fields.forEach(field => {
          if (field === 'destinationAccount') {
            campaign.destinationAccount = this.props.account;
            campaign.accountName = this.getAccName(this.props.account);
            let cc = data.conversion;
            if (data?.campaign?.adsets?.[0]?.ads?.[0]?.url_tags?.includes('pixel_value')) {
              let internalCc = getInternalConversion(data.campaign.adsets[0].ads[0].url_tags);
              if (internalCc) {
                campaign.cc = internalCc.value;
                campaign.campaignNote = data.campaignNote;
              }
            } else {
              campaign.cc = cc;
              campaign.campaignNote = data.campaignNote;
            }
          }
          if (field === 'campaignObjective') {
            const objective = data.objective.toLowerCase();
            campaign.campaignObjective =
              objective === 'conversions'
                ? 'outcome_sales'
                : objective === 'link_clicks'
                ? 'outcome_traffic'
                : objective;
          }
          if (field === 'site_code') {
            campaign.site_code = data.site_code;
          }
          if (field === 'device') {
            campaign.device = this.getDevices(parsedName.platform);
          }
          if (field === 'countries') {
            campaign.countries = [data.country];
            campaign.geoName = data.country;
          }
          if (field === 'bid_strategy') {
            campaign.bidStrategyType = data.bid_strategy;
          }
          if (field === 'budget') {
            campaign.budget = getBudgetInfo(data).budget / 100;
          }
          if (field === 'budgetType') {
            campaign.budgetType = getBudgetInfo(data).budgetType;
          }
          if (field === 'bid_amount') {
            let bidAmount = data.bid_amount || data.campaign.adsets[0].bid_amount;
            campaign.bidAmount = bidAmount ? bidAmount / 100 : 0;
          }
          if (field === 'start_date') {
            campaign.campaignStartTime = this.setPresetDate(campaign.presetStartDateValue);
          }
          if (field === 'end_date') {
            campaign.campaignStopTime = this.setPresetDate(campaign.presetEndDateValue);
          }
        });

        const clv = articleCodeLanguageVersion(this.props.articleList, data.widget_id, parsedName.article);
        preset.widgetCodeName = clv[0];
        preset.widgetLanguageCode = clv[1];
        preset.widgetVersion = clv[2];
        preset.widget_id = data.widget_id;
        preset.widget_path = data.widget_path;
        preset.showChilds = true;
      }
    }
  }
  async setPreset() {
    if (!this.state.preset) {
      toast.warn('Select preset!');
      return;
    }
    const preset = this.state.presets.find(e => e.name === this.state.preset);

    if (!preset) {
      toast.warn('Could not find a preset!');
      return;
    }

    try {
      let presetResult;
      this.props.startLoading();

      presetResult = await retrievePreset(preset.id, PLATFORMS.FACEBOOK2);

      if (!presetResult || presetResult.length === 0 || !presetResult[0].preset) {
        toast.error('Failed to Retrieve Preset');
        return;
      }

      let singlePresetData = this.ensurePresetDataStructure(presetResult[0]);

      if (!singlePresetData && !singlePresetData.preset) {
        return;
      }
      const conversionsByAccount = await this.getAllAccountsConversionsCached(singlePresetData.preset.data);

      await this.processPresetData(singlePresetData, conversionsByAccount);

      if (this.props.task === TASKS.CREATE) {
        this.setState({
          campaignCreator: singlePresetData.preset?.target[0]?.campaignCreator || this.state.campaignCreator,
          parentsList: singlePresetData.preset.data
        });
      } else {
        this.setState({
          parentsList: singlePresetData.preset.data
        });
      }

      this.readPresets();

      this.props.endLoading();
    } catch (error) {
      console.error('An error occurred: get preset', error);
      toast.error('An error occurred. Please try to set preset again.');
      this.props.endLoading();
    }
  }

  handleSelectPreset(preset) {
    this.setState({ preset });
  }

  async savePreset(presetName) {
    try {
      const { saveCreator, campaignCreator } = this.state;
      const parentsList = _.cloneDeep(this.state.parentsList);
      const userMail = this.props.userMail;

      if (!presetName || !userMail || !parentsList) {
        toast.error('Invalid input for saving preset');
        return;
      }
      let selectedCreator = saveCreator && campaignCreator ? campaignCreator : undefined;

      parentsList.forEach(preset => {
        preset.showChilds = true;
        preset.userMail = userMail || '';
        preset.campaignsList.forEach(el => {
          if (!el.cc.includes('depc') && !isPurchaseConversion(el.cc)) {
            const cc = el.customConversions.find(cc => el.cc === cc.id);
            el.cc = cc ? cc.name : '';
          }
          if (el.presetFields && el.presetFields.length) {
            let presetFields = el.presetFields.split(',');
            presetFields.forEach(field => {
              el[field] = FB.THE_SAME_AS_ORIGINAL;
            });
          }
        });
      });
      const { targets } = this.getTargetAndBudget();
      const result = selectedCreator
        ? { data: parentsList, target: targets, selectedCreator }
        : { data: parentsList, target: targets };

      await createPreset(presetName, userMail, JSON.stringify(result), PLATFORMS.FACEBOOK2);
      await this.readPresets();
      this.handleSelectPreset(presetName);
      toast.success('Successfully saved preset');
    } catch (error) {
      this.setState({ saveCreator: false });
      toast.error('An error occurred: ' + error.message);
    }
  }

  async saveAsPreset(presetName) {
    try {
      if (!presetName && !this.state.preset) {
        this.setState({
          saveCreator: false
        });
        toast.error('Invalid input for saving preset');
        return;
      }
      const presetToUpdate = this.state.presets.find(e => e.name === this.state.preset);

      if (presetToUpdate) {
        await updatePreset(presetToUpdate.id, presetName);
        await this.readPresets();
        this.handleSelectPreset(presetName);
        toast.success('Successfully saved preset');
      } else {
        toast.error('Preset not found');
      }
    } catch (error) {
      toast.error('An error occurred: ' + error.message);
    }
  }

  async deletePreset() {
    try {
      if (this.state.preset) {
        const preset = this.state.presets.find(e => e.name === this.state.preset);

        if (!preset) {
          toast.error('Selected preset not found');
          return;
        }

        await deletePreset(preset.id, PLATFORMS.FACEBOOK2);
        await this.readPresets();

        toast.success('Successfully deleted preset');
      } else {
        toast.error('Deleting failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred while deleting preset. Please try again.');
    }
  }

  updateCountries(idx, idx2, xs) {
    const parentsList = this.state.parentsList;
    let countries = parentsList[idx].campaignsList[idx2].countries;
    let geoName = '';
    if (countries) {
      if (countries.indexOf('ww') !== -1 && xs !== '' && xs.length) {
        countries = ['ww'];
      } else if (xs.indexOf('ww') !== -1) {
        countries = ['ww'];
      } else {
        countries = xs;
      }
    } else {
      countries = xs;
    }
    if (typeof countries === 'string' && countries !== '') {
      countries = countries.split(',');
    }
    if (
      (Array.isArray(countries) &&
        countries.length === 1 &&
        countries[0].length === 2 &&
        !countries.includes('ww') &&
        !xs.includes('ww')) ||
      (typeof countries === 'string' && countries.length === 2 && countries !== 'ww' && !xs.includes('ww'))
    ) {
      geoName = xs;
    } else if (!countries || !countries.length) {
      geoName = '';
    } else {
      geoName = 'ww';
    }

    let exclude = parentsList[idx].campaignsList[idx2].geoType;
    if (exclude === 'exclude') {
      geoName = 'ww';
    }

    parentsList[idx].campaignsList[idx2].countries = countries;
    parentsList[idx].campaignsList[idx2].geoName = geoName;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'countries', countries);
      this.handleTemplateSelect(parentsList, idx, idx2, 'geoName', geoName);
    });
  }

  updatePage(idx, idx2, page) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].page = page;
    this.setState({ parentsList });
  }

  updateCountriesGroup(idx, idx2, geoType) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].geoType = geoType;
    this.setState({ parentsList }, () => {
      this.updateCountries(idx, idx2, parentsList[idx].campaignsList[idx2].countries);
      this.handleTemplateSelect(parentsList, idx, idx2, 'geoType', geoType);
    });
  }

  updateDuplicationType(idx, idx2, duplicationType) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].duplicationType = duplicationType;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'duplicationType', duplicationType);
    });
  }

  fetchOneConversionList(accountId) {
    return getFbConversionsAndPixels(accountId);
  }

  fetchOneConversionListCached(accountId) {
    return getFbConversionsAndPixels(accountId, true);
  }

  getDefaultSite(acc) {
    if (acc && acc.prefix) {
      const site = this.state.siteList.find(el => acc.prefix.includes(el.value));
      return site ? site.value : '';
    }
  }

  async updateDestinationAccount(idx, idx2, destinationAccount) {
    if (!destinationAccount) {
      this.props.endLoading();
      return;
    }
    const acc = getAccount(destinationAccount, this.props.accounts);
    let bmId = null;
    let isBulkDuplication = this.state.isBulkDuplication;
    const accountName = acc.prefix ? acc.prefix : '';
    let customConversions;
    let parentsList = this.state.parentsList;
    let unfilteredParentsList = this.state.unfilteredParentsList;
    let selectedCampaigns = this.state.selectedCampaigns;

    if (this.state.blacklistedAds && this.state.blacklistedAds.length) {
      let blacklisted = this.state.blacklistedAds;
      for (let bm of blacklisted) {
        if (bm.blacklisted_bm && bm.blacklisted_bm[acc.business_mgr_id]) {
          if (isBulkDuplication) {
            selectedCampaigns = selectedCampaigns.filter(camp => camp.value !== bm.campaign_name);
            parentsList = parentsList.filter(el => el.sourceCampaignName !== bm.campaign_name);
            toast.error(`"${bm.campaign_name}" incompatible with selected BM ID. Removed from the queue.`);
          } else {
            toast.error(
              `Ads from the "${bm.campaign_name}" cannot be used with the selected BM ID: ${acc.business_mgr_id}. Ads are blacklisted for this account`
            );
            return;
          }
        }
      }
    }
    if (this.state.creativeFromInsightsAdded) {
      parentsList = this.state.unfilteredParentsList.filter(parent => !parent.blockedForAccounts[acc.business_mgr_id]);
      if (unfilteredParentsList.length !== parentsList.length) {
        const blockedCount = this.state.unfilteredParentsList.length - parentsList.length;
        toast.warn(
          `${blockedCount} campaign${
            blockedCount !== 1 ? 's are' : ' is'
          } blocked with selected BM ID. Removed from the queue.`
        );
      }
      if (parentsList.length === 0) {
        toast.error('All creative IDs are blocked for this account. Please select another account');
        this.props.endLoading();
        return;
      }
    }
    let { widget_id } = parentsList[idx];
    let optionalFilter = { filterConverions: false, cc: FB.QUIZ_CONVERSIONS };

    if (widget_id.includes(FB.QUIZ)) {
      optionalFilter.filterConverions = true;
    }
    if (isBulkDuplication) {
      this.setState({
        selectedCampaigns,
        loadingRows: {
          parent: [idx],
          child: [idx2]
        }
      });
    } else {
      this.setState({
        loadingRows: {
          parent: [idx],
          child: [idx2]
        }
      });
    }
    if (FB.ALL_SITES_ACCOUNT_LIST.includes(destinationAccount)) {
      this.setState({ siteList: this.state.allSites.filter(el => !FB.VIP_SITES_LIST.includes(el.value)) });
    } else {
      this.setState({ siteList: this.props.siteList });
    }

    this.setState({ isLoadingAcc: true });

    customConversions = await this.fetchOneConversionList(destinationAccount);
    if (acc && acc.business_mgr_id && customConversions) {
      customConversions = await filterConversionsByBmId(
        customConversions,
        acc.business_mgr_id,
        this.state.userMail,
        optionalFilter,
        FB.DYNAMIC_CC_USERS,
        this.isSwag
      );
    }
    bmId = acc.business_mgr_id;
    console.log({ bmId });
    const previousCcList = this.state.parentsList[idx].campaignsList[idx2].customConversions;
    let previousCcId = this.state.parentsList[idx].campaignsList[idx2]?.cc;
    const previousCc = previousCcId ? previousCcList.filter(c => c.id === previousCcId) : '';
    const currentCc = previousCcId ? customConversions.filter(c => c.id === previousCcId) : '';

    if (previousCcId && previousCcId.length > 0) {
      previousCcId = customConversions.find(cc => cc.id === previousCcId) ? previousCcId : '';
    }

    parentsList[idx].campaignsList[idx2] = {
      ...parentsList[idx].campaignsList[idx2],
      destinationAccount,
      accountName,
      bmId,
      customConversions,
      cc: previousCcId
    };
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'customConversions', customConversions);
      this.handleTemplateSelect(parentsList, idx, idx2, 'destinationAccount', destinationAccount);
      this.handleTemplateSelect(parentsList, idx, idx2, 'accountName', accountName);
      this.handleTemplateSelect(parentsList, idx, idx2, 'bm', bmId);

      const domain = this.getDefaultSite(acc);
      if (domain) {
        this.updateOneDomain(idx, idx2, domain);
      }
      this.setDefaultCc(idx, idx2, previousCc, currentCc);
      this.setState({ isLoadingAcc: false });
    });
  }

  updateOneAccountName(idx, accountName) {
    const campaignsList = this.state.campaignsList;
    campaignsList[idx] = { ...campaignsList[idx], accountName };
    this.setState({ campaignsList });
  }

  setCc(idx, idx2, cc) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].cc = cc;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'cc', cc);
    });
  }
  updateOneDomain(idx, idx2, site_code) {
    const parentsList = this.state.parentsList;
    const website = getWebsite(parentsList[idx], site_code);
    parentsList[idx].campaignsList[idx2].site_code = site_code;
    parentsList[idx].campaignsList[idx2].website = website;
    this.setState({ parentsList, [FB.KEY_SITE]: site_code }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'site_code', site_code);
    });
  }

  toggleMultiAdvertiser(idx, idx2) {
    const parentsList = this.state.parentsList;
    const value = !parentsList[idx].campaignsList[idx2].multi_advertiser;
    parentsList[idx].campaignsList[idx2].multi_advertiser = value;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'multi_advertiser', value);
    });
  }

  toggleTextGeneration(idx, idx2) {
    const parentsList = this.state.parentsList;
    const value = !parentsList[idx].campaignsList[idx2].text_generation;
    parentsList[idx].campaignsList[idx2].text_generation = value;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'text_generation', value);
    });
  }

  toggleSetup(idx, idx2) {
    const parentsList = this.state.parentsList;
    const secondSetup = !parentsList[idx].campaignsList[idx2].secondSetup;
    parentsList[idx].campaignsList[idx2].secondSetup = secondSetup;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'secondSetup', secondSetup);
    });
  }

  toggleChilds(idx) {
    const parentsList = this.state.parentsList;
    parentsList[idx].showChilds = !parentsList[idx].showChilds;
    this.setState({ parentsList });
  }

  clearChilds(idx) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList.splice(1);
    this.setState({ parentsList });
  }

  updateOneCampaignNote(idx, idx2, campaignNote) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].campaignNote = campaignNote;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'campaignNote', campaignNote);
    });
  }

  updateQs(idx, idx2, qs) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].qs = qs;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'qs', qs);
    });
  }

  validateAlphaNumericGeo(event) {
    if (!/[a-z]/.test(event.key) || event.target.value.length > 1) {
      event.preventDefault();
    }
  }
  updateGeoName(idx, idx2, geoName) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].geoName = geoName.trim();
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'geoName', geoName.trim());
    });
  }

  updateOneGender(idx, idx2, genders) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].genders = genders;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'genders', genders);
    });
  }

  updateOneMinAge(idx, idx2, age_min) {
    const parentsList = this.state.parentsList;
    let ageList = parentsList[idx].campaignsList[idx2].ageList;
    ageList.maxAge = age_min ? ageList.maxAge.filter(x => x.value >= age_min) : FB.AGE_LIST;
    parentsList[idx].campaignsList[idx2] = { ...parentsList[idx].campaignsList[idx2], age_min, ageList };
    this.setState({ parentsList });
  }

  updateOneMaxAge(idx, idx2, age_max) {
    const parentsList = this.state.parentsList;
    let ageList = parentsList[idx].campaignsList[idx2].ageList;
    ageList.minAge = age_max ? ageList.minAge.filter(x => x.value <= age_max) : FB.AGE_LIST;
    parentsList[idx].campaignsList[idx2] = { ...parentsList[idx].campaignsList[idx2], age_max, ageList };
    this.setState({ parentsList });
  }

  updateLanguages(idx, idx2, language) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].language = language;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'language', language);
    });
  }

  updateBudgetType(idx, idx2, budgetType) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].budgetType = budgetType;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'budgetType', budgetType);
    });
  }

  updateBudget(idx, idx2, budget) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].budget = budget;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'budget', budget);
    });
  }

  updateBudgetEndTime(idx, idx2, budgetEndTime) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].budgetEndTime = budgetEndTime;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'budgetEndTime', budgetEndTime);
    });
  }

  updateBidStrategyType(idx, idx2, bidStrategyType) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].bidStrategyType = bidStrategyType;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'bidStrategyType', bidStrategyType);
    });
  }

  updateBidAmount(idx, idx2, bidAmount) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].bidAmount = bidAmount;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'bidAmount', bidAmount);
    });
  }

  updateRoasAverageFloor(idx, idx2, roasAverageFloor) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].roasAverageFloor = roasAverageFloor;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'roasAverageFloor', roasAverageFloor);
    });
  }

  updateInterval(idx, idx2, interval, field) {
    const parentsList = this.state.parentsList;
    if (field === 'cc') {
      parentsList[idx].campaignsList[idx2].interval.cc = Number(interval);
      this.setState({ parentsList });
    } else if (field === 'bid') {
      parentsList[idx].campaignsList[idx2].interval.bid = Number(interval);
      this.setState({ parentsList });
    } else if (field === 'startDateTime') {
      parentsList[idx].campaignsList[idx2].interval.startDateTime = Number(interval);
      this.setState({ parentsList });
    }
  }

  updateRepeat(idx, idx2, repeat, field) {
    const parentsList = this.state.parentsList;
    if (field === 'cc') {
      parentsList[idx].campaignsList[idx2].repeat.cc = Number(repeat);
      this.setState({ parentsList });
    } else if (field === 'bid') {
      parentsList[idx].campaignsList[idx2].repeat.bid = Number(repeat);
      this.setState({ parentsList });
    } else if (field === 'startDateTime') {
      parentsList[idx].campaignsList[idx2].repeat.startDateTime = Number(repeat);
      this.setState({ parentsList });
    }
  }

  updateCampaignStartTime(idx, idx2, campaignStartTime) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].campaignStartTime = campaignStartTime;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'campaignStartTime', campaignStartTime);
    });
  }

  updateCampaignStopTime(idx, idx2, campaignStopTime) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].campaignStopTime = campaignStopTime;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'campaignStopTime', campaignStopTime);
    });
  }

  updateOneDevice(idx, idx2, device) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].device = device;
    switch (device) {
      case 'Mobile':
        parentsList[idx].campaignsList[idx2].mobilePlatforms = 'Android,iOS';
        parentsList[idx].campaignsList[idx2].platform = 'm';
        break;
      case 'Desktop':
        parentsList[idx].campaignsList[idx2].platform = 'd';
        break;
      case 'IOS':
        parentsList[idx].campaignsList[idx2].mobilePlatforms = 'iOS';
        parentsList[idx].campaignsList[idx2].platform = 'm';
        break;
      case 'Android':
        parentsList[idx].campaignsList[idx2].mobilePlatforms = 'Android';
        parentsList[idx].campaignsList[idx2].platform = 'm';
        break;
      case 'All':
        parentsList[idx].campaignsList[idx2].platform = 'a';
        break;
    }
    parentsList[idx].campaignsList[idx2].campaignNote = this.setOneCampaignNote(
      parentsList[idx].campaignsList[idx2],
      parentsList[idx].campaignsList[idx2].cc
    );
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'device', device);
      this.handleTemplateSelect(parentsList, idx, idx2, 'platform', parentsList[idx].campaignsList[idx2].platform);
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'mobilePlatforms',
        parentsList[idx].campaignsList[idx2].mobilePlatforms
      );
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'campaignNote',
        parentsList[idx].campaignsList[idx2].campaignNote
      );
    });
  }

  getInternalNote(cc, platform, mobPlatform) {
    let parts = [];
    parts.push(this.state.userMail.slice(0, this.state.userMail.indexOf('@')));
    if (isPurchaseConversion(cc)) {
      if (cc.toLowerCase().includes('server')) {
        parts.push(`Purchase-s2s`);
      } else {
        parts.push(`Purchase`);
      }
    } else {
      parts.push(cc.slice(cc.indexOf(' ')));
    }
    parts.push(platform === 'm' ? 'Mobile' : platform === 'a' ? 'All' : 'Desktop');
    if (platform === 'm') {
      parts.push(mobPlatform === 'Android' ? 'and' : mobPlatform === 'iOS' ? 'ios' : 'all');
    }
    return parts.join(' / ').trim();
  }

  handleChangeCcCheckbox(idx, idx2) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].useDynamicCc = !parentsList[idx].campaignsList[idx2].useDynamicCc;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'useDynamicCc',
        parentsList[idx].campaignsList[idx2].useDynamicCc
      );
    });
  }

  setOneCc(campaignItem, cc) {
    let campaignNote = this.setOneCampaignNote(campaignItem, cc);

    campaignItem.cc = cc;
    campaignItem.campaignNote = campaignNote;
    return campaignItem;
  }

  setOneCampaignNote(campaignItem, cc) {
    let campaignNote = campaignItem.campaignNote;
    if (this.isSwag) {
      const ccList = this.getConversionList(campaignItem.customConversions, campaignItem.bidStrategyType);
      if (cc) {
        let customConversion = ccList.find(el => el.value === cc);
        if (customConversion) {
          campaignNote = this.getInternalNote(
            customConversion.label,
            campaignItem.platform,
            campaignItem.mobilePlatforms
          );
        } else return;
      } else {
        return;
      }
    }
    return campaignNote;
  }

  updateOneCc(idx, idx2, cc) {
    const parentsList = this.state.parentsList;
    const campaignItem = parentsList[idx].campaignsList[idx2];
    let campaignNote = this.setOneCampaignNote(campaignItem, cc);

    parentsList[idx].campaignsList[idx2].cc = cc;
    parentsList[idx].campaignsList[idx2].campaignNote = campaignNote;

    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'cc', cc);
      this.handleTemplateSelect(parentsList, idx, idx2, 'campaignNote', campaignNote);
    });
  }

  getConversionList(ccList, bidStrategyType) {
    if (bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS') {
      return [FB.CONVS.PURCHASE, FB.CONVS.PURCHASE_CAPI];
    }
    let conversionList;
    conversionList = ccList.map(el => {
      return { label: el.name, value: el.id };
    });
    return conversionList;
  }

  getDevices(platform, oss) {
    if (platform === 'm' && !oss) {
      return 'Mobile';
    } else if (platform === 'a') {
      return 'All';
    } else if (platform === 'd') {
      return 'Desktop';
    } else if (platform === 'm' && oss) {
      return oss[0];
    }
  }

  getDeviceList() {
    return FB.DEVICE_LIST.map(x => ({ label: x, value: x }));
  }

  updateStatus(idx, idx2, status) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].status = status;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'status', status);
    });
  }

  updateWebsite(website) {
    this.setState({ website });
  }

  updateTimes(idx, idx2, times) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].times = times;
    this.setState({ parentsList });
  }
  handleAdsDropdown() {
    let toggleAd = this.state.toggleAd;
    toggleAd = !toggleAd;
    this.setState({ toggleAd });
  }
  updateKnownUrlParams(idx, idx2, params) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].knownUrlParams = params;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'knownUrlParams', params);
    });
  }

  toggleAutoPlacements(idx, idx2) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].autoPlacements = !parentsList[idx].campaignsList[idx2].autoPlacements;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'autoPlacements',
        parentsList[idx].campaignsList[idx2].autoPlacements
      );
    });
  }

  //* change the 'device' field to 'All' for every campaign in parentsList
  changeDeviceToAll(parentsList) {
    if (Array.isArray(parentsList)) {
      for (let parent of parentsList) {
        if (Array.isArray(parent.campaignsList)) {
          for (let campaign of parent.campaignsList) {
            if (Object.prototype.hasOwnProperty.call(campaign, 'device')) {
              campaign.prevDevice = campaign.device;
              campaign.device = 'All';
            }
            if (Object.prototype.hasOwnProperty.call(campaign, 'platform')) {
              campaign.prevPlatform = campaign.platform;
              campaign.platform = 'a';
            }
          }
        }
      }
    }
  }

  //* toggle the 'device' field back to its original value
  //* working only for 1 adset
  toggleDeviceBack(parentsList) {
    if (Array.isArray(parentsList)) {
      for (let parent of parentsList) {
        if (Array.isArray(parent.campaignsList)) {
          for (let campaign of parent.campaignsList) {
            if (Object.prototype.hasOwnProperty.call(campaign, 'prevDevice')) {
              campaign.device = campaign.prevDevice;
              delete campaign.prevDevice;
            }
            if (Object.prototype.hasOwnProperty.call(campaign, 'prevPlatform')) {
              campaign.platform = campaign.prevPlatform;
              delete campaign.prevPlatform;
            }
          }
        }
      }
    }
  }

  toggleAdvantagePlusAdset(adset, isChecked) {
    const currentAdvantage = adset.advantage_placements;
    const currentAutoPlacements = adset.autoPlacements;

    adset.advantage_placements = !currentAdvantage;
    adset.autoPlacements = !currentAutoPlacements;

    const parentsList = this.state.parentsList;
    if (isChecked) {
      this.changeDeviceToAll(parentsList);
    } else {
      this.toggleDeviceBack(parentsList);
    }
    this.setState({ parentsList });
  }

  toggleAdvantagePlusCreative(ad) {
    const current = ad.advantage_placements_creative;
    ad.advantage_placements_creative = !current;
  }

  updateFreeText(idx, idx2, freeText) {
    const parentsList = this.state.parentsList;
    const campaignNumber = getNewCampaignNumber(this.state.campaigns) + parentsList[idx].campaignsList.length;
    const testName = FB.TEST_CAMPAIGN_NAME;
    const newCampName = constructCampaignName(testName, this.state.accountName || 'accN', campaignNumber, this.state);
    let tooltips = {};
    const maxAddedLength = 1 + FB.MAX_ADSET_PART_LENGTH + 1 + FB.MAX_AD_PART_LENGTH;
    const charsLeft = FB.MAX_CAMP_NAME_LENGTH - newCampName.length - freeText.length - maxAddedLength;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    const nonLetterNumber = /[^a-z0-9]+/i;
    if (freeText.match(nonLetterNumber)) {
      tooltips.text = 'Only letters and numbers';
      freeText = freeText.replace(nonLetterNumber, '');
      parentsList[idx].campaignsList[idx2].tooltips = tooltips;
      parentsList[idx].campaignsList[idx2].freeText = freeText;
      this.setState({ parentsList }, () => {
        this.handleTemplateSelect(parentsList, idx, idx2, 'tooltips', tooltips);
        this.handleTemplateSelect(parentsList, idx, idx2, 'freeText', freeText);
      });
      return;
    }
    if (charsLeft <= 0) {
      //take into account the length of '-a1-v10-'
      freeText = freeText.slice(0, FB.MAX_CAMP_NAME_LENGTH - newCampName.length - maxAddedLength);
      tooltips.text = `Chars left: 0`;
    }
    parentsList[idx].campaignsList[idx2].tooltips = tooltips;
    parentsList[idx].campaignsList[idx2].freeText = freeText;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'tooltips', tooltips);
      this.handleTemplateSelect(parentsList, idx, idx2, 'freeText', freeText);
    });
  }

  onBlurFreeText(idx, idx2) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].tooltips.open = false;
    this.setState({ parentsList });
  }

  updatePresetStartDate(idx, idx2, date) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].presetStartDateValue = date;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'presetStartDateValue',
        parentsList[idx].campaignsList[idx2].presetStartDateValue
      );
    });
  }

  updatePresetEndDate(idx, idx2, date) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList[idx2].presetEndDateValue = date;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'presetEndDateValue',
        parentsList[idx].campaignsList[idx2].presetEndDateValue
      );
    });
  }

  updateWidgetFields(idx, key, value) {
    const parentsList = this.state.parentsList;
    value = key === 'article' ? value.target.value : value;
    key = key === 'widgetid' ? 'widget_id' : key;
    parentsList[idx][key] = value;
    this.setState({ parentsList }, () => {
      this.updateWebsite(getWebsite(this.state.parentsList[idx]));
    });
  }

  // maybe move creation logic to the builder?
  addAdset(originalOrType) {
    const adsets = this.state.adsets;
    if (originalOrType === undefined) {
      switch (this.state.adsetTypeToCreate) {
        case 'SINGLE':
          adsets.push({
            type: this.state.adsetTypeToCreate,
            ads: [],
            advantage_placements: false,
            autoPlacements: false
          });
          break;
        case 'SINGLE_VIDEO':
          adsets.push({
            type: this.state.adsetTypeToCreate,
            ads: []
          });
          break;
        case 'DYNAMIC': // in dynamic adset image/imageHashes should have the same length
          adsets.push({
            type: this.state.adsetTypeToCreate,
            ads: [
              {
                image: [],
                text: [''],
                title: ['']
              }
            ],
            advantage_placements: false,
            autoPlacements: false
          });
          break;
        default:
          throw new Error(`Unknown adset type: ${this.state.adsetTypeToCreate}`);
      }
    } else {
      adsets.push(originalOrType);
    }

    this.setState({ adsets });
  }

  deleteAdset(idx) {
    const adsets = this.state.adsets;
    adsets.splice(idx, 1);
    this.setState({ adsets });
  }

  modifyAdset(idx, adset) {
    const adsets = this.state.adsets;
    adsets[idx] = adset;
    this.setState({ adsets });
  }

  modifyAdsets(ad) {
    const adsets = this.state.adsets;
    adsets.push({ type: this.state.adsetTypeToCreate, ads: [ad] });
    this.setState({ adsets });
  }

  populateAllText(adset) {
    const adsets = this.state.adsets;
    adsets.forEach(a =>
      a.ads.forEach(ad => {
        ad.title = adset.title;
        ad.text = adset.text;
      })
    );
    this.setState({ adsets });
  }

  getSubmitButtonText() {
    return 'Create';
  }

  getAllowedWidgetVersions() {
    return FB.ALLOWED_VERSIONS;
  }

  updateImagesAmount(idx, value) {
    const parentsList = this.state.parentsList;
    parentsList[idx].imagesAmount = value;
    this.setState({ parentsList });
  }

  validateDuplication() {
    let sourceNames = [];
    let sourcePrefix = [];
    this.state.parentsList.map(camp => {
      if (!camp.widget_id) {
        sourceNames.push(camp.sourceCampaignName);
      }
      return sourceNames;
    });
    if (!this.state.prefix) {
      this.state.parentsList.map(camp => {
        const prefix = parseCampaignName(PLATFORMS.FACEBOOK, camp.sourceCampaignName).prefix;
        if (!this.props.programOptions?.prefix.includes(prefix)) {
          sourcePrefix.push({
            prefix,
            name: camp.sourceCampaignName
          });
        }
        return sourcePrefix;
      });
    }
    let validations = [
      this.validate(this.state.parentsList[0].campaignsList.length > 1, 'Add at least one campaign'),
      this.validate(
        this.state.parentsList[0].campaignsList.every(camp => camp.destinationAccount),
        'Empty destination account'
      ),
      this.validate(
        this.state.parentsList[0].campaignsList.every(camp => camp.countries.length > 0),
        'Select at least one geo'
      ),
      this.validate(
        this.state.parentsList[0].campaignsList.every(camp => camp.site_code),
        'Select domain'
      ),
      this.validate(
        this.state.parentsList[0].campaignsList.every(camp => camp.device),
        'Select device'
      ),
      this.validate(
        this.state.parentsList[0].campaignsList.every(camp => camp.cc),
        'Select custom conversion'
      )
    ];
    this.state.parentsList[0].campaignsList.forEach(camp => {
      if (camp.budgetType.includes('lifetime')) {
        validations.push(this.validate(camp.budgetEndTime, 'Select budget end time'));
      }
    });

    this.state.parentsList.forEach(el => {
      validations.push(this.validate(el.widget_id, `Empty widget id for campaign ${sourceNames}`));
    });
    sourcePrefix.every(el => {
      validations.push(
        this.validate(!el, `Prefix: ${el.prefix} is missing in permissions for duplication: ${el.name}.`)
      );
    });
    return validations.every(x => x());
  }
  validateCreation() {
    let validations = [this.hasCampaign(), this.hasWidgetId(), this.isValidTrafficObjective(), this.hasAdset()];

    this.state.parentsList.forEach(parent => {
      validations = validations.concat(this.validateCampaigns(parent));
    });

    return validations.every(x => x());
  }
  isEmptyObject = obj => {
    return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object);
  };

  hasCampaign() {
    return this.validate(
      this.state.parentsList.every(parent => parent.campaignsList.length > 1),
      'Add at least one campaign'
    );
  }

  hasWidgetId() {
    return this.validate(
      this.state.parentsList.every(parent => parent.widget_id),
      'Empty widget id'
    );
  }

  isValidTrafficObjective() {
    return this.validate(
      !this.state.parentsList.every(parent =>
        parent.campaignsList.every(
          c => c.campaignObjective === 'outcome_traffic' && c.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS'
        )
      ),
      `Outcome traffic campaign objective forbidden with Lowest min ROAS bid strategy.`
    );
  }

  hasAdset() {
    return this.validate(this.state.adsets.length > 0, 'Add at least one adset');
  }

  validateCampaigns(parent) {
    let validations = [];
    parent.campaignsList.slice(1).forEach(camp => {
      validations.push(
        this.validate(camp.campaignObjective, 'Select campaign objective'),
        this.validate(camp.bidStrategyType, 'Select bid strategy'),
        this.validate(camp.destinationAccount, 'Empty destination account'),
        this.validate(camp.countries.length > 0, 'Select at least one geo'),
        this.validate(camp.site_code, 'Select domain'),
        this.validate(camp.device, 'Select device'),
        this.validate(camp.cc, 'Select custom conversion'),
        this.validate(camp.countries, 'Select geo'),
        this.validate(camp.budgetType, 'Select budget type'),
        this.validate(camp.budget, 'Select budget amount')
      );

      validations = validations.concat(this.hasBudgetEndTime(camp));
      validations = validations.concat(this.isValidAds(camp));
    });
    return validations;
  }

  hasBudgetEndTime(camp) {
    let validations = [];
    if (camp.budgetType.includes('lifetime')) {
      validations.push(this.validate(camp.budgetEndTime, 'Select budget end time'));
    }

    return validations;
  }

  isValidAds(camp) {
    let validations = [];
    if (camp.bmId && this.state.blacklistedAds && this.state.blacklistedAds.length) {
      this.state.blacklistedAds.forEach(ad => {
        if (ad.blacklisted_bm && ad.blacklisted_bm[camp.bmId]) {
          validations.push(this.validate(false, FB.ERRORS.BLOCKED_CREATIVE_FOR_BM_ID));
        }
      });
    }
    return validations;
  }

  validate(condition, errorMessage) {
    return () => {
      if (!condition) {
        console.warn(errorMessage);
        toast.error(errorMessage);
        return false;
      }
      return true;
    };
  }

  validateSetup() {
    if (this.state.isBulkDuplication) {
      return this.validateDuplication();
    } else {
      return this.validateCreation();
    }
  }
  validateCreator(targets) {
    const xs = targets.flatMap(target => {
      return this.validate(target.campaignCreator, 'Select creator');
    });
    return xs.every(x => x());
  }

  validateAdsets() {
    const xs = this.state.adsets.flatMap((adset, idx) => {
      switch (adset.type) {
        case 'SINGLE':
          return [
            this.validate(adset.ads.length > 0, 'SINGLE adset should have at least one ad'),
            this.validate(
              adset.ads.every(ad => ad.text.trim() !== ''),
              "SINGLE adset's ad should have all text fields populated"
            ),
            this.validate(
              adset.ads.every(ad => byteSize(ad.title) <= FB.MAX_TITLE_LENGTH),
              ` Adset ${idx + 1}, title - is longer than 255 bytes`
            )
          ];
        case 'SINGLE_VIDEO':
          return [
            this.validate(adset.ads.length > 0, 'SINGLE adset should have at least one ad'),
            this.validate(
              adset.ads.every(ad => byteSize(ad.title) <= FB.MAX_TITLE_LENGTH),
              ` Adset ${idx + 1}, title - is longer than 255 bytes`
            )
          ];
        case 'DYNAMIC':
          return [
            this.validate(adset.ads[0].image.length > 0, 'Dynamic adset should have at least one image'),
            this.validate(
              adset.ads[0].text.filter(x => x.trim() !== '').length > 0,
              'Dynamic adset should have at least one text'
            ),
            this.validate(
              adset.ads.every(ad => byteSize(ad.title) <= FB.MAX_TITLE_LENGTH),
              ` Adset ${idx + 1}, title - is longer than 255 bytes`
            )
          ];

        default:
          throw new Error(`Unknown adset type: ${adset.type}`);
      }
    });

    return xs.every(x => x());
  }

  validateData = async data => {
    let validationErrors = {};
    try {
      await arraySchema.validate(data, { abortEarly: false });
      this.setState({ validationErrors });
      return validationErrors;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
        Object.keys(newErrors).forEach(key => {
          toast.error(newErrors[key]);
        });
        this.setState({ validationErrors: newErrors });
        return newErrors;
      }
    }
  };
  setDefaultCc(idx, idx2, previousCc, currentCc) {
    if (currentCc.length) {
      this.updateOneCc(idx, idx2, currentCc[0].id);
    } else {
      this.updateOneCc(idx, idx2, '');
    }
    const parentsList = this.state.parentsList;
    let ccList = this.getConversionList(
      parentsList[idx].campaignsList[idx2].customConversions,
      parentsList.bidStrategyType
    );
    let newList = [];
    if (previousCc.length && this.props.task === TASKS.DUPLICATE) {
      let parsedCcName = previousCc[0].name.split('-')[0];
      let needle = Number(previousCc[0].name.split('-')[1]);
      if (parsedCcName && parsedCcName.toLowerCase().includes('sites')) {
        newList = ccList.filter(cc => {
          return cc.label.toLowerCase().includes('sites');
        });
        let newValueList = newList
          .map(cc => Number(cc.label.split('-')[1]))
          .flat()
          .filter(e => e);
        if (newValueList.length) {
          const closest = newValueList.reduce((a, b) => {
            return Math.abs(b - needle) < Math.abs(a - needle) ? b : a;
          });
          currentCc = newList.find(cc => cc.label.includes(closest));
          this.updateOneCc(idx, idx2, currentCc.value);
        }
      }
    }
  }

  getTargetAndBudget() {
    const { prefix, campaignCreator, useTrackingInUrl, sourceAds, isBulkDuplication } = this.state;
    const parentsList = JSON.parse(JSON.stringify(this.state.parentsList));
    let { tags } = this.state;
    tags = Array.isArray(tags) ? tags.join(',') : tags;
    if (isBulkDuplication) {
      const camp = parentsList[0].campaignsList;
      for (const parent of parentsList) {
        parent.campaignsList = camp;
      }
    }
    const pacing_type = ['standard'];
    const billing_event = 'IMPRESSIONS';
    const campaign = {
      adsets: this.state.adsets
    };
    clearAdsetFields({ campaign: campaign });

    const targetingTemplate = camp => {
      let data = {
        prefix: isBulkDuplication ? camp.prefix : prefix,
        fromPlatform: PLATFORMS.FACEBOOK2,
        geoType: camp.geoType,
        country: Array.isArray(camp.countries) ? camp.countries.join(',') : camp.countries,
        geoName: Array.isArray(camp.geoName) ? camp.geoName.join('') : camp.geoName,
        duplicationType: camp.duplicationType,
        site_code: camp.site_code,
        account: camp.destinationAccount,
        mobilePlatforms: camp.mobilePlatforms,
        budget: camp.budget,
        bid_amount: camp.bidAmount,
        accountCodeName: camp.accountName,
        campaignNote: camp.campaignNote,
        bid_constraints:
          camp.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS'
            ? { roas_average_floor: camp.roasAverageFloor * 10000 }
            : undefined,
        genders: camp.genders,
        age_min: camp.age_min,
        age_max: camp.age_max,
        status: prefix === 'xx' ? FB.PAUSED : camp.status.toUpperCase(),
        budgetType: camp.budgetType,
        bid_strategy: camp.bidStrategyType,
        start_time: camp.campaignStartTime ? formatNYTimestamp(moment(camp.campaignStartTime)) : undefined,
        stop_time: camp.campaignStopTime ? formatNYTimestamp(moment(camp.campaignStopTime)) : undefined,
        end_time: camp.budgetEndTime ? formatNYTimestamp(moment(camp.budgetEndTime)) : undefined,
        page: camp.page,
        platform: camp.platform,
        conversion: camp.cc,
        website: camp.website,
        language: camp.language,
        qs: [camp.qs ? camp.qs : undefined, camp.knownUrlParams ? camp.knownUrlParams.join('&') : undefined]
          .filter(t => t)
          .join('&'),
        freeText: camp.freeText,
        objective: camp.campaignObjective.toUpperCase(),
        widgetCodeName: camp.widgetCodeName,
        widgetLanguageCode: camp.widgetLanguageCode,
        widgetVersion: camp.widgetVersion,
        article: camp.article,
        widget_id: camp.widget_id,
        widget_path: camp.widget_path,
        widget_title: camp.widget_title,
        campaignCreator: camp.campaignCreator,
        presetName: this.state.preset,
        pacing_type,
        billing_event,
        campaign: this.state.isBulkDuplication ? camp.campaign : campaign,
        isSwag: this.isSwag,
        useTrackingInUrl,
        sourceAds,
        tags: camp.tags,
        isBulkDuplication,
        paramSub: camp.paramSub,
        sourceCampaignName: camp.sourceCampaignName,
        creativeId: camp.creativeId,
        blockedForBmIds: camp.blockedForAccounts,
        bmId: camp.bm,
        multi_advertiser: camp.multi_advertiser,
        text_generation: camp.text_generation
      };
      if (camp.subid4) {
        data.subid4 = camp.subid4;
      }
      return data;
    };

    const targetsPerGeo = parentsList.map(parent => {
      parent.campaignsList = parent.campaignsList.map(camp => {
        camp.widgetCodeName = parent.widgetCodeName;
        camp.widgetLanguageCode = parent.widgetLanguageCode;
        camp.widgetVersion = parent.widgetVersion;
        camp.article = parent.article;
        camp.widget_id = parent.widget_id;
        camp.widget_path = parent.widget_path;
        camp.widget_title = parent.widget_title;
        camp.campaignCreator = this.isSwag ? this.setCreator(campaignCreator) : '';
        camp.tags = parent.tags ? parent.tags : tags;
        camp.sourceCampaignName = parent.sourceCampaignName;
        camp.creativeId = parent.creativeId || '';
        camp.blockedForAccounts = parent.blockedForAccounts || '';
        if (isBulkDuplication) {
          camp.sourceAds = parent.sourceAds;
          camp.campaign = { adsets: parent.adsets };
          clearAdsetFields(camp);
          camp.campaignCreator = this.isSwag
            ? getNewCreatorValue(parent.campaignCreator, this.props.creatorsList) || campaignCreator
            : '';
          if (!this.state.prefix) {
            camp.prefix = parseCampaignName(PLATFORMS.FACEBOOK, parent.sourceCampaignName).prefix;
          } else {
            camp.prefix = this.state.prefix;
          }
        }

        if (this.isSwag && camp.paramSub) {
          camp.subid4 = true;
        }
        return camp;
      });
      parent.campaignsList.splice(0, 1);
      return parent.campaignsList.map(camp => targetingTemplate(camp));
    });
    const targets = targetsPerGeo.flat();
    return {
      targets
    };
  }

  async submit() {
    if (!this.validateSetup()) {
      return;
    }

    if (!this.validateAdsets()) {
      return;
    }

    if (this.props.programOptions.spm_access_block_with_message) {
      window.location.reload();
    }

    const { targets } = this.getTargetAndBudget();

    if (this.isSwag) {
      if (!this.validateCreator(targets)) {
        return;
      }
    }

    const validation = await this.validateData(targets);
    if (!this.isEmptyObject(validation)) {
      toast.error(
        `Validation error: Some fields are incorrect or missing. Please review the form and ensure all required information is filled out correctly.`
      );
      console.log(`Validation request has failed: ${JSON.stringify(this.state.validationErrors)}`);
      return;
    }
    console.dir(targets, { colors: true });
    confirmAlert({
      title: 'Create',
      message: `You will create ${targets.length} campaigns. Are you sure you want to do this?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.createCampaigns(targets);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  upsertSnapshot() {
    this.props.upsertSnapshot(this.state);
  }

  createCampaigns(targets) {
    this.props.createCampaign(targets);
  }

  addCampaign(idx, idx2) {
    const parentsList = this.state.parentsList;
    let i = 0;

    while (i < parentsList[idx].campaignsList[0].times) {
      const newCampaign = JSON.parse(JSON.stringify(parentsList[idx].campaignsList[idx2]));
      parentsList[idx].campaignsList.push(newCampaign);
      i++;
    }
    this.setState({ parentsList });
  }

  getCCNumber(ccList, ccValue) {
    const element = ccList.find(el => el.value === ccValue);
    return element.label.split(' ').pop();
  }

  getCCValue(ccList, ccNumber) {
    const element = ccList.find(el => el.label.endsWith(ccNumber));
    return element?.value;
  }

  repeatCampaign(idx, idx2) {
    const parentsList = this.state.parentsList;
    const template = { ...parentsList[idx].campaignsList[idx2] };
    const ccList = this.getConversionList(template.customConversions, template.bidStrategyType);
    const times = template.times;
    const repeatCC = template.repeat.cc;
    const intervalCC = template.interval.cc;
    const repeatBid = template.repeat.bid;
    const intervalBid = template.interval.bid;
    const repeatStartDateTime = template.repeat.startDateTime;
    const intervalStartDateTime = template.interval.startDateTime;
    const useDepc = parentsList[idx].useDynamicCc;
    let cc = useDepc ? template.cc.split(' ')[1] : this.getCCNumber(ccList, template.cc);
    let bid = Number(template.bidAmount);
    let startTime = template.campaignStartTime !== undefined ? moment(template.campaignStartTime).clone() : undefined;

    let ccArr = [cc];
    let bidArr = [bid];
    let minuteArr = [startTime];

    for (let i = 1; i < repeatCC && !Number.isNaN(cc); i++) {
      cc = Number(cc) + intervalCC;
      ccArr.push(cc.toFixed(2));
    }
    for (let i = 1; i < repeatBid; i++) {
      bid = bid + intervalBid;
      bidArr.push(bid.toFixed(2));
    }
    for (let i = 1; i < repeatStartDateTime && startTime; i++) {
      startTime = startTime.clone().add(intervalStartDateTime, 'minutes'); // Change 'hours' to 'minutes'
      minuteArr.push(startTime);
    }
    for (let ccVal of ccArr) {
      for (let bidVal of bidArr) {
        for (let minute of minuteArr) {
          for (let time = 1; time <= times; time++) {
            const item = {
              ...template,
              bidAmount: bidVal,
              campaignStartTime: minute
            };
            const ccValue = useDepc ? `depc ${ccVal}` : this.getCCValue(ccList, ccVal);
            this.setOneCc(item, ccValue);
            parentsList[idx].campaignsList.push(item);
          }
        }
      }
    }

    this.setState({ parentsList });
  }

  deleteCampaign(idx, idx2) {
    const parentsList = this.state.parentsList;
    parentsList[idx].campaignsList.splice(idx2, 1);
    this.setState({ parentsList });
  }

  addParent(idx) {
    const parentsList = this.state.parentsList;
    const newParent = JSON.parse(JSON.stringify(parentsList[idx]));
    parentsList.push(newParent);
    this.setState({ parentsList });
  }

  deleteParent(idx) {
    const parentsList = this.state.parentsList;
    parentsList.splice(idx, 1);
    this.setState({ parentsList });
  }

  getDestinationAccountOptions() {
    return this.props.accounts
      .map(x => ({
        label: x.desc,
        value: x.id || x.account_id,
        isOptionDisabled: x.fb_account_status !== undefined && x.fb_account_status !== null && x.fb_account_status !== 1
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  addParamSub(idx, idx2) {
    const parentsList = this.state.parentsList;
    const value = !parentsList[idx].campaignsList[idx2].paramSub;
    parentsList[idx].campaignsList[idx2].paramSub = value;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'paramSub', value);
    });
  }

  fetchArticleData = async idx => {
    const widget_id = this.state.parentsList[idx].widget_id;
    const siteList = getPlatformSites(PLATFORMS.FACEBOOK);
    for (;;) {
      try {
        const site = siteList[Math.floor(Math.random() * siteList.length)].name;
        const res = await getArticleData(site, widget_id);
        const imgLink = `https://img.${site}/articles/${widget_id}/`;
        const imagesAmount = this.state.parentsList[idx].imagesAmount;
        const parsedData = JSON.parse(res.substr(61).slice(0, -1)).blocks.map(block => `${imgLink}${block.image.name}`);
        const linkList = imagesAmount ? parsedData.slice(0, imagesAmount) : parsedData;
        const batchList = this.makeBatch(linkList, 50);
        const adsets = [];
        batchList.forEach(xs => {
          adsets.push({
            images: xs,
            titles: [],
            texts: []
          });
        });
        this.addCreatives(adsets);
        break;
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
        continue;
      }
    }
  };

  makeBatch(arr, num) {
    let chunks = [];
    let i = 0;
    while (i < arr.length) {
      chunks.push(arr.slice(i, (i += num)));
    }
    return chunks;
  }

  handleUpdatingPreset(value) {
    this.setState({ presetName: value });
  }

  updatePresetFields(idx, idx2, field) {
    const parentsList = this.state.parentsList;
    if (field.includes('start_date')) {
      parentsList[idx].campaignsList[idx2].presetStartDate = true;
    } else {
      parentsList[idx].campaignsList[idx2].presetStartDate = false;
    }
    if (field.includes('end_date')) {
      parentsList[idx].campaignsList[idx2].presetEndDate = true;
    } else {
      parentsList[idx].campaignsList[idx2].presetEndDate = false;
    }
    parentsList[idx].campaignsList[idx2].presetFields = field;
    this.setState({ parentsList }, () => {
      this.handleTemplateSelect(parentsList, idx, idx2, 'presetFields', field);
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'presetStartDate',
        parentsList[idx].campaignsList[idx2].presetStartDate
      );
      this.handleTemplateSelect(
        parentsList,
        idx,
        idx2,
        'presetEndDate',
        parentsList[idx].campaignsList[idx2].presetEndDate
      );
    });
  }

  handleChangeCampaignNames(selectedOptions) {
    this.setState({ selectedCampaigns: selectedOptions });
  }

  handleCreateOption(inputValue) {
    const { campaignOptions } = this.state;
    const normalizedInput = inputValue.replace(/\s+/g, ' ');
    const inputCampaigns = normalizedInput.split(/[, ]+/);
    const validCampaigns = inputCampaigns
      .filter(value => value.length >= 20 && value.length <= 40)
      .map(name => ({
        value: name,
        label: name
      }));

    this.setState({
      campaignOptions: [...campaignOptions, ...validCampaigns],
      selectedCampaigns: [...this.state.selectedCampaigns, ...validCampaigns]
    });
  }
  renderParent(parent, idxP) {
    return (
      <React.Fragment key={'parent' + idxP}>
        <Grid container direction={'row'}>
          {this.state.isBulkDuplication ? null : (
            <StyledGrid container presetMode={this.state.preset && this.state.preset.length} direction="row">
              <Grid container spacing={1}>
                <Grid item>
                  <StyledParentAvatar>
                    <Typography gutterBottom variant={'subtitle2'}>
                      {FB.CHARS[idxP]}
                    </Typography>
                  </StyledParentAvatar>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="row" spacing={1} alignItems="flex-end">
                    <Grid item xs sm={6}>
                      <WidgetSelector
                        modify={(name, code, version) => this.updateWidgetFields(idxP, name, code, version)}
                        articleList={this.props.articleList}
                        block={this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B}
                        options={parent}
                        allowedVersions={this.getAllowedWidgetVersions()}
                        platform={PLATFORMS.FACEBOOK2}
                        programOptions={this.props.programOptions}
                      />
                    </Grid>

                    {this.state.parentsList[idxP].widget_id && !this.props.sourceData ? (
                      <>
                        <Grid item>
                          <Grid item>
                            <Typography gutterBottom variant={'subtitle2'}>
                              Images amount
                            </Typography>
                          </Grid>
                          <input
                            style={{ width: '120px', lineHeight: '27px' }}
                            type="number"
                            name="images_amount"
                            step={1}
                            min="1"
                            max="50"
                            value={parent.imagesAmount}
                            onChange={e => this.updateImagesAmount(idxP, e.target.value)}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            component="span"
                            onClick={() => this.fetchArticleData(idxP)}
                          >
                            Retrieve images
                          </Button>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => this.addParent(idxP)}>
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => this.deleteParent(idxP)} size="large">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </StyledGrid>
          )}

          {parent.campaignsList.map((camp, idxC) => {
            if (idxC === 0 || this.state.parentsList[idxP].showChilds) {
              return this.renderCampaign(camp, idxC, idxP);
            }
            return null;
          })}
        </Grid>
      </React.Fragment>
    );
  }

  renderCampaign(camp, idxC, idxP) {
    const { parent, child } = this.state.loadingRows;
    return (
      <React.Fragment key={idxC}>
        <StyledTemplateGrid container direction={'row'}>
          <StyledPaper idx={idxC} presetMode={this.state.preset && this.state.preset.length}>
            <StyledContainer container item spacing={1}>
              {parent &&
              child &&
              ((child.includes(idxC) && parent.includes(idxP)) || (child.includes(0) && parent.includes(idxP))) ? (
                <StyledDiv isLoadingAcc={this.state.isLoadingAcc}>
                  <Loader />
                </StyledDiv>
              ) : null}
              <Grid item>
                <StyledAvatar idx={idxC}>
                  <Typography gutterBottom variant="subtitle2">{`${FB.CHARS[idxP]}${idxC}`}</Typography>
                </StyledAvatar>
              </Grid>
              <Grid container item sm={12} alignItems="flex-end" spacing={1}>
                <Grid item>
                  <Typography gutterBottom variant="subtitle2">
                    Campaign objective
                  </Typography>
                  <Select
                    onChange={x => this.updateObjective(idxP, idxC, x.value)}
                    options={FB.CAMPAIGN_OBJECTIVES}
                    value={getSelectValue(FB.CAMPAIGN_OBJECTIVES, camp.campaignObjective)}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Bid strategy
                  </Typography>
                  <Select
                    value={getSelectValue(FB.BID_STRATEGY_TYPES, camp.bidStrategyType)}
                    onChange={x => this.updateBidStrategyType(idxP, idxC, x.value)}
                    options={FB.BID_STRATEGY_TYPES}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Destination account
                  </Typography>
                  <Select
                    onChange={x => this.updateDestinationAccount(idxP, idxC, x.value)}
                    options={this.state.accounts}
                    value={getValue(this.state.accounts, camp.destinationAccount)}
                    isOptionDisabled={option => option.isOptionDisabled}
                    placeholder={this.isSwag && !this.state.parentsList[idxP].widget_id ? 'Select widget' : 'Select...'}
                    isDisabled={this.isSwag && !this.state.parentsList[idxP].widget_id}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Domain
                  </Typography>
                  <Select
                    isClearable
                    onChange={x => this.updateOneDomain(idxP, idxC, x?.value)}
                    options={this.state.siteList}
                    value={getValue(this.state.siteList, camp.site_code)}
                  />
                </Grid>
                {this.props.allowPageSelection ? (
                  <Grid item>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Page
                    </Typography>
                    <input
                      type="text"
                      name="page"
                      style={{ lineHeight: '27px' }}
                      value={camp.page}
                      onChange={e => this.updatePage(idxP, idxC, e.target.value)}
                    />
                  </Grid>
                ) : null}
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Device
                  </Typography>
                  <Select
                    onChange={x => this.updateOneDevice(idxP, idxC, x.value)}
                    options={this.getDeviceList()}
                    value={getSelectValue(this.getDeviceList(), camp.device)}
                  />
                </Grid>
                <Grid item>
                  <Grid item container direction={'row'}>
                    <Grid item sm={4}>
                      <Typography gutterBottom variant={'subtitle2'}>
                        CC
                      </Typography>
                    </Grid>
                    <Grid item>
                      <input
                        type="checkbox"
                        style={{ padding: '7px 4px' }}
                        checked={camp.useDynamicCc}
                        onChange={() => this.handleChangeCcCheckbox(idxP, idxC)}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <Typography gutterBottom variant={'subtitle2'}>
                        depc
                      </Typography>
                    </Grid>

                    {idxC === 0 && (
                      <Grid item sm={1}>
                        <DuplicationPopover
                          idx={idxC}
                          camp={camp}
                          field={'cc'}
                          updateInterval={(idx, value) => this.updateInterval(idxP, idx, value, 'cc')}
                          updateRepeat={(idx, value) => this.updateRepeat(idxP, idx, value, 'cc')}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Select
                    isClearable
                    onChange={x => this.updateOneCc(idxP, idxC, x?.value)}
                    options={this.getConversionList(camp.customConversions, camp.bidStrategyType)}
                    value={getSelectValue(
                      this.getConversionList(camp.customConversions, camp.bidStrategyType),
                      camp.cc
                    )}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Select targeted geo
                  </Typography>
                  <Select
                    isMulti
                    onChange={xs => this.updateCountries(idxP, idxC, getOnlyValue(xs))}
                    options={this.countryList}
                    value={getMultiSelectValue(this.countryList, camp.countries)}
                  />
                </Grid>
                <Grid item>
                  <Grid container item direction={'column'}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Geo type
                    </Typography>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <Switch
                          size="small"
                          checked={camp.geoType === 'include'}
                          onChange={e =>
                            this.updateCountriesGroup(idxP, idxC, e.target.checked ? 'include' : 'exclude')
                          }
                          color="primary"
                        />
                      }
                      label={
                        camp.geoType === 'include' ? (
                          <Typography gutterBottom variant={'subtitle2'}>
                            Include
                          </Typography>
                        ) : (
                          <Typography gutterBottom variant={'subtitle2'}>
                            Exclude
                          </Typography>
                        )
                      }
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                {camp.bidStrategyType === 'LOWEST_COST_WITH_BID_CAP' || camp.bidStrategyType === 'COST_CAP' ? (
                  <>
                    <Grid item>
                      <Grid item container direction={'row'}>
                        <Grid item sm={6}>
                          <Typography gutterBottom variant={'subtitle2'}>
                            Bid
                          </Typography>
                        </Grid>
                        {idxC === 0 && (
                          <Grid item>
                            <DuplicationPopover
                              idx={idxC}
                              camp={camp}
                              field={'bid'}
                              updateInterval={(idx, value) => this.updateInterval(idxP, idx, value, 'bid')}
                              updateRepeat={(idx, value) => this.updateRepeat(idxP, idx, value, 'bid')}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <input
                        style={{ width: '65px', lineHeight: '27px' }}
                        type="number"
                        name="bid_amount"
                        step={0.01}
                        min="0"
                        value={camp.bidAmount}
                        onChange={e => this.updateBidAmount(idxP, idxC, e.target.value)}
                      />
                    </Grid>
                  </>
                ) : null}
                {camp.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS' ? (
                  <>
                    <Grid item>
                      <Grid item container direction={'row'}>
                        <Grid item sm={6}>
                          <Typography gutterBottom variant={'subtitle2'}>
                            ROAS&nbsp;average&nbsp;floor
                          </Typography>
                        </Grid>
                      </Grid>
                      <input
                        style={{ width: '135px', lineHeight: '27px' }}
                        type="number"
                        name="bid_amount"
                        step={0.001}
                        min="0.001"
                        value={camp.roasAverageFloor}
                        onChange={e => this.updateRoasAverageFloor(idxP, idxC, e.target.value)}
                      />
                    </Grid>
                  </>
                ) : null}
                {camp.currency && (
                  <Grid item sm={1}>
                    <Typography gutterBottom variant={'subtitle2'}>
                      {camp.currency}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Budget
                  </Typography>
                  <Select
                    value={{ label: camp.budgetType, value: camp.budgetType }}
                    onChange={x => this.updateBudgetType(idxP, idxC, x.value)}
                    options={FB.BUDGET_TYPE}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Amount
                  </Typography>
                  <input
                    style={{ width: '65px', lineHeight: '27px' }}
                    type="number"
                    name="budget"
                    min="0"
                    value={camp.budget}
                    onChange={e => this.updateBudget(idxP, idxC, e.target.value)}
                  />
                </Grid>
                {camp.destAccCurrency && (
                  <Typography gutterBottom variant={'subtitle2'}>
                    {camp.destAccCurrency}
                  </Typography>
                )}
                {(camp.budgetType === 'lifetime adset' || camp.budgetType === 'lifetime campaign') && (
                  <Grid item>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Adset end time
                    </Typography>
                    <DatePicker
                      sx={{ zIndex: '2' }}
                      selected={camp.budgetEndTime ? new Date(camp.budgetEndTime) : ''}
                      onChange={x => this.updateBudgetEndTime(idxP, idxC, x)}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={
                        camp.campaignStartTime
                          ? moment(camp.campaignStartTime)
                              .add(1, 'day')
                              .toDate()
                          : moment()
                              .add(1, 'day')
                              .toDate()
                      }
                    />
                  </Grid>
                )}
                <Grid item>
                  <Grid item container direction={'row'}>
                    <Grid item sm={10}>
                      <Typography gutterBottom variant={'subtitle2'}>
                        Start date
                      </Typography>
                    </Grid>
                    {idxC === 0 && (
                      <Grid item sm={1}>
                        <DuplicationPopover
                          idx={idxC}
                          camp={camp}
                          field={'startDateTime'}
                          updateInterval={(idx, value) => this.updateInterval(idxP, idx, value, 'startDateTime')}
                          updateRepeat={(idx, value) => this.updateRepeat(idxP, idx, value, 'startDateTime')}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <DatePicker
                    sx={{ zIndex: '2' }}
                    selected={camp.campaignStartTime ? new Date(camp.campaignStartTime) : ''}
                    onChange={x => this.updateCampaignStartTime(idxP, idxC, x)}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={moment().toDate()}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Stop date
                  </Typography>
                  <DatePicker
                    sx={{ zIndex: '2' }}
                    selected={camp.campaignStopTime ? new Date(camp.campaignStopTime) : ''}
                    onChange={x => this.updateCampaignStopTime(idxP, idxC, x)}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={
                      camp.campaignStartTime
                        ? moment(camp.campaignStartTime)
                            .add(1, 'day')
                            .toDate()
                        : moment()
                            .add(1, 'day')
                            .toDate()
                    }
                  />
                </Grid>
                {idxC === 0 && (
                  <Grid item>
                    <Typography gutterBottom variant={'subtitle2'}>
                      Times
                    </Typography>
                    <input
                      style={{ width: '50px', lineHeight: '27px' }}
                      type="number"
                      name="times"
                      value={camp.times}
                      onChange={e => this.updateTimes(idxP, idxC, e.target.value)}
                    />
                  </Grid>
                )}
                <CampaignsNote
                  platform={PLATFORMS.FACEBOOK2}
                  value={camp.campaignNote}
                  onChange={e => this.updateOneCampaignNote(idxP, idxC, e.target.value)}
                />
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Url parameters
                  </Typography>
                  <Select
                    isMulti
                    onChange={xs =>
                      this.updateKnownUrlParams(
                        idxP,
                        idxC,
                        xs.map(x => x.value)
                      )
                    }
                    options={FB.KNOWN_URL_PARAMS}
                    isClearable
                    value={getMultiSelectValue(FB.KNOWN_URL_PARAMS, camp.knownUrlParams)}
                  />
                </Grid>
                {this.isSwag && (
                  <>
                    <Grid item>
                      <Typography gutterBottom variant={'subtitle2'}>
                        Query parameters
                      </Typography>
                      <input
                        style={{ lineHeight: '27px' }}
                        type="text"
                        name="freeParameters"
                        value={camp.qs}
                        onChange={e => this.updateQs(idxP, idxC, e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom variant="subtitle2">
                        Add parameter subid4
                      </Typography>
                      <Grid item>
                        <input
                          style={{ lineHeight: '25px', marginBottom: '15px' }}
                          type="checkbox"
                          onChange={x => this.addParamSub(idxP, idxC, x)}
                          checked={camp.paramSub}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Multi advertiser
                  </Typography>
                  <Grid item>
                    <input
                      type="checkbox"
                      style={{ lineHeight: '25px', marginBottom: '15px' }}
                      checked={camp.multi_advertiser}
                      onChange={() => this.toggleMultiAdvertiser(idxP, idxC)}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Text generation
                  </Typography>
                  <Grid item>
                    <input
                      type="checkbox"
                      style={{ lineHeight: '25px', marginBottom: '15px' }}
                      checked={camp.text_generation}
                      onChange={() => this.toggleTextGeneration(idxP, idxC)}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <IconButton onClick={() => this.toggleSetup(idxP, idxC)} size="large">
                    {camp.secondSetup ? <ArrowDropUp fontSize={'small'} /> : <ArrowDropDown fontSize={'small'} />}
                  </IconButton>
                  <IconButton onClick={() => this.addCampaign(idxP, idxC)} size="large">
                    <FileCopyOutlinedIcon fontSize={'small'} />
                  </IconButton>
                  <IconButton onClick={() => this.deleteCampaign(idxP, idxC)} size="large">
                    <CloseIcon fontSize={'small'} />
                  </IconButton>
                  {idxC === 0 && <Button onClick={() => this.repeatCampaign(idxP, idxC)}>repeat</Button>}
                  {idxC === 0 && (
                    <Button onClick={() => this.toggleChilds(idxP)}>
                      {this.state.parentsList[idxP].showChilds ? 'Hide' : 'Show'}
                    </Button>
                  )}
                  {idxC === 0 && <Button onClick={() => this.clearChilds(idxP)}>Clear</Button>}
                </Grid>
                {camp.secondSetup ? this.renderSecondSetup(camp, idxC, idxP) : null}
              </Grid>
            </StyledContainer>
          </StyledPaper>
        </StyledTemplateGrid>
      </React.Fragment>
    );
  }

  renderSecondSetup(camp, idxC, idxP) {
    return (
      <React.Fragment key={'secondSetup' + idxC}>
        <Grid container direction={'row'} spacing={1} sx={{ margin: '2px' }} alignItems="flex-end">
          <Grid item>
            <Typography gutterBottom variant={'subtitle2'}>
              Geo name
            </Typography>
            <input
              style={{ width: '100px', lineHeight: '27px' }}
              type="text"
              name="geoName"
              value={camp.geoName}
              onChange={e => this.updateGeoName(idxP, idxC, e.target.value)}
              onKeyDown={event => this.validateAlphaNumericGeo(event)}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom variant={'subtitle2'}>
              Genders
            </Typography>
            <Select
              onChange={x => this.updateOneGender(idxP, idxC, x?.value)}
              options={FB.GENDERS_LIST}
              isClearable
              value={getSelectValue(FB.GENDERS_LIST, camp.genders)}
            />
          </Grid>
          <Grid item sm={1}>
            <Typography gutterBottom variant={'subtitle2'}>
              Age min
            </Typography>
            <Select
              isClearable
              onChange={x => this.updateOneMinAge(idxP, idxC, x?.value)}
              options={FB.AGE_LIST}
              value={getSelectValue(camp.ageList.minAge, camp.age_min)}
            />
          </Grid>
          <Grid item sm={1}>
            <Typography gutterBottom variant={'subtitle2'}>
              Age max
            </Typography>
            <Select
              isClearable
              onChange={x => this.updateOneMaxAge(idxP, idxC, x?.value)}
              options={camp.ageList.maxAge}
              value={getSelectValue(camp.ageList.maxAge, camp.age_max)}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom variant={'subtitle2'}>
              Languages
            </Typography>
            <Select
              closeMenuOnSelect={false}
              isMulti
              onChange={xs =>
                this.updateLanguages(
                  idxP,
                  idxC,
                  xs.map(x => x.value)
                )
              }
              options={this.languageList}
              value={getMultiSelectValue(this.languageList, camp.language)}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
            <Select
              value={getSelectValue(FB.STATUS, camp.status)}
              onChange={x => this.updateStatus(idxP, idxC, x.value)}
              options={FB.STATUS}
            />
          </Grid>
          {this.state.isBulkDuplication ? null : (
            <>
              <Grid item>
                <Typography gutterBottom variant={'subtitle2'}>
                  Set from source campaign
                </Typography>
                <Select
                  isMulti
                  value={getMultiSelectValue(PRESET_OPTIONS, camp.presetFields)}
                  onChange={xs =>
                    this.updatePresetFields(
                      idxP,
                      idxC,
                      xs.map(x => x.value)
                    )
                  }
                  options={PRESET_OPTIONS}
                />
              </Grid>
              {camp.presetStartDate ? (
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Preset start date
                  </Typography>
                  <input
                    style={{ width: '100px', lineHeight: '27px' }}
                    key={'startDate'}
                    value={camp.presetStartDateValue}
                    onChange={e => this.updatePresetStartDate(idxP, idxC, e.target.value)}
                    type={'number'}
                  />
                </Grid>
              ) : null}
              {camp.presetEndDate ? (
                <Grid item>
                  <Typography gutterBottom variant={'subtitle2'}>
                    Preset end date
                  </Typography>
                  <input
                    style={{ width: '100px', lineHeight: '27px' }}
                    key={'endDate'}
                    value={camp.presetEndDateValue}
                    onChange={e => this.updatePresetEndDate(idxP, idxC, e.target.value)}
                    type={'number'}
                  />
                </Grid>
              ) : null}
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const { campaignOptions, selectedCampaigns } = this.state;

    return (
      <>
        <Grid item sm={1}>
          <Prefix
            platform={this.props.platform}
            value={{ label: this.state.prefix, value: this.state.prefix }}
            onChange={e => this.updatePrefix(e.value)}
            options={makeSelectOptions(this.props.programOptions?.prefix)}
          />
        </Grid>
        {this.isSwag && (
          <>
            <Grid item sm={1}>
              <Typography gutterBottom variant={'subtitle2'}>
                Creator
              </Typography>
              <Select
                value={getSelectValue(this.props.creatorsList, this.state.campaignCreator)}
                onChange={x => this.updateCreator(x.value)}
                options={this.props.creatorsList}
              />
            </Grid>
          </>
        )}
        <>
          {!this.state.isBulkDuplication && this.props.task === TASKS.CREATE ? (
            <Grid item sm={2}>
              <Typography gutterBottom variant={'subtitle2'}>
                Tags
              </Typography>
              <Select
                value={getMultiSelectValue(GLOBAL.TAGS, this.state.tags)}
                onChange={xs => this.updateTags(getOnlyValue(xs))}
                options={GLOBAL.TAGS}
                components={{ MultiValue: CustomMultiValue }}
                isClearable
                isMulti
              />
            </Grid>
          ) : null}
        </>
        <Grid item sm={4}>
          <Tooltip
            title={
              'You can paste a list of the comma or space separated campaign names. Example:\n' +
              'name1,\n' +
              'name2,\n' +
              'name3" or\n' +
              '"name1 name2"'
            }
          >
            <Typography gutterBottom variant={'subtitle2'}>
              Source campaigns {<HelpOutlineIcon color={'disabled'} fontSize={'small'} />}
            </Typography>
          </Tooltip>
          <CreatableSelect
            isMulti
            options={campaignOptions}
            value={selectedCampaigns}
            onChange={e => this.handleChangeCampaignNames(e)}
            onCreateOption={e => this.handleCreateOption(e)}
            placeholder="Type or paste campaign name..."
            formatCreateLabel={inputValue => `${inputValue}`}
          />
        </Grid>
        <Grid item>
          <Typography gutterBottom variant={'subtitle2'} color="textSecondary">
            {this.props.sourceData && this.props.sourceData.length ? this.state.parentsList.length : 0} campaigns for
            duplication
          </Typography>
          <Tooltip placement={'top-start'} title={'Get source campaigns'}>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => this.props.fetchFbBulkCampaigns(selectedCampaigns)}
            >
              Fetch campaigns
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant={'subtitle2'}>
            Source creatives
          </Typography>
          <input
            type="text"
            name="sourceCreatives"
            style={{ lineHeight: '27px', width: '300px' }}
            value={this.props.creativeHashes}
            onChange={e => this.props.updateCreativeHashes(e.target.value)}
            onPaste={this.props.handleCreativesPaste.bind(this)}
            onKeyDown={e => this.props.handleCreativeHashesKeyDown(e)}
          />
        </Grid>
        {this.state.isBulkDuplication ? null : (
          <>
            <Grid item sm={2}>
              <Typography gutterBottom variant={'subtitle2'}>
                Presets
              </Typography>

              <Select
                value={getSelectValue(this.getPresetOptions(), this.state.preset)}
                onChange={x => this.handleSelectPreset(x.value)}
                options={this.getPresetOptions()}
              />
            </Grid>
            <Grid item>
              <PresetButtonGroup
                setPreset={() => this.setPreset()}
                savePreset={() => this.savePreset(this.state.presetName)}
                saveAsPreset={() => this.saveAsPreset(this.state.presetName)}
                deletePreset={() => this.deletePreset()}
                handleUpdatingPreset={value => this.handleUpdatingPreset(value)}
                presetName={this.state.presetName}
                platform={PLATFORMS.FACEBOOK2}
              />
            </Grid>
            {this.props.task === TASKS.CREATE ? (
              <>
                <Grid item>
                  <input
                    type="checkbox"
                    style={{ lineHeight: '25px', marginBottom: '15px' }}
                    checked={this.state.saveCreator}
                    onChange={() => this.handleSaveCreator()}
                  />
                </Grid>
                <Grid item>
                  <Typography gutterBottom style={{ marginBottom: '10px' }} variant={'subtitle2'}>
                    Save selected creator
                  </Typography>
                </Grid>
              </>
            ) : null}
          </>
        )}

        {this.state.parentsList.map((parent, idx) => {
          if (this.state.isBulkDuplication) {
            return idx === 0 ? this.renderParent(parent, idx) : null;
          } else {
            return this.renderParent(parent, idx);
          }
        })}
        <Grid container item sm={12}>
          <Grid item xs={12} sm={12}>
            <AdsetsBuilder
              adsets={this.state.adsets}
              adsetTypeToCreate={this.state.adsetTypeToCreate}
              addAdset={maybeAdset => this.addAdset(maybeAdset)}
              deleteAdset={idx => this.deleteAdset(idx)}
              populateAllText={adset => this.populateAllText(adset)}
              modifyAdset={(idx, adset) => this.modifyAdset(idx, adset)}
              modifyAdsets={ad => this.modifyAdsets(ad)}
              videoCreatives={this.state.videoCreatives}
              onAddImage={(a, b) => this.onAddImage(a, b)}
              onAddDynamicImage={(a, b) => this.onAddDynamicImage(a, b)}
              changeAdsetTypeToCreate={x => this.changeAdsetTypeToCreate(x)}
              toggleAd={this.state.toggleAd}
              handleAdsDropdown={() => this.handleAdsDropdown()}
              platform={PLATFORMS.FACEBOOK2}
              toggleAdvantagePlusAdset={(adset, isChecked) => this.toggleAdvantagePlusAdset(adset, isChecked)}
              toggleAdvantagePlusCreative={ad => this.toggleAdvantagePlusCreative(ad)}
            />
          </Grid>
        </Grid>
        <Grid container item sm={8} sx={{ margin: '10px ' }} direction={'row'} spacing={1}>
          {this.state.isBulkDuplication ? null : (
            <>
              <Grid item sm={2}>
                <Select
                  value={getSelectValue(FB.AD_TYPE, this.state.adsetTypeToCreate)}
                  onChange={x => this.changeAdsetTypeToCreate(x.value)}
                  options={FB.AD_TYPE}
                />
              </Grid>
              <Grid item sm={2}>
                <Button fullWidth variant="outlined" color="primary" onClick={() => this.addAdset()}>
                  Add Adset
                </Button>
              </Grid>
            </>
          )}
          <Grid item sm={3}>
            <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
              {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
            </Button>
          </Grid>
          <Grid item sm={2}>
            <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
              {this.getSubmitButtonText()}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  async addCreatives(adsets) {
    if (this.state.adsetTypeToCreate === 'DYNAMIC') {
      const newAdsets = adsets.map(() => ({
        type: this.state.adsetTypeToCreate,
        ads: [{ image: [], text: [], title: [] }]
      }));

      this.setState({ adsets: newAdsets }, async () => {
        for (let adsetIndex = 0; adsetIndex < adsets.length; adsetIndex++) {
          const adset = adsets[adsetIndex];
          for (let imageIndex = 0; imageIndex < adset.images.length; imageIndex++) {
            const img = adset.images[imageIndex];
            const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
            blob.name = img.split('/').pop();

            await this.onAddDynamicImage(adsetIndex, { target: { files: [blob] } });

            const validatedAdset = this.state.adsets[adsetIndex];

            validatedAdset.ads[0].title = adset.titles;
            validatedAdset.ads[0].text = adset.texts;
            this.modifyAdset(adsetIndex, validatedAdset);
          }
        }
      });
    } else {
      if (this.props.creative) {
        const ads = [];
        for (const x of adsets) {
          for (const text of x.texts) {
            for (const title of x.titles) {
              for (const image of x.images) {
                ads.push({ title, text, image });
              }
            }
          }
        }

        const newAdsets = [
          {
            type: this.state.adsetTypeToCreate,
            ads: []
          }
        ];

        const adsetIndex = 0;

        this.setState({ adsets: newAdsets }, async () => {
          for (const [adIndex, ad] of ads.entries()) {
            const img = ad.image;
            const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
            blob.name = img.split('/').pop();
            await this.onAddImage(adsetIndex, { target: { files: [blob] } });

            const validatedAdset = this.state.adsets[adsetIndex];
            validatedAdset.ads[adIndex].title = ad.title;
            validatedAdset.ads[adIndex].text = ad.text;
            this.modifyAdset(adsetIndex, validatedAdset);
          }
        });
      } else {
        const newAdsets = adsets.map(() => ({
          type: this.state.adsetTypeToCreate,
          ads: []
        }));
        this.setState({ adsets: newAdsets }, async () => {
          for (let adsetIndex = 0; adsetIndex < adsets.length; adsetIndex++) {
            const adset = adsets[adsetIndex];
            for (let imageIndex = 0; imageIndex < adset.images.length; imageIndex++) {
              const img = adset.images[imageIndex];
              const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
              blob.name = img.split('/').pop();
              await this.onAddImage(adsetIndex, { target: { files: [blob] } });
              const validatedAdset = this.state.adsets[adsetIndex];
              this.modifyAdset(adsetIndex, validatedAdset);
            }
          }
        });
      }
    }
  }

  async addCreativesFromFbLibrary(adsets) {
    const newAdsets = [
      {
        type: this.state.adsetTypeToCreate,
        ads: []
      }
    ];
    const adsetIndex = 0;
    this.setState({ adsets: newAdsets }, async () => {
      for (const [adIndex, ad] of adsets[adsetIndex].ads.entries()) {
        const img = ad.image;
        const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
        blob.name = img.split('/').pop();
        await this.onAddImage(adsetIndex, { target: { files: [blob] } });

        const validatedAdset = this.state.adsets[adsetIndex];
        validatedAdset.ads[adIndex].title = ad.title;
        validatedAdset.ads[adIndex].text = ad.text;
        this.modifyAdset(adsetIndex, validatedAdset);
      }
    });
  }

  changeAdsetTypeToCreate(adsetTypeToCreate) {
    this.setState({ adsetTypeToCreate });
  }

  onAddDynamicImage(index, event) {
    const images = event;
    const errs = [];
    const warnings = [];

    if (images.length > 10) {
      const msg = 'Up to 10 images can be uploaded at a time';
      console.log(msg);
      return toast.error(msg);
    }

    const formData = new FormData();

    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        images.forEach((file, i) => {
          let filename_truncated = truncateFileName(file.name);

          if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], index, 'FB', warnings, errs)) {
            return;
          }

          formData.append(i, file);
        });
        return;
      })
      .then(() => {
        // FIXME: here we did not abort if errors are present
        // is this intended?
        displayWarnings(errs, warnings);

        const options = {
          minWidth: FB.MIN_WIDTH,
          minHeight: FB.MIN_HEIGHT,
          noDownscale: true
        };
        return storeFiles(formData, options);
      })
      .then(filenames => {
        const adset = this.state.adsets[index];
        adset.ads[0].image = adset.ads[0].image.concat(filenames);
        this.modifyAdset(index, adset);
      })
      .catch(error => {
        console.log(error);
        toast.error(error);
        Sentry.captureException(error);
      });
  }

  onAddImage(index, event) {
    const images = Array.from(event.target.files);

    return processFacebookImages(images, index, storeFiles, (index, filenames) => {
      this.updateAdsetWithImages(index, filenames);
    });
  }

  updateAdsetWithImages(adsetIndex, imgFilenames) {
    console.debug('FILENAMES', imgFilenames);
    if (imgFilenames) {
      const adsets = this.state.adsets;
      const adset = this.state.adsets[adsetIndex];
      const ads = imgFilenames.map(image => {
        return { image: getFullPath(image), text: '', title: '', advantage_placements_creative: false };
      });
      let adsetLength = adset.ads.length;
      const allAds = adsetLength + ads.length;
      let adsAmount = FB.ADSET_LIMIT - adsetLength;

      if (allAds >= FB.ADSET_LIMIT) {
        //Add new ads to the current adset until limit
        const currentAds = ads.slice(0, adsAmount);
        adset.ads = adset.ads.concat(currentAds);
        this.modifyAdset(adsetIndex, adset);
        // Rest of the ads split into equal parts
        const nextAds = ads.slice(adsAmount, ads.length);
        const adsList = this.makeBatch(nextAds, FB.ADSET_LIMIT);
        adsList.forEach(list => {
          adsets.push({ type: this.state.adsetTypeToCreate, ads: list });
        });
        //Add to the new adsets
        this.setState({ adsets });
      } else {
        adset.ads = adset.ads.concat(ads);
        this.modifyAdset(adsetIndex, adset);
      }
    }
  }

  async parseDuplicateData(sourceData) {
    let {
      country,
      objective,
      bid_strategy,
      billing_event,
      conversion,
      campaign: { adsets },
      name,
      widget_id,
      bid_amount,
      campaignNote,
      campaignCreator,
      tags
    } = sourceData;
    let creator = '';
    if (this.isSwag) {
      //if a user is trying to duplicate an ad without a creator, we put them as the creator instead of not duplicating https://shinez.slack.com/archives/G018P55DT2T/p1707644636038089?thread_ts=1707394791.201759&cid=G018P55DT2T
      creator = getNewCreatorValue(campaignCreator, this.props.creatorsList) || this.state.campaignCreator;
    }

    adsets = adsets.map(adset => {
      adset.ads = adset.ads.filter(ad => {
        return !(typeof ad.blacklisted === 'boolean' && ad.blacklisted === true);
      });
      return adset;
    });
    const sourceAds = getCampaignAds(sourceData);
    tags = tags ? (tags.includes(GLOBAL.TAG) ? tags : `${tags},${GLOBAL.TAG}`) : GLOBAL.TAG;

    let blacklistedAds = [];
    let blockedAds = sourceAds.filter(ad => ad.blacklisted && typeof ad.blacklisted === 'boolean');
    console.log({ blockedAds });
    sourceAds.forEach(ad => {
      if (ad.blacklisted && typeof ad.blacklisted !== 'boolean') {
        blacklistedAds.push({
          campaign_name: ad.name,
          creative_id: ad.creative,
          blacklisted_bm: ad.blacklisted
        });
      }
    });

    if (blockedAds && blockedAds.length > 0) {
      toast.error(
        `You are trying to duplicate campaign ${name} with blacklisted ads. ${blockedAds.length} ads will not be added for duplication`
      );
    }
    console.log({ blacklistedAds });
    this.setState({ blacklistedAds, blockedAds, sourceAds, campaignCreator: creator, tags });

    const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, name);
    this.fetchCampaigns(this.props.account);
    const bidStrategyType = bid_strategy || adsets[0].bid_strategy;
    const bidAmount = bid_amount || adsets[0].bid_amount;
    let bmId = null;
    const roasAverageFloor =
      adsets[0] && adsets[0].bid_constraints && adsets[0].bid_constraints.roas_average_floor
        ? adsets[0].bid_constraints.roas_average_floor
        : undefined;
    const acc = getAccount(this.props.account, this.props.accounts);

    let customConversions = await this.fetchOneConversionList(this.props.account);
    let optionalFilter = { filterConverions: false, cc: FB.QUIZ_CONVERSIONS };
    if (widget_id.includes(FB.QUIZ)) {
      optionalFilter.filterConverions = true;
    }
    customConversions = await filterConversionsByBmId(
      customConversions,
      acc.business_mgr_id,
      this.props.userMail,
      optionalFilter,
      FB.DYNAMIC_CC_USERS,
      this.isSwag
    );
    bmId = acc?.business_mgr_id;
    let cc = sourceData.conversion;
    if (sourceData?.campaign?.adsets?.[0]?.ads?.[0]?.url_tags?.includes('pixel_value')) {
      let internalCc = getInternalConversion(sourceData.campaign.adsets[0].ads[0].url_tags);
      if (internalCc) {
        cc = internalCc.value;
      }
    }
    if (sourceAds && sourceAds[0] && sourceAds[0].url_tags) {
      const s2sConversion = getPurchaseConversion(sourceAds[0].url_tags);
      if (s2sConversion) {
        cc = s2sConversion.value;
      }
    }
    const mobilePlatform = sourceData.campaign.adsets[0].user_os;
    const clv = articleCodeLanguageVersion(this.props.articleList, widget_id, parsedName.article);
    const parent = {
      [GLOBAL.KEY_WIDGET_CODE_NAME]: clv[0],
      [GLOBAL.KEY_WIDGET_LANGUAGE_CODE]: clv[1],
      [GLOBAL.KEY_WIDGET_VERSION]: clv[2],
      widget_id: widget_id,
      [GLOBAL.KEY_CAMPAIGN_NOTE]: campaignNote,
      tags,
      billing_event: billing_event,
      conversion: conversion,
      adsets: adsets,
      sourceCampaignName: name,
      prefix: parsedName.prefix,
      showChilds: true,
      campaignsList: [
        {
          //maybe just ...DEFAULT_CAMPAIGN, ?
          campaignObjective:
            objective.toLowerCase() === 'conversions' || objective.toLowerCase() === 'outcome_sales'
              ? 'outcome_sales'
              : 'outcome_traffic',
          site_code: parsedName.domain,
          countries: [country],
          geoName: country,
          duplicationType: 'group',
          geoType: 'include',
          accountName: parsedName.fullAccountName,
          customConversions: customConversions,
          campaignNote: campaignNote,
          destinationAccount: this.props.account,
          bmId,
          budget: getBudgetInfo(sourceData).budget / 100,
          budgetType: getBudgetInfo(sourceData).budgetType,
          bidStrategyType: bidStrategyType,
          bidAmount: bidAmount ? bidAmount / 100 : 0,
          roasAverageFloor: roasAverageFloor ? roasAverageFloor / 10000 : 0.001,
          times: 1,
          cc: cc,
          useDynamicCc: false,
          platform: parsedName.platform,
          website: getWebsite(this.state, parsedName.domain),
          mobilePlatforms: mobilePlatform ? mobilePlatform.join() : '',
          secondSetup: false,
          presetStartDate: false,
          presetEndDate: false,
          device: this.getDevices(parsedName.platform, mobilePlatform),
          genders: 0,
          ageList: {
            minAge: FB.AGE_LIST,
            maxAge: FB.AGE_LIST
          },
          language: [],
          freeText: '',
          presetStartDateValue: 0,
          presetStartTime: '00:00',
          presetEndDateValue: 0,
          presetEndTime: '00:00',
          tooltips: { text: '', open: false },
          status: 'active',
          interval: DEFAULT_CAMPAIGN.interval,
          repeat: DEFAULT_CAMPAIGN.repeat,
          presetFields: []
        }
      ]
    };
    return { parentsList: [parent], adsets: adsets, prefix: parsedName.prefix };
  }

  async parseBulkData(sourceData) {
    if (!sourceData || !sourceData.length) {
      toast.error('Source campaign data empty. Please check campaigns list.');
      return { parentsList: DEFAULT_PARENT, isBulkDuplication: false };
    }
    const parents = [];
    const blockedCampaigns = [];
    const blacklistedAds = [];
    let creator = '';
    let selectedCampaigns = this.state.selectedCampaigns;
    sourceData.forEach(camp => {
      const {
        billing_event,
        campaign: { adsets },
        name,
        widget_id,
        campaignCreator
      } = camp;
      let { tags } = camp.campaign;
      let destinationBdId = null;
      if (this.state.parentsList[0].campaignsList[0]?.bmId) {
        destinationBdId = this.state.parentsList[0].campaignsList[0].bmId;
      }
      if (camp.blacklisted) {
        if (typeof camp.blacklisted === 'boolean') {
          blockedCampaigns.push(name);
          return;
        } else {
          let blAd = this.constructBlacklistedAd(camp);
          if (blAd.blacklisted_bm && blAd.blacklisted_bm[destinationBdId] && destinationBdId) {
            blockedCampaigns.push(blAd.campaign_name);
            return;
          } else {
            blacklistedAds.push(blAd);
          }
        }
      }
      const clv = this.validateCampaign(camp, widget_id, name);
      if (!clv) {
        blockedCampaigns.push(name);
        return;
      }
      if (this.isSwag) {
        creator = this.setCreator(campaignCreator);
      }
      parents.push(this.constructParentObject(camp, billing_event, name, widget_id, creator, tags, clv));
    });
    if (blockedCampaigns.length) {
      selectedCampaigns = selectedCampaigns.filter(camp => !blockedCampaigns.includes(camp.value));
      toast.error(`Campaigns with blocked article ${blockedCampaigns.join(', ')}. Removed from the queue`);
    }
    selectedCampaigns = selectedCampaigns.filter(camp => sourceData.some(sourceItem => sourceItem.name === camp.value));
    return {
      parentsList: parents,
      isBulkDuplication: true,
      prefix: '',
      tags: '',
      blacklistedAds,
      selectedCampaigns,
      blockedCampaigns
    };
  }

  constructBlacklistedAd(camp) {
    return {
      campaign_name: camp.name,
      creative_id: camp.creative,
      blacklisted_bm: camp.blacklisted
    };
  }

  validateCampaign(camp, widget_id, name) {
    const parsedName = parseCampaignName(PLATFORMS.FACEBOOK, name);
    return articleCodeLanguageVersion(
      this.props.articleList,
      widget_id,
      parsedName.article,
      '',
      this.isSwag ? FB.ARTICLE_BLOCK_SWAG : FB.ARTICLE_BLOCK_B2B
    );
  }

  setCreator(campaignCreator) {
    //if a user is trying to duplicate an ad without a creator, we put them as the creator instead of not duplicating https://shinez.slack.com/archives/G018P55DT2T/p1707644636038089?thread_ts=1707394791.201759&cid=G018P55DT2T
    return (
      getNewCreatorValue(campaignCreator || this.state.userMail, this.props.creatorsList) || this.state.campaignCreator
    );
  }

  constructParentObject(camp, billing_event, name, widget_id, creator, tags, clv) {
    return {
      [GLOBAL.KEY_WIDGET_CODE_NAME]: clv[0],
      [GLOBAL.KEY_WIDGET_LANGUAGE_CODE]: clv[1],
      [GLOBAL.KEY_WIDGET_VERSION]: clv[2],
      [GLOBAL.KEY_CAMPAIGN_NOTE]: '',
      billing_event,
      showChilds: true,
      adsets: [
        {
          type: 'SINGLE',
          ads: [
            {
              image: camp.campaign.s3ImageUrl || camp.campaign.image_url || '',
              text: camp.campaign.text || '',
              title: camp.campaign.title || ''
            }
          ]
        }
      ],
      campaignsList: [DEFAULT_CAMPAIGN],
      sourceCampaignName: name,
      widget_id,
      sourceAds: [name],
      campaignCreator: creator,
      tags: tags ? (tags.includes(GLOBAL.TAG) ? tags : `${tags},${GLOBAL.TAG}`) : GLOBAL.TAG
    };
  }

  async parseData() {
    let parsed = null;
    if (this.props.sourceData.length === 1 && this.props.sourceData[0].objective) {
      parsed = await this.parseDuplicateData(this.props.sourceData[0]);
    } else {
      parsed = await this.parseBulkData(this.props.sourceData);
      if (parsed.parentsList.length) {
        toast.success(`Successfully read ${parsed.parentsList.length} campaigns`);
      }
    }
    return parsed;
  }
}

export default FacebookCreate2;
