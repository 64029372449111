import React, { useEffect, useState } from 'react';
import { Typography, Box, styled } from '@mui/material';
import { getServiceMessage } from '../Api';
import parse from 'html-react-parser';

const ServiceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh'
}));

const ContentBox = styled(Box)(({ theme }) => ({
  maxWidth: 640,
  width: '100%',
  textAlign: 'center'
}));

const StyledImage = styled('img')({
  padding: '10px 0',
  width: 640
});

const ServicePage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    getServiceMessage().then(data => {
      const message = data.find(el => el.isBlockSpm === true);
      setData(message);
    });
  }, []);

  return (
    <ServiceBox>
      <Typography variant="h4">{data.title}</Typography>
      <ContentBox mt={2}>
        <Typography gutterBottom variant="subtitle1">
          {parse(String(data.text))}
        </Typography>
        <StyledImage src="https://partners-blog.shinez.io/maintenance1.gif" alt="Website maintenance" />
      </ContentBox>
      <Typography variant="subtitle1">— The Team</Typography>
    </ServiceBox>
  );
};

export default ServicePage;
