import React, { Component } from 'react';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid, Typography, Button, Divider } from '@mui/material';
import * as Sentry from '@sentry/react';

import RevcontentBids from './RevcontentBids';
import RevcontentCreativeGroupBuilder from './RevcontentCreativeGroupBuilder';
import { storeFiles, getRevcontentConversions } from '../Api';
import { getPlatformSites } from '../Sites';
import { RC, TASKS, PLATFORMS, GLOBAL } from '../Constants';
import WidgetSelector from '../WidgetSelector';
import { toast } from 'react-toastify';
import { parseCampaignName } from '../NameParser';
import CampaignsNote from '../CampaignsNote';
import {
  truncateFileName,
  validateAndPrepareImage,
  displayWarnings,
  getImageDimensions
} from '../utils/imageUploadUtils';
import { getFileHash, validateCreativeGroups } from '../Shared';
import Prefix from '../Prefix';

const DEVICES = [RC.DESKTOP, RC.MOBILE, RC.TABLET];
const STATUS = [RC.ACTIVE, RC.INACTIVE];
const OS = {
  [RC.MOBILE]: [RC.IOS, RC.ANDROID],
  [RC.TABLET]: [RC.IOS, RC.ANDROID],
  [RC.DESKTOP]: [RC.MACOS, RC.LINUX, RC.WINDOWS]
};
const CAMPAIGN_TYPES = [RC.STANDARD, RC.AUTO];

class RevcontentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      osChoices: [],
      conversions: []
    };
    this.spendingModelList = [
      {
        label: 'Daily',
        value: 'daily'
      },
      {
        label: 'Unlimited',
        value: 'unlimited'
      }
    ];
    this.bidTypeList = [
      {
        label: 'Engagement',
        value: 'cpc'
      },
      {
        label: 'CPA ROI',
        value: 'cpa'
      }
    ];
    getRevcontentConversions('jonasttb').then(res => {
      const conversions = res.map(el => {
        return { label: el.name, value: el.id };
      });
      this.setState({ conversions: conversions });
    });
  }

  componentDidMount() {
    if (!this.props.loadedFromSnapshot) {
      this.props.modifyOptions(RC.KEY_BUDGET, 'unlimited');
      this.props.modifyOptions(RC.KEY_BID_TYPE, 'cpc');
      this.props.modifyOptions(RC.KEY_CAMPAIGN_TYPE, 'Auto');
      this.props.modifyOptions(RC.KEY_CONVERSION_RATE, '0.001');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerRowUpdate !== prevProps.triggerRowUpdate) {
      this.modifyBidsRows();
    }

    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      this.addCreatives(this.props.creativeFromFbLibrary);
    }

    if (this.props.creative) {
      this.props.creativeDone();

      let parsedName = {};
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);
      this.modifyOptions(RC.KEY_COUNTRY, parsedName.country || '');
      // this.modifyOptions(RC.KEY_OS, parsedName.country)
      this.modifyOptions(RC.KEY_SITE, parsedName.site || []);

      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(RC.KEY_DEVICE, RC.DESKTOP);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(RC.KEY_DEVICE, [RC.DESKTOP, RC.MOBILE, RC.TABLET].join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(RC.KEY_DEVICE, RC.MOBILE);
      } else {
        if (parsedName.platform && parsedName.platform.indexOf('adr') !== -1) {
          this.modifyOptions(RC.KEY_OS, 'Android');
          this.modifyOptions(RC.KEY_DEVICE, RC.MOBILE);
        }
        if (parsedName.platform && parsedName.platform.indexOf('ios') !== -1) {
          this.modifyOptions(RC.KEY_OS, 'iOS');
          this.modifyOptions(RC.KEY_DEVICE, RC.MOBILE);
        }
      }

      if (typeof this.props.creative.image_url === 'string') {
        this.addCreatives([
          {
            images: [this.props.creative.image_url],
            texts: [this.props.creative.text],
            titles: [],
            tooltips: []
          }
        ]);
      } else {
        this.addCreatives([
          {
            images: this.props.creative.image_url,
            texts: [this.props.creative.text],
            titles: [],
            tooltips: []
          }
        ]);
      }
    }
  }

  getBrandingText(domain) {
    const site = getPlatformSites(PLATFORMS.REVCONTENT.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.branding;
    } else {
      const error = "Can't find site corresponding to 3-letter domain " + domain + '. Branding text will be empty';
      console.log(error);
      toast.warn(error);
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.REVCONTENT.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
  }

  validateOptions(key) {
    if (key === RC.KEY_DEFAULT_BID) {
      const value = this.props.state.options[key];
      if (value < RC.MIN_BID) {
        console.log('Min bid reached');
        this.props.modifyOptions(key, RC.MIN_BID);
        return;
      }
      if (value > RC.MAX_BID) {
        console.log('Max bid reached');
        this.props.modifyOptions(key, RC.MAX_BID);
        return;
      }
    }

    if (key === RC.KEY_CONVERSION_RATE) {
      const value = this.props.state.options[key];
      if (value < RC.MIN_CONVERSION_BID) {
        console.log('Min bid reached');
        this.props.modifyOptions(key, RC.MIN_CONVERSION_BID);
        return;
      }
      if (value > RC.MAX_CONVERSION_BID) {
        console.log('Max bid reached');
        this.props.modifyOptions(key, RC.MAX_CONVERSION_BID);
        return;
      }
    }
  }

  modifyOptions(key, value) {
    if (
      key === RC.KEY_BUDGET_AMOUNT ||
      key === RC.KEY_DEFAULT_BID ||
      key === RC.KEY_QUERY_PARAMETERS ||
      key === RC.KEY_PREFIX ||
      key === 'campName' ||
      key === RC.KEY_CONVERSION_RATE
    ) {
      // It's an "input" element
      value = value.target.value;
    }
    if (key === RC.KEY_SITE) {
      //this.props.modifyOptions(RC.KEY_BRANDING_TEXT, this.getBrandingText(value));
    }
    this.props.modifyOptions(key, value);
    if (key === RC.KEY_DEVICE) {
      let devices = value;
      if (typeof devices === 'string') {
        devices = devices.split(',');
      }
      let newValue = [];
      devices.forEach(dev => {
        if (dev !== RC.DESKTOP) {
          // Only show OSs for non-desktop
          OS[dev].forEach(os => {
            if (newValue.indexOf(os) === -1) {
              newValue.push(os);
            }
          });
        }
      });
      this.props.modifyOptions(RC.KEY_OS, newValue);
      this.setState({
        osChoices: newValue
      });
    }
    if (key === RC.KEY_COUNTRY || key === RC.KEY_DEVICE || key === RC.KEY_OS || key === RC.KEY_SITE) {
      // Update bid rows
      this.modifyBidsRows();
    }
    if (key === RC.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
  }

  modifyBidsRows() {
    if (
      !this.props.state.options[RC.KEY_COUNTRY] ||
      !this.props.state.options[RC.KEY_DEVICE] ||
      (this.props.state.options[RC.KEY_DEVICE].indexOf(RC.DESKTOP) === -1 && !this.props.state.options[RC.KEY_OS]) ||
      !this.props.state.options[RC.KEY_SITE]
    ) {
      return;
    }
    // for some reason some of the values are arrays, some are strings
    //* in Select input - value type is string, when select all - array */
    let geos = this.props.state.options[RC.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    let devices = this.props.state.options[RC.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }
    let oss = this.props.state.options[RC.KEY_OS];
    if (typeof oss === 'string') {
      oss = oss.split(',');
    }
    let sites = this.props.state.options[RC.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    let newBids = [];
    let oldBids = this.props.state.options[RC.KEY_BIDS];
    geos.forEach(geo => {
      sites.forEach(site => {
        devices.forEach(dev => {
          if (dev === RC.DESKTOP) {
            // special handling of DESKTOP, no OSs here
            newBids.push({
              country: geo,
              device: dev,
              site: site,
              fullSite: this.getFullSite(site),
              branding_text: this.getBrandingText(site),
              bid: this.props.state.options[RC.KEY_DEFAULT_BID]
            });
          } else {
            oss.forEach(os => {
              if (OS[dev].indexOf(os) === -1) {
                // Skip OS not compatible with the device
                return;
              }
              newBids.push({
                country: geo,
                device: dev,
                os: os,
                site: site,
                fullSite: this.getFullSite(site),
                branding_text: this.getBrandingText(site),
                bid: this.props.state.options[RC.KEY_DEFAULT_BID]
              });
            });
          }
        });
      });
    });
    newBids = newBids.map(newBid => {
      const old = oldBids.find(
        el =>
          el.country === newBid.country && el.device === newBid.device && el.os === newBid.os && el.site === newBid.site
      );
      if (old) {
        newBid.bid = old.bid;
      }
      return newBid;
    });
    this.props.modifyOptions(RC.KEY_BIDS, newBids);
  }

  modifyBid(idx, e) {
    const bid = e.target.value;
    console.log('Modifying bid ', bid);
    let bids = this.props.state.options[RC.KEY_BIDS];
    bids[idx].bid = bid;
    this.props.modifyOptions(RC.KEY_BIDS, bids);
    let warningBids = bids.filter(el => {
      return el.bid > RC.MAX_WARN_BID;
    });
    this.props.modifyOptions('warnings', warningBids);
  }

  validateBid(idx) {
    let bids = this.props.state.options[RC.KEY_BIDS];
    const bid = bids[idx].bid;
    if (bid < RC.MIN_BID) {
      bids[idx].bid = RC.MIN_BID;
      this.props.modifyOptions(RC.KEY_BIDS, bids);
    } else if (bid > RC.MAX_BID) {
      bids[idx].bid = RC.MAX_BID;
      this.props.modifyOptions(RC.KEY_BIDS, bids);
    }
  }

  setDefaultBid(val) {
    let bids = this.props.state.options[RC.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < RC.MIN_BID) {
        b.bid = RC.MIN_BID;
      } else if (val > RC.MAX_BID) {
        b.bid = RC.MAX_BID;
      }
    });
    this.props.modifyOptions(RC.KEY_BIDS, bids);
  }

  onAddImages(groupIdx, e) {
    const images = Array.from(e.target.files);
    const errs = [];
    const warnings = [];

    const formData = new FormData();

    let basicValidatedImages = [];
    let hashes = [];
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        images.forEach((file, i) => {
          const filename_truncated = truncateFileName(file.name);

          if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], groupIdx, 'RC', warnings, errs)) {
            return;
          }

          formData.append(i, file);
          basicValidatedImages.push(file);
        });

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(calculatedHashes => {
        hashes = calculatedHashes;
        if (displayWarnings(errs, warnings)) return;
        const options = {
          minWidth: RC.MIN_WIDTH,
          minHeight: RC.MIN_HEIGHT
        };
        return storeFiles(formData, options);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
          groups[groupIdx].images = groups[groupIdx].images.concat(filenames);
          groups[groupIdx].imageHashes = groups[groupIdx].imageHashes.concat(hashes);
          this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        console.log(err);
      });
  }

  onChangeImageText(groupIdx, imageIdx, e) {
    const text = e.target.value;
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    groups[groupIdx].texts[imageIdx] = text;
    groups[groupIdx].tooltips[imageIdx] = {
      text: `Chars left: ${RC.MAX_TEXT_CHARS - text.length}`,
      open: true
    };
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  onBlurImageText(groupIdx, imageIdx) {
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    groups[groupIdx].tooltips[imageIdx] = {
      open: false
    };
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  onPasteImageText(groupIdx, imageIdx, e) {
    const text = e.clipboardData.getData('Text');
    if (text.length > RC.MAX_TEXT_CHARS) {
      toast.error(`Pasted text too long: ${text.length} chars. Truncating.`);
    }
  }

  removeImage(groupIdx, imageIdx) {
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.splice(imageIdx, 1);
    groups[groupIdx].texts.splice(imageIdx, 1);
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(JSON.parse(JSON.stringify(groups[groupIdx])));
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.forEach((_, idx) => {
      groups[groupIdx].texts[idx] = groups[groupIdx].texts[imageIdx];
    });
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  async addCreatives(xs) {
    const groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS];
    const newGroups = xs.map(x => ({ images: [], imageHashes: [], texts: [], tooltips: x.tooltips || [] }));
    this.setState(
      { options: { ...this.props.state.options, [RC.KEY_CREATIVE_GROUPS]: [...groups, ...newGroups] } },
      async () => {
        for (let i = 0; i < xs.length; i++) {
          const x = xs[i];
          const textsAndTitles = (x.texts || []).concat(x.titles || []);

          for (const text of textsAndTitles)
            for (const img of x.images) {
              const blob = await fetch(`/api/getImg?img=${encodeURIComponent(img)}`).then(r => r.blob());
              blob.name = img.split('/').pop();

              newGroups[i].texts.push(text);

              this.modifyOptions(RC.KEY_CREATIVE_GROUPS, [...groups, ...newGroups]);
              this.onAddImages(groups.length + i, { target: { files: [blob] } });
            }
        }
      }
    );
  }

  addCreativeGroup() {
    let groups = this.props.state.options[RC.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      images: [],
      imageHashes: [],
      texts: [],
      tooltips: []
    });
    this.modifyOptions(RC.KEY_CREATIVE_GROUPS, groups);
  }

  upsertSnapshot() {
    this.props.upsertSnapshot();
  }

  startCreateCampaign() {
    if (this.props.task === TASKS.CREATE) {
      this.props.createCampaign();
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.duplicateCampaign();
    }
  }

  getCampaignsNumber() {
    return this.props.state.options[RC.KEY_BIDS].length * this.props.state.options[RC.KEY_CREATIVE_GROUPS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    let sites = this.props.state.options[RC.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (RC.SITE_ALLOWED_VERSIONS[s]) {
        return RC.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return RC.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    let res = parts.filter(el => el).join('-') + ' = ' + bid.bid;
    console.log(res);
    return res;
  }

  validateSetup() {
    // Check that we have campaigns to create
    if (!this.getCampaignsNumber()) {
      const err = 'There are no campaigns to create/duplicate';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (!this.props.state.options.widgetid) {
      const err = 'widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }
    // Check creative group validity
    const validationInfo = validateCreativeGroups(this.props.state.options[RC.KEY_CREATIVE_GROUPS]);
    if (validationInfo.isValid) {
      console.log('Creative groups are valid');
    } else {
      console.log('Creative groups are invalid', validationInfo.data);
      validationInfo.data.forEach(err => {
        toast.error(err);
      });
      return false;
    }

    if (this.props.programOptions && !this.props.state.options[GLOBAL.KEY_DESTINATION_ACCOUNT]) {
      const err = 'Destination account is empty';
      console.error(err);
      toast.error(err);
      return false;
    }

    return true;
  }

  submit() {
    if (!this.validateSetup()) {
      return;
    }
    console.log(this.props.state.options);
    let title = '';
    let text = null;
    if (this.props.state.options.warnings && this.props.state.options.warnings.length > 0) {
      const warningBids = this.props.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = 'Are you sure to do this?';
    }

    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  render() {
    return (
      <>
        <Prefix
          value={this.props.state.options[RC.KEY_PREFIX] || ''}
          onChange={value => this.modifyOptions(RC.KEY_PREFIX, value)}
          options={this.props.programOptions.prefix?.map(x => ({ label: x, value: x }))}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            closeOnSelect={false}
            multi
            onChange={value => this.modifyOptions(RC.KEY_COUNTRY, value)}
            options={this.props.countryList}
            removeSelected={true}
            defaultValue={[]}
            rtl={false}
            simpleValue
            value={this.props.state.options[RC.KEY_COUNTRY]}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            closeOnSelect={false}
            multi
            onChange={value => this.modifyOptions(RC.KEY_SITE, value)}
            options={this.props.siteList}
            removeSelected={true}
            rtl={false}
            simpleValue
            value={this.props.state.options[RC.KEY_SITE]}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            clearable={true}
            multi
            closeOnSelect={false}
            onChange={value => this.modifyOptions(RC.KEY_DEVICE, value)}
            options={DEVICES.map(el => {
              return { label: el, value: el };
            })}
            removeSelected={true}
            defaultValue={[]}
            rtl={false}
            simpleValue
            value={this.props.state.options[RC.KEY_DEVICE]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select OS
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            clearable={true}
            multi
            closeOnSelect={false}
            onChange={value => this.modifyOptions(RC.KEY_OS, value)}
            options={OS[RC.MOBILE].map(el => {
              return { label: el, value: el };
            })}
            removeSelected={true}
            defaultValue={[]}
            rtl={false}
            simpleValue
            value={this.props.state.options[RC.KEY_OS]}
          />
        </Grid>
        <WidgetSelector
          modify={(key, value) => this.modifyOptions(key, value)}
          articleList={this.props.articleList}
          options={this.props.state.options}
          allowedVersions={this.getAllowedWidgetVersions()}
          defaultVersion={RC.DEFAULT_VERSION}
          triggerSelectorUpdate={this.props.triggerSelectorUpdate}
          programOptions={this.props.programOptions}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Query parameters
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="text"
            name={RC.KEY_QUERY_PARAMETERS}
            value={this.props.state.options[RC.KEY_QUERY_PARAMETERS]}
            onChange={value => this.modifyOptions(RC.KEY_QUERY_PARAMETERS, value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign Type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            closeOnSelect={true}
            multi={false}
            onChange={value => this.modifyOptions(RC.KEY_CAMPAIGN_TYPE, value)}
            options={CAMPAIGN_TYPES.map(el => {
              return { label: el, value: el };
            })}
            removeSelected={false}
            rtl={false}
            simpleValue
            value={this.props.state.options[RC.KEY_CAMPAIGN_TYPE]}
          />
        </Grid>
        {this.props.state.options[RC.KEY_CAMPAIGN_TYPE] === 'Auto' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Conversion pixels
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                closeOnSelect={true}
                multi={false}
                onChange={value => this.modifyOptions(RC.KEY_CONVERSION, value)}
                options={this.state.conversions}
                removeSelected={false}
                rtl={false}
                simpleValue
                value={this.props.state.options[RC.KEY_CONVERSION]}
              />
            </Grid>
          </>
        ) : null}
        {this.props.state.options[RC.KEY_CAMPAIGN_TYPE] === 'Auto' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Typical conversion rate
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <input
                type="number"
                name={RC.KEY_CONVERSION_RATE}
                step={RC.BID_CONVERSION_STEP}
                value={this.props.state.options[RC.KEY_CONVERSION_RATE]}
                onChange={value => this.modifyOptions(RC.KEY_CONVERSION_RATE, value)}
                onBlur={() => this.validateOptions(RC.KEY_CONVERSION_RATE)}
              />
            </Grid>
          </>
        ) : null}
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Budget type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              closeOnSelect={true}
              multi={false}
              onChange={value => this.modifyOptions(RC.KEY_BUDGET, value)}
              options={this.spendingModelList}
              removeSelected={false}
              rtl={false}
              simpleValue
              value={this.props.state.options[RC.KEY_BUDGET]}
            />
          </Grid>
        </>
        {this.props.state.options[RC.KEY_BUDGET] !== 'unlimited' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Budget
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <input
                type="number"
                name={RC.KEY_BUDGET_AMOUNT}
                value={this.props.state.options[RC.KEY_BUDGET_AMOUNT]}
                onChange={value => this.modifyOptions(RC.KEY_BUDGET_AMOUNT, value)}
              />
            </Grid>
          </>
        ) : null}
        {this.props.state.options[RC.KEY_CAMPAIGN_TYPE] !== 'Auto' ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Bid strategy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                closeOnSelect={true}
                multi={false}
                onChange={value => this.modifyOptions(RC.KEY_BID_TYPE, value)}
                options={this.bidTypeList}
                removeSelected={false}
                rtl={false}
                simpleValue
                value={this.props.state.options[RC.KEY_BID_TYPE]}
              />
            </Grid>
          </>
        ) : null}
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaign end date
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePicker
              selected={
                this.props.state.options[RC.KEY_CAMPAIGN_STOP_TIME]
                  ? new Date(this.props.state.options[RC.KEY_CAMPAIGN_STOP_TIME])
                  : ''
              }
              onChange={value => this.modifyOptions(RC.KEY_CAMPAIGN_STOP_TIME, value)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              closeOnSelect={true}
              multi={false}
              onChange={value => this.modifyOptions(RC.KEY_STATUS, value)}
              options={STATUS.map(el => {
                return { label: el, value: el };
              })}
              removeSelected={false}
              rtl={false}
              simpleValue
              value={this.props.state.options[RC.KEY_STATUS]}
            />
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Typography gutterBottom variant={'subtitle2'}>
              Bids
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} align="center">
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Default bid budget
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="number"
              name={RC.KEY_DEFAULT_BID}
              step={RC.BID_STEP}
              value={this.props.state.options[RC.KEY_DEFAULT_BID]}
              onChange={value => this.modifyOptions(RC.KEY_DEFAULT_BID, value)}
              onBlur={() => this.validateOptions(RC.KEY_DEFAULT_BID)}
            />
          </Grid>
          <CampaignsNote
            value={this.props.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
            onChange={e => {
              this.props.modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
            }}
          />

          {this.props.state.options[RC.KEY_CAMPAIGN_TYPE] !== 'Auto' ? (
            <RevcontentBids
              bids={this.props.state.options[RC.KEY_BIDS]}
              modifyBid={(idx, e) => this.modifyBid(idx, e)}
              validateBid={idx => this.validateBid(idx)}
            />
          ) : null}

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Creative Groups:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} align="right"></Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button fullWidth variant="contained" color="primary" onClick={() => this.addCreativeGroup()}>
              Add group
            </Button>
          </Grid>
          <RevcontentCreativeGroupBuilder
            groups={this.props.state.options[RC.KEY_CREATIVE_GROUPS]}
            onAddImages={(idx, e) => this.onAddImages(idx, e)}
            onChangeImageText={(groupIdx, imageIdx, e) => this.onChangeImageText(groupIdx, imageIdx, e)}
            onBlurImageText={(groupIdx, imageIdx) => this.onBlurImageText(groupIdx, imageIdx)}
            onPasteImageText={(groupIdx, imageIdx, e) => this.onPasteImageText(groupIdx, imageIdx, e)}
            removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
            removeGroup={idx => this.removeGroup(idx)}
            duplicateGroup={idx => this.duplicateGroup(idx)}
            populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
          />
          <Grid item xs={12} sm={4} align="right"></Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
              {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} align="right">
            <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
              {this.getCreateButtonText()}
            </Button>
          </Grid>
        </>
      </>
    );
  }
}

export default RevcontentCreate;
