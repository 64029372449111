import React from 'react';
import Select from 'react-select';
import { FB } from './Constants';
import { Grid, Typography, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getMultiSelectValue } from './Shared';
import { isPurchaseConversion } from './FbUtils';

class FbCampaigns extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.domain !== prevProps.domain || this.props.bidStrategyType !== prevProps.bidStrategyType) {
      const uniqueDomains = [...new Set(this.props.campaignsList.map(el => el.site_code || el.domain))];
      uniqueDomains.forEach(domain => {
        this.props.campaignsList.forEach((el, idx) => {
          if (el.site_code === domain || el.domain === domain) {
            const conversionList = this.getConversionList(domain);
            this.setDefaultCc(el, idx, conversionList);
          }
        });
      });
    }
  }

  renderRowName(fields) {
    const parts = [fields.domain || fields.site_code, fields.platform];
    return parts.filter(el => el).join('-');
  }

  setDefaultCc(fields, idx, ccList) {
    if (isPurchaseConversion(fields.cc)) {
      // Ugly patch. This code initiates refresh of campaign note in case of Purchaise-optimized campaign duplication.
      // Idea behind:
      //  - cc is set from outside
      //  - note should be set in the same way, from parseFBSourceData, but this not easy to do, because code which generates note in handleOneCcSelect is tightly bounded to state
      //  - solution is to call handleCcSelect here when cc is provided, but note it not
      if (this.props.campaignsList[idx].campaignNote) {
        return;
      }
      this.props.handleCcSelect(idx, fields.cc);
      return;
    }
    if (ccList.length === 1) {
      this.props.handleCcSelect(idx, ccList[0].value);
      return;
    }
    let defaultCcBid = null;
    if (fields.platform === 'desktop' || fields.platform === 'd') {
      defaultCcBid = FB.DEFAULT_DESK_CC_BID;
    } else if (fields.platform === 'mobile' || fields.platform === 'm') {
      defaultCcBid = FB.DEFAULT_MOB_CC_BID;
    } else if (fields.platform === 'all' || fields.platform === 'a') {
      defaultCcBid = FB.DEFAULT_ALL_CC_BID;
    }
    let defaultList = ccList.map(cc => {
      let start = this.props.programOptions.isSwag
        ? cc.label.indexOf('0.')
        : !cc.label.includes('depc ')
        ? cc.label.indexOf('0.')
        : cc.label.includes('depc ')
        ? cc.label.indexOf('0.')
        : -1;
      if (start !== -1) {
        let bid = +cc.label.slice(start, start + 4);
        let diff = Math.abs(bid - defaultCcBid);
        return diff;
      } else {
        return FB.MAX_CC_BID;
      }
    });
    let min = Math.min(...defaultList);
    let minIdx = defaultList.indexOf(min);

    if (!this.props.campaignsList[idx].cc && min !== 2) {
      if (ccList[minIdx]) {
        this.props.handleCcSelect(idx, ccList[minIdx].value);
      } else {
        console.log('Could not set CC', ccList, minIdx, ccList[minIdx]);
      }
    }
  }

  getVal(options, xs) {
    if (typeof xs === 'string') {
      xs = xs.split(',');
    }
    return getMultiSelectValue(options, xs);
  }

  renderCampaignRow(fields, idx, conversionList) {
    return (
      <React.Fragment key={`conversionList-${idx}`}>
        <Grid direction={'row'} container item xs={6} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            {this.renderRowName(fields)}
          </Typography>
          <Tooltip title="Choose the 'Custom Conversion' (EPC value) you will be targeting" placement="right">
            <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
              ?
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Select
            isMulti
            onChange={(idx2, ccId) => this.props.handleCcSelect(idx, idx2, ccId)}
            options={conversionList}
            value={this.getVal(conversionList, this.props.campaignsList[idx].cc)}
          />
        </Grid>
        <Grid item xs={1} sm={2}>
          <IconButton
            color="primary"
            type="button"
            onClick={idx2 => this.props.handleRemoveCampaign(idx, idx2)}
            size="large"
          >
            <DeleteForeverIcon />
          </IconButton>
        </Grid>
        {this.props.campaignsList[idx].campaignNote
          ? this.props.campaignsList[idx].campaignNote.map((note, index) => this.renderInternalNote(idx, index))
          : null}
      </React.Fragment>
    );
  }

  getConversionList() {
    if (this.props.bidStrategyType === 'LOWEST_COST_WITH_MIN_ROAS') {
      if (this.props.programOptions.isSwag) {
        return [FB.CONVS.PURCHASE, FB.CONVS.PURCHASE_CAPI];
      } else {
        return [FB.CONVS.PURCHASE_CAPI];
      }
    }
    let conversionList = this.props.conversionList;
    conversionList = [...new Set(conversionList)];
    return conversionList;
  }

  renderDomainRow(domain, campaignList, index) {
    return (
      <React.Fragment key={'campaignByDomain' + index}>
        {campaignList.map((el, idx) => {
          if (el.site_code === domain || el.domain === domain) {
            const conversionList = this.getConversionList();
            return this.renderCampaignRow(el, idx, conversionList);
          } else {
            return null;
          }
        })}
      </React.Fragment>
    );
  }

  renderInternalNote(campaignIdx, noteIdx) {
    return (
      <React.Fragment key={{ noteIdx }}>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign Note
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            style={{ padding: '7px', marginBottom: '2px' }}
            value={this.props.campaignsList[campaignIdx].campaignNote[noteIdx]}
            maxLength={150}
            aria-label="minimum height"
            placeholder="Campaign Note"
            onChange={e => this.props.handleOneCampaignNote(campaignIdx, noteIdx, e.target.value)}
          />
        </Grid>
      </React.Fragment>
    );
  }

  render() {
    const uniqueDomains = [...new Set(this.props.campaignsList.map(el => el.site_code || el.domain))];
    return (
      <>
        <Grid container direction="row" alignItems="center">
          {uniqueDomains.length
            ? uniqueDomains.map((dom, idx) => {
                return this.renderDomainRow(dom, this.props.campaignsList, idx);
              })
            : null}
        </Grid>
      </>
    );
  }
}
export default FbCampaigns;
