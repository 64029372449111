import React, { Component } from 'react';
import { TASKS, PLATFORMS } from '../Constants';
import { Grid, Paper, Typography } from '@mui/material';
import { getB2BPrefixes, getArticleList } from '../Api';
import { getParsedUserOptions } from '../../utils/AuthService';
import FacebookUploadCreatives from '../facebook/FacebookUploadCreatives';
import FacebookReviewCreatives from '../facebook/FacebookReviewCreatives';
import { AntTab, AntTabs } from '../CreativeNav';
import Loader from '../Loader';
import styled from '@emotion/styled';

const StyledFbPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '99vw',
  fontSize: '0.80rem',
  fontFamily: 'Open Sans',
  fontWeight: '500'
}));

class FacebookCreativesPage extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      platform: '',
      isCreativesReviewer: false,
      programOptions: {},
      isLoading: true,
      spmReviewAccess: false,
      spmUploadAccess: false
    };

    this.state = JSON.parse(JSON.stringify(this.defaultState));
  }

  fetchOptions = async () => {
    await Promise.all([this.updateUserOptions(), this.loadAdditionalData()]);
  };

  async componentDidMount() {
    await this.fetchOptions();
    this.setState({ isLoading: false });
    if (this.state.spmUploadAccess) {
      this.setState({ platform: PLATFORMS.FACEBOOK_UPLOAD_CREATIVES });
    } else if (this.state.spmReviewAccess) {
      this.setState({ platform: PLATFORMS.FACEBOOK_REVIEW_CREATIVES });
    } else this.setState({ platform: '' });
  }

  async creativesAccess(options) {
    if (options.spm_upload_access || options.spm_review_access) {
      options.platforms = [];
      this.setState({ programOptions: options });
    }
    if (options.spm_upload_access) {
      this.setState({ spmUploadAccess: options.spm_upload_access });
    }
    if (options.spm_review_access) {
      this.setState({ spmReviewAccess: options.spm_review_access });
    }
    if (options.spm_reviewer_access) {
      this.setState({ isCreativesReviewer: options.spm_reviewer_access });
    }
  }

  async updateUserOptions() {
    const options = await getParsedUserOptions();

    await this.creativesAccess(options);
    this.setState({
      programOptions: options
    });

    const userOpts = options;
    let prefixes = [...userOpts.prefix];
    userOpts.prefix = userOpts.spm_prefix_for_facebook
      ? [userOpts.spm_prefix_for_facebook]
      : [userOpts.spm_prefix_default];
    userOpts.prefixList = prefixes;
    if (this.state.isCreativesReviewer) {
      userOpts.prefixList = await getB2BPrefixes();
    }
    userOpts.articleList = this.state.articleList;

    userOpts.reviewerUser = userOpts.email;
    Object.assign(this.defaultState, userOpts);
    this.setState(userOpts);
  }

  async loadAdditionalData() {
    const clv = await getArticleList();
    const wid = {};
    for (const i in clv) {
      const art = clv[i];
      wid[art.wid] = art;
    }
    //TODO turn on when OB will be enabled

    // const interests = await getObInterests();
    // const OBinterestsList = interests
    //   .map(el => ({
    //     label: el.name,
    //     value: el.id
    //   }))
    //   .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

    return new Promise(resolve => {
      this.setState(
        {
          // OBinterestsList,
          articleList: { clv, wid }
        },
        resolve
      );
    });
  }

  renderFacebookUploadCreatives() {
    return (
      <FacebookUploadCreatives
        task={TASKS.CREATE}
        platform={this.state.platform}
        programOptions={this.state.programOptions}
        articleList={this.state.articleList}
      />
    );
  }
  renderFacebookReviewCreatives() {
    return (
      <FacebookReviewCreatives
        isCreativesReviewer={this.state.isCreativesReviewer}
        articleList={this.state.articleList}
        programOptions={this.state.programOptions}
      />
    );
  }

  selectPlatform(newPlatform) {
    this.setState({
      platform: newPlatform
    });
  }

  handleChangeTab(e, value) {
    this.setState({ platform: value });
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div className={this.state.isLoading ? 'placeholder-wrapper isActive' : 'placeholder-wrapper'}>
            <Loader />
          </div>
        ) : (
          <>
            {this.state.spmUploadAccess || this.state.spmReviewAccess ? (
              <>
                {this.state.platforms && (
                  <Grid container direction={'row'} spacing={0} sx={{ backgroundColor: '#ffffff' }}>
                    <AntTabs
                      value={this.state.platform ? this.state.platform : false}
                      centered={true}
                      onChange={(e, value) => this.handleChangeTab(e, value)}
                      aria-label="ant example"
                    >
                      {this.state.spmUploadAccess ? (
                        <AntTab
                          label={'Upload'}
                          value={PLATFORMS.FACEBOOK_UPLOAD_CREATIVES}
                          onClick={() => this.selectPlatform(PLATFORMS.FACEBOOK_UPLOAD_CREATIVES)}
                        />
                      ) : null}
                      {this.state.spmReviewAccess ? (
                        <AntTab
                          label={'Review'}
                          value={PLATFORMS.FACEBOOK_REVIEW_CREATIVES}
                          onClick={() => this.selectPlatform(PLATFORMS.FACEBOOK_REVIEW_CREATIVES)}
                        />
                      ) : null}
                    </AntTabs>
                  </Grid>
                )}
                {this.state.spmUploadAccess && this.state.platform === PLATFORMS.FACEBOOK_UPLOAD_CREATIVES && (
                  <StyledFbPaper elevation={0} square variant="outlined">
                    <Grid container direction={'row'} spacing={1} alignItems="flex-end">
                      {this.renderFacebookUploadCreatives()}
                    </Grid>
                  </StyledFbPaper>
                )}
                {this.state.spmReviewAccess && this.state.platform === PLATFORMS.FACEBOOK_REVIEW_CREATIVES && (
                  <StyledFbPaper elevation={0} square variant="outlined">
                    {this.renderFacebookReviewCreatives()}
                  </StyledFbPaper>
                )}
              </>
            ) : (
              <Typography gutterBottom variant="subtitle1">
                You have no access to this page
              </Typography>
            )}
          </>
        )}
      </>
    );
  }
}

export default FacebookCreativesPage;
