import React, { Component } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import { PLATFORMS } from './Constants';

export const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  indicator: {
    backgroundColor: '#1890ff'
  }
});

export const AntTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 80,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover': {
    color: '#40a9ff',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&:focus': {
    color: '#40a9ff'
  }
}));

const StyledContainer = styled(Grid)({
  backgroundColor: '#ffffff'
});

class CreativeNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.platform
    };
  }

  selectPlatform(platform) {
    this.props.selectPlatform(platform);
  }

  handleChangeTab(e, value) {
    this.setState({ value: value });
  }

  componentDidUpdate(prevProps) {
    if (this.props.platform !== prevProps.platform) {
      this.setState({ value: this.props.platform });
    }
  }

  render() {
    return (
      <StyledContainer container direction="row" spacing={0}>
        <AntTabs
          value={this.state.value}
          onChange={(e, value) => this.handleChangeTab(e, value)}
          aria-label="ant example"
        >
          <AntTab label="Facebook" value={PLATFORMS.FACEBOOK} onClick={() => this.selectPlatform(PLATFORMS.FACEBOOK)} />
          {this.props.spmUploadAccess ? (
            <AntTab
              label="Upload"
              value={PLATFORMS.FACEBOOK_UPLOAD_CREATIVES}
              onClick={() => this.selectPlatform(PLATFORMS.FACEBOOK_UPLOAD_CREATIVES)}
            />
          ) : null}
          {this.props.spmReviewAccess ? (
            <AntTab
              label="Review"
              value={PLATFORMS.FACEBOOK_REVIEW_CREATIVES}
              onClick={() => this.selectPlatform(PLATFORMS.FACEBOOK_REVIEW_CREATIVES)}
            />
          ) : null}
        </AntTabs>
      </StyledContainer>
    );
  }
}

export default CreativeNav;
